import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = { class: "table table-hover" }
const _hoisted_5 = { style: {"width":"40px"} }
const _hoisted_6 = { style: {"width":"80px"} }
const _hoisted_7 = { style: {"width":"300px"} }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Attendance = _resolveComponent("Attendance")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "fw-bold mb-0" }, "Santri Attendance")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"d-flex align-items-center\" data-v-0a7addbe><span style=\"flex:1;\" data-v-0a7addbe></span><div class=\"b-hadir\" data-v-0a7addbe></div> <span data-v-0a7addbe>Hadir</span><div class=\"b-izin\" data-v-0a7addbe></div> <span data-v-0a7addbe>Izin</span><div class=\"b-sakit\" data-v-0a7addbe></div> <span data-v-0a7addbe>Sakit</span><div class=\"b-alpha\" data-v-0a7addbe></div> <span data-v-0a7addbe>Alpha</span><div class=\"b-tugas\" data-v-0a7addbe></div> <span data-v-0a7addbe>Tugas</span></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "No"),
              _createElementVNode("th", null, "Name"),
              _createElementVNode("th", null, "Status"),
              _createElementVNode("th", null, "Note")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attendances, (v, k) => {
              return (_openBlock(), _createElementBlock("tr", null, [
                _createElementVNode("td", _hoisted_5, _toDisplayString(k + 1), 1),
                _createElementVNode("td", null, _toDisplayString(v.student_name), 1),
                _createElementVNode("td", _hoisted_6, [
                  (_openBlock(), _createBlock(_component_Attendance, {
                    key: `std${v.student_id}`,
                    status: v.status,
                    "is-student": true,
                    onStatusChange: s => _ctx.onStatusChange(s, k)
                  }, null, 8, ["status", "onStatusChange"]))
                ]),
                _createElementVNode("td", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": ($event: any) => ((v.note) = $event),
                      class: "form-control"
                    }, null, 8, _hoisted_9), [
                      [_vModelText, v.note]
                    ])
                  ])
                ])
              ]))
            }), 256))
          ])
        ])
      ])
    ])
  ]))
}