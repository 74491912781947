import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/img/bc-horizontal-new.png'
import _imports_1 from '../../assets/img/daily.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = {
  key: 0,
  id: "print",
  class: "card-body"
}
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "d-flex flex-column text-center" }
const _hoisted_9 = { class: "fw-bold mb-0" }
const _hoisted_10 = { class: "table-bordered table mt-2" }
const _hoisted_11 = {
  colspan: "6",
  style: {"padding":"0 !important"}
}
const _hoisted_12 = { class: "table table-bordered mb-0 border-none" }
const _hoisted_13 = { colspan: "6" }
const _hoisted_14 = { style: {"white-space":"pre"} }
const _hoisted_15 = { colspan: "6" }
const _hoisted_16 = { style: {"white-space":"pre"} }
const _hoisted_17 = { colspan: "6" }
const _hoisted_18 = { style: {"white-space":"pre"} }
const _hoisted_19 = { class: "card-footer text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.classroomJournal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"flex":"1"} }, [
                      _createElementVNode("img", {
                        src: _imports_0,
                        height: "50",
                        alt: "navbar brand",
                        class: "navbar-brand"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "fw-bold mb-0" }, "CLASSROOM JOURNAL", -1)),
                      _createElementVNode("h4", _hoisted_9, "Academic Year " + _toDisplayString(_ctx.classroomJournal?.date.split('-')[0] ?? ''), 1)
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("div", {
                      style: {"flex":"1"},
                      class: "text-right"
                    }, [
                      _createElementVNode("img", {
                        src: _imports_1,
                        height: "50"
                      })
                    ], -1))
                  ]),
                  _createElementVNode("table", _hoisted_10, [
                    _createElementVNode("tr", null, [
                      _cache[5] || (_cache[5] = _createElementVNode("td", { class: "fw-bold" }, "Date ", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.toIndoDateName(_ctx.classroomJournal.date)), 1),
                      _cache[6] || (_cache[6] = _createElementVNode("td", { class: "fw-bold" }, "Lesson Hours", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.classroomJournal.lesson_hours), 1),
                      _cache[7] || (_cache[7] = _createElementVNode("td", { colspan: "2" }, null, -1))
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[8] || (_cache[8] = _createElementVNode("td", { class: "fw-bold" }, "Grade", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.classroomJournal.grade_name), 1),
                      _cache[9] || (_cache[9] = _createElementVNode("td", { class: "fw-bold" }, "Subject", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.classroomJournal.subject_name), 1),
                      _cache[10] || (_cache[10] = _createElementVNode("td", { class: "fw-bold" }, "Teacher", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.classroomJournal.teacher_name), 1)
                    ]),
                    _cache[18] || (_cache[18] = _createElementVNode("tr", null, [
                      _createElementVNode("td", {
                        colspan: "6",
                        class: "text-center fw-bold"
                      }, "Student Attendance")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_11, [
                        _createElementVNode("table", _hoisted_12, [
                          _cache[11] || (_cache[11] = _createElementVNode("tr", null, [
                            _createElementVNode("td", { class: "fw-bold" }, "Name"),
                            _createElementVNode("td", { class: "fw-bold" }, "Status"),
                            _createElementVNode("td", { class: "fw-bold" }, "Note")
                          ], -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classroomJournal.attendances, (v) => {
                            return (_openBlock(), _createElementBlock("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(v.student_name), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.attendanceStatus(v.status)), 1),
                              _createElementVNode("td", null, _toDisplayString(v.note), 1)
                            ]))
                          }), 256))
                        ])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_13, [
                        _cache[12] || (_cache[12] = _createElementVNode("span", { class: "fw-bold" }, "Materi Pembelajaran", -1)),
                        _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.classroomJournal.materi_pembelajaran), 1)
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_15, [
                        _cache[14] || (_cache[14] = _createElementVNode("span", { class: "fw-bold" }, "Kegiatan Pembelajaran", -1)),
                        _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.classroomJournal.kegiatan_pembelajaran), 1)
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_17, [
                        _cache[16] || (_cache[16] = _createElementVNode("span", { class: "fw-bold" }, "Catatan", -1)),
                        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.classroomJournal.catatan), 1)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali"),
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)))
              }, "Print")
            ])
          ])
        ])
      ])
    ])
  ]))
}