import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "col-sm-12 col-md-6" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "col-sm-12" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "d-flex align-items-end mb-3" }
const _hoisted_16 = { class: "d-flex flex-column align-items-end" }
const _hoisted_17 = { class: "d-flex" }
const _hoisted_18 = {
  key: 1,
  class: "my-2",
  style: {"width":"250px"}
}
const _hoisted_19 = { class: "table table-bordered" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "card-footer d-flex flex-row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "fw-bold" }, "Detail Kelas")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", null, "Kelas", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.ref_class.find(v => +v.id === +_ctx.ref_class_id)?.name ?? ''), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", null, "Nama Kelas", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.ref_class.find(v => +v.id === +_ctx.ref_class_id)?.name ?? '') + " - " + _toDisplayString(_ctx.name ?? ''), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", null, "Wali Kelas", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.ref_user.find(v => +v.id === +_ctx.user_id)?.name ?? ''), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _cache[8] || (_cache[8] = _createElementVNode("label", { class: "mb-0" }, "Siswa", -1)),
                      _cache[9] || (_cache[9] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
                      _createElementVNode("div", _hoisted_16, [
                        (!_ctx.addStudent)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              class: "btn btn-sm btn-success ml-2",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addStudent = true))
                            }, " Tambah Siswa "))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_17, [
                          (_ctx.addStudent)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "btn btn-sm btn-success ml-2",
                                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.doAddStudent && _ctx.doAddStudent(...args)))
                              }, " Tambahkan "))
                            : _createCommentVNode("", true),
                          (_ctx.addStudent)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                class: "btn btn-sm btn-danger ml-2",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addStudent = false))
                              }, " Batal "))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.addStudent)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              _createVNode(_component_Multiselect, {
                                modelValue: _ctx.selectedAddStudent,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedAddStudent) = $event)),
                                options: _ctx.refStudent,
                                searchable: true,
                                "can-clear": false,
                                "can-deselect": false
                              }, null, 8, ["modelValue", "options"])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("table", _hoisted_19, [
                      _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, "No"),
                          _createElementVNode("th", null, "Nama"),
                          _createElementVNode("th")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (v, k) => {
                          return (_openBlock(), _createElementBlock("tr", null, [
                            _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                            _createElementVNode("td", null, _toDisplayString(v.student.nama), 1),
                            _createElementVNode("td", null, [
                              _createElementVNode("button", {
                                class: "btn btn-danger btn-sm",
                                onClick: ($event: any) => (_ctx.removeSiswa(v.student.id))
                              }, _cache[10] || (_cache[10] = [
                                _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                              ]), 8, _hoisted_20)
                            ])
                          ]))
                        }), 256))
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}