import {User} from "@/utils/Models";
import Session from "@/utils/Session";

export interface IChatGroup {
    id: number,
    user_id_1: number,
    user_id_2: number,
    last_chat: string,
    last_sent: string,
    last_sender_id: number,
    last_chat_seen: 1 | 0,
    user_1_name: string,
    user_1_photo: string,
    user_2_name: string,
    user_2_photo: string,
    user_1: User,
    user_2: User,
    active?: boolean
}

export interface IChat {
    from_id: number,
    to_id: number,
    chat: string,
    sent: string,
    ts?: number,
    done?: boolean
}

export function getOpponent(c: IChatGroup) {
    const id = Session.getCurrentUser()!.id
    if (c.user_id_1 === id) {
        return c.user_2
    }
    return c.user_1
}