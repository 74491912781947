import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "row no-gutters",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "col-sm-3 col-md-4 left" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "d-flex flex-column",
  style: {"flex":"1"}
}
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { style: {"font-size":"12px","margin-left":"auto"} }
const _hoisted_8 = {
  key: 1,
  class: "mx-auto my-auto px-5 text-center"
}
const _hoisted_9 = {
  class: "col-sm-9 col-md-8 right",
  style: {"height":"100%"}
}
const _hoisted_10 = {
  key: 0,
  class: "right-header"
}
const _hoisted_11 = { class: "d-flex align-items-center py-2 px-3" }
const _hoisted_12 = { class: "avatar-sm mr-2" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 1,
  class: "right-header"
}
const _hoisted_15 = { class: "d-flex align-items-center py-2 px-3" }
const _hoisted_16 = { class: "avatar-sm mr-2" }
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  class: "chat-container",
  id: "chat-container"
}
const _hoisted_19 = {
  key: 2,
  class: "chat-maker p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatItem = _resolveComponent("ChatItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.chatGroups.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.chatGroups, (v) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["d-flex align-items-center py-2 px-3 cg-item", {active: v.active && !_ctx.chosenPerson}]),
              onClick: ($event: any) => (_ctx.setActive(v))
            }, [
              _createElementVNode("img", {
                src: _ctx.getPhoto(_ctx.getOpponent(v)),
                alt: "",
                class: "avatar-img rounded-circle mr-2",
                style: {"width":"50px","height":"50px"}
              }, null, 8, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("b", null, _toDisplayString(_ctx.getOpponent(v).name), 1),
                _createElementVNode("div", _hoisted_6, [
                  (v.last_sender_id === _ctx.user.id)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "fa fa-check mr-1",
                        style: _normalizeStyle({color: v.active ? 'white' : '#aaaaaa'})
                      }, null, 4))
                    : _createCommentVNode("", true),
                  (!v.last_chat_seen && v.last_sender_id !== _ctx.user.id)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: _normalizeClass([{'unread-bullet-white': v.active}, "unread-bullet"])
                      }, null, 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass(["last-chat", {'unread': !v.last_chat_seen && v.last_sender_id !== _ctx.user.id, 'unread-white': v.active}])
                  }, _toDisplayString(_ctx.ellipsize(v.last_chat)), 3)
                ]),
                _createElementVNode("span", _hoisted_7, _toDisplayString(v.last_sent), 1)
              ])
            ], 10, _hoisted_3))
          }), 256))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "mt-5 d-inline-block" }, "Click `Start New Chat` to start chatting now...", -1)
          ])))
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.chosenPerson)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("img", {
                  src: _ctx.getPhoto(_ctx.chosenPerson),
                  alt: "",
                  class: "avatar-img rounded-circle"
                }, null, 8, _hoisted_13)
              ]),
              _createElementVNode("b", null, _toDisplayString(_ctx.chosenPerson.name), 1)
            ])
          ]))
        : (_ctx.activeChatGroup)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("img", {
                    src: _ctx.getPhoto(_ctx.getOpponent(_ctx.activeChatGroup)),
                    alt: "",
                    class: "avatar-img rounded-circle"
                  }, null, 8, _hoisted_17)
                ]),
                _createElementVNode("b", null, _toDisplayString(_ctx.getOpponent(_ctx.activeChatGroup).name), 1)
              ])
            ]))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_18, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chats, (v, k) => {
          return (_openBlock(), _createBlock(_component_ChatItem, {
            chat: v,
            class: _normalizeClass({'mt-1': k === 0})
          }, null, 8, ["chat", "class"]))
        }), 256))
      ]),
      (_ctx.activeChatGroup || _ctx.chosenPerson)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control mr-3",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chat) = $event)),
              style: {"flex":"1"},
              onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["enter"])),
              placeholder: "Send message..."
            }, null, 544), [
              [_vModelText, _ctx.chat]
            ]),
            _createElementVNode("i", {
              class: "fa fa-paper-plane send mr-2",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)))
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}