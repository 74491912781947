import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-4" }
const _hoisted_2 = { class: "col-sm-12 col-md-6" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "row mt-4 d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"row mt-2\"><div class=\"col-sm-12\"><div class=\"d-flex align-items-center justify-center mb-8\"><img alt=\"profil\" src=\"https://dn0vai85ainxm.cloudfront.net/assets/pijar-sekolah-v3/images/il_profil_sekolah.svg\"><div class=\"text-center ml-4\"><h3 class=\"fw-bold\">SMP Bumi Cendekia</h3><p class=\"text-sm\">70015076</p></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[9] || (_cache[9] = _createElementVNode("label", null, "Nama Sekolah", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.school.name) = $event))
          }, null, 512), [
            [_vModelText, _ctx.school.name]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[10] || (_cache[10] = _createElementVNode("label", null, "NPSN", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.school.npsn) = $event))
          }, null, 512), [
            [_vModelText, _ctx.school.npsn]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[11] || (_cache[11] = _createElementVNode("label", null, "Lembaga", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.school.lembaga) = $event))
          }, null, 512), [
            [_vModelText, _ctx.school.lembaga]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[12] || (_cache[12] = _createElementVNode("label", null, "Status Pendidikan", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.school.status) = $event))
          }, null, 512), [
            [_vModelText, _ctx.school.status]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[13] || (_cache[13] = _createElementVNode("label", null, "Jenjang", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.school.jenjang) = $event))
          }, null, 512), [
            [_vModelText, _ctx.school.jenjang]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _cache[14] || (_cache[14] = _createElementVNode("label", null, "Alamat", -1)),
          _withDirectives(_createElementVNode("textarea", {
            class: "form-control",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.school.alamat) = $event)),
            rows: "4"
          }, null, 512), [
            [_vModelText, _ctx.school.alamat]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[15] || (_cache[15] = _createElementVNode("label", null, "Provinsi", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.school.provinsi) = $event))
          }, null, 512), [
            [_vModelText, _ctx.school.provinsi]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[16] || (_cache[16] = _createElementVNode("label", null, "Kota/Kabupaten", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.school.kota) = $event))
          }, null, 512), [
            [_vModelText, _ctx.school.kota]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("button", {
        class: "btn btn-success",
        onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
      }, " Simpan ")
    ])
  ], 64))
}