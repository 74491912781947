import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "table-responsive" }
const _hoisted_8 = { class: "table table-bordered" }
const _hoisted_9 = { class: "d-block" }
const _hoisted_10 = { class: "progress progress-sm" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "History Assessment / Assignment")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("table", _hoisted_8, [
                  _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "No"),
                      _createElementVNode("th", null, "Guru Pembuat"),
                      _createElementVNode("th", null, "Subject"),
                      _createElementVNode("th", null, "Judul Tugas"),
                      _createElementVNode("th", null, "Kelas"),
                      _createElementVNode("th", null, "Waktu"),
                      _createElementVNode("th", null, "Penilaian"),
                      _createElementVNode("th", null, "Aksi")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignments, (v, k) => {
                      return (_openBlock(), _createElementBlock("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                        _createElementVNode("td", null, _toDisplayString(v.teacher_name), 1),
                        _createElementVNode("td", null, [
                          _createTextVNode(_toDisplayString(v.subject_name), 1),
                          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(_toDisplayString(v.type), 1)
                        ]),
                        _createElementVNode("td", null, _toDisplayString(v.title), 1),
                        _createElementVNode("td", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.grades, (g) => {
                            return (_openBlock(), _createElementBlock("label", _hoisted_9, _toDisplayString(g.nama_kelas), 1))
                          }), 256))
                        ]),
                        _createElementVNode("td", null, [
                          _createTextVNode(" Mulai: " + _toDisplayString(_ctx.toIndoDateNameTime(v.completion_date)), 1),
                          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Akhir: " + _toDisplayString(_ctx.toIndoDateNameTime(v.collection_date)), 1)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["progress-bar", {'bg-success': v.done_count / v.total_count * 100 === 100, 'bg-warning': v.done_count / v.total_count * 100 < 100}]),
                              style: _normalizeStyle({width: `${v.done_count / v.total_count * 100}% !important`}),
                              role: "progressbar",
                              "aria-valuenow": "100",
                              "aria-valuemin": "0",
                              "aria-valuemax": "100"
                            }, null, 6)
                          ]),
                          _createTextVNode(" " + _toDisplayString(v.done_count) + " / " + _toDisplayString(v.total_count), 1)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("button", {
                            class: "btn btn-success btn-sm mt-1 mr-1",
                            onClick: ($event: any) => (_ctx.detail(v.id)),
                            style: {"width":"41px"}
                          }, _cache[2] || (_cache[2] = [
                            _createElementVNode("i", { class: "fa fa-eye" }, null, -1)
                          ]), 8, _hoisted_11),
                          _createElementVNode("button", {
                            class: "btn btn-warning btn-sm mt-1 mr-1",
                            onClick: ($event: any) => (_ctx.evaluation(v.id)),
                            style: {"width":"41px"}
                          }, _cache[3] || (_cache[3] = [
                            _createElementVNode("i", { class: "fa fa-graduation-cap" }, null, -1)
                          ]), 8, _hoisted_12)
                        ])
                      ]))
                    }), 256))
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}