<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="fw-bold">Tambah Subject</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Kode Subject <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="kode" placeholder="Masukkan Kode Subject" required>
                                        <span v-if="kodeErr" class="text-danger">{{ kodeErr }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Nama Subject <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="nama" placeholder="Masukkan Nama Subject" required>
                                        <span v-if="namaErr" class="text-danger">{{ namaErr }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Tipe Subject <b class="text-danger">*</b></label>
                                        <select class="form-control" v-model="tipe">
                                            <option value="Karakter">Karakter</option>
                                            <option value="Literasi">Literasi</option>
                                            <option value="Numerasi">Numerasi</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Deskripsi (opsional)</label>
                                        <textarea class="form-control" v-model="deskripsi" rows="4" placeholder="Masukkan Deskripsi"></textarea>
                                    </div>
                                    <div class="form-check">
                                        <label>Kategori Subject</label><br>
                                        <label class="form-radio-label">
                                            <input class="form-radio-input" type="radio" value="Sekolahan" checked="" v-model="bc_type">
                                            <span class="form-radio-sign">Sekolahan</span>
                                        </label>
                                        <label class="form-radio-label ml-3">
                                            <input class="form-radio-input" type="radio" value="Pondok" v-model="bc_type">
                                            <span class="form-radio-sign">Pondok</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row justify-content-end">
                            <button class="btn btn-danger mr-2" @click="cancel">Batal</button>
                            <button class="btn btn-success" @click="save">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import {useRouter} from "vue-router";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";

export default defineComponent({
    name: "CreateMataPelajaran",
    setup() {
        const router = useRouter()
        const kode = ref('')
        const nama = ref('')
        const tipe = ref('Karakter')
        const bc_type = ref('Sekolahan')
        const deskripsi = ref('')

        const kodeErr = ref('')
        const namaErr = ref('')

        const cancel = () => router.back()
        const save = async () => {
            kodeErr.value = ''
            namaErr.value = ''

            if (!kode.value) {
                kodeErr.value = '* Masukkan Kode Subject'
                return
            }
            if (!nama.value) {
                namaErr.value = '* Masukkan Nama Subject'
                return
            }

            const t = loading('Menyimpan data')
            const r = await Api.post('master/pelajaran/create', {
                kode: kode.value,
                nama: nama.value,
                tipe: tipe.value,
                deskripsi: deskripsi.value,
                bc_type: bc_type.value,
            })
            t.remove()

            if (r.success) {
                router.back()
                success('Berhasil menyimpan data')
            }
            else {
                error(r.message ?? 'Gagal')
            }
        }

        return { kode, nama, tipe, deskripsi, bc_type, kodeErr, namaErr, cancel, save }
    }
})
</script>

<style scoped>

</style>
