import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "profile-picture mb-1" }
const _hoisted_8 = { class: "avatar avatar-xl" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-12 col-md-6" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_15 = { class: "col-sm-12 col-md-6" }
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_18 = { class: "col-sm-12 col-md-6" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_21 = {
  key: 0,
  class: "col-sm-12"
}
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-sm-12 col-md-6" }
const _hoisted_24 = { class: "form-group" }
const _hoisted_25 = { class: "col-sm-12 col-md-6" }
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = { class: "col-sm-12 col-md-6" }
const _hoisted_28 = { class: "form-group" }
const _hoisted_29 = { class: "col-sm-12 col-md-6" }
const _hoisted_30 = { class: "form-group" }
const _hoisted_31 = { class: "col-sm-12 col-md-6" }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = { class: "col-sm-12 col-md-6" }
const _hoisted_34 = { class: "form-check" }
const _hoisted_35 = { class: "form-radio-label" }
const _hoisted_36 = { class: "form-radio-label ml-3" }
const _hoisted_37 = { class: "col-sm-12 col-md-6" }
const _hoisted_38 = { class: "form-group" }
const _hoisted_39 = { class: "col-sm-12 col-md-6" }
const _hoisted_40 = { class: "form-group" }
const _hoisted_41 = { class: "row" }
const _hoisted_42 = { class: "col-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("img", {
                    src: _ctx.photo_url ?? _ctx.getPhoto(_ctx.user),
                    alt: "...",
                    class: "avatar-img rounded-circle",
                    id: "photo-display"
                  }, null, 8, _hoisted_9)
                ]),
                _createElementVNode("input", {
                  type: "file",
                  ref: "photo_file",
                  id: "photo_file",
                  accept: "image/*",
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.image_change && _ctx.image_change(...args))),
                  style: {"display":"none"}
                }, null, 544),
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.choose_photo && _ctx.choose_photo(...args))),
                  class: "btn btn-round btn-sm btn-outline-success ml-2"
                }, "Pilih Photo")
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", null, "Nama", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      placeholder: "Masukkan Nama",
                      name: "name",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.request.name) = $event)),
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.request.name]
                    ]),
                    (_ctx.validation.name)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.validation.name), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _cache[16] || (_cache[16] = _createElementVNode("label", null, "Username", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      placeholder: "Masukkan Username",
                      name: "username",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.request.username) = $event)),
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.request.username]
                    ]),
                    (_ctx.validation.username)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.validation.username), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[17] || (_cache[17] = _createElementVNode("label", null, "Password", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      placeholder: "Masukkan Password",
                      name: "password",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.request.password) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.request.password]
                    ]),
                    (_ctx.validation.password)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.validation.password), 1))
                      : _createCommentVNode("", true),
                    _cache[18] || (_cache[18] = _createElementVNode("small", {
                      id: "emailHelp2",
                      class: "form-text text-muted"
                    }, "Isi password hanya jika ingin mengubahnya. Jika tidak ingin mengubahnya, biarkan kosong aja ya kak :)", -1))
                  ])
                ]),
                (+_ctx.user.role_id === _ctx.Role.Kepala || +_ctx.user.role_id === _ctx.Role.Guru || +_ctx.user.role_id === _ctx.Role.Musrif)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _cache[19] || (_cache[19] = _createElementVNode("label", null, "Email", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "email",
                              class: "form-control",
                              placeholder: "Masukkan Email",
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.request.email) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.request.email]
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _cache[20] || (_cache[20] = _createElementVNode("label", null, "NIP", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "email",
                              class: "form-control",
                              placeholder: "Masukkan NIP",
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.request.nip) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.request.nip]
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _cache[21] || (_cache[21] = _createElementVNode("label", null, "NIK", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "form-control",
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.request.nik) = $event)),
                              placeholder: "Masukkan NIK"
                            }, null, 512), [
                              [_vModelText, _ctx.request.nik]
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("div", _hoisted_30, [
                            _cache[22] || (_cache[22] = _createElementVNode("label", null, "Tempat Lahir", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "form-control",
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.request.birth_place) = $event)),
                              placeholder: "Masukkan Tempat Lahir",
                              required: ""
                            }, null, 512), [
                              [_vModelText, _ctx.request.birth_place]
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _cache[23] || (_cache[23] = _createElementVNode("label", null, "Tanggal Lahir", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "date",
                              class: "form-control",
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.request.birth_date) = $event)),
                              placeholder: "Masukkan Tanggal Lahir",
                              required: ""
                            }, null, 512), [
                              [_vModelText, _ctx.request.birth_date]
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_33, [
                          _createElementVNode("div", _hoisted_34, [
                            _cache[26] || (_cache[26] = _createElementVNode("label", null, "Jenis Kelamin", -1)),
                            _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("label", _hoisted_35, [
                              _withDirectives(_createElementVNode("input", {
                                class: "form-radio-input",
                                type: "radio",
                                value: "L",
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.request.gender) = $event))
                              }, null, 512), [
                                [_vModelRadio, _ctx.request.gender]
                              ]),
                              _cache[24] || (_cache[24] = _createElementVNode("span", { class: "form-radio-sign" }, "Laki-Laki", -1))
                            ]),
                            _createElementVNode("label", _hoisted_36, [
                              _withDirectives(_createElementVNode("input", {
                                class: "form-radio-input",
                                type: "radio",
                                value: "P",
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.request.gender) = $event))
                              }, null, 512), [
                                [_vModelRadio, _ctx.request.gender]
                              ]),
                              _cache[25] || (_cache[25] = _createElementVNode("span", { class: "form-radio-sign" }, "Perempuan", -1))
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("div", _hoisted_38, [
                            _cache[28] || (_cache[28] = _createElementVNode("label", null, "Alamat", -1)),
                            _withDirectives(_createElementVNode("textarea", {
                              class: "form-control",
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.request.address) = $event)),
                              rows: "4",
                              placeholder: "Masukkan Alamat Lengkap"
                            }, null, 512), [
                              [_vModelText, _ctx.request.address]
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _cache[29] || (_cache[29] = _createElementVNode("label", null, "Nomor Telepon", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "form-control",
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.request.phone) = $event)),
                              placeholder: "Masukkan Nomor Telepon",
                              required: ""
                            }, null, 512), [
                              [_vModelText, _ctx.request.phone]
                            ])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-success",
                    style: {"margin-top":"36px"},
                    onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
                  }, _cache[30] || (_cache[30] = [
                    _createElementVNode("i", { class: "fas fa-save" }, null, -1),
                    _createTextVNode(" Simpan ")
                  ]))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}