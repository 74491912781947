<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Syllabus / Lesson Plan</div>
                                <div class="card-tools">
                                    <button @click="add" class="b-time btn btn-success btn-round mr-2">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Guru Pembuat</th>
                                    <th>Subject</th>
                                    <th>Kelas</th>
                                    <th>Semester</th>
                                    <th>Lampiran</th>
                                    <th>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v, k) in syllabus">
                                    <td>{{ k + 1 }}</td>
                                    <td>{{ v.teacher_name }}</td>
                                    <td>{{ v.subject_name }}</td>
                                    <td><label v-for="g in v.grades" class="d-block">{{ g.nama_kelas }}</label></td>
                                    <td>{{ v.semester_name }}</td>
                                    <td><a :href="v.attachment?.url" target="_blank">{{ v.attachment?.name }}</a></td>
                                    <td>
                                        <button class="btn btn-warning btn-sm mr-1" @click="edit(v.id)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                        <button class="btn btn-success btn-sm mr-1" @click="detail(v.id)">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                        <button class="btn btn-danger btn-sm" @click="destroy(v.id)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {ISyllabus} from "@/utils/interface/Syllabus";
import Api from "@/utils/Api";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "Syllabus",
    setup() {
        const router = useRouter()
        const syllabus = ref<ISyllabus[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('syllabus')
            t.remove()

            if (r.success) {
                syllabus.value = r.data as ISyllabus[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.get(`syllabus/delete/${id}`)
                t.remove()

                if (r.success) {
                    syllabus.value = r.data as ISyllabus[]
                    success('Berhasil menghapus data')
                }
                else {
                    error(r.message ?? 'gagal')
                }
            }
        }

        return {
            syllabus,
            add: () => router.push('/academic/syllabus/add'),
            edit: (id: number) => router.push(`/academic/syllabus/edit/${id}`),
            detail: (id: number) => router.push(`/academic/syllabus/${id}`),
            destroy
        }
    }
})
</script>

<style scoped>

</style>