<template>
    <div class="card timeline-item">
        <div class="card-header d-flex">
            <span style="flex: 1; color: #7997A1">{{toIndoDateNameTime(announcement.created_at)}}</span>
            <label v-if="dashboard" class="badge-mobile text-white">{{'Announcement'}}</label>
        </div>
        <div class="card-body">
            <h3 class="fw-bold mb-0" style="color: #135F6A">{{announcement.title}}</h3>
            <span style="color: #7997A1">{{announcement.message}}</span>
            <div v-if="announcement.action_detail" class="d-flex align-items-center mt-4">
                <div class="btn-bc-round" @click="detail(announcement.action_detail)">
                  DETAIL
                  <img src="@/assets/img/right.png" alt="">
                </div>
                <div style="flex: 1"></div>
                <a v-if="announcement.pdf" :href="announcement.pdf" target="_blank" class="action-ctr">
                    <img src="@/assets/img/export.png" alt="">
                </a>
            </div>
<!--            <div v-if="announcement.action_detail" class="ctr">-->
<!--                <div v-if="expanded" v-html="announcement.action_detail"/>-->
<!--                <div class="d-flex justify-content-center">-->
<!--                    <i @click="() => expanded = !expanded" class="toggle">{{expanded ? 'Hide Details' : 'Show Details'}}</i>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="d-flex justify-content-center align-items-center mt-2">-->
<!--                <a class="btn btn-success btn-sm mt-2" v-if="announcement.pdf" :href="announcement.pdf" target="_blank">-->
<!--                    Download Attachment-->
<!--                </a>-->
<!--            </div>-->
        </div>
    </div>
    <Modal
        :show="showModal"
        @close="showModal = false"
        title="Message Detail"
    >
        <template #body>
            <div v-html="modalData"></div>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {IAnnouncement} from "@/utils/interface/Announcement";
import {toIndoDateNameTime} from "@/utils/Helpers";
import Modal from "@/components/partials/Modal.vue";

export default defineComponent({
    name: "MobileAnnouncementItemNew",
    components: {Modal},
    props: {
        announcement: Object as PropType<IAnnouncement>,
        dashboard: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const expanded = ref(false)
        const showModal = ref(false)
        const modalData = ref('')

        const detail = (detail: string) => {
            showModal.value = true
            modalData.value = detail
        }

        return {
            toIndoDateNameTime, expanded, showModal, modalData, detail
        }
    }
})
</script>

<style scoped>
.ctr {
    padding: 10px;
    background: #f6f6f6;
    margin-top: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.ctr-toggle {
    padding: 10px;
    background: #f6f6f6;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.toggle {
    text-decoration: underline;
    color: #1572e8;
}
.timeline-item {
    margin: 20px auto !important;
    width: 100%;
    max-width: 750px;
    border-radius: 20px;
}
.bg {
    color: white;
    background: linear-gradient(to right, #f52020, #f18f8f);
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.badge-mobile {
    border-radius: 5px;
    background: #E1DA14;
    color: #135F6A !important;
    font-weight: bold;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
}
.btn-bc-round {
    display: inline-block;
    background: #135F6A;
    border-radius: 100px;
    padding: 8px 10px 8px 16px;
    color: #E1DA14;
    font-weight: bold;
}
.btn-bc-round img {
    width: 21px;
    margin-left: 10px;
}
.action-ctr {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: #0098A426;
    padding: 8px 10px;
}
.action-ctr img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}
</style>

<style>
.ctr div embed {
    display: none !important;
    height: 0 !important;
}
</style>