import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InboxMobileChatGroup = _resolveComponent("InboxMobileChatGroup")!
  const _component_InboxMobileChat = _resolveComponent("InboxMobileChat")!

  return (!_ctx.activeChatGroup && !_ctx.chosenPerson)
    ? (_openBlock(), _createBlock(_component_InboxMobileChatGroup, {
        key: 0,
        "chosen-person": _ctx.chosenPerson,
        load: _ctx.load,
        onSetActiveGroup: _ctx.setActiveGroup
      }, null, 8, ["chosen-person", "load", "onSetActiveGroup"]))
    : (_openBlock(), _createBlock(_component_InboxMobileChat, {
        key: 1,
        "load-person": _ctx.loadPerson,
        "chosen-person": _ctx.chosenPerson,
        "active-chat-group": _ctx.activeChatGroup,
        onChatGroupsChange: _ctx.chatGroupsChange,
        onBack: _ctx.back
      }, null, 8, ["load-person", "chosen-person", "active-chat-group", "onChatGroupsChange", "onBack"]))
}