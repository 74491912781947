import DailyJournal from "@/components/daily_journal/DailyJournal.vue";
import AddDailyJournal from "@/components/daily_journal/AddDailyJournal.vue";
import EditDailyJournal from "@/components/daily_journal/EditDailyJournal.vue";
import DetailDailyJournal from "@/components/daily_journal/DetailDailyJournal.vue";

export default [
  {
    path: '/daily-journal/:level',
    name: 'daily-journal',
    component: DailyJournal,
    meta: {
      title: 'Daily Journal'
    }
  },
  {
    path: '/daily-journal/:level/add',
    name: 'daily-journal/add',
    component: AddDailyJournal,
    meta: {
      title: 'Add Daily Journal'
    }
  },
  {
    path: '/daily-journal/:level/edit/:id',
    name: 'daily-journal/edit',
    component: EditDailyJournal,
    meta: {
      title: 'Update Daily Journal'
    }
  },
  {
    path: '/daily-journal/:level/:id',
    name: 'daily-journal/detail',
    component: DetailDailyJournal,
    meta: {
      title: 'Detail Daily Journal'
    }
  },
]