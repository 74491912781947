<script setup lang="ts">
import {computed, h, PropType, ref, useSlots, watch, ComputedRef} from "vue"

const props = defineProps({
	data: { type: Array as PropType<any[]>, required: true },
})

// type DataType = props.data.length ? typeof props.data[0] : null
type DataType = (typeof props.data extends (infer U)[] ? U : null)

const getTextsFromNode: (v: any) => string = (node: any) => {
	if (typeof node === 'string') {
		return node
	}
	if (Array.isArray(node)) {
		return node.map((v: any) => getTextsFromNode(v)).join(' ') ?? ''
	}
	return !node.children ? '' : typeof node.children === 'string' ? node.children : (node.children?.map((v: any) => getTextsFromNode(v))?.join(' ') ?? '')
}

const slots = useSlots()
const show = ref('10')
const search = ref('')
const page = ref(1)

//@ts-ignore
const rows = computed(() => props.data.map((v, k) => [...h('div', slots.tbody!({row: r(v), index: k})).children].map(v => getTextsFromNode(v.children)).join(' ')))
const searched: ComputedRef<DataType[]> = computed(() => {
	let satisfied = [] as DataType[]
	rows.value.forEach((v, k) => {
		if (!search.value || JSON.stringify(v).toLowerCase().includes(search.value.toLowerCase())) {
			satisfied.push(props.data[k])
		}
	})
	return satisfied
})
const totalPage = computed(() => Math.ceil(searched.value.length / +show.value))
const paginations = computed(() => {
	const total_page = totalPage.value, each_side = 1, curr_page = +page.value
	let start_page, end_page, return_me = []

	if (total_page <= (2 * each_side) + 5) {
		start_page = 1
		end_page = total_page
	} else if (curr_page <= each_side + 3) {
		start_page = 1
		end_page = (2 * each_side) + 3
	} else if (curr_page >= total_page - (each_side + 2)) {
		start_page = total_page - (2 * each_side) - 2
		end_page = total_page
	} else {
		start_page = curr_page - each_side
		end_page = curr_page + each_side
	}

	if (start_page > 1)
		return_me.push("1")
	if (start_page > 2)
		return_me.push("...")
	for (let x = start_page; x <= end_page; x++)
		return_me.push(x)
	if (end_page < total_page - 1)
		return_me.push("...")
	if (end_page < total_page)
		return_me.push(total_page)

	return return_me
})

watch(() => [search.value, show.value], () => page.value = 1)

const r = (d: DataType) => {
	return {data: d} as { data: DataType }
}

</script>

<template>
<div class="row">
	<div class="col-sm-12">
		<div class="row">
			<div class="col-sm-12 col-md-6">
				<div class="dataTables_length" id="basic-datatables_length">
					<label class="d-flex align-items-center">
						<span class="mr-1">Show</span>
						<select style="width: 68px" v-model="show" class="form-control form-control-sm">
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
						<span class="ml-1">entries</span>
					</label>
				</div>
			</div>
			<div class="col-sm-12 col-md-6">
				<div id="basic-datatables_filter" class="dataTables_filter d-flex flex-row-reverse">
					<label class="d-flex align-items-center">
						<span class="mr-1">Search:</span>
						<input type="search" v-model="search" class="form-control form-control-sm" placeholder="" aria-controls="basic-datatables">
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="table-responsive">
					<table class="table table-bordered mt-2">
						<thead>
						<tr>
							<slot name="thead"></slot>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(v, k) in searched">
							<slot
								v-if="k >= (page - 1) * +show && k < ((page - 1) * +show) + +show"
								name="tbody"
								v-bind:row="r(v)"
								v-bind:index="k"
							/>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12 col-md-5">
				<div class="dataTables_info" id="basic-datatables_info" role="status" aria-live="polite">
					Showing {{ !searched.length ? 0 : ((page - 1) * +show) + 1 }}
					to {{ page * +show > searched.length ? searched.length : page * +show }}
					of {{ search && searched.length != props.data.length ? searched.length : props.data.length }} entries
					{{ search && searched.length != props.data.length ? `(filtered from ${props.data.length} total entries)` : '' }}
				</div>
			</div>
			<div class="col-sm-12 col-md-7">
				<div class="d-flex flex-row-reverse">
					<ul class="pagination">
						<li :class="`paginate_button page-item previous ${page === 1 ? 'disabled' : ''}`" id="basic-datatables_previous">
							<span data-dt-idx="0" tabindex="0" class="page-link" @click="page = page === 1 ? 1 : page - 1">Previous</span>
						</li>

						<li v-for="v in paginations" :class="`paginate_button page-item ${page === v ? 'active' : ''} ${v === '...' ? 'disabled' : ''}`">
					<span :data-dt-idx="v"
						  tabindex="0"
						  class="page-link"
						  @click="page = v === '...' ? page : v"
					>
						{{ v }}
					</span>
						</li>
						<li :class="`paginate_button page-item next ${page === totalPage ? 'disabled' : ''}`" id="basic-datatables_next">
							<span data-dt-idx="8" tabindex="0" class="page-link" @click="page = page === totalPage ? page : page + 1">Next</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<style scoped>

</style>