import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-head-row mb-3 align-items-center flex-column" }
const _hoisted_8 = {
  key: 0,
  class: "card-title"
}
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "card-head-row" }
const _hoisted_12 = { class: "card-tools" }
const _hoisted_13 = { class: "card-body" }
const _hoisted_14 = { class: "table table-bordered" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DailyScheduleView = _resolveComponent("DailyScheduleView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, " Daily Schedule Bumi Cendekia Yogyakarta ", -1)),
                (_ctx.schedules.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.schedules[0].tahun_ajaran), 1))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.schedules.length)
                ? (_openBlock(), _createBlock(_component_DailyScheduleView, {
                    key: 0,
                    "daily-schedule-id": _ctx.schedules[0].id
                  }, null, 8, ["daily-schedule-id"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-title" }, "List Daily Schedule", -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
                    class: "b-time btn btn-success btn-round mr-2"
                  }, " Add ")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("table", _hoisted_14, [
                _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "No"),
                    _createElementVNode("th", null, "Tahun Ajaran"),
                    _createElementVNode("th", null, "Aksi")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schedules, (v, k) => {
                    return (_openBlock(), _createElementBlock("tr", null, [
                      _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                      _createElementVNode("td", null, _toDisplayString(v.tahun_ajaran), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("button", {
                          class: "btn btn-warning btn-sm mr-1",
                          onClick: ($event: any) => (_ctx.edit(v.id))
                        }, _cache[3] || (_cache[3] = [
                          _createElementVNode("i", { class: "fa fa-pencil" }, null, -1)
                        ]), 8, _hoisted_15),
                        _createElementVNode("button", {
                          class: "btn btn-success btn-sm mr-1",
                          onClick: ($event: any) => (_ctx.detail(v.id))
                        }, _cache[4] || (_cache[4] = [
                          _createElementVNode("i", { class: "fa fa-eye" }, null, -1)
                        ]), 8, _hoisted_16),
                        _createElementVNode("button", {
                          class: "btn btn-danger btn-sm",
                          onClick: ($event: any) => (_ctx.destroy(v.id))
                        }, _cache[5] || (_cache[5] = [
                          _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                        ]), 8, _hoisted_17)
                      ])
                    ]))
                  }), 256))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}