import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav nav-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/my-assessment')}])
    }, [
      _createVNode(_component_router_link, { to: "/my-assessment" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fas fa-graduation-cap" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "My Assessment ", -1)
        ])),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/my-score')}])
    }, [
      _createVNode(_component_router_link, { to: "/my-score" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "fas fa-user-graduate" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "My Score ", -1)
        ])),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/st-calendar')}])
    }, [
      _createVNode(_component_router_link, { to: "/st-calendar" }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "fas fa-calendar" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "Academic Calendar ", -1)
        ])),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/announcements')}])
    }, [
      _createVNode(_component_router_link, { to: "/announcements" }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "fas fa-bullhorn" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "Announcement ", -1)
        ])),
        _: 1
      })
    ], 2)
  ]))
}