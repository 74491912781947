<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="fw-bold">Edit Kelas</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Kelas <b class="text-danger">*</b></label>
                                        <select class="form-control" v-model="ref_class_id">
                                            <option v-for="v in ref_class" :value="v.id">{{ v.name }}</option>
                                        </select>
                                        <span v-if="ref_class_idErr" class="text-danger">{{ ref_class_idErr }}</span>
                                    </div>
                                    <div class="form-group" v-if="ref_class_id">
                                        <label>Nama Kelas <b class="text-secondary">(Opsional)</b></label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">{{ ref_class.find(v => +v.id === +ref_class_id).name }} -</div>
                                            </div>
                                            <input type="text" class="form-control" v-model="name" placeholder="Masukkan Nama Kelas" required>
                                        </div>
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Nama Kelas <b class="text-secondary">(Opsional)</b></label>
                                        <input type="text" class="form-control" v-model="name" placeholder="Masukkan Nama Kelas" required>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Wali Kelas <b class="text-danger">*</b></label>
                                        <select class="form-control" v-model="user_id">
                                            <option v-for="v in ref_user" :value="v.id">{{ v.name }}</option>
                                        </select>
                                        <span v-if="user_idErr" class="text-danger">{{ user_idErr }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row justify-content-end">
                            <button class="btn btn-danger mr-2" @click="cancel">Batal</button>
                            <button class="btn btn-success" @click="save">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Api from "@/utils/Api";
import {Kelas} from "@/utils/Models";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "UpdateKelas",
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id

        const ref_class = ref([])
        const ref_user = ref([])
        const ref_class_id = ref('')
        const name = ref('')
        const user_id = ref('')

        const ref_class_idErr = ref('')
        const user_idErr = ref('')

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const rx = await Api.get(`master/kelas/${id}`)
            const r = await Api.get(`master/kelas/ref`)
            const r2 = await Api.get(`master/guru`)
            t.remove()

            if (r.success) {
                ref_class.value = r.data
            }
            else {
                error(r.message ?? 'gagal')
            }

            if (r2.success) {
                ref_user.value = r2.data
            }
            else {
                error(r.message ?? 'gagal')
            }

            if (rx.success) {
                const kelas = rx.data as Kelas
                ref_class_id.value = kelas.ref_class_id
                name.value = kelas.name
                user_id.value = kelas.user_id
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const cancel = () => router.back()
        const save = async () => {
            ref_class_idErr.value = ''
            user_idErr.value = ''

            if (!ref_class_id.value) {
                ref_class_idErr.value = '* Pilih Kelas'
                return
            }
            if (!user_id.value) {
                user_idErr.value = '* Pilih Wali Kelas'
                return
            }

            const t = loading('Menyimpan data')
            const r = await Api.put(`master/kelas/update/${id}`, {
                ref_class_id: ref_class_id.value,
                name: name.value,
                user_id: user_id.value
            })
            t.remove()

            if (r.success) {
                router.back()
                success('Berhasil menyimpan data')
            }
            else {
                error(r.message ?? 'Gagal')
            }
        }

        return { cancel, save, ref_class, ref_user, ref_class_id, ref_class_idErr, name, user_id, user_idErr }
    }
})
</script>

<style scoped>

</style>
