<template>
    <div class="card">
        <div class="card-header">
            <h4 class="fw-bold mb-0">II. Santri Attendance</h4>
        </div>
        <div class="card-body" style="background-color: #F9FBFD">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <label class="fw-bold" style="flex: 1">a. Absence</label>
                        <button class="btn btn-success btn-round btn-sm" @click="addAbsent">
                            Add
                        </button>
                    </div>
                    <div class="table-hover">
                        <table class="table table-hover">
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Grade</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v, k) in request!.student.absent">
                                <td style="width: 20px">{{ k + 1 }}</td>
                                <td style="width: 200px">
                                    <div class="form-group">
                                        <Multiselect
                                            v-model="v.grade_id"
                                            :options="refGrade"
                                            :searchable="true"
                                            :can-deselect="false"
                                            :can-clear="false"
                                            @change="() => v.person_id = 0"
                                        />
                                    </div>
                                </td>
                                <td style="width: 200px">
                                    <div class="form-group">
                                        <Multiselect
                                            v-model="v.person_id"
                                            :options="refStudent.filter(x => x.student_class.find(z => +z.trx_class?.id  === +v.grade_id))"
                                            :searchable="true"
                                            :can-deselect="false"
                                            :can-clear="false"
                                            @change="studentAbsentChange"
                                        />
                                    </div>
                                </td>
                                <td style="width: 80px">
                                    <Attendance :key="`att${v.person_id}`" :status="v.status" @statusChange="(s) => studentStatusChange(s, +k)"/>
                                </td>
                                <td style="width: 20px">
                                    <button class="btn btn-sm btn-danger" @click="removeAbsent(k)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-3">
                            <div class="form-group">
                                <label>Absent</label>
                                <input type="text" class="form-control" v-model="request!.student_absent">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <label class="fw-bold" style="flex: 1">b. Come Late</label>
                        <button class="btn btn-success btn-round btn-sm" @click="addComeLate">
                            Add
                        </button>
                    </div>
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>Grade</th>
                            <th>Name</th>
                            <th>Note</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v, k) in request!.student.come_late">
                            <td style="width: 20px">{{ k + 1 }}</td>
                            <td style="width: 200px">
                                <div class="form-group">
                                    <Multiselect
                                        v-model="v.grade_id"
                                        :options="refGrade"
                                        :searchable="true"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        @change="() => v.person_id = 0"
                                    />
                                </div>
                            </td>
                            <td style="width: 200px">
                                <div class="form-group">
                                    <Multiselect
                                        v-model="v.person_id"
                                        :options="refStudent.filter(x => x.student_class.find(z => +z.trx_class?.id === +v.grade_id))"
                                        :searchable="true"
                                        :can-deselect="false"
                                        :can-clear="false"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="form-group">
                                    <textarea class="form-control" v-model="v.note"></textarea>
                                </div>
                            </td>
                            <td style="width: 20px">
                                <button class="btn btn-sm btn-danger" @click="removeComeLate(+k)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <label class="fw-bold" style="flex: 1">b. Leave Early</label>
                        <button class="btn btn-success btn-round btn-sm" @click="addLeaveEarly">
                            Add
                        </button>
                    </div>
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>Grade</th>
                            <th>Name</th>
                            <th>Note</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v, k) in request!.student.leave_early">
                            <td style="width: 20px">{{ k + 1 }}</td>
                            <td style="width: 200px">
                                <div class="form-group">
                                    <Multiselect
                                        v-model="v.grade_id"
                                        :options="refGrade"
                                        :searchable="true"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        @change="() => v.person_id = 0"
                                    />
                                </div>
                            </td>
                            <td style="width: 200px">
                                <div class="form-group">
                                    <Multiselect
                                        v-model="v.person_id"
                                        :options="refStudent.filter(x => x.student_class.find(z => +z.trx_class?.id === +v.grade_id))"
                                        :searchable="true"
                                        :can-deselect="false"
                                        :can-clear="false"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="form-group">
                                    <textarea class="form-control" v-model="v.note"></textarea>
                                </div>
                            </td>
                            <td style="width: 20px">
                                <button class="btn btn-sm btn-danger" @click="removeLeaveEarly(+k)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import Attendance from "../partials/Attendance.vue";
import Multiselect from "@vueform/multiselect";
import DailyJournalRequest from "../../utils/interface/DailyJournal";

export default defineComponent({
    name: "SantriAttendance",
    components: {
        Attendance,
        Multiselect
    },
    props: {
        request: Object as () => DailyJournalRequest,
        refGrade: {type: Array, required: true},
        refStudent: {type: Array, required: true},
    },
    setup(_) {
		onMounted(() => console.log(_.refStudent))
        return {
            studentStatusChange: (s: string, i: number) => {
                _.request!.student.absent[i].status = s;
            },
            addAbsent: () => {
                _.request!.student.absent.push({ id: new Date().getTime(), person_id: 0, grade_id: 0, status: 'a', sum: '' })
                setTimeout(() => _.request!.student_absent = _.request!.student.absent.filter(x => x.person_id).length, 100)
            },
            studentAbsentChange: () => {
                setTimeout(() => _.request!.student_absent = _.request!.student.absent.filter(x => x.person_id).length, 100)
            },
            addComeLate: () => {
                _.request!.student.come_late.push({ id: new Date().getTime(), person_id: 0, grade_id: 0, status: 'a', sum: '' })
            },
            addLeaveEarly: () => {
                _.request!.student.leave_early.push({ id: new Date().getTime(), person_id: 0, grade_id: 0, status: 'a', sum: '' })
            },
            removeAbsent: (i: number) => {
                _.request!.student.absent.splice(i, 1)
                setTimeout(() => _.request!.student_absent = _.request!.student.absent.filter(x => x.person_id).length, 100)
            },
            removeComeLate: (i: number) => {
                _.request!.student.come_late.splice(i, 1)
            },
            removeLeaveEarly: (i: number) => {
                _.request!.student.leave_early.splice(i, 1)
            }
        }
    }
})
</script>

<style scoped>

</style>
