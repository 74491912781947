import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-row items-center justify-content-center mb-1 mt-1",
  style: {"height":"40px"}
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  class: "fw-bold mx-4",
  style: {"font-size":"30px"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["title", "onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "fw-bold ml-2 mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar_view = _resolveComponent("calendar-view")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_calendar_view, {
      items: _ctx.agendas,
      "show-date": _ctx.showDate,
      class: "theme-default holiday-us-traditional holiday-us-official"
    }, {
      header: _withCtx(({ headerProps }) => [
        _createElementVNode("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { style: {"flex":"1"} }, null, -1)),
          _createElementVNode("span", {
            onClick: ($event: any) => (_ctx.setShowDate(headerProps.previousPeriod)),
            style: {"font-size":"18px","cursor":"pointer"}
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("i", { class: "fa fa-chevron-left" }, null, -1)
          ]), 8, _hoisted_2),
          _createElementVNode("span", _hoisted_3, _toDisplayString(headerProps.periodLabel), 1),
          _createElementVNode("span", {
            onClick: _withModifiers(($event: any) => (_ctx.setShowDate(headerProps.nextPeriod)), ["prevent"]),
            style: {"font-size":"18px","cursor":"pointer"}
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "fa fa-chevron-right" }, null, -1)
          ]), 8, _hoisted_4),
          _cache[5] || (_cache[5] = _createElementVNode("span", { style: {"flex":"1"} }, null, -1))
        ])
      ]),
      dayContent: _withCtx(({ day }) => [
        _createElementVNode("div", {
          class: _normalizeClass(["my-cv-day-number", {
                     'my-cv-today': _ctx.isToday(day),
                     'my-cv-day-number-hoverable': !!_ctx.onDateClick,
                     pointer: !!_ctx.onDateClick,
                 }]),
          onClick: ($event: any) => (_ctx.onDateClick?.(day))
        }, _toDisplayString(day.getDate()), 11, _hoisted_5)
      ]),
      item: _withCtx(({ value: item, top, weekStartDate }) => [
        _createElementVNode("div", {
          draggable: "false",
          class: _normalizeClass([
                     ...item.classes, 'cv-item', 'my-cv-item', `my-cv-item-${item.originalItem.customData.color}`,
                     {pointer: !!_ctx.onAgendaClick}
                 ]),
          title: item.title,
          onClick: ($event: any) => (_ctx.onAgendaClick?.(item.originalItem)),
          style: _normalizeStyle([{"height":"30px","padding-top":"6px","padding-left":"10px"}, `top: ${top.slice(0, top.indexOf(')')) + ` + 16px + ${item.itemRow * 12}px` + top.slice(top.indexOf(')'))};`])
        }, _toDisplayString(item.title), 15, _hoisted_6)
      ]),
      _: 1
    }, 8, ["items", "show-date"]),
    (_ctx.nearest)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mt-2 d-flex", {'py-2 px-3': _ctx.isMobile()}])
        }, [
          _cache[7] || (_cache[7] = _createElementVNode("span", { style: {"flex":"1"} }, null, -1)),
          _createElementVNode("span", {
            class: "a",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("i", null, "See Nearest Agenda", -1)
          ])),
          _cache[8] || (_cache[8] = _createElementVNode("span", { style: {"flex":"1"} }, null, -1))
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, {
      show: _ctx.showModal,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
      title: "Nearest Agenda"
    }, {
      body: _withCtx(() => [
        (!_ctx.nearest.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[9] || (_cache[9] = [
              _createElementVNode("i", null, "No Agenda", -1)
            ])))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.nearest, (v, k) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["card mb-0", {'mt-2': k > 0}])
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      class: _normalizeClass(`my-cv-item-${v.customData?.color}`),
                      style: {"width":"15px","height":"15px","border-radius":"20px"}
                    }, null, 2),
                    _createElementVNode("h4", _hoisted_10, _toDisplayString(v.title), 1)
                  ]),
                  _createElementVNode("b", null, _toDisplayString(_ctx.toIndoDateName(v.start_date)) + " - " + _toDisplayString(_ctx.toIndoDateName(v.end_date)), 1)
                ])
              ], 2))
            }), 256))
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}