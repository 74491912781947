import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav nav-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/health')}])
    }, [
      _createVNode(_component_router_link, { to: "/health" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fas fa-suitcase-medical" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "Health ", -1)
        ])),
        _: 1
      })
    ], 2)
  ]))
}