import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = {
  class: "d-flex align-items-center",
  style: {"width":"100%"}
}
const _hoisted_8 = {
  class: "mb-0 fw-bold",
  style: {"flex":"1"}
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-12" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = {
  class: "d-flex align-items-center ml-3",
  style: {"width":"100%"}
}
const _hoisted_16 = { style: {"font-size":"15px"} }
const _hoisted_17 = {
  key: 0,
  class: "form-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("div", { class: "card-head-row" }, [
                  _createElementVNode("div", { class: "card-title" }, "Academic Calendar")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Calendar, {
                  agendas: _ctx.agendas,
                  "on-date-click": _ctx.onDateClick,
                  "on-agenda-click": _ctx.onAgendaClick
                }, null, 8, ["agendas", "on-date-click", "on-agenda-click"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      show: _ctx.showModal,
      onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showModal = false))
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.modalTitle), 1),
          (_ctx.editedAgendaId)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-danger btn-sm",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.destroy()))
              }, "Hapus"))
            : _createCommentVNode("", true)
        ])
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[10] || (_cache[10] = _createElementVNode("label", null, "Nama Agenda", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newAgenda.title) = $event))
              }, null, 512), [
                [_vModelText, _ctx.newAgenda.title]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[11] || (_cache[11] = _createElementVNode("label", null, "Waktu Mulai", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "datetime-local",
                class: "form-control",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newAgenda.start_date) = $event))
              }, null, 512), [
                [_vModelText, _ctx.newAgenda.start_date]
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[12] || (_cache[12] = _createElementVNode("label", null, "Waktu Selesai", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "datetime-local",
                class: "form-control",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newAgenda.end_date) = $event))
              }, null, 512), [
                [_vModelText, _ctx.newAgenda.end_date]
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[13] || (_cache[13] = _createElementVNode("label", null, "Warna", -1)),
              _createVNode(_component_Multiselect, {
                modelValue: _ctx.newAgenda.customData.color,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newAgenda.customData.color) = $event)),
                options: _ctx.refColor,
                "can-clear": false,
                "can-deselect": false
              }, {
                option: _withCtx(({ option }) => [
                  _createElementVNode("span", {
                    style: {"width":"13px","height":"13px","background":"red","border-radius":"4px","margin-right":"4px"},
                    class: _normalizeClass(`opt-${option.value}`)
                  }, null, 2),
                  _createElementVNode("span", null, _toDisplayString(option.label), 1)
                ]),
                singlelabel: _withCtx(({ value }) => [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("span", {
                      style: {"width":"13px","height":"13px","background":"red","border-radius":"4px","margin-right":"4px"},
                      class: _normalizeClass(`opt-${value.value}`)
                    }, null, 2),
                    _createElementVNode("span", _hoisted_16, _toDisplayString(value.label), 1)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "options"])
            ]),
            (_ctx.editedAgendaId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", null, "Pembuat", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newAgenda.customData.creator_name) = $event)),
                    readonly: ""
                  }, null, 512), [
                    [_vModelText, _ctx.newAgenda.customData.creator_name]
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          class: "btn btn-warning btn-sm",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showModal = false))
        }, "Cancel"),
        _createElementVNode("button", {
          class: "btn btn-success btn-sm",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
        }, "Save")
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}