import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../assets/img/e_s.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center flex-column",
  style: {"height":"calc(100vh - 80px)"}
}
const _hoisted_6 = {
  key: 0,
  style: {"font-style":"italic"}
}
const _hoisted_7 = {
  key: 1,
  style: {"font-style":"italic"}
}
const _hoisted_8 = { class: "card assessment-item" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "d-flex align-items-center" }
const _hoisted_11 = { class: "avatar-sm" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "d-flex flex-column ml-2" }
const _hoisted_14 = { class: "fw-bold" }
const _hoisted_15 = {
  style: {"font-size":"11px"},
  class: "text-muted"
}
const _hoisted_16 = {
  class: "d-flex align-items-center text-success mt-2",
  style: {"flex":"1"}
}
const _hoisted_17 = { class: "ml-2" }
const _hoisted_18 = {
  class: "d-flex align-items-center text-success mt-2",
  style: {"flex":"1"}
}
const _hoisted_19 = { class: "ml-2" }
const _hoisted_20 = {
  class: "fw-bold mt-2",
  style: {"font-size":"16px"}
}
const _hoisted_21 = {
  class: "text-muted mt-2",
  style: {"font-size":"12px"}
}
const _hoisted_22 = { class: "d-flex align-items-center flex-column" }
const _hoisted_23 = { style: {"font-size":"34px","font-weight":"bold"} }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "mb-0" }
const _hoisted_26 = { class: "card mb-2" }
const _hoisted_27 = { class: "card-body d-flex align-items-center" }
const _hoisted_28 = { class: "avatar-sm" }
const _hoisted_29 = ["src"]
const _hoisted_30 = {
  style: {"flex":"1"},
  class: "ml-2"
}
const _hoisted_31 = { style: {"font-size":"18px","font-weight":"bold"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.assessment.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    src: _imports_0,
                    width: "200"
                  }, null, -1)),
                  (_ctx.done)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Tidak ada tugas :)"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, "Mendapatkan data tugas"))
                ]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.assessment, (v) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("img", {
                            class: "avatar-img rounded-circle",
                            style: {"width":"35px","height":"35px"},
                            src: _ctx.getPhoto(v.assignment.teacher_photo)
                          }, null, 8, _hoisted_12)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(v.assignment.teacher_name ?? '-'), 1),
                          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.toIndoDateNameTime(v.assignment.created_at)), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa fa-book" }, null, -1)),
                        _createElementVNode("span", _hoisted_17, _toDisplayString(v.assignment.subject_name) + " - " + _toDisplayString(v.assignment.type), 1)
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-user-graduate" }, null, -1)),
                        _createElementVNode("span", _hoisted_19, _toDisplayString(v.assignment.assignment_students.length) + " Siswa ", 1)
                      ]),
                      _cache[8] || (_cache[8] = _createElementVNode("div", {
                        style: {"height":"1px","background":"#eee"},
                        class: "my-2"
                      }, null, -1)),
                      _createElementVNode("div", _hoisted_20, _toDisplayString(v.assignment.title), 1),
                      _createElementVNode("div", _hoisted_21, _toDisplayString(v.assignment.description), 1),
                      _cache[9] || (_cache[9] = _createElementVNode("div", {
                        style: {"height":"1px","background":"#eee"},
                        class: "my-2"
                      }, null, -1)),
                      _createElementVNode("div", _hoisted_22, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "ml-1" }, "Nilai kamu:", -1)),
                        _createElementVNode("span", _hoisted_23, _toDisplayString(v.score), 1)
                      ]),
                      (v.note)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                            _cache[5] || (_cache[5] = _createElementVNode("div", {
                              style: {"height":"1px","background":"#eee"},
                              class: "my-2"
                            }, null, -1)),
                            _cache[6] || (_cache[6] = _createElementVNode("b", null, "Catatan dari guru:", -1)),
                            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("i", _hoisted_25, _toDisplayString(v.note), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 256))
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      show: _ctx.showModal,
      title: "Daftar Nilai",
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false))
    }, {
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataModal.assignment.assignment_students, (v) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("img", {
                  class: "avatar-img rounded-circle",
                  style: {"width":"35px","height":"35px"},
                  src: _ctx.getPhoto(v.student_photo)
                }, null, 8, _hoisted_29)
              ]),
              _createElementVNode("span", _hoisted_30, _toDisplayString(v.student_name), 1),
              _createElementVNode("span", _hoisted_31, _toDisplayString(v.score), 1)
            ])
          ]))
        }), 256))
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}