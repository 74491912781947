<template>
    <!-- Sidebar -->
    <div class="sidebar sidebar-style-2" v-if="user">
        <div class="sidebar-wrapper scrollbar scrollbar-inner">
            <div class="sidebar-content">
                <div class="user">
                    <div class="avatar-sm float-left mr-2">
                        <img :src="getPhoto(user)" alt="..." class="avatar-img rounded-circle">
                    </div>
                    <div class="info">
                        <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                        <span>
                            {{ user.name }}
                            <span class="user-level">{{ user.role_name }}</span>
                            <span class="caret"></span>
                        </span>
                        </a>
                        <div class="clearfix"></div>

                        <div class="collapse in" id="collapseExample">
                            <ul class="nav">
                                <li>
                                    <a href="#">
                                        <span class="link-collapse">Edit Profile</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <SidebarOrtu v-if="+role === Role.Ortu"/>
                <SidebarStudent v-if="+role === Role.Siswa"/>
                <SidebarDokter v-if="+role === Role.Dokter"/>
                <SidebarGuru v-else-if="+role === Role.Guru || +role === Role.Kepala || +role === Role.Musrif"/>
            </div>
        </div>
    </div>
    <!-- End Sidebar -->

</template>

<script lang="ts">
import {defineComponent} from "vue";
import Session from "@/utils/Session";
import {useRoute} from "vue-router";
import {getPhoto} from "@/utils/Helpers";
import Role from "@/utils/Role";
import SidebarOrtu from "@/components/partials/SidebarOrtu.vue"
import SidebarGuru from "@/components/partials/SidebarGuru.vue"
import SidebarStudent from "@/components/partials/SidebarStudent.vue"
import SidebarDokter from "@/components/partials/SidebarDokter.vue";

export default defineComponent({
    name: 'Sidebar',
    components: {
        SidebarDokter,
        SidebarOrtu,
        SidebarGuru,
        SidebarStudent,
    },
    setup() {
        const route = useRoute()
        const user = Session.getCurrentUser()
        const role = user?.role_id

        return { getPhoto, user, route, role, Role }
    }
})
</script>

<style>
.nav-collapse {
    margin-bottom: 0 !important;
}
</style>