import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "d-flex align-items-end" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "avatar-sm mr-2" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "d-block" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedTable = _resolveComponent("PaginatedTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-success mb-2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)))
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "fa fa-plus" }, null, -1),
          _createTextVNode(" Tambah")
        ]))
      ]),
      _createVNode(_component_PaginatedTable, {
        data: _ctx.siswa,
        class: "mt-3"
      }, {
        thead: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("th", null, "No", -1),
          _createElementVNode("th", null, "Nama", -1),
          _createElementVNode("th", null, "Username", -1),
          _createElementVNode("th", null, "NISN", -1),
          _createElementVNode("th", null, "NIS", -1),
          _createElementVNode("th", null, "Kelas", -1),
          _createElementVNode("th", null, "Aksi", -1)
        ])),
        tbody: _withCtx(({row, index}) => [
          _createElementVNode("td", null, _toDisplayString(index + 1), 1),
          _createElementVNode("td", null, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: _ctx.getPhoto(row.data),
                  alt: "...",
                  class: "avatar-img rounded-circle"
                }, null, 8, _hoisted_6)
              ]),
              _createTextVNode(" " + _toDisplayString(row.data.nama), 1)
            ])
          ]),
          _createElementVNode("td", null, _toDisplayString(row.data.username), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.nisn), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.nis), 1),
          _createElementVNode("td", null, [
            (!row.data.student_class.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(row.data.kelas), 1))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(row.data.student_class, (x) => {
                  return (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(x.trx_class?.nama_kelas ?? ''), 1))
                }), 256))
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("button", {
              class: "btn btn-sm btn-success mr-1",
              onClick: ($event: any) => (_ctx.detail(row.data.id))
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "fa fa-eye" }, null, -1)
            ]), 8, _hoisted_9),
            _createElementVNode("button", {
              class: "btn btn-sm btn-warning mr-1",
              onClick: ($event: any) => (_ctx.edit(row.data.id))
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("i", { class: "fa fa-pencil" }, null, -1)
            ]), 8, _hoisted_10)
          ])
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ]))
}