<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Add Master Report</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Angkatan <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.angkatan" :options="refAngkatan" :searchable="true" :can-clear="false" :can-deselect="false"/>
                                        <span class="text-danger" v-if="validation.angkatan">{{ validation.angkatan }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Kelas <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.trx_class_id" :options="refGrade" :searchable="true" :can-clear="false" :can-deselect="false"/>
                                        <span class="text-danger" v-if="validation.trx_class_id">{{ validation.trx_class_id }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Semester <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.semester" :options="refSemester" :can-clear="false" :can-deselect="false"/>
                                        <span class="text-danger" v-if="validation.semester">{{ validation.semester }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save">Simpan</button>
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import Multiselect from "@vueform/multiselect";
import {IOption} from "@/utils/interface/Assignment";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {Kelas, Pelajaran, Siswa, User} from "@/utils/Models";
import {useRouter} from "vue-router";

export default defineComponent({
    name: "AddReport",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const refGrade = ref<IOption[]>([])
        const refAngkatan = ref<IOption[]>([
            {value: new Date().getFullYear(), label: `${new Date().getFullYear()}`},
            {value: new Date().getFullYear() - 1, label: `${new Date().getFullYear() - 1}`},
            {value: new Date().getFullYear() - 2, label: `${new Date().getFullYear() - 2}`},
        ])
        const refSemester = ref<IOption[]>([
            {value: 1, label: 'Mid Semester Ganjil'},
            {value: 2, label: 'Semester Ganjil'},
            {value: 3, label: 'Mid Semester Genap'},
            {value: 4, label: 'Semester Genap'},
        ])
        const request = reactive<IMasterReport>({})
        const validation = reactive({
            angkatan: '',
            trx_class_id: '',
            semester: '',
        })

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            t.remove()

            if (r.success) {
                refGrade.value = (r.data.grade as Kelas[]).map(v => ({value: v.id, label: v.nama_kelas}))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const save = async () => {
            validation.angkatan = ''
            validation.trx_class_id = ''
            validation.semester = ''

            if (!request.angkatan) {
                validation.angkatan = 'Pilih angkatan dulu'
                return
            }
            if (!request.trx_class_id) {
                validation.trx_class_id = 'Pilih kelas dulu'
                return
            }
            if (!request.semester) {
                validation.semester = 'Pilih semester dulu'
                return
            }

            const t = loading('Menyimpan data')
            const r = await Api.post('master-report/create', {...request})
            t.remove()

            if (r.success) {
                success('Berhasil menambah data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        return {
            request, refAngkatan, refGrade, save, validation, refSemester,
            back: () => router.back(),
        }
    }
})
</script>

<style scoped>

</style>