<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="!assessment.length" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 80px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada tugas :)</span>
                        <span style="font-style: italic" v-else>Mendapatkan data tugas</span>
                    </div>
                    <div class="card assessment-item" v-else v-for="v in assessment">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div class="avatar-sm">
                                    <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(v.assignment.teacher_photo)">
                                </div>
                                <div class="d-flex flex-column ml-2">
                                    <span class="fw-bold">{{ v.assignment.teacher_name ?? '-' }}</span>
                                    <span style="font-size: 11px" class="text-muted">{{ toIndoDateNameTime(v.assignment.created_at) }}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center text-success mt-2" style="flex: 1">
                                <i class="fa fa-book"></i><span class="ml-2">{{ v.assignment.subject_name }} - {{ v.assignment.type }}</span>
                            </div>
                            <div class="d-flex align-items-center text-success mt-2" style="flex: 1">
                                <i class="fas fa-user-graduate"></i>
                                <span class="ml-2">
                                    {{ v.assignment.assignment_students.length }} Siswa
                                </span>
                            </div>
                            <div style="height: 1px; background: #eee" class="my-2"></div>
                            <div class="fw-bold mt-2" style="font-size: 16px">{{ v.assignment.title }}</div>
                            <div class="text-muted mt-2" style="font-size: 12px">{{ v.assignment.description }}</div>
                            <div style="height: 1px; background: #eee" class="my-2"></div>
                            <div class="d-flex align-items-center flex-column">
                                <span class="ml-1">Nilai kamu:</span>
                                <span style="font-size: 34px; font-weight: bold">{{ v.score }}</span>
                            </div>
                            <div v-if="v.note">
                                <div style="height: 1px; background: #eee" class="my-2"></div>
                                <b>Catatan dari guru:</b><br>
                                <i class="mb-0">{{ v.note }}</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal :show="showModal" title="Daftar Nilai" @close="showModal = false">
        <template #body>
            <div v-for="v in dataModal.assignment.assignment_students" class="card mb-2">
                <div class="card-body d-flex align-items-center">
                    <div class="avatar-sm">
                        <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(v.student_photo)">
                    </div>
                    <span style="flex: 1" class="ml-2">{{ v.student_name }}</span>
                    <span style="font-size: 18px; font-weight: bold">{{ v.score }}</span>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Session from "@/utils/Session";
import {IAssignmentStudent} from "@/utils/interface/Assignment";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";
import Modal from "@/components/partials/Modal.vue";

export default defineComponent({
    name: "MyScore",
    components: {Modal},
    setup() {
        const user = Session.getCurrentUser()!
        const assessment = ref<IAssignmentStudent[]>([])
        const done = ref(false)
        const showModal = ref(false)
        const dataModal = ref<IAssignmentStudent|null>(null)

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`assignment/student/score/${user.connection_id}`)
            t.remove()

            if (r.success) {
                assessment.value = r.data as IAssignmentStudent[]
            }
            else {
                error(r.message ?? 'gagal')
            }

            done.value = true
        })

        const show = (v: IAssignmentStudent) => {
            dataModal.value = v
            showModal.value = true
        }

        return {
            assessment, done, getPhoto, toIndoDateNameTime, show, dataModal, showModal
        }
    }
})
</script>

<style scoped>
.assessment-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>