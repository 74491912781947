import MyAssessment from "@/components/student/MyAssessment.vue";
import MyAssessmentDo from "@/components/student/MyAssessmentDo.vue";
import MyScore from "@/components/student/MyScore.vue";
import StudentAcademicCalendar from "@/components/student/StudentAcademicCalendar.vue";

export default [
  {
    path: '/my-assessment',
    name: 'my-assessment',
    component: MyAssessment,
    meta: {
      title: 'My Assessment'
    }
  },
  {
    path: '/my-assessment/:id',
    name: 'my-assessment-do',
    component: MyAssessmentDo,
    meta: {
      title: 'My Assessment'
    }
  },
  {
    path: '/my-score',
    name: 'my-score',
    component: MyScore,
    meta: {
      title: 'My Score'
    }
  },
  {
    path: '/st-calendar',
    name: 'st-calendar',
    component: StudentAcademicCalendar,
    meta: {
      title: 'Academic Calendar'
    }
  },
]