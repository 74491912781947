<template>
    <div class="card timeline-item">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="avatar-sm">
                    <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(assessment.assignment.teacher_photo)">
                </div>
                <div class="d-flex flex-column ml-2">
                    <span class="fw-bold">{{ assessment.assignment.teacher_name ?? '-' }}</span>
                    <span style="font-size: 11px" class="text-muted">{{ toIndoDateNameTime(assessment.created_at) }}</span>
                </div>
            </div>
            <div class="d-flex align-items-center text-success mt-2" style="flex: 1">
                <i class="fa fa-book"></i><span class="ml-2">{{ assessment.assignment.subject_name }} - {{ assessment.assignment.type }}</span>
            </div>
            <div class="d-flex align-items-center text-success" style="flex: 1; margin-top: 2px">
                <i class="fas fa-user-graduate"></i><span class="ml-2"></span> {{ assessment.student_name }}
            </div>
            <div class="d-flex align-items-center text-success" style="flex: 1; margin-top: 2px">
                <i class="fa fa-thumbtack"></i><span class="ml-2">{{ assessment.assignment.title }}</span>
            </div>
            <div style="height: 1px; background: #eee" class="my-2"></div>
            <div class="d-flex align-items-center flex-column">
                <span class="ml-1">Score:</span>
                <span style="font-size: 34px; font-weight: bold; line-height: 94%">{{ assessment.score }}</span>
            </div>
            <div v-if="assessment.note">
                <div style="height: 1px; background: #eee" class="my-2"></div>
                <b>Catatan dari guru:</b><br>
                <i class="mb-0">{{ assessment.note }}</i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {IAssignment} from "@/utils/interface/Assignment";
import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";

export default defineComponent({
    name: "ScoreItem",
    props: {
        assessment: Object as PropType<IAssignment>
    },
    setup() {
        return {
            getPhoto, toIndoDateNameTime
        }
    }
})
</script>

<style scoped>

</style>