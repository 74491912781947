import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, vModelRadio as _vModelRadio, unref as _unref, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "col-sm-12 col-md-6" }
const _hoisted_21 = { class: "form-check" }
const _hoisted_22 = { class: "form-radio-label" }
const _hoisted_23 = { class: "form-radio-label ml-3" }
const _hoisted_24 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_25 = { class: "form-group" }
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_28 = { class: "profile-picture mb-1" }
const _hoisted_29 = { class: "avatar avatar-xl" }
const _hoisted_30 = ["src"]
const _hoisted_31 = { class: "form-group" }
const _hoisted_32 = {
  key: 0,
  class: "text-danger"
}

import {onMounted, ref} from "vue";
import {Kelas, Siswa, StudentClass} from "@/utils/Models";
import {error as e, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {useRoute, useRouter} from "vue-router";
import {file2Base64, getPhoto} from "@/utils/Helpers";
import Multiselect from "@vueform/multiselect";

interface Error {
  nisn?: string | null,
  nama?: string | null,
  tempat_lahir?: string | null,
  tgl_lahir?: string | null,
  nis?: string | null,
  agama?: string | null,
  kelas?: string | null,
  jenis_kelamin?: string | null,
  password?: string | null,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateSiswa',
  setup(__props) {


const router = useRouter()
const route = useRoute()
const siswa = ref<Siswa>({
  id: 0,
  nisn: '',
  nis: '',
  nama: '',
  username: '',
  jenis_kelamin: '',
  kelas: '',
  nama_kelas: '',
  trx_class_id: 0,
  alamat: '',
  tempat_lahir: '',
  tgl_lahir: '',
  agama: 'Islam',
  no_telp: '',
  kategori: '',
  ortu_id: null,
  ortu2_id: null,
  photo: '',
  student_class: [],
})
const kelas = ref<Kelas[]>([])
const photo_file = ref(null)
const photo_url = ref<string|null>(null)
const photo_b64 = ref<string|null>(null)
const password = ref('')
const error = ref<Error>({})
const ref_kelas = ref<{}[]>([])
const selected_kelas = ref<number[]>([])

onMounted(async () => {
  const t = loading('Mendapatkan data')
  const res = await Api.get('master/kelas')
  t.remove()

  if (res.success) {
    kelas.value = res.data as Kelas[]
    ref_kelas.value = (res.data as Kelas[]).map(v => ({label: v.nama_kelas, value: v.id}))
  }
  else {
    e(res.message ?? 'Gagal medapatkan data')
  }
})

const cancel = () => router.back()
const save = async () => {
  const er: Error = {...error.value}
  Object.keys(er).forEach(function(key) {
    er[key as keyof Error] = null
  })
  error.value = er as typeof er

  if (!siswa.value) {
    return
  }
  if (!siswa.value.nama) {
    error.value.nama = 'Masukkan Nama'
    return
  }
  if (!siswa.value.nis) {
    error.value.nis = 'Masukkan NIS'
    return
  }
  if (!siswa.value.password) {
    error.value.password = 'Masukkan Password'
    return
  }

  const t = loading('Menyimpan data')
  const r = await Api.post(`master/siswa/create`, {
    nisn: siswa.value.nisn,
    nis: siswa.value.nis,
    nama: siswa.value.nama,
    jenis_kelamin: siswa.value.jenis_kelamin,
    alamat: siswa.value.alamat,
    tempat_lahir: siswa.value.tempat_lahir,
    tgl_lahir: siswa.value.tgl_lahir,
    agama: siswa.value.agama,
    password: password.value,
    photo_file: photo_b64.value,
    student_class: selected_kelas.value,
    created_by: 1,
  })
  t.remove()

  if (r.success) {
    router.back()
    success('Berhasil menyimpan data')
  }
  else {
    e(r.message ?? 'Gagal')
  }
}
const choose_photo = () => {
  let fileUpload = document.getElementById('photo_file')
  if (fileUpload != null) {
    fileUpload.click()
  }
}
const image_change = async (event: any) => {
  const file = event.target.files[0]
  if (file.size > 1000000) {
    e('Maksimal ukuran gambar 1 MB')
    return
  }

  photo_url.value = URL.createObjectURL(file)
  photo_b64.value = await file2Base64(file)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "fw-bold" }, "Tambah Siswa")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              (siswa.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _cache[11] || (_cache[11] = _createElementVNode("label", null, "NISN", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((siswa.value.nisn) = $event)),
                          placeholder: "Masukkan NISN"
                        }, null, 512), [
                          [_vModelText, siswa.value.nisn]
                        ]),
                        (error.value.nisn)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(error.value.nisn), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _cache[12] || (_cache[12] = _createElementVNode("label", null, [
                          _createTextVNode("Nama "),
                          _createElementVNode("b", { class: "text-danger" }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((siswa.value.nama) = $event)),
                          placeholder: "Masukkan Nama"
                        }, null, 512), [
                          [_vModelText, siswa.value.nama]
                        ]),
                        (error.value.nama)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(error.value.nama), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[13] || (_cache[13] = _createElementVNode("label", null, "Tempat Lahir", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((siswa.value.tempat_lahir) = $event)),
                          placeholder: "Masukkan Tempat Lahir"
                        }, null, 512), [
                          [_vModelText, siswa.value.tempat_lahir]
                        ]),
                        (error.value.tempat_lahir)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(error.value.tempat_lahir), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _cache[14] || (_cache[14] = _createElementVNode("label", null, "Tanggal Lahir", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "date",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((siswa.value.tgl_lahir) = $event)),
                          placeholder: "Masukkan Tanggal Lahir"
                        }, null, 512), [
                          [_vModelText, siswa.value.tgl_lahir]
                        ]),
                        (error.value.tgl_lahir)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(error.value.tgl_lahir), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _cache[15] || (_cache[15] = _createElementVNode("label", null, [
                          _createTextVNode("NIS "),
                          _createElementVNode("b", { class: "text-danger" }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((siswa.value.nis) = $event)),
                          placeholder: "Masukkan NIS"
                        }, null, 512), [
                          [_vModelText, siswa.value.nis]
                        ]),
                        (error.value.nis)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(error.value.nis), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _cache[17] || (_cache[17] = _createElementVNode("label", null, "Agama", -1)),
                        _withDirectives(_createElementVNode("select", {
                          class: "form-control",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((siswa.value.agama) = $event))
                        }, _cache[16] || (_cache[16] = [
                          _createStaticVNode("<option value=\"Islam\">Islam</option><option value=\"Kristen\">Kristen</option><option value=\"Katolik\">Katolik</option><option value=\"Budha\">Budha</option><option value=\"Hindu\">Hindu</option>", 5)
                        ]), 512), [
                          [_vModelSelect, siswa.value.agama]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _cache[20] || (_cache[20] = _createElementVNode("label", null, "Jenis Kelamin", -1)),
                        _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("label", _hoisted_22, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-radio-input",
                            type: "radio",
                            value: "L",
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((siswa.value.jenis_kelamin) = $event))
                          }, null, 512), [
                            [_vModelRadio, siswa.value.jenis_kelamin]
                          ]),
                          _cache[18] || (_cache[18] = _createElementVNode("span", { class: "form-radio-sign" }, "Laki-Laki", -1))
                        ]),
                        _createElementVNode("label", _hoisted_23, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-radio-input",
                            type: "radio",
                            value: "P",
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((siswa.value.jenis_kelamin) = $event))
                          }, null, 512), [
                            [_vModelRadio, siswa.value.jenis_kelamin]
                          ]),
                          _cache[19] || (_cache[19] = _createElementVNode("span", { class: "form-radio-sign" }, "Perempuan", -1))
                        ]),
                        (error.value.jenis_kelamin)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(error.value.jenis_kelamin), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _cache[22] || (_cache[22] = _createElementVNode("label", null, "Alamat (Opsional)", -1)),
                        _withDirectives(_createElementVNode("textarea", {
                          class: "form-control",
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((siswa.value.alamat) = $event)),
                          rows: "4",
                          placeholder: "Masukkan Alamat"
                        }, null, 512), [
                          [_vModelText, siswa.value.alamat]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _cache[23] || (_cache[23] = _createElementVNode("label", null, [
                          _createTextVNode("Kelas "),
                          _createElementVNode("b", { class: "text-danger" }, "*")
                        ], -1)),
                        _createVNode(_unref(Multiselect), {
                          modelValue: selected_kelas.value,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((selected_kelas).value = $event)),
                          options: ref_kelas.value,
                          searchable: true,
                          "create-option": false,
                          mode: "tags"
                        }, null, 8, ["modelValue", "options"]),
                        (error.value.kelas)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(error.value.kelas), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("img", {
                            src: photo_url.value ?? _unref(getPhoto)(siswa.value),
                            alt: "...",
                            class: "avatar-img rounded-circle",
                            id: "photo-display"
                          }, null, 8, _hoisted_30)
                        ]),
                        _createElementVNode("input", {
                          type: "file",
                          ref_key: "photo_file",
                          ref: photo_file,
                          id: "photo_file",
                          accept: "image/*",
                          onChange: image_change,
                          style: {"display":"none"}
                        }, null, 544),
                        _createElementVNode("button", {
                          type: "button",
                          onClick: choose_photo,
                          class: "btn btn-round btn-sm btn-outline-success ml-2"
                        }, "Pilih Photo")
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _cache[24] || (_cache[24] = _createElementVNode("label", null, [
                          _createTextVNode("Password "),
                          _createElementVNode("b", { class: "text-danger" }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "password",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((siswa.value.password) = $event)),
                          placeholder: "Masukkan Password",
                          autocomplete: "new-password"
                        }, null, 512), [
                          [_vModelText, siswa.value.password]
                        ]),
                        (error.value.password)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(error.value.password), 1))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", { class: "card-footer d-flex flex-row justify-content-end" }, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: cancel
              }, "Batal"),
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: save
              }, "Simpan")
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})