<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row d-flex">
                                <div class="card-title" style="flex: 1">Add Daily Journal {{level === 'smp' ? 'SMP' : level === 'sma' ? 'SMA' : 'Pesantren'}}</div>
                                <i>{{ toIndoDateName(request.date) }}</i>
                            </div>
                        </div>
                        <div class="card-header">
                            <div class="row">
                                <div class="col-6 col-md-3">
                                    <div class="form-group">
                                        <label>Day</label>
                                        <Multiselect v-model="request.day" :options="refDay" :searchable="true" :can-clear="false" :can-deselect="false"/>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group">
                                        <label>Date</label>
                                        <input type="date" class="form-control" v-model="request.date" @change="onDateChange">
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group">
                                        <label>PIC of The Day</label>
                                        <Multiselect v-model="request.pic_id" :options="refPic" :searchable="true" :can-clear="false" :can-deselect="false"/>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group">
                                        <label>Lesson Hours</label>
                                        <input type="text" class="form-control" v-model="request.lesson_hour">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TeacherAttendance
                        :ref-pic="refPic"
                        :request="request"
                        :ref-grade="refGrade"
                        :ref-subject="refSubject"
                        @statusChange="statusChange"
						@absenceTeachersChange="absenceTeachersChange"
                    />
                    <SantriAttendance :request="request" :ref-grade="refGrade" :ref-student="refStudent"/>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <CasesAndSolution :cases="request.cases" @change="casesChange"/>
                        </div>
                        <div class="col-12 col-md-6">
                            <Evaluation :evaluation="request.evaluation" @change="evaluationChange"/>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save">Simpan</button>
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, computed, watch} from "vue";
import {days, getDayName, toIndoDateName, toSqlDate} from "@/utils/Helpers";
import {Kelas, Pelajaran, Siswa, User} from "@/utils/Models";
import Api from "@/utils/Api";
import Session from "@/utils/Session";
import Attendance from "@/components/partials/Attendance.vue"
import Multiselect from '@vueform/multiselect'
import DailyJournalRequest, {Teacher} from "@/utils/interface/DailyJournal";
import TeacherAttendance from "@/components/daily_journal/TeacherAttendance.vue";
import SantriAttendance from "@/components/daily_journal/SantriAttendance.vue";
import CasesAndSolution from "@/components/daily_journal/CasesAndSolution.vue";
import Evaluation from "@/components/daily_journal/Evaluation.vue";
import {useRoute, useRouter} from "vue-router";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "AddDailyJournal",
    components: {
        Evaluation,
        CasesAndSolution,
        SantriAttendance,
        TeacherAttendance,
        Attendance,
        Multiselect
    },
    setup() {
        const router = useRouter()
		const route = useRoute()
		const level = computed(() => route.params.level)
        const request = ref<DailyJournalRequest>({
            day: getDayName(new Date()),
            date: toSqlDate(new Date()),
            pic_id: Session.getCurrentUser()!.id,
            lesson_hour: '',
            teacher: [],
            absent_teachers: [],
            student: {
                absent: [],
                come_late: [],
                leave_early: [],
            },
            cases: '',
            evaluation: '',
            created_by: Session.getCurrentUser()!.id,
            teacher_absent: 0,
            teacher_present: 0,
            student_absent: 0,
        })
        const refDay = days.map(v => ({value: v, label: v}))
        const refPic = ref<{}[]>([])
        const refStudent = ref<{}[]>([])
        const refGrade = ref<{}[]>([])
        const refSubject = ref<{}[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            t.remove()

            if (r.success) {
                refPic.value = (r.data.pic as User[]).map(v => ({value: v.id, label: v.name}))
                request.value = {
                    ...request.value,
                    teacher: (r.data.pic as User[]).map(v => ({
                        id: new Date().getTime(),
                        person_id: v.id,
                        name: v.name,
                        status: 'h',
                        grade_id: 0,
                        subject_id: 0,
                        time: ''
                    })),
                    teacher_present: (r.data.pic as User[]).length
                }
                refStudent.value = (r.data.siswa as Siswa[]).map(v => ({value: v.id, label: v.nama, student_class: v.student_class}))
                refGrade.value = (r.data.grade as Kelas[]).map(v => ({value: v.id, label: v.nama_kelas}))
                refSubject.value = (r.data.mapel as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const save = async () => {
            const t = loading('Menyimpan data')
            const r = await Api.post('daily-journal/create', {
				...request.value,
				teacher: request.value.absent_teachers,
				level: level.value,
			})
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

		const statusChange = () => {
			request.value.teacher_absent = request.value.teacher.filter(v => v.status !== 'h').length
			request.value.teacher_present = request.value.teacher.filter(v => v.status === 'h').length
		}
		const absenceTeachersChange = (teachers: Teacher[]) => {
			request.value.absent_teachers = teachers
		}

        return {
            request, refPic, refDay, refStudent, refGrade, refSubject, level,
            toIndoDateName,
            save,
            casesChange: (s: string) => request.value.cases = s,
            evaluationChange: (s: string) => request.value.evaluation = s,
            statusChange,
			absenceTeachersChange,
            onDateChange: (e: any) => request.value.day = getDayName(new Date(e.target.value)),
            back: () => router.back()
        }
    }
})
</script>

<style scoped>

</style>
