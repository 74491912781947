import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-head-row" }
const _hoisted_8 = { class: "card-tools" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["href"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedTable = _resolveComponent("PaginatedTable")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-title" }, "Announcement", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("button", {
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
                      class: "b-time btn btn-success btn-round mr-2"
                    }, " Add ")
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_PaginatedTable, {
                  data: _ctx.announcements,
                  class: "mt-3"
                }, {
                  thead: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("th", null, "No", -1),
                    _createElementVNode("th", null, "Created At", -1),
                    _createElementVNode("th", null, "Title", -1),
                    _createElementVNode("th", null, "Message", -1),
                    _createElementVNode("th", null, "Message Detail", -1),
                    _createElementVNode("th", null, "Kelas", -1),
                    _createElementVNode("th", null, "Attachment", -1),
                    _createElementVNode("th", null, "Aksi", -1)
                  ])),
                  tbody: _withCtx(({row, index}) => [
                    _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.toIndoDateNameTime(row.data.created_at)), 1),
                    _createElementVNode("td", null, _toDisplayString(row.data.title), 1),
                    _createElementVNode("td", null, _toDisplayString(row.data.message), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("button", {
                        class: "btn btn-success btn-sm",
                        onClick: ($event: any) => (_ctx.detail(row.data.action_detail))
                      }, " Show Detail ", 8, _hoisted_10)
                    ]),
                    _createElementVNode("td", null, _toDisplayString(row.data.announcement_grades.map(x => x.grade_name).join(', ')), 1),
                    _createElementVNode("td", null, [
                      (row.data.pdf)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            target: "_blank",
                            href: row.data.pdf,
                            class: "btn btn-success btn-sm"
                          }, _cache[4] || (_cache[4] = [
                            _createElementVNode("i", { class: "fa fa-download" }, null, -1)
                          ]), 8, _hoisted_11))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("button", {
                        class: "btn btn-danger btn-sm",
                        onClick: ($event: any) => (_ctx.destroy(row.data.id))
                      }, _cache[5] || (_cache[5] = [
                        _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                      ]), 8, _hoisted_12)
                    ])
                  ]),
                  _: 1
                }, 8, ["data"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      show: _ctx.showModal,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
      title: "Message Detail"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", { innerHTML: _ctx.modalData }, null, 8, _hoisted_13)
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}