<template>
    <div class="main-header" v-if="user">
        <!-- Logo Header -->
        <div class="logo-header" data-background-color="white" style="padding-left: 15px">

            <a href="/" class="logo">
                <img
                    v-if="!isAndroid"
                    src="../../assets/img/bc-horizontal-new.png"
                     height="50"
                     alt=""
                     class="navbar-brand">
                <img
                    v-else
                    src="../../assets/img/logo_smp_sma.png"
                     height="50"
                     alt=""
                     class="navbar-brand">
            </a>
            <button class="navbar-toggler sidenav-toggler ml-auto"
                    type="button"
                    data-toggle="collapse"
                    data-target="collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    @click="toggleSidebar"
            >
                <span class="navbar-toggler-icon">
                    <i class="icon-menu"></i>
                </span>
            </button>
            <button class="topbar-toggler more" @click="toggleBar"><i class="icon-options-vertical"></i></button>
            <div class="nav-toggle">
                <button class="btn btn-toggle toggle-sidebar">
                    <i class="icon-menu"></i>
                </button>
            </div>
        </div>
        <!-- End Logo Header -->

        <!-- Navbar Header -->
        <nav class="navbar navbar-header navbar-expand-lg" data-background-color="green2">

            <div class="container-fluid">
                <div class="collapse" id="search-nav">
                    <form class="navbar-left navbar-form nav-search mr-md-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button type="submit" class="btn btn-search pr-1">
                                    <i class="fa fa-search search-icon"></i>
                                </button>
                            </div>
                            <input type="text" placeholder="Search ..." class="form-control">
                        </div>
                    </form>
                </div>
                <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
                    <li class="nav-item toggle-nav-search hidden-caret">
                        <a class="nav-link" data-toggle="collapse" href="#search-nav" role="button" aria-expanded="false" aria-controls="search-nav">
                            <i class="fa fa-search"></i>
                        </a>
                    </li>
                    <li class="nav-item dropdown hidden-caret">
                        <a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
                            <div class="position-relative">
                                <div class="avatar-sm">
                                    <img :src="getPhoto(user)" alt="..." class="avatar-img rounded-circle">
                                </div>
                                <div v-if="adaChat" class="notif-badge notif-badge-abs"></div>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-user animated fadeIn">
                            <div class="dropdown-user-scroll scrollbar-outer">
                                <li>
                                    <div class="user-box">
                                        <div class="avatar-lg"><img :src="getPhoto(user)" alt="image profile" class="avatar-img rounded"></div>
                                        <div class="u-text">
                                            <h4>{{ user.name }}</h4>
                                            <p class="text-muted">{{ user.email || user.username }}</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="dropdown-divider"></div>
                                    <router-link class="dropdown-item" to="/profile">
                                        Edit Profile
                                    </router-link>
                                    <div class="dropdown-divider"></div>
                                    <router-link class="dropdown-item" to="/inbox">
                                        Inbox
                                        <div v-if="adaChat" class="notif-badge d-inline-block ml-1"></div>
                                    </router-link>
                                    <div v-if="!isAndroid" class="dropdown-divider"></div>
                                    <button v-if="!isAndroid" @click="logout" class="dropdown-item">Logout</button>
                                </li>
                            </div>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- End Navbar -->
    </div>

</template>

<script lang="ts">
import {computed, defineComponent, onBeforeUnmount, ref} from "vue";
import {useRouter} from "vue-router";
import Session from "@/utils/Session";
import {getPhoto} from '@/utils/Helpers'
import Api from "@/utils/Api";
import {useStore} from "vuex";

export default defineComponent({
    name: 'Header',
    props: {
        isAndroid: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const router = useRouter()
        const user = Session.getCurrentUser()
        const store = useStore()

        if (!user) {
            router.push('/login')
        }

        return {
            adaChat: computed(() => store.state.adaChat),
            user,
            getPhoto,
            logout: () => {
                Session.logout()
                router.push('/login')
            },
            toggleSidebar: () => {
                let root = document.getElementsByTagName('html')![0]!
                if (!root.classList.contains('nav_open')) {
                    root.classList.add('nav_open')
                }
                else {
                    root.classList.remove('nav_open')
                }
            },
            toggleBar: () => {
                let root = document.getElementsByTagName('html')![0]!
                if (!root.classList.contains('topbar_open')) {
                    root.classList.add('topbar_open')
                }
                else {
                    root.classList.remove('topbar_open')
                }
            },
        }
    }
})
</script>

<style scoped>
.notif-badge {
    width: 10px;
    height: 10px;
    border-radius: 8px;
    background: red;
}
.notif-badge-abs {
    position: absolute;
    top: 0;
    right: 0;
}
</style>