<script setup lang="ts">
import {useRoute, useRouter} from "vue-router";
// @ts-ignore
import UploadAdapter from "@/utils/UploadAdapter";
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {onMounted, reactive, ref} from "vue";
import {file2Base64, getPhoto} from "@/utils/Helpers";
import {IInformation} from "@/components/information/Information.vue";
import Session from "@/utils/Session";

const ckeditor = CKEditor.component
const router = useRouter()
const route = useRoute()
const title = ref('')
const editorData = ref('')
const validation = reactive({
  image: '',
  title: '',
  body: '',
})
const photo_file = ref(null)
const photo_url = ref<string|null>(null)
const photo_b64 = ref<string|null>(null)
const information = ref<IInformation|null>(null)
const user = Session.getCurrentUser()!

onMounted(async () => {
  const id = route.params.id
  if (id) {
    const t = loading('Mendapatkan data')
    const r = await Api.get(`information/${id}`)
    t.remove()
    if (r.success) {
      information.value = r.data as IInformation
      title.value = information.value!.title
      editorData.value = information.value!.body
    }
  }
})

function uploader(editor: any) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
    return new UploadAdapter( loader );
  }
}

const choose_photo = () => {
  let fileUpload = document.getElementById('photo_file')
  if (fileUpload != null) {
    fileUpload.click()
  }
}

const image_change = async (event: any) => {
  const file = event.target.files[0]
  if (file.size > 1000000) {
    error('Maksimal ukuran gambar 1 MB')
    return
  }

  photo_url.value = URL.createObjectURL(file)
  photo_b64.value = await file2Base64(file)
}

const validate = () => {
  validation.image = ''
  validation.title = ''
  validation.body = ''

  if (!information.value && !photo_url.value) {
    validation.image = 'Pilih gambar'
    return false
  }
  if (!title.value) {
    validation.title = 'Masukkan title'
    return false
  }
  if (!editorData.value) {
    validation.body = 'Masukkan body'
    return false
  }
  return true
}

const save = async () => {
  if (!validate()) {
    return
  }

  let data = new FormData()
  data.append('title', title.value)
  data.append('body', editorData.value)
  data.append('body', editorData.value)

  const t = loading('Menyimpan data')
  const url = information.value ? `information/edit/${information.value!.id}` : `information/create`
  const r = await Api.post(url, {
    title: title.value,
    body: editorData.value,
    image: photo_b64.value,
    created_by: user.id
  })
  t.remove()

  if (r.success) {
    success('Berhasil menyimpan data')
    router.back()
  }
  else {
    error(r.message ?? 'gagal')
  }
}

</script>

<template>
  <div class="content">
    <div class="page-inner">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-head-row">
                <div class="card-title">Add Announcement</div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="profile-picture mb-1">
                    <img v-if="photo_url" :src="photo_url" alt="..." class="img" id="photo-display">
                    <img v-else-if="information" :src="getPhoto(information!.image)" alt="..." class="img" id="photo-display">
                    <img v-else src="../../assets/img/ph.png" alt="..." class="img" id="photo-display">
                    <span class="text-danger" v-if="validation.image">{{ validation.image }}</span>

                    <input type="file" ref="photo_file" id="photo_file" accept="image/*" @change="image_change" style="display: none">
                    <div class="d-flex flex-column align-items-center mt-2">
                      <button type="button" @click="choose_photo" class="btn btn-sm btn-outline-success ml-2">Pilih Gambar</button>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Title <b class="text-danger">*</b></label>
                    <input type="text" v-model="title" class="form-control">
                    <span class="text-danger" v-if="validation.title">{{ validation.title }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <ckeditor :editor="ClassicEditor" v-model="editorData" :config="{extraPlugins: [uploader]}"></ckeditor>
                  <span class="text-danger" v-if="validation.body">{{ validation.body }}</span>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex flex-row-reverse">
              <button class="btn btn-success" @click="save">Simpan</button>
              <button class="btn btn-danger mr-2" @click="() => router.back()">Kembali</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.img {
  width: 100%;
}
</style>