import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "card-body d-flex align-items-center" }
const _hoisted_4 = {
  class: "d-flex flex-column ml-3 mr-2",
  style: {"flex":"1"}
}
const _hoisted_5 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "card-body d-flex align-items-center" }
const _hoisted_8 = {
  class: "d-flex flex-column ml-3 mr-2",
  style: {"flex":"1"}
}
const _hoisted_9 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "fw-bold" }
const _hoisted_13 = { class: "fw-bold" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "fw-bold" }
const _hoisted_17 = { class: "fw-bold" }
const _hoisted_18 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "card pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalTeacherShow = true))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _cache[5] || (_cache[5] = _createElementVNode("img", {
              alt: "profil",
              src: "https://dn0vai85ainxm.cloudfront.net/assets/pijar-sekolah-v3/images/il_profil_sekolah.svg"
            }, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("h4", {
                class: "fw-bold mb-0",
                style: {"color":"#2BB930"}
              }, "Teacher Attendance", -1)),
              (_ctx.done && !_ctx.dailyJournal)
                ? (_openBlock(), _createElementBlock("i", _hoisted_5, "Has not been updated today"))
                : _createCommentVNode("", true)
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("i", {
              class: "fa fa-chevron-right fw-bold",
              style: {"font-size":"18px","color":"#2BB930"}
            }, null, -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "card pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalStudentShow = true))
        }, [
          _createElementVNode("div", _hoisted_7, [
            _cache[8] || (_cache[8] = _createElementVNode("img", {
              alt: "profil",
              src: "https://dn0vai85ainxm.cloudfront.net/assets/pijar-sekolah-v3/images/il_profil_sekolah.svg"
            }, null, -1)),
            _createElementVNode("div", _hoisted_8, [
              _cache[7] || (_cache[7] = _createElementVNode("h4", {
                class: "fw-bold mb-0",
                style: {"color":"#2BB930"}
              }, "Student Attendance", -1)),
              (_ctx.done && !_ctx.dailyJournal)
                ? (_openBlock(), _createElementBlock("i", _hoisted_9, "Has not been updated today"))
                : _createCommentVNode("", true)
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("i", {
              class: "fa fa-chevron-right fw-bold",
              style: {"font-size":"18px","color":"#2BB930"}
            }, null, -1))
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      show: _ctx.modalTeacherShow,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modalTeacherShow = false)),
      title: "Teacher Attendance Today"
    }, {
      body: _withCtx(() => [
        (_ctx.done && !_ctx.dailyJournal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[10] || (_cache[10] = [
              _createElementVNode("i", { class: "fw-bold" }, "No Data", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("h4", _hoisted_12, "Present (" + _toDisplayString(_ctx.teachers.length - _ctx.dailyJournal.teacher.length) + ")", 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teachers.filter(x => !_ctx.dailyJournal.teacher.find(t => t.person_id === x.id)), (v) => {
                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(_ctx.capitalize(v.name)), 1))
                }), 256))
              ]),
              _createElementVNode("h4", _hoisted_13, "Absent (" + _toDisplayString(_ctx.dailyJournal.teacher.length) + ")", 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailyJournal.teacher, (v) => {
                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(_ctx.capitalize(v.name)), 1))
                }), 256))
              ])
            ]))
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_Modal, {
      show: _ctx.modalStudentShow,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.modalStudentShow = false)),
      title: "Student Attendance Today"
    }, {
      body: _withCtx(() => [
        (_ctx.done && !_ctx.dailyJournal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[11] || (_cache[11] = [
              _createElementVNode("i", { class: "fw-bold" }, "No Data", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("h4", _hoisted_16, "Absent (" + _toDisplayString(_ctx.dailyJournal.student.absent.length) + ")", 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailyJournal.student.absent, (v) => {
                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(_ctx.capitalize(v.name)), 1))
                }), 256))
              ]),
              _createElementVNode("h4", _hoisted_17, "Come Late (" + _toDisplayString(_ctx.dailyJournal.student.come_late.length) + ")", 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailyJournal.student.come_late, (v) => {
                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(_ctx.capitalize(v.name)), 1))
                }), 256))
              ]),
              _createElementVNode("h4", _hoisted_18, "Leave Early (" + _toDisplayString(_ctx.dailyJournal.student.leave_early.length) + ")", 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailyJournal.student.leave_early, (v) => {
                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(_ctx.capitalize(v.name)), 1))
                }), 256))
              ])
            ]))
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}