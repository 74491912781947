import {createStore} from "vuex";

export interface IStore {
    adaChat: boolean
}

export const store = createStore({
    state () {
        return {
            adaChat: false
        }
    },
    mutations: {
        adaChat(state: IStore, b: boolean) {
            state.adaChat = b
        }
    }
})
