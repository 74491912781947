import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/img/e_s.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center flex-column",
  style: {"height":"calc(100vh - 300px)"}
}
const _hoisted_6 = {
  key: 0,
  style: {"font-style":"italic"}
}
const _hoisted_7 = {
  key: 1,
  style: {"font-style":"italic"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationItem = _resolveComponent("NotificationItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.notifications.length || !_ctx.done)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  src: _imports_0,
                  width: "200"
                }, null, -1)),
                (_ctx.done)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Tidak ada data"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, "Mendapatkan data"))
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (v, k) => {
            return (_openBlock(), _createBlock(_component_NotificationItem, { notification: v }, null, 8, ["notification"]))
          }), 256))
        ])
      ])
    ])
  ]))
}