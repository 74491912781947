import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeacherAttendanceChart = _resolveComponent("TeacherAttendanceChart")!
  const _component_StudentAttendanceChart = _resolveComponent("StudentAttendanceChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Report")
              ])
            ], -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["card-body", {'px-0': _ctx.isMobile()}])
            }, [
              _createVNode(_component_TeacherAttendanceChart),
              _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
              _createVNode(_component_StudentAttendanceChart)
            ], 2)
          ])
        ])
      ])
    ])
  ]))
}