<template>
    <ul :class="`nav ${isAndroid ? 'nav-primary-app' : 'nav-primary'}`">
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/timeline')}">
            <router-link to="/timeline">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/timeline') ? '-active' : ''}/dashboard.png`">
                <i v-else class="fas fa-home"></i>
                <span class="sub-item">Dashboard </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/academic-development')}">
            <router-link to="/academic-development">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/academic-development') ? '-active' : ''}/progress-achievement.png`">
                <i v-else class="fas fa-graduation-cap"></i>
                <span class="sub-item">Progress / Achievements </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/student-classroom')}">
            <router-link to="/student-classroom">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/student-classroom') ? '-active' : ''}/classroom-journal.png`">
                <i v-else class="fas fa-bookmark"></i>
                <span class="sub-item">Classroom Journal </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/score-list')}">
            <router-link to="/score-list">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/score-list') ? '-active' : ''}/score-list.png`">
                <i v-else class="fas fa-user-graduate"></i>
                <span class="sub-item">Score List </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/parent-report')}">
            <router-link to="/parent-report">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/parent-report') ? '-active' : ''}/report.png`">
                <i v-else class="fas fa-book"></i>
                <span class="sub-item">Report </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/academic-calendar')}">
            <router-link to="/academic-calendar">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/academic-calendar') ? '-active' : ''}/calendar.png`">
                <i v-else class="fas fa-calendar"></i>
                <span class="sub-item">Academic Calendar </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/student-health')}">
            <router-link to="/student-health">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/student-health') ? '-active' : ''}/health.png`">
                <i v-else class="fas fa-suitcase-medical"></i>
                <span class="sub-item">Health </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/announcements')}">
            <router-link to="/announcements">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/announcements') ? '-active' : ''}/announcement.png`">
                <i v-else class="fas fa-bullhorn"></i>
                <span class="sub-item">Announcement </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/notification')}">
            <router-link to="/notification">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/notification') ? '-active' : ''}/notification.png`">
                <i v-else class="fas fa-bell"></i>
                <span class="sub-item">Notification </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/inbox')}">
            <router-link to="/inbox">
                <img v-if="isAndroid"
                     alt=""
                     :src="`/images/ortu${route.fullPath.startsWith('/inbox') ? '-active' : ''}/inbox.png`">
                <i v-else class="fa fa-inbox"></i>
                <span class="sub-item">Inbox <span v-if="adaChat" class="notif-badge d-inline-block ml-1"></span></span>
            </router-link>
        </li>
    </ul>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import Session from "@/utils/Session";

export default defineComponent({
    name: "SidebarOrtu",
    setup() {
        const route = useRoute()
        const store = useStore()
        const isAndroid = Session.getIsAndroid()

        return {
            isAndroid,
            route,
            adaChat: computed(() => store.state.adaChat)
        }
    }
})
</script>

<style scoped>
.notif-badge {
    width: 10px;
    height: 10px;
    border-radius: 8px;
    background: red;
}
</style>