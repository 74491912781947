import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_15 = { class: "card-footer d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Add Master Report")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", null, [
                      _createTextVNode("Angkatan "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.angkatan,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.request.angkatan) = $event)),
                      options: _ctx.refAngkatan,
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.validation.angkatan)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.validation.angkatan), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", null, [
                      _createTextVNode("Kelas "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.trx_class_id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.request.trx_class_id) = $event)),
                      options: _ctx.refGrade,
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.validation.trx_class_id)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.validation.trx_class_id), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", null, [
                      _createTextVNode("Semester "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.semester,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.request.semester) = $event)),
                      options: _ctx.refSemester,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.validation.semester)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.validation.semester), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
              }, "Simpan"),
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}