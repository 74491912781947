<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Teacher Attendance</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Teacher Name</th>
                                    <th>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v, k) in refTeacher">
                                    <td>{{ k + 1 }}</td>
                                    <td>{{ v.label }}</td>
                                    <td>
                                        <button class="btn btn-success btn-sm" @click="detail(v.value)">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Api from "@/utils/Api";
import {User} from "@/utils/Models";
import {useRouter} from "vue-router";
import {error, loading} from "@/utils/Toast";

export default defineComponent({
    name: "TeacherAttendance",
    setup() {
        const router = useRouter()
        const refTeacher = ref<{}[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            t.remove()

            if (r.success) {
                refTeacher.value = (r.data.pic as User[]).map(v => ({value: v.id, label: v.name}))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        return {
            refTeacher,
            detail: (id: number) => router.push(`teacher-attendance/${id}`)
        }
    }
})
</script>

<style scoped>

</style>