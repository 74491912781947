import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/img/e_s.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center flex-column",
  style: {"height":"calc(100vh - 300px)"}
}
const _hoisted_6 = {
  key: 0,
  style: {"font-style":"italic"}
}
const _hoisted_7 = {
  key: 1,
  style: {"font-style":"italic"}
}
const _hoisted_8 = { class: "card timeline-item" }
const _hoisted_9 = { class: "card-header" }
const _hoisted_10 = { class: "mb-0 fw-bold" }
const _hoisted_11 = { class: "card-body p-0" }
const _hoisted_12 = { class: "card-footer d-flex" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_pdf_embed = _resolveComponent("vue-pdf-embed")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.report.length || !_ctx.done)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    src: _imports_0,
                    width: "200"
                  }, null, -1)),
                  (_ctx.done)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Tidak ada data"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, "Mendapatkan data"))
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report, (v) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h4", _hoisted_10, "Raport " + _toDisplayString(v.student_name) + " (Kelas " + _toDisplayString(v.master_report?.nama_kelas) + " Semester " + _toDisplayString(v.master_report?.semester_name) + ")", 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_vue_pdf_embed, {
                    source: v.report.url,
                    page: 1
                  }, null, 8, ["source"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
                  _createElementVNode("button", {
                    class: "btn btn-warning btn-sm mr-2",
                    onClick: ($event: any) => (_ctx.show(v))
                  }, " Lihat Semua Halaman ", 8, _hoisted_13),
                  _createElementVNode("a", {
                    class: "btn btn-success btn-sm",
                    target: "_blank",
                    href: v.report.url
                  }, " Simpan ", 8, _hoisted_14),
                  _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1))
                ])
              ]))
            }), 256))
          ])
        ])
      ])
    ]),
    (_ctx.dataModal)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          size: "lg",
          show: _ctx.showModal,
          title: `Raport ${_ctx.dataModal.student_name} (Kelas ${_ctx.dataModal.master_report?.nama_kelas} Semester ${_ctx.dataModal.master_report?.semester_name})`,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = !_ctx.showModal)),
          "remove-body-padding": true
        }, {
          body: _withCtx(() => [
            _createVNode(_component_vue_pdf_embed, {
              source: _ctx.dataModal.report.url
            }, null, 8, ["source"])
          ]),
          _: 1
        }, 8, ["show", "title"]))
      : _createCommentVNode("", true)
  ], 64))
}