import StudentList from "@/components/doctor/StudentList.vue";
import Health from "@/components/doctor/Health.vue";
import AddHealth from "@/components/doctor/AddHealth.vue";

export default [
  {
    path: '/student-list',
    name: 'student-list',
    component: StudentList,
    meta: {
      title: 'Student List'
    }
  },
  {
    path: '/health',
    name: 'health',
    component: Health,
    meta: {
      title: 'Health'
    }
  },
  {
    path: '/health/add',
    name: 'health/add',
    component: AddHealth,
    meta: {
      title: 'Add Health'
    }
  },
]