<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="d-flex align-items-end">
                <button class="btn btn-success mb-2" @click="add"><i class="fa fa-plus"></i> Tambah</button>
            </div>
			<PaginatedTable :data="kelas" class="mt-3">
				<template #thead>
					<th>No</th>
					<th>Nama Kelas</th>
					<th>Wali Kelas</th>
					<th>Aksi</th>
				</template>
				<template #tbody="{row, index}">
					<td>{{ index + 1 }}</td>
					<td>{{ row.data.nama_kelas }}</td>
					<td>{{ row.data.wali_kelas }}</td>
					<td>
						<button class="btn btn-sm btn-success mr-1" @click="detail(row.data.id)"><i class="fa fa-eye"></i> </button>
						<button class="btn btn-sm btn-warning mr-1" @click="edit(row.data.id)"><i class="fa fa-pencil"></i> </button>
						<button class="btn btn-sm btn-danger" @click="destroy(row.data.id)"><i class="fa fa-trash"></i> </button>
					</td>
				</template>
			</PaginatedTable>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import Api from "@/utils/Api";
import {Kelas} from "@/utils/Models";
import {error, loading, success} from "@/utils/Toast";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "TabKelas",
	components: {PaginatedTable},
    setup() {
        const router = useRouter()
        const kelas = ref<Kelas[]>([])

        const reload = async () => {
            const res = await Api.get('master/kelas')
            if (res.success) {
                kelas.value = res.data as Kelas[]
            }
            else {
                error(res.message ?? 'Gagal medapatkan data')
            }
        }
        onMounted(async () => {
            await reload()
        })

        const add = () => router.push('/master-data/add-kelas')
        const edit = (id: number) => router.push(`/master-data/edit-kelas/${id}`)
        const detail = (id: number) => router.push(`/master-data/detail-kelas/${id}`)
        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.delete(`master/kelas/destroy/${id}`)
                t.remove()

                if (r.success) {
                    success('Berhasil menghapus data')
                    await reload()
                }
                else {
                    error(r.message ?? 'Gagal')
                }
            }
        }

        return { kelas, add, destroy, edit, detail }
    }
})
</script>

<style scoped>

</style>
