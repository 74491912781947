<template>
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm">
        <div class="container">
            <img src="../../assets/img/bc-horizontal-new.png" height="40" alt="navbar brand" class="navbar-brand">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
            </div>
        </div>
    </nav>

    <main class="py-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-header">Login</div>

                        <div class="card-body">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">Username</label>

                                <div class="col-md-6">
                                    <input v-model="username" type="text" class="form-control" name="username" required autofocus>

    <!--                                <span class="invalid-feedback" role="alert">-->
    <!--                                    <strong>{{ $message }}</strong>-->
    <!--                                </span>-->
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">Password</label>
                                <div class="col-md-6">
                                    <input v-model="password" type="password" class="form-control" name="password" required autocomplete="current-password" @keyup.enter="login">
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="button" class="btn btn-primary" @click="login" :disabled="loading">
                                        {{ loading ? 'Signing in...' : 'Sign In' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import { useRouter } from "vue-router";
import Api from "@/utils/Api";
import {toast} from "vue3-toastify";
import {crypt} from "@/utils/Crypt";
import Session from "@/utils/Session";
import {error, success} from "@/utils/Toast";
import {User} from "@/utils/Models";
import Role from "@/utils/Role";
import {useStore} from "vuex";

export default defineComponent({
    name: "Login",
    setup() {
        const router = useRouter()
        const username = ref('')
        const password = ref('')
        const loading = ref(false)
        const store = useStore()

        store.commit('adaChat', false)

        const login = async () => {
            loading.value = true
            const r = await Api.login(username.value, password.value)
            loading.value = false

            if (!r.success) {
                error(r.message!)
                return
            }

            Session.login({
                password: crypt('wkwk', password.value),
                user: JSON.stringify(r.data.user),
                school: JSON.stringify(r.data.school),
                token: r.data.token
            })

            const role_id = (r.data.user as User).role_id

            if (role_id === Role.Ortu) {
                await router.push('/timeline')
            }
            else if (role_id === Role.Siswa) {
                await router.push('/my-assessment')
            }
            else if (role_id === Role.Dokter) {
                await router.push('/health')
            }
            else {
                await router.push('/')
            }
            success('Berhasil login')
        }

        return { login, username, password, loading }
    }
})
</script>

<style scoped>

</style>
