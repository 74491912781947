import {Id, toast} from "vue3-toastify";

class Loading {
    id?: Id

    constructor(id: Id) {
        this.id = id
    }

    remove() {
        if (this.id) {
            toast.remove(this.id)
        }
    }
}

export const loading = (msg: string = 'Mendapatkan data') => new Loading(
    toast.loading(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
    })
)

export const success = (msg: string) => toast.success(msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
})

export const error = (msg: string) => toast.error(msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
})