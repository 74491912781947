import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center ml-auto"
}
const _hoisted_2 = { style: {"font-size":"12px","color":"#888888"} }
const _hoisted_3 = {
  key: 0,
  class: "fa fa-clock ml-1",
  style: {"color":"#aaaaaa"}
}
const _hoisted_4 = {
  key: 1,
  class: "fa fa-check ml-1",
  style: {"color":"#aaaaaa"}
}
const _hoisted_5 = {
  key: 1,
  class: "ml-auto"
}
const _hoisted_6 = { style: {"font-size":"12px","color":"#888888"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'my-chat': _ctx.chat.from_id === _ctx.user.id, 'their-chat': _ctx.chat.from_id !== _ctx.user.id}, "chat"]),
    style: _normalizeStyle({'max-width': _ctx.isMobile() ? '300px' : '400px'})
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.chat.chat), 1),
    (_ctx.chat.from_id === _ctx.user.id)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.chat.sent), 1),
          (!_ctx.chat.done && _ctx.chat.ts)
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : (_openBlock(), _createElementBlock("i", _hoisted_4))
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.chat.sent), 1)
        ]))
  ], 6))
}