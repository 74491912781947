<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card assessment-item">
                        <div class="card-body" v-if="!assessment">
                            <div class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 80px)">
                                <img src="../../assets/img/e_s.png" width="200">
                                <span style="font-style: italic">Mendapatkan data tugas</span>
                            </div>
                        </div>
                        <div class="card-body" v-else>
                            <div class="d-flex align-items-center">
                                <div class="avatar-sm">
                                    <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(assessment.assignment.teacher_photo)">
                                </div>
                                <div class="d-flex flex-column ml-2">
                                    <span class="fw-bold">{{ assessment.assignment.teacher_name ?? '-' }}</span>
                                    <span style="font-size: 12px" class="text-muted">{{ toIndoDateNameTime(assessment.assignment.created_at) }}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center text-success mt-2" style="flex: 1">
                                <i class="fa fa-book"></i><span class="ml-2">{{ assessment.assignment.subject_name }}</span>
                            </div>
                            <div style="height: 1px; background: #eee" class="my-2"></div>
                            <div class="fw-bold mt-2" style="font-size: 18px">{{ assessment.assignment.title }}</div>
                            <div class="text-muted mt-2">{{ assessment.assignment.description }}</div>
                            <div v-if="assessment.assignment.link" class="mt-2">
                                <span style="font-size: 12px; font-weight: bold">Link:</span>
                                <a :href="assessment.assignment.link" target="_blank" class="d-block">{{ assessment.assignment.link }}</a>
                            </div>
                            <div v-if="assessment.assignment.questions.length" class="mt-2">
                                <span style="font-size: 12px; font-weight: bold">Unduh Soal ({{ assessment.assignment.questions.length }} file):</span>
                                <a v-for="q in assessment.assignment.questions" :href="q.url" target="_blank" class="d-block">{{ q.file_name }}</a>
                            </div>
                            <div style="height: 1px; background: #eee" class="my-2"></div>
                            <div style="font-size: 12px" class="fw-bold">Waktu Pengerjaan:</div>
                            <div>{{ toIndoDateNameTime(assessment.assignment.completion_date) }} - {{ toIndoDateNameTime(assessment.assignment.collection_date) }}</div>

                            <div style="height: 1px; background: #eee" class="mt-2 mb-4"></div>

                            <div class="d-flex align-items-center">
                                <div style="width: 35px; height: 35px">
                                    <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(user)">
                                </div>
                                <div class="d-flex flex-column ml-2">
                                    <span class="fw-bold">{{ user.name ?? '-' }}</span>
                                </div>
                            </div>
                            <div class="form-group px-0">
                                <ckeditor :editor="editor" v-model="assessment.answer" :config="editorConfig"></ckeditor>
                            </div>
                            <div class="form-group">
                                <label>Link</label>
                                <input type="text" v-model="assessment.link" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Lampiran</label>
                                <div class="form-control">
                                    <div v-for="v in assessment.attachments.filter(v => v.deleted !== true)"
                                         class="form-control mb-2 d-flex align-items-center"
                                         style="padding: 0.6rem"
                                    >
                                        <span style="flex: 1; min-width: 0">
                                            <a v-if="v.url" :href="v.url ?? ''" target="_blank">{{ v.file_name }}</a>
                                            <span v-else>{{ v.file?.name }}</span>
                                        </span>
                                        <button class="btn btn-sm btn-danger ml-1" @click="() => v.deleted = true">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                    <input type="file"
                                           ref="question_file"
                                           id="question_file"
                                           accept="image/*, .mp3, video/mp4, video/x-m4v, video/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .zip, .rar, .7zip"
                                           @change="question_change"
                                           style="display: none">
                                    <div class="d-flex flex-row-reverse">
                                        <button type="button" @click="add_question" class="btn btn-sm btn-success">
                                            <i class="fa fa-plus"></i> Tambah File
                                        </button>
                                    </div>
                                </div>
                                <p class="mt-1 mb-0">Tipe file yang diizinkan adalah .mp3, .mp4, .pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .xlsx, .xls, .rar, .zip, .ppt dengan maksimal 5 MB.</p>
                            </div>

                            <div style="height: 1px; background: #eee" class="mt-2 mb-4"></div>

                            <div class="d-flex">
                                <div style="flex: 1"></div>
                                <button class="btn btn-success" @click="submit">Submit</button>
                                <div style="flex: 1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirmation
        :show="showConfirm"
        @close="showConfirm = false"
        @ok="onOk"
    >
        <template #body>
            Pastikan semua jawaban kamu sudah sesuai. Lanjut submit?
        </template>
    </ModalConfirmation>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {IAssignmentStudent} from "@/utils/interface/Assignment";
import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";
import Session from "@/utils/Session";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ModalConfirmation from "@/components/partials/ModalConfirmation.vue";
// @ts-ignore
import UploadAdapter from "@/utils/UploadAdapter";

export default defineComponent({
    name: "MyAssessmentDo",
    components: {
        ModalConfirmation
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id
        const assessment = ref<IAssignmentStudent|null>(null)
        const user = Session.getCurrentUser()!
        const showConfirm = ref(false)

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`assignment/student/assignment/find/${id}`)
            t.remove()

            if (r.success) {
                assessment.value = r.data as IAssignmentStudent
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        function uploader(editor: any) {
            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
                return new UploadAdapter( loader );
            }
        }

        const remove_question = (id: number) => {
            if (assessment.value) {
                assessment.value.attachments = assessment.value.attachments || []
                assessment.value.attachments = assessment.value.attachments?.filter(v => v.id !== id)
            }
        }
        const question_change = (event: any) => {
            if (assessment.value) {
                assessment.value.attachments = assessment.value.attachments || []
                assessment.value.attachments?.push({
                    id: Math.floor(Math.random() * 10000) + 1,
                    file: event.target.files[0],
                    type: event.target.files[0].name.split('.').pop(),
                })
            }
        }
        const add_question = () => document.getElementById('question_file')?.click()

        const onOk = async () => {
            let data = new FormData()
            if (assessment.value!.attachments) {
                assessment.value!.attachments.forEach(v => {
                    if (v.file) {
                        data.append('attachments[]', v.file)
                    }
                    else if (v.deleted) {
                        data.append('deleted[]', v.file_name ?? '')
                    }
                })
            }
            data.append('link', `${assessment.value!.link}`)
            data.append('answer', `${assessment.value!.answer}`)
            data.append('id', `${assessment.value!.id}`)

            const t = loading('Menyimpan jawaban')
            const r = await Api.post(`assignment/student/assignment/submit`, data, { 'Content-Type': 'multipart/form-data' })
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan jawaban')
                showConfirm.value = false
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        return {
            assessment, getPhoto, toIndoDateNameTime, user, showConfirm,
            editor: ClassicEditor,
            ckeditor: CKEditor.component,
            editorConfig: {
                extraPlugins: [uploader],
            },
            add_question, question_change, remove_question,
            submit: () => showConfirm.value = true, onOk
        }
    }
})
</script>

<style scoped>
.assessment-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>