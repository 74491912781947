<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Student Academic Progress / Achievements</div>
                                <div class="card-tools">
                                    <button @click="add" class="b-time btn btn-success btn-round mr-2">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
							<PaginatedTable :data="progress" class="mt-3">
								<template #thead>
									<th>No</th>
									<th>Siswa</th>
									<th>Subject</th>
									<th>Semester</th>
									<th>Catatan</th>
									<th>Attachments</th>
									<th>Aksi</th>
								</template>
								<template #tbody="{row, index}">
									<td>{{ index + 1 }}</td>
									<td>{{ row.data.student_name }}</td>
									<td>{{ row.data.subject_name }}</td>
									<td>{{ row.data.semester_name }}</td>
									<td>
										<div style="width: 100%; max-width: 500px; max-height: 80px; overflow: auto">
											<pre>{{ row.data.note }}</pre>
										</div>
									</td>
									<td>
										<a v-for="a in row.data.attachments" target="_blank" :href="a.url" class="d-block">{{ a.file_name }}</a>
									</td>
									<td>
										<button class="btn btn-warning btn-sm mr-1" @click="edit(row.data.id)">
											<i class="fa fa-pencil"></i>
										</button>
										<button class="btn btn-danger btn-sm" @click="destroy(row.data.id)">
											<i class="fa fa-trash"></i>
										</button>
									</td>
								</template>
							</PaginatedTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import Api from "@/utils/Api";
import {IProgress} from "@/utils/interface/Progress";
import {error, loading, success} from "@/utils/Toast";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "Progress",
	components: {PaginatedTable},
    setup() {
        const router = useRouter()
        const progress = ref<IProgress[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('progress')
            t.remove()

            if (r.success) {
                progress.value = r.data as IProgress[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.get(`progress/delete/${id}`)
                t.remove()

                if (r.success) {
                    progress.value = r.data as IProgress[]
                    success('Berhasil menghapus data')
                }
                else {
                    error(r.message ?? 'gagal')
                }
            }
        }

        return {
            progress,
            add: () => router.push('/academic/progress/add'),
            edit: (id: number) => router.push(`/academic/progress/edit/${id}`),
            detail: (id: number) => router.push(`/academic/progress/${id}`),
            destroy
        }
    }
})
</script>

<style scoped>
pre {
    -webkit-font-smoothing: antialiased;
    font-family: Lato,sans-serif;
    color: #575962;
    font-size: 14px;
}
</style>