import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "mb-0" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "modal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "header", {}, () => [
                    _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.title), 1)
                  ], true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _renderSlot(_ctx.$slots, "footer", {}, () => [
                    _createElementVNode("button", {
                      class: "btn btn-warning btn-sm",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    }, " Cancel "),
                    _createElementVNode("button", {
                      class: "btn btn-success btn-sm",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('ok')))
                    }, " OK ")
                  ], true)
                ])
              ])
            ])
          ])
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}