<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card timeline-item">
                        <div class="card-header">
                            <h4 class="fw-bold mb-0">Filter</h4>
                        </div>
                        <div class="card-body" v-if="filterShowing">
                            <div v-if="refChildren.length > 1" class="form-group">
                                <label>Siswa</label>
                                <Multiselect v-model="filter.student_id" :options="refChildren" :searchable="true"/>
                            </div>
                            <div class="form-group">
                                <label>Guru</label>
                                <Multiselect v-model="filter.teacher_id" :options="refTeacher" :searchable="true"/>
                            </div>
                            <div class="form-group">
                                <label>Subject</label>
                                <Multiselect v-model="filter.subject_id" :options="refSubject" :searchable="true"/>
                            </div>
                            <div class="form-group">
                                <label>Range Tanggal Tugas</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" v-model="filter.start_date" class="form-control" style="flex: 1">
                                    <span class="mx-2">s/d</span>
                                    <input type="date" v-model="filter.end_date" class="form-control" style="flex: 1">
                                </div>
                            </div>
                            <div class="d-flex">
                                <div style="flex: 1"></div>
                                <button class="btn btn-success mt-2 mx-auto" @click="search">
                                    Cari
                                </button>
                                <div style="flex: 1"></div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex">
                                <div style="flex: 1"></div>
                                <u class="text-success" @click="filterShowing = !filterShowing">
                                    {{ filterShowing ? 'Sembunyikan filter' : 'Tampilkan filter' }}
                                </u>
                                <div style="flex: 1"></div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!assessment.length || !done" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 300px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada data</span>
                        <span style="font-style: italic" v-else>Mendapatkan data</span>
                    </div>
                    <ScoreItem v-else v-for="v in assessment" :assessment="v"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {IAssignmentStudent, IOption} from "@/utils/interface/Assignment";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import Session from "@/utils/Session";
import {Kelas, Pelajaran, Siswa, User} from "@/utils/Models";
import Multiselect from "@vueform/multiselect";
import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";
import ScoreItem from "@/components/parent/partials/ScoreItem.vue";

interface IFilter {
    student_id: number,
    teacher_id: number,
    subject_id: number,
    start_date?: string,
    end_date?: string,
}

export default defineComponent({
    name: "ScoreList",
    components: {
        ScoreItem,
        Multiselect
    },
    setup() {
        const user = Session.getCurrentUser()!
        const filterShowing = ref(false)
        const done = ref(false)
        const assessment = ref<IAssignmentStudent[]>([])
        const refChildren = ref<IOption[]>([])
        const refTeacher = ref<IOption[]>([])
        const refSubject = ref<IOption[]>([])
        const filter = reactive<IFilter>({
            student_id: 0,
            teacher_id: 0,
            subject_id: 0,
        })

        onMounted(async () => {
            const t = loading()
            const r = await Api.get(`parent/children/${user.connection_id}`)
            const r2 = await Api.get('master/journal')
            t.remove()

            if (r.success) {
                refChildren.value = (r.data as Siswa[]).map(v => ({value: v.id, label: v.nama}))
                filter.student_id = refChildren.value[0].value
                search()
            }
            else {
                error(r.message ?? 'gagal')
            }

            if (r2.success) {
                refTeacher.value = (r2.data.pic as User[]).map(v => ({value: v.id, label: v.name}))
                refSubject.value = (r2.data.mapel as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const search = async () => {
            filterShowing.value = false
            done.value = false
            const t = loading('Mendapatkan data')
            const r = await Api.post(`parent/score/${user.connection_id}`, {...filter})
            t.remove()

            if (r.success) {
                assessment.value = r.data as IAssignmentStudent[]
            }
            else {
                error(r.message ?? 'gagal')
            }

            done.value = true
        }

        return {
            refChildren, filter, refTeacher, refSubject, search, filterShowing, assessment, done,
            getPhoto, toIndoDateNameTime
        }
    }
})
</script>

<style scoped>
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>