<template>
    <div class="circle" :class="`circle-${status}`" @click="click">
        <b>{{ status.toUpperCase() }}</b>
    </div>
    <AttendanceOptions :show="show" :top="top" :left="left" :key="key" :statuses="statuses" @choose="onChoose"/>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import AttendanceOptions from '@/components/partials/AttendanceOptions.vue'

export default defineComponent({
    name: "Attendance",
    props: {
        status: String,
		key: [Number, String],
        isStudent: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    components: {
        AttendanceOptions
    },
    emits: [
        'statusChange'
    ],
    setup(props, { emit }) {
        const top = ref(0)
        const left = ref(0)
        const show = ref(false)

        const click = (event: any) => {
            top.value = mouseY(event) ?? 0
            left.value = mouseX(event) ?? 0
            setTimeout(() => show.value = true, 100)
        }
        const onChoose = (c?: string) => {
            show.value = false
            if (c) {
                emit('statusChange', c)
            }
        }

        const statuses = props.isStudent ?
            {h: 'Hadir', s: 'Sakit', i: 'Izin', a: 'Alpha'} :
            {h: 'Hadir', s: 'Sakit', i: 'Izin', a: 'Alpha', t: 'Tugas'}

        return { click, top, left, show, onChoose, statuses }
    }
})

function mouseX(evt: any) {
    const get = () => {
        if (evt.pageX) {
            return evt.pageX
        } else if (evt.clientX) {
            return evt.clientX + (document.documentElement.scrollLeft ?
                document.documentElement.scrollLeft :
                document.body.scrollLeft)
        } else {
            return null
        }
    }

    const res = get()
    if (!res)
        return res
    if (res + 250 > document.body.clientWidth)
        return document.body.clientWidth - 250

    return res
}

function mouseY(evt: any) {
    const get = () => {
        if (evt.pageY) {
            return evt.pageY - (
                document.documentElement.scrollTop ?
                    document.documentElement.scrollTop :
                    document.body.scrollTop
            )
        } else if (evt.clientY) {
            return evt.clientY - (
                document.documentElement.scrollTop ?
                    document.documentElement.scrollTop :
                    document.body.scrollTop
            )
        } else {
            return null
        }
    }

    const res = get()
    if (!res)
        return res
    if (res + 350 > document.body.clientHeight)
        return document.body.clientHeight - 350

    return res
}
</script>

<style scoped>
.circle {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    color: white;
    font-size: 24px;
    text-align: center;
    padding-top: 3px;
    cursor: pointer;
}
.circle-h {
    background-color: #8BC53F;
}
.circle-s {
    background-color: #F6931E;
}
.circle-i {
    background-color: #3088C8;
}
.circle-a {
    background-color: #ED1F24;
}
.circle-t {
    background-color: #8d0db0;
}
</style>
