<template>
    <div class="card timeline-item">
        <div class="card-body pb-0">
            <div class="d-flex align-items-center">
                <div class="avatar-sm">
                    <img class="avatar-img rounded-circle" :src="getPhoto(attendance.classroom_journal.teacher_photo)">
                </div>
                <div class="d-flex flex-column ml-2" style="flex: 1">
                    <div class="d-flex">
                        <span style="flex: 1" class="fw-bold">{{ attendance.classroom_journal.teacher_name ?? '-' }}</span>
                        <label v-if="showTag" class="badge badge-primary text-white">Classroom</label>
                    </div>
                    <span class="text-muted" style="font-size: 12px">{{ toIndoDateNameTime(attendance.created_at) }}</span>
                </div>
            </div>
        </div>
        <div class="card-header text-success d-flex flex-column">
            <div class="d-flex align-items-center" style="flex: 1">
                <i class="fa fa-book"></i>
                <span class="ml-2">{{ attendance.classroom_journal.subject_name }} ({{toIndoDateName(attendance.classroom_journal.date)}} {{attendance.classroom_journal.lesson_hours}})</span>
            </div>
            <div class="d-flex align-items-center" style="flex: 1; margin-top: 4px">
                <i class="fas fa-user-graduate"></i><span class="ml-2"></span> {{ attendance.student_name }}
            </div>
        </div>
        <div class="card-header">
            <b>Status:</b><br>
            <span style="font-size: 16px">{{attendanceStatus(attendance.status)}}</span>
            <div v-if="attendance.note" class="mt-2">
                <b>Note:</b><br>
                <span style="font-size: 16px">{{ attendance.note }}</span>
            </div>
            <div class="ctr">
                <div v-if="expanded">
                    <b>Materi Pembelajaran</b><br>
                    <span>{{attendance.classroom_journal?.materi_pembelajaran}}</span>
                    <hr class="my-2">
                    <b>Kegiatan Pembelajaran</b><br>
                    <span>{{attendance.classroom_journal?.kegiatan_pembelajaran}}</span>
                    <hr class="my-2">
                    <b>Catatan</b><br>
                    <span>{{attendance.classroom_journal?.catatan}}</span>
                    <hr class="my-2">
                </div>
                <div class="d-flex justify-content-center">
                    <i @click="() => expanded = !expanded" class="toggle">{{expanded ? 'Hide Details' : 'Show Details'}}</i>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {StudentAttendance} from "@/utils/interface/ClassroomJournal";
import {attendanceStatus, getPhoto, toIndoDateName, toIndoDateNameTime} from "@/utils/Helpers";

export default defineComponent({
    name: "JournalItem",
    props: {
        attendance: Object as PropType<StudentAttendance>,
        showTag: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const expanded = ref(false)

        return {
            getPhoto, toIndoDateNameTime, toIndoDateName, attendanceStatus, expanded
        }
    }
})
</script>

<style scoped>
.ctr {
    padding: 10px;
    background: #f6f6f6;
    margin-top: 14px;
    margin-bottom: 4px;
    border-radius: 6px;
}
.toggle {
    text-decoration: underline;
    color: #1572e8;
}
</style>