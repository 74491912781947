<template>
    <ul class="nav nav-primary">
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/health')}">
            <router-link to="/health">
                <i class="fas fa-suitcase-medical"></i>
                <span class="sub-item">Health </span>
            </router-link>
        </li>
    </ul>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "SidebarDokter",
    setup() {
        const route = useRoute()
        return { route }
    }
})
</script>

<style scoped>

</style>