import { createApp, nextTick } from 'vue'
import App from './App.vue'
import router from './router'
import {clickOutside} from "@/utils/ClickOutside";
import CKEditor from '@ckeditor/ckeditor5-vue'
import {store} from "@/utils/Store";
import 'vue3-toastify/dist/index.css'

const DEFAULT_TITLE = 'BC Smart School';
router.afterEach((to, from) => {
    nextTick(() => {
        document.title = to.meta.title ? `${to.meta.title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE

        let root = document.getElementsByTagName('html')![0]!
        root.classList.remove('nav_open')
        root.classList.remove('topbar_open')
    })
})

createApp(App)
    .use(store)
    .use(router)
    .use(CKEditor)
    .directive("click-outside", clickOutside)
    .mount('#app')
