<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Teacher Attendance: {{ teacher?.name }}</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <span style="flex: 1"></span>
                                <div class="b-hadir"></div> <span>Hadir</span>
                                <div class="b-izin"></div> <span>Izin</span>
                                <div class="b-sakit"></div> <span>Sakit</span>
                                <div class="b-alpha"></div> <span>Alpha</span>
                                <div class="b-tugas"></div> <span>Tugas</span>
                            </div>
                            <table class="table table-bordered mt-2">
                                <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Note</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v, k) in attendances">
                                    <td>{{ k + 1 }}</td>
                                    <td>{{ v.date }}</td>
                                    <td>
                                        <span class="d-flex align-items-center">
                                            <span class="circle mr-1" :class="`circle-${v.status}`">{{ v.status.toUpperCase() }}</span>
                                        </span>
                                    </td>
                                    <td>{{ v.note }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {User} from "@/utils/Models";
import Api from "@/utils/Api";
import {useRoute, useRouter} from "vue-router";
import {error, loading} from "@/utils/Toast";

export default defineComponent({
    name: "TeacherAttendanceDetail",
    setup() {
        const route = useRoute()
        const router = useRouter()
        const id = route.params.id
        const teacher = ref<User|null>(null)
        const attendances = ref<{date: string, status: string, note: string}[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`master/guru/${id}`)
            const r2 = await Api.get(`report/teacher_attendance/${id}`)
            t.remove()

            if (r.success) {
                teacher.value = r.data as User
            }
            else {
                error(r.message ?? 'gagal')
            }
            if (r2.success) {
                attendances.value = r2.data as {date: string, status: string, note: string}[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        return {
            teacher, attendances
        }
    }
})
</script>

<style scoped>
.circle {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    color: white;
    font-size: 24px;
    text-align: center;
    padding-top: 3px;
    cursor: pointer;
}
</style>