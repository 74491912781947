import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["rowspan"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.times, (t, k) => {
    return (_openBlock(), _createElementBlock("tr", null, [
      (k === 0 || t.first)
        ? (_openBlock(), _createElementBlock("td", {
            key: 0,
            rowspan: _ctx.times.length ? _ctx.times.length : 1
          }, [
            _createElementVNode("b", null, _toDisplayString(_ctx.day), 1),
            _createElementVNode("span", {
              class: "btn btn-success btn-sm ml-2",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addTime(_ctx.day)))
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "fa fa-clock" }, null, -1)
            ]))
          ], 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("td", null, [
        (!t.first)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(t.time ?? '-'), 1),
              _createElementVNode("span", {
                class: "btn btn-success btn-sm ml-2",
                onClick: ($event: any) => (_ctx.editEvent(t))
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "fa fa-flag" }, null, -1)
              ]), 8, _hoisted_3),
              _createElementVNode("span", {
                class: "btn btn-warning btn-sm ml-2",
                onClick: ($event: any) => (_ctx.editTime(t.id, t.time ?? ''))
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "fa fa-pencil" }, null, -1)
              ]), 8, _hoisted_4),
              _createElementVNode("span", {
                class: "btn btn-danger btn-sm ml-2",
                onClick: ($event: any) => (_ctx.deleteTime(t.id))
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
              ]), 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true)
      ]),
      (!t.event)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.refGrade, (g) => {
            return (_openBlock(), _createElementBlock("td", null, [
              _createTextVNode(_toDisplayString(t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_name ?? '') + " ", 1),
              (!t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_name && !t.first)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    onClick: ($event: any) => (_ctx.addSubject(t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.id ?? 0)),
                    class: "btn btn-sm btn-success"
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("i", { class: "fa fa-plus" }, null, -1)
                  ]), 8, _hoisted_6))
                : (!t.first)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: "btn btn-sm btn-warning ml-2",
                      onClick: ($event: any) => (_ctx.editSubject(t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.id ?? 0, t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_id ?? 0))
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("i", { class: "fa fa-pencil" }, null, -1)
                    ]), 8, _hoisted_7))
                  : _createCommentVNode("", true)
            ]))
          }), 256))
        : (_openBlock(), _createElementBlock("td", {
            key: 2,
            colspan: _ctx.refGrade.length,
            class: "bg-eaea"
          }, _toDisplayString(t.event ?? ''), 9, _hoisted_8))
    ]))
  }), 256))
}