<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Academic Calendar</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <Calendar
                                :agendas="agendas"
                                :on-date-click="onDateClick"
                                :on-agenda-click="onAgendaClick"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal
        :show="showModal"
        @close="showModal = false">
        <template #header>
            <div class="d-flex align-items-center" style="width: 100%">
                <h4 class="mb-0 fw-bold" style="flex: 1">{{ modalTitle }}</h4>
                <button v-if="editedAgendaId" class="btn btn-danger btn-sm" @click="destroy()">Hapus</button>
            </div>
        </template>
        <template #body>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Nama Agenda</label>
                        <input type="text" class="form-control" v-model="newAgenda.title">
                    </div>
                    <div class="form-group">
                        <label>Waktu Mulai</label>
                        <input type="datetime-local" class="form-control" v-model="newAgenda.start_date">
                    </div>
                    <div class="form-group">
                        <label>Waktu Selesai</label>
                        <input type="datetime-local" class="form-control" v-model="newAgenda.end_date">
                    </div>
                    <div class="form-group">
                        <label>Warna</label>
                        <Multiselect v-model="newAgenda.customData.color" :options="refColor" :can-clear="false" :can-deselect="false">
                            <template v-slot:option="{ option }">
                                <span style="width: 13px; height: 13px; background: red; border-radius: 4px; margin-right: 4px" :class="`opt-${option.value}`"></span>
                                <span>{{ option.label }}</span>
                            </template>
                            <template #singlelabel="{ value }">
                                <div class="d-flex align-items-center ml-3" style="width: 100%">
                                    <span style="width: 13px; height: 13px; background: red; border-radius: 4px; margin-right: 4px" :class="`opt-${value.value}`"></span>
                                    <span style="font-size: 15px">{{ value.label }}</span>
                                </div>
                            </template>
                        </Multiselect>
                    </div>
                    <div class="form-group" v-if="editedAgendaId">
                        <label>Pembuat</label>
                        <input type="text" class="form-control" v-model="newAgenda.customData.creator_name" readonly>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <button class="btn btn-warning btn-sm" @click="showModal = false">Cancel</button>
            <button class="btn btn-success btn-sm" @click="save">Save</button>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import Modal from "@/components/partials/Modal.vue";
import {toIndoDateName, toSqlDate, toSqlDateTime} from "@/utils/Helpers";
import {AgendaResponse, agendaResponseToI, IAgenda} from "@/utils/interface/Agenda";
import Multiselect from "@vueform/multiselect";
import Api from "@/utils/Api";
import Session from "@/utils/Session";
import Calendar from "@/components/partials/Calendar.vue";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "AcademicCalendar",
    components: {
        Calendar,
        Modal,
        Multiselect
    },
    setup() {
        const showDate = ref(new Date())
        const modalTitle = ref('Tambah Agenda')
        const showModal = ref(false)
        const selectedDate = ref(new Date())
        const agendas = ref<IAgenda[]>()
        const newAgenda = reactive<IAgenda>({ title: '', customData: { color: 'success' } })
        const editedAgendaId = ref<number|null>(null)
        const refColor = ref([
            {value: 'primary', label: ''}, {value: 'secondary', label: ''}, {value: 'success', label: ''},
            {value: 'warning', label: ''}, {value: 'danger', label: ''}
        ])

        onMounted(async () => {
            const t = loading('Mendapatkan agenda')
            const r = await Api.get('agenda')
            t.remove()
            if (r.success) {
                agendas.value = (r.data as AgendaResponse[]).map(v => agendaResponseToI(v))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const onDateClick = (day: Date) => {
            selectedDate.value = day
            newAgenda.startDate = day
            newAgenda.endDate = day
            newAgenda.start_date = toSqlDateTime(day)
            newAgenda.end_date = toSqlDateTime(day)
            modalTitle.value = 'Tambah Agenda'
            editedAgendaId.value = null
            showModal.value = true
        }

        const onAgendaClick = (v: IAgenda) => {
            newAgenda.title = v.title
            newAgenda.start_date = v.start_date
            newAgenda.end_date = v.end_date
            newAgenda.customData!.color = v.customData!.color
            newAgenda.customData!.creator_name = v.customData!.creator_name
            modalTitle.value = 'Edit Agenda'
            editedAgendaId.value = v.id!
            showModal.value = true
        }

        const clearForm = (r: any) => {
            agendas.value = (r.data as AgendaResponse[]).map(v => agendaResponseToI(v))
            newAgenda.title = ''
            newAgenda.start_date = ''
            newAgenda.end_date = ''
            newAgenda.customData!.color = 'success'
            editedAgendaId.value = null
            showModal.value = false
        }

        const add = async () => {
            const t = loading('Menambah agenda')
            const r = await Api.post('agenda/create', {
                title: newAgenda.title,
                start_date: newAgenda.start_date,
                end_date: newAgenda.end_date,
                color: newAgenda.customData?.color ?? 'primary',
                created_by: Session.getCurrentUser()!.id,
            })
            t.remove()

            if (r.success) {
                success('Berhasil menambah agenda')
                clearForm(r)
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        const update = async () => {
            const t = loading('Mengedit agenda')
            const r = await Api.post(`agenda/update/${editedAgendaId.value}`, {
                title: newAgenda.title,
                start_date: newAgenda.start_date,
                end_date: newAgenda.end_date,
                color: newAgenda.customData?.color ?? 'primary',
            })
            t.remove()

            if (r.success) {
                success('Berhasil mengedit agenda')
                clearForm(r)
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        const save = () => {
            if (editedAgendaId.value) {
                update()
            }
            else {
                add()
            }
        }

        const destroy = async () => {
            if (confirm('Yakin mau menghapus agenda ini?')) {
                const t = loading('Menghapus agenda')
                const r = await Api.get(`agenda/delete/${editedAgendaId.value}`)
                t.remove()

                if (r.success) {
                    success('Berhasil menghapus agenda')
                    clearForm(r)
                }
                else {
                    error(r.message ?? 'gagal')
                }
            }
        }

        return {
            showDate, editedAgendaId,
            setShowDate: (d: Date) => showDate.value = d,
            agendas, isToday, showModal, newAgenda, refColor, save, onAgendaClick,
            onDateClick, selectedDate, toIndoDateName, toSqlDate, modalTitle, destroy
        }
    }
})

function isToday(date: Date) {
    const today = new Date();
    return today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate();
}

</script>

<style>

</style>