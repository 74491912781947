<template>
    <div class="content">
        <div class="page-inner py-0">
            <div class="row">
                <div class="col-md-12" style="height: calc(100vh - 60px - 64px); overflow-y: auto" ref="el">
                    <div v-if="!attendances.length || !done" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 300px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada data</span>
                        <span style="font-style: italic" v-else>Mendapatkan data</span>
                    </div>
                    <JournalItem v-else v-for="v in attendances" :attendance="v" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, onUnmounted, ref} from "vue";
import Session from "@/utils/Session";
import {StudentAttendance} from "@/utils/interface/ClassroomJournal";
import ProgressItem from "@/components/parent/partials/ProgressItem.vue";
import JournalItem from "@/components/parent/partials/JournalItem.vue";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import {useInfiniteScroll, useIntersectionObserver} from "@vueuse/core";

export default defineComponent({
    name: "StudentClassroom",
    components: {JournalItem, ProgressItem},
    setup() {
        const done = ref(false)
        const gettingData = ref(false)
        const user = Session.getCurrentUser()!
        const attendances = ref<StudentAttendance[]>([])
        const el = ref<HTMLElement | null>(null)
        const page = ref(0)
        const reachingLast = ref(false)

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`student-classroom-journal/${user.connection_id}/${page.value}`)
            t.remove()

            if (r.success) {
                attendances.value = r.data as StudentAttendance[]
            }
            else {
                error(r.message ?? 'gagal')
            }

            done.value = true
        })

        useInfiniteScroll(el, async () => {
            if (done.value && !gettingData.value && !reachingLast.value) {
                gettingData.value = true
                const t = loading('Mendapatkan data lebih banyak')
                const r = await Api.get(`student-classroom-journal/${user.connection_id}/${++page.value}`)
                t.remove()
                if (r.success) {
                    attendances.value = [...attendances.value, ...(r.data as StudentAttendance[])]
                    if (r.last) {
                        reachingLast.value = true
                    }
                }
                else {
                    error(r.message ?? 'gagal')
                }
                gettingData.value = false
            }
        }, { distance: 276 })

        return {
            done, user, attendances, el
        }
    }
})
</script>

<style scoped>
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>