<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="fw-bold">Edit Siswa</h4>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="siswa">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>NISN</label>
                                        <input type="text" class="form-control" v-model="siswa.nisn" placeholder="Masukkan NISN">
                                        <span v-if="error.nisn" class="text-danger">{{ error.nisn }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Nama <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="siswa.nama" placeholder="Masukkan Nama">
                                        <span v-if="error.nama" class="text-danger">{{ error.nama }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Tempat Lahir</label>
                                        <input type="text" class="form-control" v-model="siswa.tempat_lahir" placeholder="Masukkan Tempat Lahir">
                                        <span v-if="error.tempat_lahir" class="text-danger">{{ error.tempat_lahir }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Tanggal Lahir</label>
                                        <input type="date" class="form-control" v-model="siswa.tgl_lahir" placeholder="Masukkan Tanggal Lahir">
                                        <span v-if="error.tgl_lahir" class="text-danger">{{ error.tgl_lahir }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>NIS <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="siswa.nis" placeholder="Masukkan NIS">
                                        <span v-if="error.nis" class="text-danger">{{ error.nis }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Agama</label>
                                        <select class="form-control" v-model="siswa.agama">
                                            <option value="Islam">Islam</option>
                                            <option value="Kristen">Kristen</option>
                                            <option value="Katolik">Katolik</option>
                                            <option value="Budha">Budha</option>
                                            <option value="Hindu">Hindu</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-check">
                                        <label>Jenis Kelamin</label><br>
                                        <label class="form-radio-label">
                                            <input class="form-radio-input" type="radio" value="L" v-model="siswa.jenis_kelamin">
                                            <span class="form-radio-sign">Laki-Laki</span>
                                        </label>
                                        <label class="form-radio-label ml-3">
                                            <input class="form-radio-input" type="radio" value="P" v-model="siswa.jenis_kelamin">
                                            <span class="form-radio-sign">Perempuan</span>
                                        </label>
                                        <span v-if="error.jenis_kelamin" class="text-danger">{{ error.jenis_kelamin }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Alamat (Opsional)</label>
                                        <textarea class="form-control" v-model="siswa.alamat" rows="4" placeholder="Masukkan Alamat"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Kelas <b class="text-danger">*</b></label>
<!--                                        <select class="form-control" v-model="siswa.trx_class_id">-->
<!--                                            <option v-for="v in kelas" :value="v.id">{{ v.nama_kelas }}</option>-->
<!--                                        </select>-->
                                        <Multiselect
                                            v-model="selected_kelas"
                                            :options="ref_kelas"
                                            :searchable="true"
                                            :create-option="false"
                                            mode="tags"
                                        />
                                        <span v-if="error.kelas" class="text-danger">{{ error.kelas }}</span>
                                    </div>
                                    <div class="profile-picture mb-1">
                                        <div class="avatar avatar-xl">
                                            <img :src="photo_url ?? getPhoto(siswa)" alt="..." class="avatar-img rounded-circle" id="photo-display">
                                        </div>
                                        <input type="file" ref="photo_file" id="photo_file" accept="image/*" @change="image_change" style="display: none">
                                        <button type="button" @click="choose_photo" class="btn btn-round btn-sm btn-outline-success ml-2">Pilih Photo</button>
                                    </div>
                                    <div class="form-group">
                                        <label>Password (Hanya isi ketika ingin dirubah)</label>
                                        <input type="password" class="form-control" v-model="password" placeholder="Masukkan Password" autocomplete="new-password">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row justify-content-end">
                            <button class="btn btn-danger mr-2" @click="cancel">Batal</button>
                            <button class="btn btn-success" @click="save">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Api from "@/utils/Api";
import {Kelas, Siswa} from "@/utils/Models";
import {file2Base64, getPhoto} from "@/utils/Helpers";
import {loading, error as e, success} from "@/utils/Toast";
import Multiselect from "@vueform/multiselect";

interface Error {
    nisn?: string | null,
    nama?: string | null,
    tempat_lahir?: string | null,
    tgl_lahir?: string | null,
    nis?: string | null,
    agama?: string | null,
    kelas?: string | null,
    jenis_kelamin?: string | null,
}

export default defineComponent({
    name: "UpdateSiswa",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id

        const siswa = ref<Siswa|null>(null)
        const kelas = ref<Kelas[]>([])
        const photo_file = ref(null)
        const photo_url = ref<string|null>(null)
        const photo_b64 = ref<string|null>(null)
        const password = ref('')
        const error = ref<Error>({})
        const ref_kelas = ref<{}[]>([])
        const selected_kelas = ref<number[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`master/siswa/${id}`)
            const res = await Api.get('master/kelas')
            t.remove()

            if (r.success) {
                siswa.value = r.data as Siswa
                selected_kelas.value = siswa.value.student_class.map(v => v.trx_class?.id ?? 0)
                console.log(selected_kelas.value)
            }
            else {
                e(r.message ?? 'gagal')
            }

            if (res.success) {
                kelas.value = res.data as Kelas[]
                ref_kelas.value = (res.data as Kelas[]).map(v => ({label: v.nama_kelas, value: v.id}))
            }
            else {
                e(res.message ?? 'Gagal medapatkan data')
            }
        })

        const cancel = () => router.back()
        const save = async () => {
            const er: Error = {...error.value}
            Object.keys(er).forEach(function(key) {
                er[key as keyof Error] = null
            })
            error.value = er as typeof er

            if (!siswa.value) {
                return
            }
            if (!siswa.value.nama) {
                error.value.nama = 'Masukkan Nama'
                return
            }
            if (!siswa.value.nis) {
                error.value.nis = 'Masukkan NIS'
                return
            }

            const t = loading('Menyimpan data')
            const r = await Api.put(`master/siswa/update/${id}`, {
                nisn: siswa.value.nisn,
                nis: siswa.value.nis,
                nama: siswa.value.nama,
                jenis_kelamin: siswa.value.jenis_kelamin,
                alamat: siswa.value.alamat,
                tempat_lahir: siswa.value.tempat_lahir,
                tgl_lahir: siswa.value.tgl_lahir,
                agama: siswa.value.agama,
                password: password.value,
                photo_file: photo_b64.value,
                student_class: selected_kelas.value
            })
            t.remove()

            if (r.success) {
                router.back()
                success('Berhasil menyimpan data')
            }
            else {
                e(r.message ?? 'Gagal')
            }
        }
        const choose_photo = () => {
            let fileUpload = document.getElementById('photo_file')
            if (fileUpload != null) {
                fileUpload.click()
            }
        }
        const image_change = async (event: any) => {
            const file = event.target.files[0]
            if (file.size > 1000000) {
                e('Maksimal ukuran gambar 1 MB')
                return
            }

            photo_url.value = URL.createObjectURL(file)
            photo_b64.value = await file2Base64(file)
        }

        return { cancel, save, siswa, kelas, choose_photo, image_change, photo_file, photo_url, getPhoto, password, error, ref_kelas, selected_kelas }
    }
})
</script>

<style scoped>

</style>
