import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card assessment-item" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "col-sm-12 col-md-6" }
const _hoisted_10 = {
  key: 0,
  class: "card-footer"
}
const _hoisted_11 = { class: "table-responsive" }
const _hoisted_12 = { class: "table table-bordered" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["href"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = ["onUpdate:modelValue", "readonly"]
const _hoisted_19 = ["onClick", "disabled"]
const _hoisted_20 = { class: "card-footer d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EvaluationMobile = _resolveComponent("EvaluationMobile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Assesment / Assignment Evaluation")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.done)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[1] || (_cache[1] = _createElementVNode("b", null, "Guru Pembuat", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.assignment.teacher_name), 1),
                      _cache[2] || (_cache[2] = _createElementVNode("b", null, "Subject", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.assignment.subject_name), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("b", null, "Judul Tugas", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.assignment.title), 1),
                      _cache[4] || (_cache[4] = _createElementVNode("b", null, "Tanggal & Waktu Pengerjaan", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.assignment.completion_date)), 1),
                      _cache[5] || (_cache[5] = _createElementVNode("b", null, "Tanggal & Waktu Pengumpulan", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.assignment.collection_date)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[6] || (_cache[6] = _createElementVNode("b", null, "Kelas", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.assignment.grades.map(v => v.nama_kelas).join(', ')), 1),
                      _cache[7] || (_cache[7] = _createElementVNode("b", null, "Siswa", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.assignment.students.map(v => v.nama).join(', ')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("table", _hoisted_12, [
                      _cache[9] || (_cache[9] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, "No"),
                          _createElementVNode("th", null, "Siswa"),
                          _createElementVNode("th", null, "Jawaban"),
                          _createElementVNode("th", null, "Link"),
                          _createElementVNode("th", null, "Lampiran"),
                          _createElementVNode("th", null, "Nilai"),
                          _createElementVNode("th", null, "Aksi")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignment.assignment_students, (v, k) => {
                          return (_openBlock(), _createElementBlock("tr", null, [
                            _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                            _createElementVNode("td", null, [
                              _createElementVNode("span", null, _toDisplayString(v.student_name), 1)
                            ]),
                            _createElementVNode("td", null, [
                              _createElementVNode("div", {
                                innerHTML: v.answer
                              }, null, 8, _hoisted_13)
                            ]),
                            _createElementVNode("td", null, [
                              (v.link)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: v.link,
                                    target: "_blank"
                                  }, _toDisplayString(v.link), 9, _hoisted_14))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("td", null, [
                              (v.attachments.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(v.attachments.length) + " file:", 1))
                                : _createCommentVNode("", true),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.attachments, (a) => {
                                return (_openBlock(), _createElementBlock("a", {
                                  href: a.url,
                                  class: "d-block"
                                }, _toDisplayString(a.file_name), 9, _hoisted_16))
                              }), 256))
                            ]),
                            _createElementVNode("td", null, [
                              _createElementVNode("div", _hoisted_17, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "number",
                                  class: "form-control form-control-sm",
                                  "onUpdate:modelValue": ($event: any) => ((v.score) = $event),
                                  readonly: v.saving
                                }, null, 8, _hoisted_18), [
                                  [_vModelText, v.score]
                                ])
                              ])
                            ]),
                            _createElementVNode("td", null, [
                              _createElementVNode("button", {
                                class: "btn btn-sm btn-success",
                                onClick: ($event: any) => (_ctx.save(v)),
                                disabled: v.saving
                              }, _cache[8] || (_cache[8] = [
                                _createElementVNode("i", { class: "fa fa-save" }, null, -1)
                              ]), 8, _hoisted_19)
                            ])
                          ]))
                        }), 256))
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("button", {
                class: "btn btn-danger",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali")
            ])
          ]),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignment.assignment_students, (v, k) => {
              return (_openBlock(), _createBlock(_component_EvaluationMobile, {
                assignment: v,
                done: !!_ctx.doneScoring,
                onOnChange: (n) => _ctx.onChange(k, n)
              }, null, 8, ["assignment", "done", "onOnChange"]))
            }), 256))
          ])
        ])
      ])
    ])
  ]))
}