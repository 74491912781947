<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Add Daily Schedule</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Tahun Ajaran <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="request.tahun_ajaran">
                                        <span class="text-danger" v-if="validation.tahun_ajaran">{{ validation.tahun_ajaran }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save">Simpan</button>
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {IDailySchedule} from "@/utils/interface/DailySchedule";
import {IOption} from "@/utils/interface/Assignment";
import Api from "@/utils/Api";
import {Kelas, User} from "@/utils/Models";
import Multiselect from "@vueform/multiselect";
import {useRouter} from "vue-router";
import Session from "@/utils/Session";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "AddDailySchedule",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const request = reactive<IDailySchedule>({
            tahun_ajaran: '',
        })
        const validation = reactive({
            tahun_ajaran: '',
        })
        const save = async () => {
            validation.tahun_ajaran = ''
            if (!request.tahun_ajaran) {
                validation.tahun_ajaran = 'Masukkan tahun ajaran. Contoh: (2022 - 2023)'
                return
            }

            let data = new FormData()
            data.append('tahun_ajaran', `${request.tahun_ajaran}`)
            data.append('created_by', `${Session.getCurrentUser()!.id}`)

            const t = loading('Menyimpan data')
            const r = await Api.post('daily-schedule/create', data, { 'Content-Type': 'multipart/form-data' })
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        return {
            request, save, validation,
            back: () => router.back()
        }
    }
})
</script>

<style scoped>

</style>