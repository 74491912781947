import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_15 = { class: "col-sm-12 col-md-6" }
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-control" }
const _hoisted_19 = {
  class: "form-control mb-2 d-flex align-items-center",
  style: {"padding":"0.6rem"}
}
const _hoisted_20 = { style: {"flex":"1","min-width":"0"} }
const _hoisted_21 = ["href"]
const _hoisted_22 = { key: 1 }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "d-flex flex-row-reverse" }
const _hoisted_25 = { class: "card-footer d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Edit Student Academic Progress / Achievements")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", null, [
                      _createTextVNode("Siswa "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.student_id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.request.student_id) = $event)),
                      options: _ctx.refStudent,
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.validation.student)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.validation.student), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", null, [
                      _createTextVNode("Subject "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.subject_id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.request.subject_id) = $event)),
                      options: _ctx.refSubject,
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.validation.subject)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.validation.subject), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", null, [
                      _createTextVNode("Semester "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.semester,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.request.semester) = $event)),
                      options: [{value: 1, label: 'Ganjil'},{value: 2, label: 'Genap'}],
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue"]),
                    (_ctx.validation.semester)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.validation.semester), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", null, [
                      _createTextVNode("Catatan "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.request.note) = $event)),
                      rows: "5"
                    }, null, 512), [
                      [_vModelText, _ctx.request.note]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", null, [
                      _createTextVNode("Lampiran "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createElementVNode("div", _hoisted_18, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request.attachments.filter(v => v.deleted !== true), (v) => {
                        return (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createElementVNode("span", _hoisted_20, [
                            (v.url)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  href: v.url ?? '',
                                  target: "_blank"
                                }, _toDisplayString(v.file_name), 9, _hoisted_21))
                              : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(v.file?.name), 1))
                          ]),
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-danger ml-1",
                            onClick: () => v.deleted = true
                          }, _cache[12] || (_cache[12] = [
                            _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                          ]), 8, _hoisted_23)
                        ]))
                      }), 256)),
                      _createElementVNode("input", {
                        type: "file",
                        ref: "question_file",
                        id: "question_file",
                        accept: "image/*, .mp3, video/mp4, video/x-m4v, video/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .zip, .rar, .7zip",
                        onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.question_change && _ctx.question_change(...args))),
                        style: {"display":"none"}
                      }, null, 544),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("button", {
                          type: "button",
                          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.add_question && _ctx.add_question(...args))),
                          class: "btn btn-sm btn-success"
                        }, _cache[13] || (_cache[13] = [
                          _createElementVNode("i", { class: "fa fa-plus" }, null, -1),
                          _createTextVNode(" Tambah File ")
                        ]))
                      ])
                    ]),
                    _cache[15] || (_cache[15] = _createElementVNode("p", { class: "mt-1 mb-0" }, "Tipe file yang diizinkan adalah .mp3, .mp4, .pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .xlsx, .xls, .rar, .zip, .ppt dengan maksimal 5 MB.", -1))
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
              }, "Simpan"),
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}