<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card timeline-item">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Academic Calendar</div>
                            </div>
                        </div>
                        <div class="card-body" :class="{'px-0': isMobile()}">
                            <Calendar :nearest="nearest" :agendas="agendas" :on-agenda-click="onAgendaClick"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal :show="showModal" @close="showModal = false">
        <template #header>
            <div class="d-flex align-items-center" style="width: 100%">
                <h4 class="mb-0" style="flex: 1">Detail Agenda</h4>
                <div :class="`my-cv-item-${dataModal?.customData?.color}`" style="width: 15px; height: 15px; border-radius: 20px"></div>
            </div>
        </template>
        <template #body>
            <b>Agenda</b>
            <p>{{ dataModal?.title }}</p>
            <b>Date</b>
            <p>{{ toIndoDateName(dataModal?.start_date) }} - {{ toIndoDateName(dataModal?.end_date) }}</p>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Calendar from "@/components/partials/Calendar.vue";
import {AgendaResponse, agendaResponseToI, IAgenda} from "@/utils/interface/Agenda";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {isMobile, toIndoDateName} from "@/utils/Helpers";
import Modal from "@/components/partials/Modal.vue";

export default defineComponent({
    name: "AcademicCalendar",
    components: {
        Modal,
        Calendar,
    },
    setup() {
        const agendas = ref<IAgenda[]>()
        const nearest = ref<IAgenda[]>()
        const showModal = ref(false)
        const dataModal = ref<IAgenda|null>(null)

        onMounted(async () => {
            const t = loading('Mendapatkan agenda')
            const r = await Api.get('agenda')
            const r3 = await Api.get('agenda/nearest')
            t.remove()
            if (r.success) {
                agendas.value = (r.data as AgendaResponse[]).map(v => agendaResponseToI(v))
            }
            else {
                error(r.message ?? 'gagal')
            }
            if (r3.success) {
                nearest.value = (r3.data as AgendaResponse[]).map(v => agendaResponseToI(v))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const onAgendaClick = (v: IAgenda) => {
            dataModal.value = v
            showModal.value = true
        }

        return {
            agendas, isMobile, showModal, onAgendaClick, dataModal, toIndoDateName, nearest
        }
    }
})

</script>

<style scoped>
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 800px;
}
</style>