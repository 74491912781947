import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, vModelRadio as _vModelRadio, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "col-sm-12 col-md-6" }
const _hoisted_21 = { class: "form-check" }
const _hoisted_22 = { class: "form-radio-label" }
const _hoisted_23 = { class: "form-radio-label ml-3" }
const _hoisted_24 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_25 = { class: "form-group" }
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_28 = { class: "profile-picture mb-1" }
const _hoisted_29 = { class: "avatar avatar-xl" }
const _hoisted_30 = ["src"]
const _hoisted_31 = { class: "form-group" }
const _hoisted_32 = { class: "card-footer d-flex flex-row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "fw-bold" }, "Edit Siswa")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.siswa)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _cache[15] || (_cache[15] = _createElementVNode("label", null, "NISN", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.siswa.nisn) = $event)),
                          placeholder: "Masukkan NISN"
                        }, null, 512), [
                          [_vModelText, _ctx.siswa.nisn]
                        ]),
                        (_ctx.error.nisn)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.error.nisn), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _cache[16] || (_cache[16] = _createElementVNode("label", null, [
                          _createTextVNode("Nama "),
                          _createElementVNode("b", { class: "text-danger" }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.siswa.nama) = $event)),
                          placeholder: "Masukkan Nama"
                        }, null, 512), [
                          [_vModelText, _ctx.siswa.nama]
                        ]),
                        (_ctx.error.nama)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.error.nama), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[17] || (_cache[17] = _createElementVNode("label", null, "Tempat Lahir", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.siswa.tempat_lahir) = $event)),
                          placeholder: "Masukkan Tempat Lahir"
                        }, null, 512), [
                          [_vModelText, _ctx.siswa.tempat_lahir]
                        ]),
                        (_ctx.error.tempat_lahir)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.error.tempat_lahir), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _cache[18] || (_cache[18] = _createElementVNode("label", null, "Tanggal Lahir", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "date",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.siswa.tgl_lahir) = $event)),
                          placeholder: "Masukkan Tanggal Lahir"
                        }, null, 512), [
                          [_vModelText, _ctx.siswa.tgl_lahir]
                        ]),
                        (_ctx.error.tgl_lahir)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.error.tgl_lahir), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _cache[19] || (_cache[19] = _createElementVNode("label", null, [
                          _createTextVNode("NIS "),
                          _createElementVNode("b", { class: "text-danger" }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.siswa.nis) = $event)),
                          placeholder: "Masukkan NIS"
                        }, null, 512), [
                          [_vModelText, _ctx.siswa.nis]
                        ]),
                        (_ctx.error.nis)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.error.nis), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _cache[21] || (_cache[21] = _createElementVNode("label", null, "Agama", -1)),
                        _withDirectives(_createElementVNode("select", {
                          class: "form-control",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.siswa.agama) = $event))
                        }, _cache[20] || (_cache[20] = [
                          _createStaticVNode("<option value=\"Islam\">Islam</option><option value=\"Kristen\">Kristen</option><option value=\"Katolik\">Katolik</option><option value=\"Budha\">Budha</option><option value=\"Hindu\">Hindu</option>", 5)
                        ]), 512), [
                          [_vModelSelect, _ctx.siswa.agama]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _cache[24] || (_cache[24] = _createElementVNode("label", null, "Jenis Kelamin", -1)),
                        _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("label", _hoisted_22, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-radio-input",
                            type: "radio",
                            value: "L",
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.siswa.jenis_kelamin) = $event))
                          }, null, 512), [
                            [_vModelRadio, _ctx.siswa.jenis_kelamin]
                          ]),
                          _cache[22] || (_cache[22] = _createElementVNode("span", { class: "form-radio-sign" }, "Laki-Laki", -1))
                        ]),
                        _createElementVNode("label", _hoisted_23, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-radio-input",
                            type: "radio",
                            value: "P",
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.siswa.jenis_kelamin) = $event))
                          }, null, 512), [
                            [_vModelRadio, _ctx.siswa.jenis_kelamin]
                          ]),
                          _cache[23] || (_cache[23] = _createElementVNode("span", { class: "form-radio-sign" }, "Perempuan", -1))
                        ]),
                        (_ctx.error.jenis_kelamin)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.error.jenis_kelamin), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _cache[26] || (_cache[26] = _createElementVNode("label", null, "Alamat (Opsional)", -1)),
                        _withDirectives(_createElementVNode("textarea", {
                          class: "form-control",
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.siswa.alamat) = $event)),
                          rows: "4",
                          placeholder: "Masukkan Alamat"
                        }, null, 512), [
                          [_vModelText, _ctx.siswa.alamat]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _cache[27] || (_cache[27] = _createElementVNode("label", null, [
                          _createTextVNode("Kelas "),
                          _createElementVNode("b", { class: "text-danger" }, "*")
                        ], -1)),
                        _createVNode(_component_Multiselect, {
                          modelValue: _ctx.selected_kelas,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selected_kelas) = $event)),
                          options: _ctx.ref_kelas,
                          searchable: true,
                          "create-option": false,
                          mode: "tags"
                        }, null, 8, ["modelValue", "options"]),
                        (_ctx.error.kelas)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.error.kelas), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("img", {
                            src: _ctx.photo_url ?? _ctx.getPhoto(_ctx.siswa),
                            alt: "...",
                            class: "avatar-img rounded-circle",
                            id: "photo-display"
                          }, null, 8, _hoisted_30)
                        ]),
                        _createElementVNode("input", {
                          type: "file",
                          ref: "photo_file",
                          id: "photo_file",
                          accept: "image/*",
                          onChange: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.image_change && _ctx.image_change(...args))),
                          style: {"display":"none"}
                        }, null, 544),
                        _createElementVNode("button", {
                          type: "button",
                          onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.choose_photo && _ctx.choose_photo(...args))),
                          class: "btn btn-round btn-sm btn-outline-success ml-2"
                        }, "Pilih Photo")
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _cache[28] || (_cache[28] = _createElementVNode("label", null, "Password (Hanya isi ketika ingin dirubah)", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "password",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.password) = $event)),
                          placeholder: "Masukkan Password",
                          autocomplete: "new-password"
                        }, null, 512), [
                          [_vModelText, _ctx.password]
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
              }, "Batal"),
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
              }, "Simpan")
            ])
          ])
        ])
      ])
    ])
  ]))
}