import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "fw-bold mb-0" }, "IV. Evaluation")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("textarea", {
          class: "form-control",
          rows: "4",
          value: _ctx.evaluation,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
        }, null, 40, _hoisted_4)
      ])
    ])
  ]))
}