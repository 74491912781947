<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Add Syllabus</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Guru Pembuat <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.teacher_id" :options="refTeacher" :searchable="true"/>
                                        <span class="text-danger" v-if="validation.teacher">{{ validation.teacher }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Subject <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.subject_id" :options="refSubject" :searchable="true"/>
                                        <span class="text-danger" v-if="validation.subject">{{ validation.subject }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Kelas <b class="text-danger">*</b></label>
                                        <Multiselect
                                            mode="tags"
                                            v-model="request.grade_options"
                                            :options="refGrade"
                                            :searchable="true"
                                            :multiple="true"
                                        />
                                        <span class="text-danger" v-if="validation.grade">{{ validation.grade }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Semester <b class="text-danger">*</b></label>
                                        <Multiselect
                                            v-model="request.semester"
                                            :options="[{value: 1, label: 'Ganjil'},{value: 2, label: 'Genap'}]"
                                            :searchable="true"
                                        />
                                        <span class="text-danger" v-if="validation.semester">{{ validation.semester }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Lampiran <b class="text-danger">*</b></label>
                                        <div class="form-control">
                                            <div v-if="request.attachment_file || request.attachment" class="form-control mb-2 d-flex align-items-center" style="padding: 0.6rem; width: 100%">
                                                <span style="flex: 1">
                                                    <span v-if="request.attachment_file">{{ request.attachment_file?.name }}</span>
                                                    <a v-else :href="request.attachment.url" target="_blank">{{ request.attachment.name }}</a>
                                                </span>
                                                <button class="btn btn-sm btn-danger ml-1" @click="remove_attachment()">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </div>
                                            <input type="file"
                                                   ref="question_file"
                                                   id="question_file"
                                                   accept="image/*, .mp3, video/mp4, video/x-m4v, video/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .zip, .rar, .7zip"
                                                   @change="attachment_change"
                                                   style="display: none">
                                            <div class="d-flex flex-row-reverse" v-if="!request.attachment_file && !request.attachment">
                                                <button type="button" @click="add_attachment" class="btn btn-sm btn-success">
                                                    <i class="fa fa-plus"></i> Pilih Lampiran
                                                </button>
                                            </div>
                                        </div>
                                        <span class="text-danger" v-if="validation.attachment">{{ validation.attachment }}</span>
                                        <p class="mt-1 mb-0">Tipe file yang diizinkan adalah .mp3, .mp4, .pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .xlsx, .xls, .rar, .zip, .ppt dengan maksimal 5 MB.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save">Simpan</button>
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {IOption} from "@/utils/interface/Assignment";
import {ISyllabus} from "@/utils/interface/Syllabus";
import Api from "../../../utils/Api";
import {Kelas, Pelajaran, User} from "@/utils/Models";
import Multiselect from "@vueform/multiselect";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "UpdateSyllabus",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id

        const refTeacher = ref<IOption[]>([])
        const refGrade = ref<IOption[]>([])
        const refSubject = ref<IOption[]>([])
        const request = reactive<ISyllabus>({
            subject_id: 0,
            grades: [],
            grade_options: [],
            teacher_id: 0,
            semester: 1,
        })
        const validation = reactive({
            teacher: '',
            subject: '',
            grade: '',
            semester: '',
            attachment: '',
        })

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            const r2 = await Api.get(`syllabus/${id}`)
            t.remove()

            if (r.success) {
                refTeacher.value = (r.data.pic as User[]).map(v => ({value: v.id, label: v.name}))
                refGrade.value = (r.data.grade as Kelas[]).map(v => ({value: v.id, label: v.nama_kelas}))
                refSubject.value = (r.data.mapel as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
            }
            else {
                error(r.message ?? 'gagal')
            }

            if (r2.success) {
                const a = r2.data as ISyllabus

                request.teacher_id = a.teacher_id
                request.teacher_name = a.teacher_name
                request.subject_id = a.subject_id
                request.subject_name = a.subject_name
                request.grades = a.grades
                request.semester_name = a.semester_name
                request.attachment = a.attachment
                request.grade_options = a.grades.map(v => v.id)
            }
            else {
                error(r2.message ?? 'gagal')
            }
        })

        const validate = () => {
            validation.teacher = ''
            validation.subject = ''
            validation.grade = ''
            validation.semester = ''
            validation.attachment = ''

            if (!request.teacher_id) {
                validation.teacher = 'Pilih guru dulu'
                return false
            }
            if (!request.subject_id) {
                validation.subject = 'Pilih subject dulu'
                return false
            }
            if (!request.grade_options.length) {
                validation.grade = 'Pilih kelas dulu'
                return false
            }
            if (!request.semester) {
                validation.semester = 'Pilih semester dulu'
                return false
            }
            if (!request.attachment_file && !request.attachment) {
                validation.attachment = 'Pilih lampiran dulu'
                return false
            }
            return true
        }
        const save = async () => {
            if (!validate()) {
                return
            }

            let data = new FormData()
            data.append('teacher_id', `${request.teacher_id}`)
            data.append('subject_id', `${request.subject_id}`)
            request.grade_options.forEach(v => data.append('grades[]', `${v}`))
            data.append('semester', `${request.semester}`)
            if (request.attachment_file) {
                data.append('attachment', request.attachment_file)
            }

            const t = loading('Menyimpan data')
            const r = await Api.post(`syllabus/update/${id}`, data, { 'Content-Type': 'multipart/form-data' })
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        const add_attachment = () => document.getElementById('question_file')?.click()
        const remove_attachment = () => request.attachment = request.attachment_file = undefined
        const attachment_change = (event: any) => request.attachment_file = event.target.files[0]

        return {
            request, refTeacher, refGrade, refSubject, validation, save,
            remove_attachment, attachment_change, add_attachment,
            back: () => router.back(),
        }
    }
})
</script>

<style scoped>

</style>