<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row mb-3 align-items-center flex-column">
                                <div class="card-title">
                                    Daily Schedule Bumi Cendekia Yogyakarta
                                </div>
                                <div class="card-title" v-if="schedules.length">
                                    {{ schedules[0].tahun_ajaran }}
                                </div>
                            </div>
                            <DailyScheduleView v-if="schedules.length" :daily-schedule-id="schedules[0].id"/>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">List Daily Schedule</div>
                                <div class="card-tools">
                                    <button @click="add" class="b-time btn btn-success btn-round mr-2">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Tahun Ajaran</th>
                                    <th>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v, k) in schedules">
                                    <td>{{ k + 1 }}</td>
                                    <td>{{ v.tahun_ajaran }}</td>
                                    <td>
                                        <button class="btn btn-warning btn-sm mr-1" @click="edit(v.id)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                        <button class="btn btn-success btn-sm mr-1" @click="detail(v.id)">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                        <button class="btn btn-danger btn-sm" @click="destroy(v.id)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {IDailySchedule} from "@/utils/interface/DailySchedule";
import Api from "@/utils/Api";
import {error, loading, success} from "@/utils/Toast";
import DailyScheduleView from "@/components/academic/daily_schedule/partials/DailyScheduleView.vue";

export default defineComponent({
    name: "DailySchedule",
    components: {
        DailyScheduleView
    },
    setup() {
        const router = useRouter()
        const schedules = ref<IDailySchedule[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('daily-schedule')
            t.remove()

            if (r.success) {
                schedules.value = r.data as IDailySchedule[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.get(`daily-schedule/delete/${id}`)
                t.remove()

                if (r.success) {
                    schedules.value = r.data as IDailySchedule[]
                    success('Berhasil menghapus data')
                }
                else {
                    error(r.message ?? 'gagal')
                }
            }
        }

        return {
            schedules,
            add: () => router.push('/academic/daily-schedule/add'),
            edit: (id: number) => router.push(`/academic/daily-schedule/edit/${id}`),
            detail: (id: number) => router.push(`/academic/daily-schedule/${id}`),
            destroy
        }
    }
})
</script>

<style scoped>

</style>