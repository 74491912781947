<template>
    <div v-if="loadingParent" class="loading-parent">
        <div style="flex: 1"></div>
        <img src="./assets/img/logo_smp_sma.png" style="width: 200px">
        <div style="flex: 1"></div>
    </div>

    <div v-else-if="route.name !== 'login'" :class="`wrapper ${!!isAndroid ? 'wrapper-mobile' : ''}`">
        <Header :is-android="!!isAndroid"/>
        <Sidebar/>
        <div class="main-panel">
            <router-view/>
            <Footer/>
        </div>
    </div>

    <router-view v-else/>
</template>

<script lang="ts">
import Header from './components/partials/Header.vue'
import Sidebar from './components/partials/Sidebar.vue'
import Footer from './components/partials/Footer.vue'
import {defineComponent, onBeforeUnmount, ref} from "vue"
import {useRoute, useRouter} from "vue-router"
import '@vueform/multiselect/themes/default.css'
import Session from "@/utils/Session"
import Api from "@/utils/Api";
import {User} from "@/utils/Models";
import {error} from "@/utils/Toast";
import {crypt} from "@/utils/Crypt";
import Role from "@/utils/Role";
import {useStore} from "vuex";

export default defineComponent({
    name: 'App',
    components: {
        Header,
        Sidebar,
        Footer
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const params = (new URL(document.location.toString())).searchParams;
        const isAndroid = params.get('a')
        const loadingParent = ref(false)
        const store = useStore()

        Session.setIsAndroid(!!isAndroid)
        console.log({isAndroid, params: params.get('a'), ses: Session.getIsAndroid()})

        const check = () => {
            const timer = setInterval(() => reload(), 60000)
            onBeforeUnmount(() => clearInterval(timer))

            const reload = async () => {
                const user = Session.getCurrentUser()
                if (user) {
                    const r = await Api.get(`chat/check/${user!.id}`)
                    store.commit('adaChat', !!r.data)
                }
            }

            reload()
        }

        const getParent = async () => {
            loadingParent.value = true

            const id = params.get('o')
            const pwd = params.get('p') ?? ''
            const r = await Api.get(`parent/${id}/${pwd}`)
            if (r.success) {
                Session.login({
                    user: JSON.stringify(r.data.user as User),
                    password: crypt('wkwk', pwd),
                    token: r.data.token,
                })
                loadingParent.value = false
                check()
                router.push('/timeline')
            }
            else {
                error('Terdapat kesalahan')
            }
        }

        if (isAndroid) {
            Session.setIsAndroid(true)
            console.log({innSIde: true, isAndroid, params: params.get('a'), ses: Session.getIsAndroid()})
            getParent()
        }
        else {
            const user = Session.getCurrentUser()
            if (!user) {
                router.push('/login')
            }

            const pwd = Session.getPassword()
            if (!pwd) {
                router.push('/login')
            }

            check()

            if (user?.role_id === Role.Ortu) {
                router.push('/timeline')
            }
            else if (user?.role_id === Role.Siswa) {
                router.push('/my-assessment')
            }
            else if (user?.role_id === Role.Dokter) {
                router.push('/health')
            }
        }

        return {
            route, isAndroid, loadingParent, Role
        }
    }
})
</script>

<style scoped>
.loading-parent {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>

<style>
@import 'https://fonts.googleapis.com/css?family=Lato:300,400,700,900';
@import 'https://administrator.bumicendekia.sch.id/assets/css/fonts.min.css';
@import 'https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css';

@import 'https://administrator.bumicendekia.sch.id/assets/css/bootstrap.min.css';
@import 'https://administrator.bumicendekia.sch.id/assets/css/atlantis.min.css';
@import 'https://administrator.bumicendekia.sch.id/assets/toastr/toastr.min.css';
@import 'https://gyrocode.github.io/jquery-datatables-checkboxes/1.2.12/css/dataTables.checkboxes.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css';

@import './assets/style/custom.css';
@import './assets/style/calendar.css';

@import 'https://administrator.bumicendekia.sch.id/assets/css/journal.css';

.wrapper-mobile {
  font-family: 'DM Sans', 'sans-serif';
}
</style>
