<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Daily Journal {{level === 'smp' ? 'SMP' : level === 'sma' ? 'SMA' : 'Pesantren'}}</div>
                                <div class="card-tools">
                                    <button @click="add" class="b-time btn btn-success btn-round mr-2">
                                        Add Journal
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-header">
                            <div class="row">
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label>Month</label>
                                        <select class="form-control" v-model="curMonth" @change="reload">
                                            <option v-for="(v, k) in months" :value="k">{{v}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label>Year</label>
                                        <select class="form-control" v-model="curYear" @change="reload">
                                            <option v-for="(v) in years" :value="v">{{v}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
							<PaginatedTable :data="dailyJournal">
								<template #thead>
									<th>No</th>
									<th>Date</th>
									<th>PIC</th>
									<th>Lesson Hours</th>
									<th>Aksi</th>
								</template>
								<template #tbody="{row, index}">
									<td>{{ index + 1 }}</td>
									<td>{{ row.data.day }}, {{ toIndoDateName(row.data.date) }}</td>
									<td>{{ row.data.pic_name }}</td>
									<td>{{ row.data.lesson_hour }}</td>
									<td>
										<button class="btn btn-success btn-sm" @click="detail(row.data.id)">
											<i class="fa fa-eye"></i>
										</button>
										<button class="btn btn-warning btn-sm ml-2" @click="edit(row.data.id)">
											<i class="fa fa-pencil"></i>
										</button>
									</td>
								</template>
							</PaginatedTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import DailyJournalRequest from "@/utils/interface/DailyJournal";
import Api from "@/utils/Api";
import {months, toIndoDateName, years} from "@/utils/Helpers";
import {error, loading} from "@/utils/Toast";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "DailyJournal",
	components: {PaginatedTable},
    setup() {
        const router = useRouter()
		const route = useRoute()
		const level = computed(() => route.params.level)
        const add = () => router.push(`/daily-journal/${level.value}/add`)
        const dailyJournal = ref<DailyJournalRequest[]>([])
        const curMonth = ref(new Date().getMonth())
        const curYear = ref(new Date().getFullYear())

        const reload = async () => {
            dailyJournal.value = []

            const t = loading()
            const r = await Api.post('daily-journal/search', {
                month: curMonth.value + 1,
                year: curYear.value,
				level: level.value,
            })
            t.remove()

            if (r.success) {
                dailyJournal.value = r.data as DailyJournalRequest[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        onMounted(() => reload())
		watch(() => level.value, () => reload())

        return {
            add, dailyJournal, toIndoDateName,
            detail: (id: number) => router.push(`/daily-journal/${level.value}/${id}`),
            edit: (id: number) => router.push(`/daily-journal/${level.value}/edit/${id}`),
            months, years, curMonth, curYear, level,
            reload,
        }
    }
})
</script>

<style scoped>

</style>
