<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Detail Master Report</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4">
                                    <b>Angkatan</b>
                                    <p>{{ report.angkatan }}</p>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4">
                                    <b>Kelas</b>
                                    <p>{{ report.nama_kelas }}</p>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4">
                                    <b>Semester</b>
                                    <p>{{ report.semester_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-danger" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Student Report</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Siswa</th>
                                        <th>Rapot</th>
                                        <th>Terupload</th>
                                        <th>Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v, k) in report.master_report_student">
                                        <td>{{ k + 1 }}</td>
                                        <td>{{ v.student_name }}</td>
                                        <td>
                                            <div class="form-group">
                                                <div class="form-control" :class="{'form-control-saving': v.saving}">
                                                    <input type="file"
                                                           ref="question_file"
                                                           :id="`question_file_${k}`"
                                                           accept="application/pdf"
                                                           @change="(e) => attachment_change(e, v)"
                                                           style="display: none">
                                                    <div class="d-flex flex-row align-items-center">
                                                        <div style="flex: 1">
                                                            <span v-if="v.report_file">{{ v.report_file.name }}</span>
                                                            <a v-else-if="v.report?.name" :href="v.report?.url" target="_blank">{{ v.report?.name }}</a>
                                                        </div>
                                                        <button type="button" @click="add_attachment(k)" class="btn btn-sm btn-success ml-1" :disabled="v.saving">
                                                            {{ v.saving ? 'Menyimpan' : (v.report_file || v.report?.name) ? 'Ganti' : 'Pilih' }} Raport
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="badge badge-danger" v-if="!v.report?.name">Belum</span>
                                            <span class="badge badge-success" v-else>Sudah</span>
                                        </td>
                                        <td>
                                            <button class="btn btn-success btn-sm" :disabled="v.saving" @click="save(v, k)">
                                                <i class="fa fa-save"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-danger" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";

export default defineComponent({
    name: "DetailReport",
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id
        const report = reactive<IMasterReport>({})

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r2 = await Api.get(`master-report/${id}`)
            t.remove()

            if (r2.success) {
                report.angkatan = r2.data.angkatan
                report.semester_name = r2.data.semester_name
                report.trx_class_id = r2.data.trx_class_id
                report.nama_kelas = r2.data.nama_kelas
                report.master_report_student = r2.data.master_report_student
            }
            else {
                error(r2.message ?? 'Gagal')
            }
        })

        const add_attachment = (k: number) => document.getElementById(`question_file_${k}`)?.click()
        const remove_attachment = (v: IMasterReportStudent) => v.report = v.report_file = undefined
        const attachment_change = (event: any, v: IMasterReportStudent) => v.report_file = event.target.files[0]
        const save = async (v: IMasterReportStudent, k: number) => {
            v.saving = true
            let data = new FormData()
            data.append('id', `${v.id}`)
            data.append('report', v.report_file!)
            const r = await Api.post(`master-report/upload/report`, data, { 'Content-Type': 'multipart/form-data' })
            if (r.success) {
                v.saving = false
                report.master_report_student![k] = r.data as IMasterReportStudent
            }
            else {
                v.saving = false
                error(r.message ?? 'gagal')
            }
        }

        return {
            report,
            back: () => router.back(),
            add_attachment, remove_attachment, attachment_change, save
        }
    }
})
</script>

<style scoped>
.form-control-saving {
    background: #eeeeee;
}
</style>