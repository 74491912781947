<template>
    <h4 class="text-center fw-bold">Teacher Attendance</h4>
    <div class="d-flex align-items-center justify-content-center">
        <select class="form-control form-control-sm" v-model="curMonth" style="max-width: 150px" @change="reload">
            <option v-for="(v, k) in months" :value="k">{{ v }}</option>
        </select>
        <select class="form-control form-control-sm ml-2" v-model="curYear" style="max-width: 150px" @change="reload">
            <option v-for="v in years" :value="v">{{ v }}</option>
        </select>
    </div>
    <div class="chart-container" :style="{height: isMobile() ? '250px' : '350px'}">
        <Bar
            id="my-chart-id"
            :options="chartOptions"
            :data="chartData"
        />
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from "vue";
import {getDays, isMobile, months, toSqlDate, years} from "@/utils/Helpers";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import {Bar} from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default defineComponent({
    name: "TeacherAttendanceChart",
    components: {
        Bar
    },
    setup() {
        const presentData = ref<number[]>([])
        const absentData = ref<number[]>([])
        const nameData = ref<string[][]>([])
        const curMonth = ref(new Date().getMonth())
        const curYear = ref(new Date().getFullYear())
        const firstDay = ref(new Date(curYear.value, curMonth.value, 1))
        const lastDay = ref(new Date(curYear.value, curMonth.value + 1, 0))

        const chartData = computed(() => ({
            labels: getDays(firstDay.value, lastDay.value),
            datasets: [
                {
                    data: presentData.value,
                    label: 'Present',
                    backgroundColor: '#75f15f',
                },
                {
                    data: absentData.value,
                    label: 'Absent',
                    backgroundColor: '#f87979',
                },
            ]
        }))

        const reload = async () => {
            firstDay.value = new Date(curYear.value, curMonth.value, 1)
            lastDay.value = new Date(curYear.value, curMonth.value + 1, 0)
            presentData.value = getDays(firstDay.value, lastDay.value).map(_ => 0)
            absentData.value = getDays(firstDay.value, lastDay.value).map(_ => 0)
            nameData.value = getDays(firstDay.value, lastDay.value).map(_ => [])

            const t = loading('Mendapatkan data')
            const r = await Api.get(`teacher_attendance/${toSqlDate(firstDay.value)}/${toSqlDate(lastDay.value)}`)
            t.remove()

            if (r.success) {
                presentData.value = r.data.map((v: any) => v.presence)
                absentData.value = r.data.map((v: any) => v.absence)
                nameData.value = r.data.map((v: any) => v.names)
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        onMounted(() => {
            reload()
        })

        return {
            curMonth, curYear, months, years, isMobile, chartData, reload,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scale: {
                    ticks: {
                        precision: 0
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(context: any) {
                                if (context.datasetIndex === 0) {
                                    return `  Presence: ${context.dataset.data[context.dataIndex]}`
                                }
                                return nameData.value[context.dataIndex].map(v => `  ${v}`)
                            },
                            title: function(context: any) {
                                if (context[0].datasetIndex === 0) {
                                    return `Present On: ${context[0].label}`
                                }
                                if (context[0].datasetIndex === 1) {
                                    return `Absent On: ${context[0].label}`
                                }
                                return '-'
                            },
                        }
                    },
                    legend: {
                        position: 'bottom',
                        align: 'end'
                    }
                }
            }
        }
    }
})
</script>

<style scoped>

</style>