<template>
    <ul class="nav nav-primary">
        <li class="nav-section">
            <span class="sidebar-mini-icon">
                <i class="fa fa-ellipsis-h"></i>
            </span>
            <h4 class="text-section">Academic</h4>
        </li>
        <li class="nav-item" :class="{active: route.fullPath === '/'}">
            <router-link to="/">
                <i class="fas fa-home"></i>
                <span class="sub-item">Dashboard </span>
            </router-link>
        </li>
        <li v-if="+role === Role.Kepala" class="nav-item" :class="{active: route.fullPath.startsWith('/master-data')}">
            <router-link to="/master-data">
                <i class="fas fa-school"></i>
                <span class="sub-item">Master Data </span>
            </router-link>
        </li>

		<li class="nav-item submenu" :class="{active: route.fullPath.startsWith('/daily-journal')}">
			<a data-toggle="collapse" href="#daily-journal">
				<i class="fas fa-book"></i>
				<span class="sub-item">Daily Journal </span>
				<span class="caret"></span>
			</a>
			<div class="collapse" id="daily-journal">
				<ul class="nav nav-collapse">
					<li :class="{active: route.fullPath.startsWith('/daily-journal/smp')}">
						<router-link to="/daily-journal/smp">
							<span class="sub-item">SMP </span>
						</router-link>
					</li>
					<li :class="{active: route.fullPath.startsWith('/daily-journal/sma')}">
						<router-link to="/daily-journal/sma">
							<span class="sub-item">SMA </span>
						</router-link>
					</li>
					<li :class="{active: route.fullPath.startsWith('/daily-journal/pesantren')}">
						<router-link to="/daily-journal/pesantren">
							<span class="sub-item">Pesantren </span>
						</router-link>
					</li>
				</ul>
			</div>
		</li>

		<li class="nav-item submenu" :class="{active: route.fullPath.startsWith('/classroom-journal')}">
			<a data-toggle="collapse" href="#classroom-journal">
				<i class="fas fa-bookmark"></i>
				<span class="sub-item">Classroom Journal </span>
				<span class="caret"></span>
			</a>
			<div class="collapse" id="classroom-journal">
				<ul class="nav nav-collapse">
					<li :class="{active: route.fullPath.startsWith('/classroom-journal/smp')}">
						<router-link to="/classroom-journal/smp">
							<span class="sub-item">SMP </span>
						</router-link>
					</li>
					<li :class="{active: route.fullPath.startsWith('/classroom-journal/sma')}">
						<router-link to="/classroom-journal/sma">
							<span class="sub-item">SMA </span>
						</router-link>
					</li>
					<li :class="{active: route.fullPath.startsWith('/classroom-journal/pesantren')}">
						<router-link to="/classroom-journal/pesantren">
							<span class="sub-item">Pesantren </span>
						</router-link>
					</li>
				</ul>
			</div>
		</li>

        <li class="nav-item submenu" :class="{active: route.fullPath.startsWith('/assignment')}">
            <a data-toggle="collapse" href="#assignment">
                <i class="fas fa-user-graduate"></i>
                <span class="sub-item">Assignment / Assessment </span>
                <span class="caret"></span>
            </a>
            <div class="collapse" id="assignment">
                <ul class="nav nav-collapse">
                    <li :class="{active: route.fullPath.startsWith('/assignment') && !route.fullPath.includes('scoring') && !route.fullPath.includes('history') && !route.fullPath.includes('report') && !route.fullPath.includes('evaluation')}">
                        <router-link to="/assignment">
                            <span class="sub-item">Assignment / Assessment </span>
                        </router-link>
                    </li>
                    <li :class="{active: route.fullPath.startsWith('/assignment/scoring')}">
                        <router-link to="/assignment/scoring">
                            <span class="sub-item">Scoring </span>
                        </router-link>
                    </li>
                    <li :class="{active: route.fullPath.startsWith('/assignment/history')}">
                        <router-link to="/assignment/history">
                            <span class="sub-item">History </span>
                        </router-link>
                    </li>
                    <li :class="{active: route.fullPath.startsWith('/assignment/report')}">
                        <router-link to="/assignment/report">
                            <span class="sub-item">Report</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li>

        <li class="nav-item submenu" :class="{active: route.fullPath.startsWith('/academic')}">
            <a data-toggle="collapse" href="#academic">
                <i class="fas fa-graduation-cap"></i>
                <span class="sub-item">Academic </span>
                <span class="caret"></span>
            </a>
            <div class="collapse" id="academic">
                <ul class="nav nav-collapse">
                    <li :class="{active: route.fullPath.startsWith('/academic/syllabus')}">
                        <router-link to="/academic/syllabus">
                            <span class="sub-item">Syllabus / Lesson Plan</span>
                        </router-link>
                    </li>
                    <li :class="{active: route.fullPath.startsWith('/academic/calendar')}">
                        <router-link to="/academic/calendar">
                            <span class="sub-item">Academic Calender</span>
                        </router-link>
                    </li>
                    <li :class="{active: route.fullPath.startsWith('/academic/daily-schedule')}">
                        <router-link to="/academic/daily-schedule">
                            <span class="sub-item">Daily Schedule</span>
                        </router-link>
                    </li>
                    <li :class="{active: route.fullPath.startsWith('/academic/progress')}">
                        <router-link to="/academic/progress">
                            <span class="sub-item">Student Academic Progress / Achievements</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li>

        <li class="nav-item" :class="{active: route.fullPath.startsWith('/announcement')}">
            <router-link to="/announcement">
                <i class="fas fa-bullhorn"></i>
                <span class="sub-item">Announcement </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/information')}">
            <router-link to="/information">
                <i class="fas fa-circle-info"></i>
                <span class="sub-item">Information </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/report')}">
            <router-link to="/report">
                <i class="far fa-chart-bar"></i>
                <span class="sub-item">Report</span>
            </router-link>
        </li>
<!--        <li class="nav-item submenu"-->
<!--            :class="{-->
<!--                active: route.fullPath.startsWith('/teacher-attendance') || route.fullPath.startsWith('/santri-attendance')-->
<!--            }"-->
<!--        >-->
<!--            <a data-toggle="collapse" href="#laporan-laundry">-->
<!--                <i class="far fa-chart-bar"></i>-->
<!--                <span class="sub-item">Report</span>-->
<!--                <span class="caret"></span>-->
<!--            </a>-->
<!--            <div class="collapse" id="laporan-laundry">-->
<!--                <ul class="nav nav-collapse">-->
<!--                    <li class="">-->
<!--                        <router-link to="/teacher-attendance">-->
<!--                            <span class="sub-item">Teacher Attendance</span>-->
<!--                        </router-link>-->
<!--                    </li>-->
<!--                    <li class="">-->
<!--                        <router-link to="/santri-attendance">-->
<!--                            <span class="sub-item">Santri Attendance</span>-->
<!--                        </router-link>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </li>-->
    </ul>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useRoute} from "vue-router";
import Session from "@/utils/Session";
import Role from "@/utils/Role";

export default defineComponent({
    name: "SidebarGuru",
    setup() {
        const route = useRoute()
        const user = Session.getCurrentUser()
        const role = user!.role_id

        return { route, role, Role }
    }
})
</script>

<style scoped>

</style>