import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "col-sm-12 col-md-6" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "ctr" }
const _hoisted_16 = { class: "d-flex align-items-end" }
const _hoisted_17 = {
  class: "row",
  style: {"flex":"1"}
}
const _hoisted_18 = { class: "col-sm-12" }
const _hoisted_19 = { class: "form-group pt-0" }
const _hoisted_20 = ["onUpdate:modelValue"]
const _hoisted_21 = ["onClick", "disabled"]
const _hoisted_22 = { class: "d-flex align-items-end" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-sm-6 pr-0" }
const _hoisted_25 = { class: "form-group pt-0" }
const _hoisted_26 = ["onUpdate:modelValue"]
const _hoisted_27 = { class: "col-sm-6 pl-0" }
const _hoisted_28 = { class: "form-group pt-0" }
const _hoisted_29 = ["onUpdate:modelValue"]
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-sm-12 d-flex justify-content-center" }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = { class: "form-group" }
const _hoisted_34 = { class: "card-footer d-flex flex-row-reverse" }
const _hoisted_35 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Add Health")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", null, [
                      _createTextVNode("Tanggal "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "date",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.request.due_date) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.request.due_date]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", null, [
                      _createTextVNode("Siswa "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.student_id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.request.student_id) = $event)),
                      options: _ctx.refStudent,
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", null, [
                      _createTextVNode("Keluhan "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.request.keluhan) = $event)),
                      rows: "3"
                    }, null, 512), [
                      [_vModelText, _ctx.request.keluhan]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[12] || (_cache[12] = _createElementVNode("label", null, [
                      _createTextVNode("Diagnosa "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.request.diagnosa) = $event)),
                      rows: "3"
                    }, null, 512), [
                      [_vModelText, _ctx.request.diagnosa]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[13] || (_cache[13] = _createElementVNode("label", null, [
                      _createTextVNode("Tindakan "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.request.tindakan) = $event)),
                      rows: "3"
                    }, null, 512), [
                      [_vModelText, _ctx.request.tindakan]
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request.obat, (o, k) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                              _cache[14] || (_cache[14] = _createElementVNode("label", null, "Nama Obat", -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "form-control",
                                "onUpdate:modelValue": ($event: any) => ((o.nama) = $event)
                              }, null, 8, _hoisted_20), [
                                [_vModelText, o.nama]
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("button", {
                          class: "btn btn-danger btn-sm mb-3",
                          onClick: ($event: any) => (_ctx.delete_obat(o.id)),
                          disabled: _ctx.request.obat.length === 1
                        }, _cache[15] || (_cache[15] = [
                          _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                        ]), 8, _hoisted_21)
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                              _cache[16] || (_cache[16] = _createElementVNode("label", null, "Jumlah", -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "number",
                                class: "form-control",
                                "onUpdate:modelValue": ($event: any) => ((o.jumlah) = $event)
                              }, null, 8, _hoisted_26), [
                                [_vModelText, o.jumlah]
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _cache[17] || (_cache[17] = _createElementVNode("label", null, "Signa", -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "form-control",
                                "onUpdate:modelValue": ($event: any) => ((o.signa) = $event)
                              }, null, 8, _hoisted_29), [
                                [_vModelText, o.signa]
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]))
                  }), 256)),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("button", {
                        class: "btn btn-success btn-sm",
                        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.add_obat && _ctx.add_obat(...args)))
                      }, " Tambah Obat ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _cache[18] || (_cache[18] = _createElementVNode("label", null, [
                      _createTextVNode("Tindak Lanjut "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.request.tindak_lanjut) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.request.tindak_lanjut]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _cache[19] || (_cache[19] = _createElementVNode("label", null, [
                      _createTextVNode("Total Biaya "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.request.total_biaya) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.request.total_biaya]
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                disabled: _ctx.saving
              }, "Simpan", 8, _hoisted_35)
            ])
          ])
        ])
      ])
    ])
  ]))
}