import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, vModelRadio as _vModelRadio, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "profile-picture mb-1" }
const _hoisted_10 = { class: "avatar avatar-xl" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_24 = { class: "col-sm-12 col-md-6" }
const _hoisted_25 = { class: "form-group" }
const _hoisted_26 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_27 = { class: "form-group" }
const _hoisted_28 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_29 = { class: "form-check" }
const _hoisted_30 = { class: "form-radio-label" }
const _hoisted_31 = { class: "form-radio-label ml-3" }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = { class: "form-group" }
const _hoisted_34 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_35 = { class: "form-group" }
const _hoisted_36 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_37 = { class: "card-footer d-flex flex-row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[35] || (_cache[35] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "fw-bold" }, "Tambah Guru")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("img", {
                        src: _ctx.photo_url ?? require('@/assets/img/profile2.png'),
                        alt: "...",
                        class: "avatar-img rounded-circle",
                        id: "photo-display"
                      }, null, 8, _hoisted_11)
                    ]),
                    _createElementVNode("input", {
                      type: "file",
                      ref: "photo_file",
                      id: "photo_file",
                      accept: "image/*",
                      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.image_change && _ctx.image_change(...args))),
                      style: {"display":"none"}
                    }, null, 544),
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.choose_photo && _ctx.choose_photo(...args))),
                      class: "btn btn-round btn-sm btn-outline-success ml-2"
                    }, "Pilih Photo")
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[18] || (_cache[18] = _createElementVNode("label", null, [
                      _createTextVNode("Nama Guru "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.name) = $event)),
                      placeholder: "Masukkan Nama Guru",
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.name]
                    ]),
                    (_ctx.nameErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.nameErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[20] || (_cache[20] = _createElementVNode("label", null, [
                      _createTextVNode("Tipe "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.role_id) = $event))
                    }, _cache[19] || (_cache[19] = [
                      _createElementVNode("option", { value: "51" }, "Guru", -1),
                      _createElementVNode("option", { value: "56" }, "Musrif", -1)
                    ]), 512), [
                      [_vModelSelect, _ctx.role_id]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[21] || (_cache[21] = _createElementVNode("label", null, [
                      _createTextVNode("Username "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.username) = $event)),
                      placeholder: "Masukkan Username",
                      required: "",
                      autocomplete: "off"
                    }, null, 512), [
                      [_vModelText, _ctx.username]
                    ]),
                    (_ctx.usernameErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.usernameErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", null, [
                      _createTextVNode("Password "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "password",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.password) = $event)),
                      placeholder: "Masukkan Password",
                      required: "",
                      autocomplete: "off"
                    }, null, 512), [
                      [_vModelText, _ctx.password]
                    ]),
                    (_ctx.passwordErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.passwordErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _cache[23] || (_cache[23] = _createElementVNode("label", null, "Email", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "email",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email) = $event)),
                      placeholder: "Masukkan Email"
                    }, null, 512), [
                      [_vModelText, _ctx.email]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[24] || (_cache[24] = _createElementVNode("label", null, [
                      _createTextVNode("NIP "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.nip) = $event)),
                      placeholder: "Masukkan NIP",
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.nip]
                    ]),
                    (_ctx.nipErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.nipErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _cache[25] || (_cache[25] = _createElementVNode("label", null, [
                      _createTextVNode("NIK "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.nik) = $event)),
                      placeholder: "Masukkan NIK",
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.nik]
                    ]),
                    (_ctx.nikErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.nikErr), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _cache[26] || (_cache[26] = _createElementVNode("label", null, [
                      _createTextVNode("Tempat Lahir "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.birth_place) = $event)),
                      placeholder: "Masukkan Tempat Lahir",
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.birth_place]
                    ]),
                    (_ctx.birth_placeErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.birth_placeErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _cache[27] || (_cache[27] = _createElementVNode("label", null, [
                      _createTextVNode("Tanggal Lahir "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "date",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.birth_date) = $event)),
                      placeholder: "Masukkan Tanggal Lahir",
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.birth_date]
                    ]),
                    (_ctx.birth_dateErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.birth_dateErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _cache[30] || (_cache[30] = _createElementVNode("label", null, "Jenis Kelamin", -1)),
                    _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("label", _hoisted_30, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-radio-input",
                        type: "radio",
                        value: "L",
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.gender) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.gender]
                      ]),
                      _cache[28] || (_cache[28] = _createElementVNode("span", { class: "form-radio-sign" }, "Laki-Laki", -1))
                    ]),
                    _createElementVNode("label", _hoisted_31, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-radio-input",
                        type: "radio",
                        value: "P",
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.gender) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.gender]
                      ]),
                      _cache[29] || (_cache[29] = _createElementVNode("span", { class: "form-radio-sign" }, "Perempuan", -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _cache[32] || (_cache[32] = _createElementVNode("label", null, "Alamat", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.address) = $event)),
                      rows: "4",
                      placeholder: "Masukkan Alamat Lengkap"
                    }, null, 512), [
                      [_vModelText, _ctx.address]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _cache[33] || (_cache[33] = _createElementVNode("label", null, [
                      _createTextVNode("Nomor Telepon "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.phone) = $event)),
                      placeholder: "Masukkan Nomor Telepon",
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.phone]
                    ]),
                    (_ctx.phoneErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.phoneErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_35, [
                    _cache[34] || (_cache[34] = _createElementVNode("label", null, [
                      _createTextVNode("Subject "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.selected_pelajaran,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selected_pelajaran) = $event)),
                      options: _ctx.pelajaran,
                      searchable: true,
                      "create-option": false,
                      mode: "tags"
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.selected_pelajaranErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.selected_pelajaranErr), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
              }, "Batal"),
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
              }, "Simpan")
            ])
          ])
        ])
      ])
    ])
  ]))
}