<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Edit Classroom Journal</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="done">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Grade <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.grade_id" :options="refGrade" :searchable="true" @change="onGradeChange" :can-clear="false" :disabled="true"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Subject <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.subject_id" :options="refSubject" :searchable="true" @change="onSubjectChange" :can-clear="false"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Teacher <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.teacher_id" :options="refTeacher" :searchable="true" :can-clear="false"/>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Date <b class="text-danger">*</b></label>
                                        <input type="date" v-model="request.date" class="form-control"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Lesson Hours <b class="text-danger">*</b></label>
                                        <input type="text" v-model="request.lesson_hours" class="form-control"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Academic Year <b class="text-danger">*</b></label>
                                        <input type="text" v-model="request.academic_year" class="form-control"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Pembelajaran</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Materi Pembelajaran <b class="text-danger">*</b></label>
                                        <textarea class="form-control" rows="4" v-model="request.materi_pembelajaran"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Catatan <b class="text-danger">*</b></label>
                                        <textarea class="form-control" rows="4" v-model="request.catatan"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Kegiatan Pembelajaran <b class="text-danger">*</b></label>
                                        <textarea class="form-control" rows="4" v-model="request.kegiatan_pembelajaran"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SantriAttendance v-if="done" :attendances="request.attendances" @onStatusChange="onStatusChange"/>
                    <div class="card">
                        <div class="card-body d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save">Simpan</button>
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {Kelas, Pelajaran, Siswa, User} from "@/utils/Models";
import Api from "@/utils/Api";
import Multiselect from "@vueform/multiselect";
import {toSqlDate} from "@/utils/Helpers";
import SantriAttendance from "@/components/classroom_journal/SantriAttendance.vue";
import {IClassroomJournal, StudentAttendance} from "@/utils/interface/ClassroomJournal";
import {useRoute, useRouter} from "vue-router";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "EditClassroomJournal",
    components: {
        SantriAttendance,
        Multiselect
    },
    setup() {
        const route = useRoute()
        const router = useRouter()
        const id = route.params.id
        const refTeacher = ref<{}[]>([])
        const allTeacher = ref<User[]>([])
        const refStudent = ref<Siswa[]>([])
        const refGrade = ref<{}[]>([])
        const refSubject = ref<{}[]>([])
        const request = reactive<IClassroomJournal>({
			id: 0,
            subject_id: 0,
            teacher_id: 0,
            grade_id: 0,
            date: toSqlDate(new Date()),
            attendances: [],
            materi_pembelajaran: '',
            academic_year: '',
            kegiatan_pembelajaran: '',
            catatan: '',
        })
        const attendances = computed(() => refStudent.value
            // .filter(v => v.trx_class_id === request.grade_id)
            .filter(v => v.student_class.find(x => x.trx_class?.id === request.grade_id))
            .map(v => ({student_id: v.id, student_name: v.nama, status: 'h'}))
        )
        const done = ref(false)

        const getData = async () => {
            const r = await Api.get(`classroom-journal/${id}`)
            if (r.success) {
                const v = r.data as IClassroomJournal
                request.subject_id = v.subject_id
                request.teacher_id = v.teacher_id
                request.grade_id = v.grade_id
                request.lesson_hours = v.lesson_hours
                request.date = v.date
                request.materi_pembelajaran = v.materi_pembelajaran
                request.academic_year = v.academic_year
                request.kegiatan_pembelajaran = v.kegiatan_pembelajaran
                request.catatan = v.catatan
                request.attendances = v.attendances.sort((a: StudentAttendance, b: StudentAttendance) => a.student_name!.localeCompare(b.student_name!))
            }
            else {
                error(r.message ?? 'gagal')
            }
            done.value = true
        }

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')

            if (r.success) {
                refStudent.value = r.data.siswa as Siswa[]
                refTeacher.value = (r.data.pic as User[]).map(v => ({value: v.id, label: v.name}))
                allTeacher.value = r.data.pic as User[]
                refGrade.value = (r.data.grade as Kelas[]).map(v => ({value: v.id, label: v.nama_kelas}))
                refSubject.value = (r.data.mapel as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
                getData()
                t.remove()
            }
            else {
                t.remove()
                error(r.message ?? 'gagal')
            }
        })

        const onGradeChange = (e: any) => {
            request.attendances = refStudent.value
                // .filter(v => v.trx_class_id === +e)
                .filter(v => v.student_class.find(x => x.trx_class?.id === +e))
                .map(v => ({student_id: v.id, student_name: v.nama, status: 'h'}))
        }
        const onSubjectChange = (e: any) => {
            const id = allTeacher.value.find(v => v.pelajaran?.find(p => p.id === +e))?.id
            if (id) {
                request.teacher_id = id
            }
        }
        const save = async () => {
            const t = loading('Menyimpan data')
            const r = await Api.post(`classroom-journal/update/${id}`, {...request})
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        return {
            request, refTeacher, refGrade, refSubject, refStudent, attendances, done,
            onGradeChange, onSubjectChange, save,
            onStatusChange: (s: string, k: number) => request.attendances[k].status = s,
            back: () => router.back()
        }
    }
})
</script>

<style scoped>

</style>
