import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sidebar sidebar-style-2"
}
const _hoisted_2 = { class: "sidebar-wrapper scrollbar scrollbar-inner" }
const _hoisted_3 = { class: "sidebar-content" }
const _hoisted_4 = { class: "user" }
const _hoisted_5 = { class: "avatar-sm float-left mr-2" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "info" }
const _hoisted_8 = {
  "data-toggle": "collapse",
  href: "#collapseExample",
  "aria-expanded": "true"
}
const _hoisted_9 = { class: "user-level" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarOrtu = _resolveComponent("SidebarOrtu")!
  const _component_SidebarStudent = _resolveComponent("SidebarStudent")!
  const _component_SidebarDokter = _resolveComponent("SidebarDokter")!
  const _component_SidebarGuru = _resolveComponent("SidebarGuru")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: _ctx.getPhoto(_ctx.user),
                  alt: "...",
                  class: "avatar-img rounded-circle"
                }, null, 8, _hoisted_6)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("a", _hoisted_8, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.user.name) + " ", 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.user.role_name), 1),
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "caret" }, null, -1))
                  ])
                ]),
                _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"clearfix\"></div><div class=\"collapse in\" id=\"collapseExample\"><ul class=\"nav\"><li><a href=\"#\"><span class=\"link-collapse\">Edit Profile</span></a></li></ul></div>", 2))
              ])
            ]),
            (+_ctx.role === _ctx.Role.Ortu)
              ? (_openBlock(), _createBlock(_component_SidebarOrtu, { key: 0 }))
              : _createCommentVNode("", true),
            (+_ctx.role === _ctx.Role.Siswa)
              ? (_openBlock(), _createBlock(_component_SidebarStudent, { key: 1 }))
              : _createCommentVNode("", true),
            (+_ctx.role === _ctx.Role.Dokter)
              ? (_openBlock(), _createBlock(_component_SidebarDokter, { key: 2 }))
              : (+_ctx.role === _ctx.Role.Guru || +_ctx.role === _ctx.Role.Kepala || +_ctx.role === _ctx.Role.Musrif)
                ? (_openBlock(), _createBlock(_component_SidebarGuru, { key: 3 }))
                : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}