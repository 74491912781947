import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "card-footer d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Detail Syllabus")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.done)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[1] || (_cache[1] = _createElementVNode("b", null, "Guru Pembuat", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.syllabus.teacher_name), 1),
                      _cache[2] || (_cache[2] = _createElementVNode("b", null, "Subject", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.syllabus.subject_name), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("b", null, "Kelas", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.syllabus.grades.map(v => v.nama_kelas).join(', ')), 1),
                      _cache[4] || (_cache[4] = _createElementVNode("b", null, "Semester", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.syllabus.semester_name), 1),
                      _cache[5] || (_cache[5] = _createElementVNode("b", null, "Lampiran", -1)),
                      _createElementVNode("p", null, [
                        _createElementVNode("a", {
                          href: _ctx.syllabus.attachment?.url,
                          target: "_blank",
                          class: "d-block"
                        }, _toDisplayString(_ctx.syllabus.attachment?.name), 9, _hoisted_9)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}