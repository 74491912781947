<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Add Health</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Tanggal <b class="text-danger">*</b></label>
                                        <input type="date" class="form-control" v-model="request.due_date">
                                    </div>
                                    <div class="form-group">
                                        <label>Siswa <b class="text-danger">*</b></label>
                                        <Multiselect
                                            v-model="request.student_id"
                                            :options="refStudent"
                                            :searchable="true"
                                            :can-clear="false"
                                            :can-deselect="false"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Keluhan <b class="text-danger">*</b></label>
                                        <textarea class="form-control" v-model="request.keluhan" rows="3"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Diagnosa <b class="text-danger">*</b></label>
                                        <textarea class="form-control" v-model="request.diagnosa" rows="3"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Tindakan <b class="text-danger">*</b></label>
                                        <textarea class="form-control" v-model="request.tindakan" rows="3"></textarea>
                                    </div>
                                    <div v-for="(o, k) in request.obat" class="ctr">
                                        <div class="d-flex align-items-end">
                                            <div class="row" style="flex: 1">
                                                <div class="col-sm-12">
                                                    <div class="form-group pt-0">
                                                        <label>Nama Obat</label>
                                                        <input type="text" class="form-control" v-model="o.nama">
                                                    </div>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger btn-sm mb-3" @click="delete_obat(o.id)" :disabled="request.obat.length === 1">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                        <div class="d-flex align-items-end">
                                            <div class="row">
                                                <div class="col-sm-6 pr-0">
                                                    <div class="form-group pt-0">
                                                        <label>Jumlah</label>
                                                        <input type="number" class="form-control" v-model="o.jumlah">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 pl-0">
                                                    <div class="form-group pt-0">
                                                        <label>Signa</label>
                                                        <input type="text" class="form-control" v-model="o.signa">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 d-flex justify-content-center">
                                            <button class="btn btn-success btn-sm" @click="add_obat">
                                                Tambah Obat
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Tindak Lanjut <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="request.tindak_lanjut">
                                    </div>
                                    <div class="form-group">
                                        <label>Total Biaya <b class="text-danger">*</b></label>
                                        <input type="number" class="form-control" v-model="request.total_biaya">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save" :disabled="saving">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import Multiselect from "@vueform/multiselect";
import {useRouter} from "vue-router";
import {IOption} from "@/utils/interface/Assignment";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {Siswa} from "@/utils/Models";
import {IHealth} from "@/utils/interface/Health";
import {toSqlDate} from "@/utils/Helpers";
import Session from "@/utils/Session";

export default defineComponent({
    name: "AddHealth",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const refStudent = ref<IOption[]>([])
        const request = reactive<IHealth>({
            due_date: toSqlDate(),
            obat: [newObat()]
        })
        const user = Session.getCurrentUser()!
        const saving = ref(false)

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            t.remove()

            if (r.success) {
                refStudent.value = (r.data.siswa as Siswa[]).map(v => ({value: v.id, label: `${v.nama_kelas ? `[${v.nama_kelas}] ` : ''}${v.nama}`}))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const save = async () => {
            if ((!request.total_biaya || request.total_biaya < 100)) {
                if (!confirm(`Yakin periksa ini biayanya ${request.total_biaya}?`)) {
                    return
                }
            }

            saving.value = true
            const t = loading('Menyimpan data')
            const r = await Api.post('health/create', {...request, created_by: user.id})
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
            saving.value = false
        }

        return {
            refStudent, request, save, saving,
            add_obat: () => request.obat?.push(newObat()),
            delete_obat: (id: number) => request.obat = request.obat?.filter(v => v.id !== id)
        }
    }
})

const newObat = () => ({id: Date.now(), nama: '', signa: '', new: true})

</script>

<style scoped>
.ctr {
    border: 1px solid #ebedf2 !important;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
}
</style>