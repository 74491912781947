import axios from "axios";
import Session from "@/utils/Session";
import {crypt} from "@/utils/Crypt";
import {useRouter} from "vue-router";

interface Solver {
    reload: boolean,
    logout?: boolean,
    data?: Response
}

export default class Api {
    static baseUrl = 'https://administrator.bumicendekia.sch.id/api/academy/'

    static async login(username: string, password: string): Promise<Response> {
        return new Promise(function (resolve) {
            axios.post(`${Api.baseUrl}login`, {
                username: username,
                password: password
            })
                .then(r => {
                    resolve(r.data)
                })
                .catch(e => {
                    resolve({success: false, message: 'Periksa koneksi anda'})
                })
        });
    }

    static async relogin() {
        const router = useRouter()
        return new Promise(function (resolve) {
            const pwd = Session.getPassword() ?? ''
            axios.post(`${Api.baseUrl}login`, {
                username: Session.getCurrentUser()!.username,
                password: pwd
            }).then(r => {
                if (r.data.success) {
                    Session.login({
                        password: crypt('wkwk', pwd),
                        user: JSON.stringify(r.data.data.user),
                        school: JSON.stringify(r.data.data.school),
                        token: r.data.data.token
                    })
                    resolve(true)
                }
                else {
                    router.push('/login')
                    resolve(false)
                }
            }).catch(e => {
                console.log(e)
                resolve(false)
            })
        })
    }

    static returnSolver(r: any): Promise<Solver> {
        const router = useRouter()
        return new Promise(function (resolve) {
            if (!r.data.success && r.data.message === 'Authorization failed') {
                if (!Session.getCurrentUser()) {
                    router.push('/login')
                }
                else {
                    Api.relogin().then(r => {
                        if (r) {
                            resolve({
                                reload: true
                            } as Solver)
                        }
                        else {
                            resolve({
                                reload: false,
                                data: {success: false, message: 'Terdapat kesalahan'}
                            } as Solver)
                        }
                    })
                }
            }
            else {
                resolve({
                    reload: false,
                    data: r.data
                } as Solver)
            }
        })
    }

    static async get(url: string, auth: boolean = true): Promise<Response> {
        try {
            let finalHeader: Record<string, any> = {}
            if (auth) {
                finalHeader.Authorization = Session.getToken()
            }

            const r = await axios.get(`${this.baseUrl}${url}`, { headers: finalHeader })
            const s = await this.returnSolver(r)
            if (s.reload) {
                return await this.get(url, auth)
            }
            else {
                return s.data!
            }
        }
        catch (e) {
            return {success: false, message: 'Periksa koneksi anda'}
        }
    }

    static async getDownload(url: string, fileName: string, auth: boolean = true) {
        try {
            let finalHeader: Record<string, any> = {}
            if (auth) {
                finalHeader.Authorization = Session.getToken()
            }

            const response = await axios.get(`${this.baseUrl}${url}`, {
                headers: finalHeader,
                responseType: 'blob'
            })

            const link = document.createElement('a')
            link.href = URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.ms-excel'}))
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()

            return {success: true, data: 'Sukses'}
        }
        catch (e) {
            return {success: false, message: 'Periksa koneksi anda'}
        }
    }

    static async post(url: string, data?: any, header?: any, auth: boolean = true): Promise<Response> {
        try {
            let finalHeader: Record<string, any> = header ?? {}
            if (auth) {
                finalHeader['Authorization'] = Session.getToken()
            }

            const r = await axios.post(`${this.baseUrl}${url}`, data ?? {}, { headers: finalHeader })
            const s = await this.returnSolver(r)
            if (s.reload) {
                return await this.post(url, data, header, auth)
            }
            else {
                return s.data!
            }
        }
        catch (e) {
            return {success: false, message: 'Periksa koneksi anda'}
        }
    }

    static async put(url: string, data: any, header?: any, auth: boolean = true): Promise<Response> {
        try {
            let finalHeader: Record<string, any> = header ?? {}
            if (auth) {
                finalHeader['Authorization'] = Session.getToken()
            }

            const r = await axios.put(`${this.baseUrl}${url}`, data, { headers: finalHeader })
            const s = await this.returnSolver(r)
            if (s.reload) {
                return await this.put(url, data, header, auth)
            }
            else {
                return s.data!
            }
        }
        catch (e) {
            return {success: false, message: 'Periksa koneksi anda'}
        }
    }

    static async delete(url: string, auth: boolean = true): Promise<Response> {
        try {
            let finalHeader: Record<string, any> = {}
            if (auth) {
                finalHeader['Authorization'] = Session.getToken()
            }

            const r = await axios.delete(`${this.baseUrl}${url}`, { headers: finalHeader })
            const s = await this.returnSolver(r)
            if (s.reload) {
                return await this.delete(url, auth)
            }
            else {
                return s.data!
            }
        }
        catch (e) {
            return {success: false, message: 'Periksa koneksi anda'}
        }
    }
}

export interface Response {
    success: boolean,
    message?: string,
    data?: any,
    last?: boolean,
    id?: string,
}
