import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card timeline-item" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "avatar-sm" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex flex-column ml-2" }
const _hoisted_7 = { class: "fw-bold" }
const _hoisted_8 = {
  style: {"font-size":"11px"},
  class: "text-muted"
}
const _hoisted_9 = {
  class: "d-flex align-items-center text-success mt-2",
  style: {"flex":"1"}
}
const _hoisted_10 = { class: "ml-2" }
const _hoisted_11 = {
  class: "d-flex align-items-center text-success",
  style: {"flex":"1","margin-top":"2px"}
}
const _hoisted_12 = {
  class: "d-flex align-items-center text-success",
  style: {"flex":"1","margin-top":"2px"}
}
const _hoisted_13 = { class: "ml-2" }
const _hoisted_14 = { class: "d-flex align-items-center flex-column" }
const _hoisted_15 = { style: {"font-size":"34px","font-weight":"bold","line-height":"94%"} }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "avatar-img rounded-circle",
            style: {"width":"35px","height":"35px"},
            src: _ctx.getPhoto(_ctx.assessment.assignment.teacher_photo)
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.assessment.assignment.teacher_name ?? '-'), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.assessment.created_at)), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa fa-book" }, null, -1)),
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.assessment.assignment.subject_name) + " - " + _toDisplayString(_ctx.assessment.assignment.type), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-user-graduate" }, null, -1)),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "ml-2" }, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.assessment.student_name), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa fa-thumbtack" }, null, -1)),
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.assessment.assignment.title), 1)
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("div", {
        style: {"height":"1px","background":"#eee"},
        class: "my-2"
      }, null, -1)),
      _createElementVNode("div", _hoisted_14, [
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "ml-1" }, "Score:", -1)),
        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.assessment.score), 1)
      ]),
      (_ctx.assessment.note)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _cache[5] || (_cache[5] = _createElementVNode("div", {
              style: {"height":"1px","background":"#eee"},
              class: "my-2"
            }, null, -1)),
            _cache[6] || (_cache[6] = _createElementVNode("b", null, "Catatan dari guru:", -1)),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("i", _hoisted_17, _toDisplayString(_ctx.assessment.note), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}