import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "row no-gutters",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "col-sm-3 col-md-4 left" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "d-flex flex-column",
  style: {"flex":"1"}
}
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { style: {"font-size":"12px","margin-left":"auto"} }
const _hoisted_8 = {
  key: 1,
  class: "mx-auto my-auto px-5 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.chatGroups.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.chatGroups, (v) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["d-flex align-items-center py-2 px-3 cg-item", {active: v.active && !_ctx.chosenPerson}]),
              onClick: ($event: any) => (_ctx.setActive(v))
            }, [
              _createElementVNode("img", {
                src: _ctx.getPhoto(_ctx.getOpponent(v)),
                alt: "",
                class: "avatar-img rounded-circle mr-2",
                style: {"width":"50px","height":"50px"}
              }, null, 8, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("b", null, _toDisplayString(_ctx.getOpponent(v).name), 1),
                _createElementVNode("div", _hoisted_6, [
                  (v.last_sender_id === _ctx.user.id)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "fa fa-check mr-1",
                        style: _normalizeStyle({color: v.active ? 'white' : '#aaaaaa'})
                      }, null, 4))
                    : _createCommentVNode("", true),
                  (!v.last_chat_seen && v.last_sender_id !== _ctx.user.id)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: _normalizeClass([{'unread-bullet-white': v.active}, "unread-bullet"])
                      }, null, 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass(["last-chat", {'unread': !v.last_chat_seen && v.last_sender_id !== _ctx.user.id, 'unread-white': v.active}])
                  }, _toDisplayString(_ctx.ellipsize(v.last_chat)), 3)
                ]),
                _createElementVNode("span", _hoisted_7, _toDisplayString(v.last_sent), 1)
              ])
            ], 10, _hoisted_3))
          }), 256))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "mt-5 d-inline-block" }, "Click `Start New Chat` to start chatting now...", -1)
          ])))
    ])
  ]))
}