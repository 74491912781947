<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="!announcements.length || !done" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 300px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada data</span>
                        <span style="font-style: italic" v-else>Mendapatkan data</span>
                    </div>
                    <MobileAnnouncementItem v-else v-for="v in announcements" :announcement="v"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {IAnnouncement} from "@/utils/interface/Announcement";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {toIndoDateNameTime} from "@/utils/Helpers";
import MobileAnnouncementItem from "@/components/partials/MobileAnnouncementItem.vue";
import Session from "@/utils/Session";
import Role from "@/utils/Role";

export default defineComponent({
    name: "MobileAnnouncement",
    components: {MobileAnnouncementItem},
    setup() {
        const done = ref(false)
        const announcements = ref<IAnnouncement[]>([])
        const user = Session.getCurrentUser()!

        onMounted(async () => {
            const t = loading()
            const r = +user.role_id === Role.Ortu ? await Api.get(`announcement/${user.connection_id}`) : await Api.get(`announcement`)
            t.remove()
            done.value = true

            if (r.success) {
                announcements.value = r.data as IAnnouncement[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        return {
            done, announcements, toIndoDateNameTime
        }
    }
})

</script>

<style scoped>
</style>