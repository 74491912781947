import Announcement from "@/components/announcement/Announcement.vue";
import AddAnnouncement from "@/components/announcement/AddAnnouncement.vue";
import Information from "@/components/information/Information.vue";
import AddInformation from "@/components/information/AddInformation.vue";
import TeacherAttendance from "@/components/report/TeacherAttendance.vue";
import TeacherAttendanceDetail from "@/components/report/TeacherAttendanceDetail.vue";
import SantriAttendance from "@/components/report/SantriAttendance.vue";
import ReportReport from "@/components/report/Report.vue";
import Inbox from "@/components/inbox/Inbox.vue";
import Profile from "@/components/dashboard/Profile.vue";

export default [
  {
    path: '/announcement',
    name: 'announcement',
    component: Announcement,
    meta: {
      title: 'Announcement'
    }
  },
  {
    path: '/announcement/add',
    name: 'announcement/add',
    component: AddAnnouncement,
    meta: {
      title: 'Add Announcement'
    }
  },
  {
    path: '/information',
    name: 'information',
    component: Information,
    meta: {
      title: 'Information'
    }
  },
  {
    path: '/information/add',
    name: 'information/add',
    component: AddInformation,
    meta: {
      title: 'Add Information'
    }
  },
  {
    path: '/information/edit/:id',
    name: 'information/edit',
    component: AddInformation,
    meta: {
      title: 'Edit Information'
    }
  },
  {
    path: '/teacher-attendance',
    name: 'teacher-attendance',
    component: TeacherAttendance,
    meta: {
      title: 'Teacher Attendance'
    }
  },
  {
    path: '/teacher-attendance/:id',
    name: 'teacher-attendance/detail',
    component: TeacherAttendanceDetail,
    meta: {
      title: 'Teacher Attendance Detail'
    }
  },
  {
    path: '/santri-attendance',
    name: 'santri-attendance',
    component: SantriAttendance,
    meta: {
      title: 'Santri Attendance'
    }
  },
  {
    path: '/report',
    name: 'report',
    component: ReportReport,
    meta: {
      title: 'Report'
    }
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: Inbox,
    meta: {
      title: 'Inbox'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'Edit Profile'
    }
  },
]