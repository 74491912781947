import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body pb-0" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { style: {"font-size":"16px","flex":"1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card timeline-item",
    onClick: _cache[0] || (_cache[0] = () => _ctx.onClick())
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("b", _hoisted_3, _toDisplayString(_ctx.notification.title), 1),
        _createElementVNode("i", null, _toDisplayString(_ctx.toChatTime(_ctx.notification.created_at)), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.notification.message), 1)
    ])
  ]))
}