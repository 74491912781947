<template>
    <ProgressItem v-if="dashboardItem.type === 'progress'" :progress="dashboardItem.data" />
    <JournalItem v-else :attendance="dashboardItem.data" />
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {IDashboard} from "@/utils/Models";
import ProgressItem from "@/components/parent/partials/ProgressItem.vue";
import JournalItem from "@/components/parent/partials/JournalItem.vue";

export default defineComponent({
    name: "TimelineItem",
    components: {JournalItem, ProgressItem},
    props: {
        dashboardItem: Object as PropType<IDashboard>
    }
})
</script>

<style scoped>

</style>