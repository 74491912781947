<template>
    <div class="account-menu"
         v-if="show"
         :key="`ao${key}`"
         :style="{top: top + 'px', left: left +'px'}"
         tabindex="0"
         v-click-outside="handleFocusOut"
    >
        <ul class="dropdown-menu">
            <template v-for="[i, [k, v]] of Object.entries(Object.entries(statuses))">
                <li @click="handleChoose(k)">
                    <span class="d-flex align-items-center">
                        <span class="circle mr-1" :class="`circle-${k}`">{{ k.toUpperCase() }}</span> {{ v }}
                    </span>
                </li>
                <li v-if="i < Object.keys(statuses).length - 1" class="dropdown-divider"></li>
            </template>
        </ul>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue"

export default defineComponent({
    name: "AttendanceOptions",
    props: {
        show: Boolean,
        top: Number,
        left: Number,
        key: [Number, String],
        statuses: Object
    },
    emits: ['choose'],
    setup(props, { emit }) {

        const choose = (c: string) => {
            emit('choose', c)
        }
        const handleChoose = (s: string) => {
            emit('choose', s)
        }
        const handleFocusOut = () => {
            if (props.show) {
                emit('choose')
            }
        }

        return { choose, handleChoose, handleFocusOut }
    }
})
</script>

<style scoped>
.account-menu {
    display: inline-block;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: fixed;
    z-index: 1000;
}

.account-menu ul {
    display: block;
    font-size: 14px;
    margin: 0 !important;
    padding: 18px 24px 12px 16px !important;
    border-radius: 14px !important;
}

.account-menu ul li {
    padding: 4px 0 !important;
    cursor: pointer;
}

.account-menu ul li:hover {
    color: #31ce36 !important;
}

.account-menu ul li.dropdown-divider {
    padding: 4px 4px 0 4px !important;
}

.account-menu ul li span {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.account-menu ul li span i {
    width: 25px;
    text-align: center;
}

.circle {
    width: 26px;
    height: 26px;
    border-radius: 30px;
    color: white;
    font-size: 14px;
    text-align: center;
    padding-top: 3px;
    cursor: pointer;
}
</style>
