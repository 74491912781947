import {Siswa, User} from "@/utils/Models";
import moment from "moment/moment";

export const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = error => reject(error);
    })
}

export function format(n: number) {
    return n?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".") ?? '0';
}

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function getDayName(date: Date) {
    return days[date.getDay()]
}

export function toSqlDate(date: Date = new Date()) {
    const pad = (i: number) => {
        if (`${i}`.length === 1) {
            return `0${i}`
        }
        return `${i}`
    }
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
}

export function toSqlDateTime(date: Date) {
    const pad = (i: number) => {
        if (`${i}`.length === 1) {
            return `0${i}`
        }
        return `${i}`
    }
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
}

export function toIndoDate(date: string | null) {
    if (date === null) {
        return ''
    }

    const t = date.split(/[- :TZ]/);
    let d = new Date(+t[0], (+t[1]) - 1, +t[2], +t[3] || 0, +t[4] || 0, +t[5] || 0),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return `${day}/${month}/${year} ${t[3]}:${t[4]}`;
}

export function toIndoDateName(date: string | null | undefined) {
    if (!date) {
        return ''
    }

    const t = date.split(/[- :TZ]/);
    let d = new Date(+t[0], +t[1] - 1, +t[2]),
        month = d.getMonth(),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (day.length < 2)
        day = '0' + day;

    const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']

    return `${day} ${months[month]} ${year}`;
}

export function toIndoDateNameTime(date: string | null) {
    if (date === null) {
        return ''
    }

    const t = date.split(/[- :TZ]/);
    let d = new Date(+t[0], +t[1] - 1, +t[2], +t[3], +t[4], +t[5]),
        month = d.getMonth(),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours() + '',
        minute = d.getMinutes() + '';

    if (day.length < 2)
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;

    const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']

    return `${day} ${months[month]} ${year} ${hour}:${minute} WIB`;
}

export function toChatTime(date: string | null) {
    if (!date) {
        return ''
    }

    const t = date.split(/[- :TZ]/);
    let d = new Date(+t[0], +t[1] - 1, +t[2], +t[3], +t[4], +t[5]),
        month = d.getMonth(),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours() + '',
        minute = d.getMinutes() + '';

    if (day.length < 2)
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;

    const today = new Date()
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    if (today.toDateString() === d.toDateString()) {
        return `${hour}:${minute}`
    }
    if (yesterday.toDateString() === d.toDateString()) {
        return `Yesterday`
    }

    const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']

    return `${day} ${months[month]} ${year}`
}

export function getPhoto(user: User | Siswa | string | null) {
    if (typeof user === 'string') {
        return `https://administrator.bumicendekia.sch.id/public/uploads/${user}`
    }
    if (user?.photo) {
        return `https://administrator.bumicendekia.sch.id/public/uploads/${user.photo}`
    }
    return require('@/assets/img/profile2.png')
}

let masterIndex = 0

export function setMasterIndex(i: number) {
    masterIndex = i
}

export function getMasterIndex() {
    return masterIndex
}

export function attendanceStatus(s: string) {
    if (s === 'h')
        return 'Hadir'
    if (s === 's')
        return 'Sakit'
    if (s === 'i')
        return 'Izin'
    if (s === 'a')
        return 'Alpha'
    if (s === 't')
        return 'Tugas'
    return '-'
}

export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const groupBy = (items: any[], key: string) => items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
)

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const years = [new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2, new Date().getFullYear() - 3, new Date().getFullYear() - 4]

export const getDays = function(start: Date, end: Date) {
    let arr=[]
    for(let dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt).getDate() + '');
    }
    return arr;
}

export function capitalize(string: string) {
    let strVal = '';
    let str = string.split(' ');
    for (let chr = 0; chr < str.length; chr++) {
        strVal += str[chr].substring(0, 1).toUpperCase() + str[chr].substring(1, str[chr].length) + ' '
    }
    return strVal
}

export function ellipsize(s: string) {
    if (s.length > 25) {
        return s.substring(0, 25) + '...'
    }
    return s
}

export const fix_lesson_hour = (lh?: string) => {
    if (!lh) {
        return '00:00-00:00'
    }

    const l1 = lh.replaceAll(' ', '').replaceAll('.', ':')
    try {
        const l2 = l1.split('-')
        const n1 = l2[0].split(':')
        const r1 = n1[0].padStart(2, '0')
        const n2 = l2[1].split(':')
        const r2 = n2[0].padStart(2, '0')
        return `${r1}:${n1[1]}-${r2}:${n2[1]}`
    }
    catch (e) {
        return l1
    }
}

let classroomDate = moment().format('YYYY-MM-DD')
export function setClassroomDate(i: string) {
    classroomDate = i
}
export function getClassroomDate() {
    return classroomDate
}
