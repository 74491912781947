import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from './assets/img/logo_smp_sma.png'


const _hoisted_1 = {
  key: 0,
  class: "loading-parent"
}
const _hoisted_2 = { class: "main-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_ctx.loadingParent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { style: {"flex":"1"} }, null, -1),
        _createElementVNode("img", {
          src: _imports_0,
          style: {"width":"200px"}
        }, null, -1),
        _createElementVNode("div", { style: {"flex":"1"} }, null, -1)
      ])))
    : (_ctx.route.name !== 'login')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(`wrapper ${!!_ctx.isAndroid ? 'wrapper-mobile' : ''}`)
        }, [
          _createVNode(_component_Header, {
            "is-android": !!_ctx.isAndroid
          }, null, 8, ["is-android"]),
          _createVNode(_component_Sidebar),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_view),
            _createVNode(_component_Footer)
          ])
        ], 2))
      : (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
}