<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="fw-bold">Detail Siswa</h4>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="siswa">
                                <div class="col-sm-12 col-md-6">
                                    <div class="d-flex align-items-center">
                                        <div class="profile-picture mb-1">
                                            <div class="avatar avatar-xl">
                                                <img :src="photo_url ?? getPhoto(siswa)" alt="..." class="avatar-img rounded-circle" id="photo-display">
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column ml-2">
                                            <h3 class="fw-bold text-primary mb-0">{{ siswa.nama }}</h3>
                                            <label>NISN: {{ siswa.nisn }}</label>
                                            <label>NIS: {{ siswa.nis }}</label>
                                        </div>
                                    </div>
                                    <table class="table table-bordered mt-2">
                                        <tbody>
                                        <tr>
                                            <th>Tempat Lahir</th>
                                            <td>{{ siswa.tempat_lahir }}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Lahir</th>
                                            <td>{{ toIndoDateName(siswa.tgl_lahir) }}</td>
                                        </tr>
                                        <tr>
                                            <th>Agama</th>
                                            <td>{{ siswa.agama }}</td>
                                        </tr>
                                        <tr>
                                            <th>Jenis Kelamin</th>
                                            <td>{{ siswa.jenis_kelamin === 'L' ? 'Laki-Laki' : 'Perempuan' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Alamat</th>
                                            <td>{{ siswa.alamat }}</td>
                                        </tr>
                                        <tr>
                                            <th>Kelas</th>
                                            <td>{{ siswa.nama_kelas || siswa.kelas }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row justify-content-end">
                            <button class="btn btn-danger mr-2" @click="cancel">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Api from "@/utils/Api";
import {Kelas, Siswa} from "@/utils/Models";
import {file2Base64, getPhoto, toIndoDateName} from "@/utils/Helpers";
import {loading, error as e, success} from "@/utils/Toast";

interface Error {
    nisn?: string | null,
    nama?: string | null,
    tempat_lahir?: string | null,
    tgl_lahir?: string | null,
    nis?: string | null,
    agama?: string | null,
    kelas?: string | null,
    jenis_kelamin?: string | null,
}

export default defineComponent({
    name: "DetailSiswa",
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id

        const siswa = ref<Siswa|null>(null)
        const kelas = ref<Kelas[]>([])
        const photo_file = ref(null)
        const photo_url = ref<string|null>(null)
        const photo_b64 = ref<string|null>(null)
        const password = ref('')
        const error = ref<Error>({})

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`master/siswa/${id}`)
            const res = await Api.get('master/kelas')
            t.remove()

            if (r.success) {
                siswa.value = r.data as Siswa
            }
            else {
                e(r.message ?? 'gagal')
            }

            if (res.success) {
                kelas.value = res.data as Kelas[]
            }
            else {
                e(res.message ?? 'Gagal medapatkan data')
            }
        })

        const cancel = () => router.back()
        const save = async () => {
            const er: Error = {...error.value}
            Object.keys(er).forEach(function(key) {
                er[key as keyof Error] = null
            })
            error.value = er as typeof er

            if (!siswa.value) {
                return
            }
            if (!siswa.value.nisn) {
                error.value.nisn = 'Masukkan NISN'
                return
            }
            if (!siswa.value.nama) {
                error.value.nama = 'Masukkan Nama'
                return
            }
            if (!siswa.value.tempat_lahir) {
                error.value.tempat_lahir = 'Masukkan Tempat Lahir'
                return
            }
            if (!siswa.value.tgl_lahir) {
                error.value.tgl_lahir = 'Masukkan Tanggal Lahir'
                return
            }
            if (!siswa.value.nis) {
                error.value.nis = 'Masukkan NIS'
                return
            }
            if (!siswa.value.jenis_kelamin) {
                error.value.jenis_kelamin = 'Pilih Jenis Kelamin'
                return
            }
            if (!siswa.value.trx_class_id) {
                error.value.kelas = 'Pilih Kelas'
                return
            }

            const t = loading('Menyimpan data')
            const r = await Api.put(`master/siswa/update/${id}`, {
                nisn: siswa.value.nisn,
                nis: siswa.value.nis,
                nama: siswa.value.nama,
                jenis_kelamin: siswa.value.jenis_kelamin,
                trx_class_id: siswa.value.trx_class_id,
                alamat: siswa.value.alamat,
                tempat_lahir: siswa.value.tempat_lahir,
                tgl_lahir: siswa.value.tgl_lahir,
                agama: siswa.value.agama,
                password: password.value,
                photo: photo_b64.value,
            })
            t.remove()

            if (r.success) {
                router.back()
                success('Berhasil menyimpan data')
            }
            else {
                e(r.message ?? 'Gagal')
            }
        }
        const choose_photo = () => {
            let fileUpload = document.getElementById('photo_file')
            if (fileUpload != null) {
                fileUpload.click()
            }
        }
        const image_change = async (event: any) => {
            const file = event.target.files[0]
            if (file.size > 1000000) {
                e('Maksimal ukuran gambar 1 MB')
                return
            }

            photo_url.value = URL.createObjectURL(file)
            photo_b64.value = await file2Base64(file)
        }

        return { cancel, save, siswa, kelas, toIndoDateName, choose_photo, image_change, photo_file, photo_url, getPhoto, password, error }
    }
})
</script>

<style scoped>

</style>
