<template>
    <ul class="nav nav-primary">
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/my-assessment')}">
            <router-link to="/my-assessment">
                <i class="fas fa-graduation-cap"></i>
                <span class="sub-item">My Assessment </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/my-score')}">
            <router-link to="/my-score">
                <i class="fas fa-user-graduate"></i>
                <span class="sub-item">My Score </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/st-calendar')}">
            <router-link to="/st-calendar">
                <i class="fas fa-calendar"></i>
                <span class="sub-item">Academic Calendar </span>
            </router-link>
        </li>
        <li class="nav-item" :class="{active: route.fullPath.startsWith('/announcements')}">
            <router-link to="/announcements">
                <i class="fas fa-bullhorn"></i>
                <span class="sub-item">Announcement </span>
            </router-link>
        </li>
    </ul>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "SidebarStudent",
    setup() {
        const route = useRoute()
        return { route }
    }
})
</script>

<style scoped>

</style>