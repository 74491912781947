import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "table table-bordered" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Teacher Attendance")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("table", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "No"),
                    _createElementVNode("th", null, "Teacher Name"),
                    _createElementVNode("th", null, "Aksi")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refTeacher, (v, k) => {
                    return (_openBlock(), _createElementBlock("tr", null, [
                      _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                      _createElementVNode("td", null, _toDisplayString(v.label), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("button", {
                          class: "btn btn-success btn-sm",
                          onClick: ($event: any) => (_ctx.detail(v.value))
                        }, _cache[0] || (_cache[0] = [
                          _createElementVNode("i", { class: "fa fa-eye" }, null, -1)
                        ]), 8, _hoisted_8)
                      ])
                    ]))
                  }), 256))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}