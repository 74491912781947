import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_12 = {
  key: 0,
  class: "form-group"
}
const _hoisted_13 = { class: "input-group mb-2" }
const _hoisted_14 = { class: "input-group-prepend" }
const _hoisted_15 = { class: "input-group-text" }
const _hoisted_16 = {
  key: 1,
  class: "form-group"
}
const _hoisted_17 = { class: "col-sm-12 col-md-6" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = ["value"]
const _hoisted_20 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_21 = { class: "card-footer d-flex flex-row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "fw-bold" }, "Edit Kelas")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", null, [
                      _createTextVNode("Kelas "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ref_class_id) = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ref_class, (v) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: v.id
                        }, _toDisplayString(v.name), 9, _hoisted_10))
                      }), 256))
                    ], 512), [
                      [_vModelSelect, _ctx.ref_class_id]
                    ]),
                    (_ctx.ref_class_idErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.ref_class_idErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.ref_class_id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _cache[7] || (_cache[7] = _createElementVNode("label", null, [
                          _createTextVNode("Nama Kelas "),
                          _createElementVNode("b", { class: "text-secondary" }, "(Opsional)")
                        ], -1)),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.ref_class.find(v => +v.id === +_ctx.ref_class_id).name) + " -", 1)
                          ]),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "form-control",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
                            placeholder: "Masukkan Nama Kelas",
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.name]
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _cache[8] || (_cache[8] = _createElementVNode("label", null, [
                          _createTextVNode("Nama Kelas "),
                          _createElementVNode("b", { class: "text-secondary" }, "(Opsional)")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.name) = $event)),
                          placeholder: "Masukkan Nama Kelas",
                          required: ""
                        }, null, 512), [
                          [_vModelText, _ctx.name]
                        ])
                      ]))
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", null, [
                      _createTextVNode("Wali Kelas "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user_id) = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ref_user, (v) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: v.id
                        }, _toDisplayString(v.name), 9, _hoisted_19))
                      }), 256))
                    ], 512), [
                      [_vModelSelect, _ctx.user_id]
                    ]),
                    (_ctx.user_idErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.user_idErr), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
              }, "Batal"),
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
              }, "Simpan")
            ])
          ])
        ])
      ])
    ])
  ]))
}