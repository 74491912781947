<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="d-flex align-items-end">
                <button class="btn btn-success mb-2" @click="add"><i class="fa fa-plus"></i> Tambah</button>
            </div>
			<PaginatedTable :data="siswa" class="mt-3">
				<template #thead>
					<th>No</th>
					<th>Nama</th>
					<th>Username</th>
					<th>NISN</th>
					<th>NIS</th>
					<th>Kelas</th>
					<th>Aksi</th>
				</template>
				<template #tbody="{row, index}">
					<td>{{ index + 1 }}</td>
					<td>
						<div class="d-flex align-items-center">
							<div class="avatar-sm mr-2">
								<img :src="getPhoto(row.data)" alt="..." class="avatar-img rounded-circle">
							</div>
							{{ row.data.nama }}
						</div>
					</td>
					<td>{{ row.data.username }}</td>
					<td>{{ row.data.nisn }}</td>
					<td>{{ row.data.nis }}</td>
					<td>
						<span v-if="!row.data.student_class.length">{{row.data.kelas}}</span>
						<span v-else v-for="x in row.data.student_class" class="d-block">{{x.trx_class?.nama_kelas ?? ''}}</span>
					</td>
					<td>
						<button class="btn btn-sm btn-success mr-1" @click="detail(row.data.id)"><i class="fa fa-eye"></i> </button>
						<button class="btn btn-sm btn-warning mr-1" @click="edit(row.data.id)"><i class="fa fa-pencil"></i> </button>
					</td>
				</template>
			</PaginatedTable>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Api from "@/utils/Api";
import {Siswa} from "@/utils/Models";
import {useRouter} from "vue-router";
import {getPhoto} from "@/utils/Helpers";
import {error, loading} from "@/utils/Toast";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "TabSiswa",
	components: {PaginatedTable},
    setup() {
        const router = useRouter()
        const siswa = ref<Siswa[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/siswa')
            t.remove()

            if (r.success) {
                siswa.value = r.data as Siswa[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const add = () => router.push('/master-data/add-siswa')
        const edit = (id: number) => router.push(`/master-data/edit-siswa/${id}`)
        const detail = (id: number) => router.push(`/master-data/detail-siswa/${id}`)

        return { siswa, add, edit, detail, getPhoto }
    }
})
</script>

<style scoped>

</style>
