<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <JournalItem v-for="v in attendances" :attendance="v" :show-tag="true" />
                </div>
                <div class="col-md-12">
                    <div class="card timeline-item">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Academic Calendar</div>
                            </div>
                        </div>
                        <div class="card-body" :class="{'px-0': isMobile()}">
                            <Calendar :nearest="nearest" :agendas="agendas" :on-agenda-click="onAgendaClick"/>
                        </div>
                    </div>
                </div>
                <div v-if="isAndroid" class="col-md-12">
                    <MobileAnnouncementItemNew v-for="v in announcement" :dashboard="true" :announcement="v"/>
                </div>
                <div v-else class="col-md-12">
                    <MobileAnnouncementItem v-for="v in announcement" :dashboard="true" :announcement="v"/>
                </div>
            </div>
        </div>
    </div>
    <Modal :show="showModal" @close="showModal = false">
        <template #header>
            <div class="d-flex align-items-center" style="width: 100%">
                <h4 class="mb-0" style="flex: 1">Detail Agenda</h4>
                <div :class="`my-cv-item-${dataModal?.customData?.color}`" style="width: 15px; height: 15px; border-radius: 20px"></div>
            </div>
        </template>
        <template #body>
            <b>Agenda</b>
            <p>{{ dataModal?.title }}</p>
            <b>Date</b>
            <p>{{ toIndoDateName(dataModal?.start_date) }} - {{ toIndoDateName(dataModal?.end_date) }}</p>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {IProgress, IProgressComment} from "@/utils/interface/Progress";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import Session from "@/utils/Session";
import ProgressItem from "@/components/parent/partials/ProgressItem.vue";
import {IClassroomJournal, StudentAttendance} from "@/utils/interface/ClassroomJournal";
import {IDashboard} from "@/utils/Models";
import TimelineItem from "@/components/parent/partials/TimelineItem.vue";
import {IAnnouncement} from "@/utils/interface/Announcement";
import MobileAnnouncementItem from "@/components/partials/MobileAnnouncementItem.vue";
import MobileAnnouncementItemNew from "@/components/partials/MobileAnnouncementItemNew.vue";
import {AgendaResponse, agendaResponseToI, IAgenda} from "@/utils/interface/Agenda";
import {isMobile, toIndoDateName} from "@/utils/Helpers";
import Calendar from "@/components/partials/Calendar.vue";
import Modal from "@/components/partials/Modal.vue";
import JournalItem from "@/components/parent/partials/JournalItem.vue";

export default defineComponent({
    name: "Timeline",
    components: {JournalItem, Modal, Calendar, MobileAnnouncementItem, MobileAnnouncementItemNew, TimelineItem, ProgressItem},
    setup() {
        const agendas = ref<IAgenda[]>()
        const nearest = ref<IAgenda[]>()
        const showModal = ref(false)
        const dataModal = ref<IAgenda|null>(null)
        const isAndroid = Session.getIsAndroid()

        const user = Session.getCurrentUser()!
        const list = ref<IDashboard[]>([])
        const announcement = ref<IAnnouncement[]>([])
        const attendances = ref<StudentAttendance[]>([])

        const getAnnouncement = async () => {
            const r = await Api.get(`last/announcement/${user.connection_id}`)
            if (r.success && r.data) {
                announcement.value = r.data as IAnnouncement[]
            }
        }

        const getAttendances = async () => {
            const r = await Api.get(`student-classroom-journal/today/${user.connection_id}`)
            if (r.success) {
                attendances.value = r.data as StudentAttendance[]
            }
        }

        onMounted(async () => {
            getAnnouncement()
            getAttendances()

            const t = loading('Mendapatkan agenda')
            const r = await Api.get('agenda')
            const r3 = await Api.get('agenda/nearest')
            t.remove()
            if (r.success) {
                agendas.value = (r.data as AgendaResponse[]).map(v => agendaResponseToI(v))
            }
            else {
                error(r.message ?? 'gagal')
            }
            if (r3.success) {
                nearest.value = (r3.data as AgendaResponse[]).map(v => agendaResponseToI(v))
            }
            else {
                error(r.message ?? 'gagal')
            }

            // const t = loading('Mendapatkan data')
            // const r = await Api.get(`get_dashboard/${user.ortu!.id}`)
            // t.remove()
            //
            // if (r.success) {
            //     list.value = r.data.map((v: any) => ({
            //         date: v.date,
            //         type: v.type,
            //         data: v.type === 'journal' ? v.data as StudentAttendance : v.data as IProgress
            //     }))
            // }
            // else {
            //     error(r.message ?? 'gagal')
            // }
        })

        const onAgendaClick = (v: IAgenda) => {
            dataModal.value = v
            showModal.value = true
        }

        return {
            list, announcement, agendas, isMobile, showModal, onAgendaClick, dataModal, toIndoDateName,
            nearest, attendances, isAndroid
        }
    }
})
</script>

<style scoped>
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>