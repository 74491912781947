import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-center fw-bold" }, "Student Attendance", -1)),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("select", {
        class: "form-control form-control-sm",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.curMonth) = $event)),
        style: {"max-width":"150px"},
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.months, (v, k) => {
          return (_openBlock(), _createElementBlock("option", { value: k }, _toDisplayString(v), 9, _hoisted_2))
        }), 256))
      ], 544), [
        [_vModelSelect, _ctx.curMonth]
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control form-control-sm ml-2",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.curYear) = $event)),
        style: {"max-width":"150px"},
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (v) => {
          return (_openBlock(), _createElementBlock("option", { value: v }, _toDisplayString(v), 9, _hoisted_3))
        }), 256))
      ], 544), [
        [_vModelSelect, _ctx.curYear]
      ])
    ]),
    _createElementVNode("div", {
      class: "chart-container",
      style: _normalizeStyle({height: _ctx.isMobile() ? '250px' : '350px'})
    }, [
      _createVNode(_component_Bar, {
        id: "my-chart-id",
        options: _ctx.chartOptions,
        data: _ctx.chartData
      }, null, 8, ["options", "data"])
    ], 4)
  ], 64))
}