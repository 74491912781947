<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Report</div>
                            </div>
                        </div>
                        <div class="card-body" :class="{'px-0': isMobile()}">
                            <TeacherAttendanceChart/>
                            <hr>
                            <StudentAttendanceChart/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {isMobile} from "@/utils/Helpers";
import TeacherAttendanceChart from "@/components/report/partials/TeacherAttendanceChart.vue";
import StudentAttendanceChart from "@/components/report/partials/StudentAttendanceChart.vue";

export default defineComponent({
    name: "Report",
    components: {
        StudentAttendanceChart,
        TeacherAttendanceChart,
    },
    setup() {

        return {
            isMobile,
        }
    }
})

</script>

<style scoped>
.chart-container {
    flex-grow: 1;
    min-height: 0;
}
.chart-container canvas {
    position: relative;
    height: 100%;
}
</style>