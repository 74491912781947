<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="!report.length || !done" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 300px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada data</span>
                        <span style="font-style: italic" v-else>Mendapatkan data</span>
                    </div>

                    <div v-for="v in report" class="card timeline-item">
                        <div class="card-header">
                            <h4 class="mb-0 fw-bold">Raport {{ v.student_name }} (Kelas {{ v.master_report?.nama_kelas }} Semester {{ v.master_report?.semester_name }})</h4>
                        </div>
                        <div class="card-body p-0">
                            <vue-pdf-embed :source="v.report.url" :page="1"/>
                        </div>
                        <div class="card-footer d-flex">
                            <div style="flex: 1"></div>
                            <button class="btn btn-warning btn-sm mr-2" @click="show(v)">
                                Lihat Semua Halaman
                            </button>
                            <a class="btn btn-success btn-sm" target="_blank" :href="v.report.url">
                                Simpan
                            </a>
                            <div style="flex: 1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal
        v-if="dataModal"
        size="lg"
        :show="showModal"
        :title="`Raport ${dataModal.student_name} (Kelas ${dataModal.master_report?.nama_kelas} Semester ${dataModal.master_report?.semester_name})`"
        @close="showModal = !showModal"
        :remove-body-padding="true"
    >
        <template #body>
            <vue-pdf-embed :source="dataModal.report.url"/>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import Session from "@/utils/Session";
import VuePdfEmbed from 'vue-pdf-embed'
import Modal from "@/components/partials/Modal.vue";

export default defineComponent({
    name: "ParentReport",
    components: {
        Modal,
        VuePdfEmbed,
    },
    setup() {
        const user = Session.getCurrentUser()!
        const report = ref<IMasterReportStudent[]>([])
        const done = ref(false)
        const showModal = ref(false)
        const dataModal = ref<IMasterReportStudent|null>(null)

        onMounted(async () => {
            const t = loading()
            const r = await Api.get(`parent/report/${user.connection_id}`)
            t.remove()

            if (r.success) {
                report.value = r.data as IMasterReportStudent[]
            }
            else {
                error(r.message ?? 'gagal')
            }
            done.value = true
        })

        const show = (v: IMasterReportStudent) => {
            dataModal.value = v
            showModal.value = true
        }

        return {
            report, done, show, showModal, dataModal
        }
    }
})
</script>

<style scoped>
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>