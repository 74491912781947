import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  class: "table-responsive",
  style: {"overflow-y":"visible"}
}
const _hoisted_4 = { class: "table table-hover" }
const _hoisted_5 = { style: {"width":"80px"} }
const _hoisted_6 = { style: {"width":"300px"} }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = { style: {"width":"250px"} }
const _hoisted_10 = {
  key: 0,
  class: "form-group"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-6 col-sm-6 col-md-3" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "col-6 col-sm-6 col-md-3" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "table table-hover" }
const _hoisted_17 = { style: {"width":"200px"} }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { style: {"width":"200px"} }
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = { style: {"width":"200px"} }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Attendance = _resolveComponent("Attendance")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "fw-bold mb-0" }, "I. Teachers Attendance")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"d-flex align-items-center\"><span style=\"flex:1;\"></span><div class=\"b-hadir\"></div> <span>Hadir</span><div class=\"b-izin\"></div> <span>Izin</span><div class=\"b-sakit\"></div> <span>Sakit</span><div class=\"b-alpha\"></div> <span>Alpha</span><div class=\"b-tugas\"></div> <span>Tugas</span></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "No"),
              _createElementVNode("th", null, "Name"),
              _createElementVNode("th", null, "Status"),
              _createElementVNode("th", null, "Note"),
              _createElementVNode("th", null, "Substitute")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request!.teacher, (v, k) => {
              return (_openBlock(), _createElementBlock("tr", null, [
                _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                _createElementVNode("td", null, _toDisplayString(v.name), 1),
                _createElementVNode("td", _hoisted_5, [
                  (_openBlock(), _createBlock(_component_Attendance, {
                    key: `att${v.person_id}`,
                    status: v.status,
                    onStatusChange: (s) => _ctx.statusChange(s, +k)
                  }, null, 8, ["status", "onStatusChange"]))
                ]),
                _createElementVNode("td", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": ($event: any) => ((v.note) = $event),
                      class: "form-control"
                    }, null, 8, _hoisted_8), [
                      [_vModelText, v.note]
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_9, [
                  (v.status !== 'h')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_Multiselect, {
                          modelValue: v.substitutes,
                          "onUpdate:modelValue": ($event: any) => ((v.substitutes) = $event),
                          options: _ctx.refPic,
                          searchable: true,
                          "can-clear": true,
                          "can-deselect": true,
                          mode: "tags"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 256))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[3] || (_cache[3] = _createElementVNode("label", null, "a. Present", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.request!.teacher_present) = $event))
            }, null, 512), [
              [_vModelText, _ctx.request!.teacher_present]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[4] || (_cache[4] = _createElementVNode("label", null, "b. Absent", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.request!.teacher_absent) = $event))
            }, null, 512), [
              [_vModelText, _ctx.request!.teacher_absent]
            ])
          ])
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("h5", { class: "fw-bold mt-3 text-center" }, "Substitute Teacher", -1)),
      _createElementVNode("table", _hoisted_16, [
        _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "No"),
            _createElementVNode("th", null, "Name"),
            _createElementVNode("th", null, "Subject"),
            _createElementVNode("th", null, "Grade"),
            _createElementVNode("th", null, "Time"),
            _createElementVNode("th", null, "Replaced By")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request!.absent_teachers, (v, k) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, _toDisplayString(k + 1), 1),
              _createElementVNode("td", null, _toDisplayString(v.name), 1),
              _createElementVNode("td", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_Multiselect, {
                    modelValue: v.subject_id,
                    "onUpdate:modelValue": ($event: any) => ((v.subject_id) = $event),
                    options: _ctx.refSubject,
                    searchable: true,
                    "can-clear": false
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                ])
              ]),
              _createElementVNode("td", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_Multiselect, {
                    modelValue: v.grade_id,
                    "onUpdate:modelValue": ($event: any) => ((v.grade_id) = $event),
                    options: _ctx.refGrade,
                    searchable: true,
                    "can-clear": false
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                ])
              ]),
              _createElementVNode("td", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((v.time) = $event),
                    class: "form-control"
                  }, null, 8, _hoisted_23), [
                    [_vModelText, v.time]
                  ])
                ])
              ]),
              _createElementVNode("td", null, _toDisplayString(_ctx.refPic.find(x => +x.value === +v.substitute_id)?.label ?? ''), 1)
            ]))
          }), 256))
        ])
      ])
    ])
  ]))
}