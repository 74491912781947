<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Detail Syllabus</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="done">
                                <div class="col-sm-12 col-md-6">
                                    <b>Guru Pembuat</b>
                                    <p>{{ syllabus.teacher_name }}</p>
                                    <b>Subject</b>
                                    <p>{{ syllabus.subject_name }}</p>
                                    <b>Kelas</b>
                                    <p>{{ syllabus.grades.map(v => v.nama_kelas).join(', ') }}</p>
                                    <b>Semester</b>
                                    <p>{{ syllabus.semester_name }}</p>
                                    <b>Lampiran</b>
                                    <p>
                                        <a :href="syllabus.attachment?.url" target="_blank" class="d-block">{{ syllabus.attachment?.name }}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {ISyllabus} from "@/utils/interface/Syllabus";
import Api from "@/utils/Api";
import {error, loading} from "@/utils/Toast";

export default defineComponent({
    name: "DetailSyllabus",
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id
        const done = ref(false)
        const syllabus = reactive<ISyllabus>({
            subject_id: 0,
            grades: [],
            grade_options: [],
            teacher_id: 0,
            semester: 1,
        })

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r2 = await Api.get(`syllabus/${id}`)
            t.remove()

            if (r2.success) {
                const a = r2.data as ISyllabus

                syllabus.teacher_id = a.teacher_id
                syllabus.teacher_name = a.teacher_name
                syllabus.subject_id = a.subject_id
                syllabus.subject_name = a.subject_name
                syllabus.grades = a.grades
                syllabus.semester_name = a.semester_name
                syllabus.attachment = a.attachment
                syllabus.grade_options = a.grades.map(v => v.id)
                done.value = true
            }
            else {
                error(r2.message ?? 'gagal')
            }
        })

        return {
            done, syllabus,
            back: () => router.back(),
        }
    }
})
</script>

<style scoped>

</style>