import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../assets/img/e_s.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center flex-column",
  style: {"height":"calc(100vh - 80px)"}
}
const _hoisted_6 = {
  key: 0,
  style: {"font-style":"italic"}
}
const _hoisted_7 = {
  key: 1,
  style: {"font-style":"italic"}
}
const _hoisted_8 = { class: "card assessment-item" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "d-flex align-items-center" }
const _hoisted_11 = { class: "avatar-sm" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "d-flex flex-column ml-2" }
const _hoisted_14 = { class: "fw-bold" }
const _hoisted_15 = {
  style: {"font-size":"11px"},
  class: "text-muted"
}
const _hoisted_16 = {
  class: "d-flex align-items-center text-success mt-2",
  style: {"flex":"1"}
}
const _hoisted_17 = { class: "ml-2" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "ml-2" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  class: "fw-bold mt-2",
  style: {"font-size":"16px"}
}
const _hoisted_22 = {
  class: "text-muted mt-2",
  style: {"font-size":"12px"}
}
const _hoisted_23 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_24 = ["href"]
const _hoisted_25 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_26 = { style: {"font-size":"12px","font-weight":"bold"} }
const _hoisted_27 = ["href"]
const _hoisted_28 = { key: 2 }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { key: 3 }
const _hoisted_31 = {
  class: "text-success",
  style: {"font-size":"12px"}
}
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "card mb-2" }
const _hoisted_34 = { class: "card-body d-flex align-items-center" }
const _hoisted_35 = { class: "avatar-sm" }
const _hoisted_36 = ["src"]
const _hoisted_37 = {
  style: {"flex":"1"},
  class: "ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.assessment.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    src: _imports_0,
                    width: "200"
                  }, null, -1)),
                  (_ctx.done)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Tidak ada tugas :)"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, "Mendapatkan data tugas"))
                ]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.assessment, (v) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("img", {
                            class: "avatar-img rounded-circle",
                            style: {"width":"35px","height":"35px"},
                            src: _ctx.getPhoto(v.assignment.teacher_photo)
                          }, null, 8, _hoisted_12)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(v.assignment.teacher_name ?? '-'), 1),
                          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.toIndoDateNameTime(v.assignment.created_at)), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa fa-book" }, null, -1)),
                        _createElementVNode("span", _hoisted_17, _toDisplayString(v.assignment.subject_name) + " - " + _toDisplayString(v.assignment.type), 1)
                      ]),
                      _createElementVNode("div", {
                        class: "d-flex align-items-center text-success mt-2",
                        style: {"flex":"1","cursor":"pointer"},
                        onClick: ($event: any) => (_ctx.show_student(v))
                      }, [
                        _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-user-graduate" }, null, -1)),
                        _createElementVNode("span", _hoisted_19, [
                          _createTextVNode(_toDisplayString(v.assignment.assignment_students.length) + " Siswa ", 1),
                          (v.assignment.assignment_students.filter(x => x.status !== 'new').length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_20, " (" + _toDisplayString(v.assignment.assignment_students.filter(x => x.status !== 'new').length) + " siswa telah mengerjakan) ", 1))
                            : _createCommentVNode("", true)
                        ])
                      ], 8, _hoisted_18),
                      _cache[6] || (_cache[6] = _createElementVNode("div", {
                        style: {"height":"1px","background":"#eee"},
                        class: "my-2"
                      }, null, -1)),
                      _createElementVNode("div", _hoisted_21, _toDisplayString(v.assignment.title), 1),
                      _createElementVNode("div", _hoisted_22, _toDisplayString(v.assignment.description), 1),
                      (v.assignment.link)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _cache[4] || (_cache[4] = _createElementVNode("span", { style: {"font-size":"12px","font-weight":"bold"} }, "Link:", -1)),
                            _createElementVNode("a", {
                              href: v.assignment.link,
                              target: "_blank",
                              class: "d-block"
                            }, _toDisplayString(v.assignment.link), 9, _hoisted_24)
                          ]))
                        : _createCommentVNode("", true),
                      (v.assignment.questions.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createElementVNode("span", _hoisted_26, "Unduh Soal (" + _toDisplayString(v.assignment.questions.length) + " file):", 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.assignment.questions, (q) => {
                              return (_openBlock(), _createElementBlock("a", {
                                href: q.url,
                                target: "_blank",
                                class: "d-block"
                              }, _toDisplayString(q.file_name), 9, _hoisted_27))
                            }), 256))
                          ]))
                        : _createCommentVNode("", true),
                      _cache[7] || (_cache[7] = _createElementVNode("div", {
                        style: {"height":"1px","background":"#eee"},
                        class: "my-2"
                      }, null, -1)),
                      _cache[8] || (_cache[8] = _createElementVNode("div", {
                        style: {"font-size":"12px"},
                        class: "fw-bold"
                      }, "Waktu Pengerjaan:", -1)),
                      _createElementVNode("div", null, _toDisplayString(_ctx.toIndoDateNameTime(v.assignment.completion_date)) + " - " + _toDisplayString(_ctx.toIndoDateNameTime(v.assignment.collection_date)), 1),
                      _cache[9] || (_cache[9] = _createElementVNode("div", {
                        style: {"height":"1px","background":"#eee"},
                        class: "my-2"
                      }, null, -1)),
                      (v.status === 'new')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                            _cache[5] || (_cache[5] = _createElementVNode("div", {
                              class: "text-danger",
                              style: {"font-size":"12px"}
                            }, "Kamu belum menyelesaikan tugas ini. Yuk kerjakan sekarang!", -1)),
                            _createElementVNode("button", {
                              class: "btn btn-danger btn-sm mt-2",
                              onClick: ($event: any) => (_ctx.detail(v.id))
                            }, " Kerjakan Tugas ", 8, _hoisted_29)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_30, [
                            _createElementVNode("div", _hoisted_31, "Kamu sudah menyelesaikan tugas ini pada " + _toDisplayString(_ctx.toIndoDateNameTime(v.submitted_at)) + ". Kalau kamu masih ragu dengan jawabanmu, kamu masih bisa mengeditnya.", 1),
                            _createElementVNode("button", {
                              class: "btn btn-success btn-sm mt-2",
                              onClick: ($event: any) => (_ctx.detail(v.id))
                            }, " Edit Jawaban ", 8, _hoisted_32)
                          ]))
                    ])
                  ]))
                }), 256))
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      show: _ctx.showModal,
      title: "Daftar Siswa",
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false))
    }, {
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataModal.assignment.assignment_students, (v) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_33, [
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("img", {
                  class: "avatar-img rounded-circle",
                  style: {"width":"35px","height":"35px"},
                  src: _ctx.getPhoto(v.student_photo)
                }, null, 8, _hoisted_36)
              ]),
              _createElementVNode("span", _hoisted_37, _toDisplayString(v.student_name), 1),
              _createElementVNode("span", {
                class: _normalizeClass(["badge", {'badge-success': v.status !== 'new', 'badge-danger': v.status === 'new'}])
              }, _toDisplayString(v.status !== 'new' ? 'SUDAH' : 'BELUM'), 3)
            ])
          ]))
        }), 256))
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}