<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Announcement</div>
                                <div class="card-tools">
                                    <button @click="add" class="b-time btn btn-success btn-round mr-2">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
							<PaginatedTable :data="announcements" class="mt-3">
								<template #thead>
									<th>No</th>
									<th>Created At</th>
									<th>Title</th>
									<th>Message</th>
									<th>Message Detail</th>
									<th>Kelas</th>
									<th>Attachment</th>
									<th>Aksi</th>
								</template>
								<template #tbody="{row, index}">
									<td>{{ index + 1 }}</td>
									<td>{{ toIndoDateNameTime(row.data.created_at) }}</td>
									<td>{{ row.data.title }}</td>
									<td>{{ row.data.message }}</td>
									<td>
										<button class="btn btn-success btn-sm" @click="detail(row.data.action_detail)">
											Show Detail
										</button>
									</td>
									<td>
										{{ row.data.announcement_grades.map(x => x.grade_name).join(', ') }}
									</td>
									<td>
										<a v-if="row.data.pdf" target="_blank" :href="row.data.pdf" class="btn btn-success btn-sm">
											<i class="fa fa-download"></i>
										</a>
									</td>
									<td>
										<button class="btn btn-danger btn-sm" @click="destroy(row.data.id)">
											<i class="fa fa-trash"></i>
										</button>
									</td>
								</template>
							</PaginatedTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal
        :show="showModal"
        @close="showModal = false"
        title="Message Detail"
    >
        <template #body>
            <div v-html="modalData"></div>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import Api from "@/utils/Api";
import {IAnnouncement} from "@/utils/interface/Announcement";
import {toIndoDateNameTime} from "@/utils/Helpers";
import Modal from "@/components/partials/Modal.vue";
import {error, loading, success} from "@/utils/Toast";
import Session from "@/utils/Session";
import Role from "@/utils/Role";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "Announcement",
    components: {Modal, PaginatedTable},
    setup() {
        const router = useRouter()
        const announcements = ref<IAnnouncement[]>([])
        const showModal = ref(false)
        const modalData = ref('')
        const user = Session.getCurrentUser()!

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = +user.role_id === Role.Ortu ? await Api.get(`announcement/${user.connection_id}`) : await Api.get(`announcement`)
            t.remove()

            if (r.success) {
                announcements.value = r.data as IAnnouncement[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.delete(`announcement/${id}`)
                t.remove()

                if (r.success) {
                    announcements.value = r.data as IAnnouncement[]
                    success('Berhasil menghapus data')
                }
                else {
                    error(r.message ?? 'gagal')
                }
            }
        }

        const detail = (detail: string) => {
            showModal.value = true
            modalData.value = detail
        }

        return {
            announcements, toIndoDateNameTime, destroy, showModal, modalData,
            add: () => router.push('/announcement/add'),
            detail
        };
    }
})
</script>

<style scoped>

</style>