<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Add Student Academic Progress / Achievements</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Siswa <b class="text-danger">*</b></label>
                                        <Multiselect
                                            v-model="request.student_id"
                                            :options="refStudent"
                                            :searchable="true"
                                            :can-clear="false"
                                            :can-deselect="false"
                                        />
                                        <span class="text-danger" v-if="validation.student">{{ validation.student }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Subject <b class="text-danger">*</b></label>
                                        <Multiselect
                                            v-model="request.subject_id"
                                            :options="refSubject"
                                            :searchable="true"
                                            :can-clear="false"
                                            :can-deselect="false"
                                        />
                                        <span class="text-danger" v-if="validation.subject">{{ validation.subject }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Semester <b class="text-danger">*</b></label>
                                        <Multiselect
                                            v-model="request.semester"
                                            :options="[{value: 1, label: 'Ganjil'},{value: 2, label: 'Genap'}]"
                                            :searchable="true"
                                            :can-clear="false"
                                            :can-deselect="false"
                                        />
                                        <span class="text-danger" v-if="validation.semester">{{ validation.semester }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Catatan <b class="text-danger">*</b></label>
                                        <textarea class="form-control" v-model="request.note" rows="5"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Lampiran <b class="text-danger">*</b></label>
                                        <div class="form-control">
                                            <div v-for="v in request.attachments" class="form-control mb-2 d-flex align-items-center" style="padding: 0.6rem">
                                                <span style="flex: 1; min-width: 0">{{ v.file.name }}</span>
                                                <button class="btn btn-sm btn-danger ml-2" @click="remove_question(v.id)">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </div>
                                            <input type="file"
                                                   ref="question_file"
                                                   id="question_file"
                                                   accept="image/*, .mp3, video/mp4, video/x-m4v, video/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .zip, .rar, .7zip"
                                                   @change="question_change"
                                                   style="display: none">
                                            <div class="d-flex flex-row-reverse">
                                                <button type="button" @click="add_question" class="btn btn-sm btn-success">
                                                    <i class="fa fa-plus"></i> Tambah File
                                                </button>
                                            </div>
                                        </div>
                                        <p class="mt-1 mb-0">Tipe file yang diizinkan adalah .mp3, .mp4, .pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .xlsx, .xls, .rar, .zip, .ppt dengan maksimal 5 MB.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save">Simpan</button>
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import Multiselect from "@vueform/multiselect";
import {useRouter} from "vue-router";
import {IOption} from "@/utils/interface/Assignment";
import Api from "@/utils/Api";
import {Pelajaran, Siswa} from "@/utils/Models";
import {IProgress} from "@/utils/interface/Progress";
import Session from "@/utils/Session";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "AddProgress",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const refStudent = ref<IOption[]>([])
        const refSubject = ref<IOption[]>([])
        const request = reactive<IProgress>({
            student_id: 0,
            subject_id: 0,
            semester: 1,
            note: '',
            attachments: []
        })
        const validation = reactive({
            student: '',
            subject: '',
            semester: '',
            note: '',
        })

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            t.remove()

            if (r.success) {
                refStudent.value = (r.data.siswa as Siswa[]).map(v => ({value: v.id, label: `${v.nama_kelas ? `[${v.nama_kelas}] ` : ''}${v.nama}`}))
                refSubject.value = (r.data.mapel as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const validate = () => {
            validation.student = ''
            validation.subject = ''
            validation.semester = ''
            validation.note = ''

            if (!request.student_id) {
                validation.student = 'Pilih siswa dulu'
                return false
            }
            if (!request.subject_id) {
                validation.subject = 'Pilih subject dulu'
                return false
            }
            if (!request.semester) {
                validation.semester = 'Pilih semester dulu'
                return false
            }
            if (!request.note) {
                validation.note = 'Masukkan catatan dulu'
                return false
            }
            return true
        }
        const save = async () => {
            if (!validate()) {
                return
            }

            let data = new FormData()
            request.attachments?.forEach(v => {
                if (v.file) {
                    data.append('attachments[]', v.file)
                }
            })
            data.append('student_id', `${request.student_id}`)
            data.append('subject_id', `${request.subject_id}`)
            data.append('semester', `${request.semester}`)
            data.append('note', `${request.note}`)
            data.append('created_by', `${Session.getCurrentUser()!.id}`)

            const t = loading('Menyimpan data')
            const r = await Api.post('progress/create', data, { 'Content-Type': 'multipart/form-data' })
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        const remove_question = (id: number) => request.attachments = request.attachments?.filter(v => v.id !== id)
        const question_change = (event: any) => request.attachments?.push({
            id: Math.floor(Math.random() * 10000) + 1,
            file: event.target.files[0],
            type: event.target.files[0].name.split('.').pop(),
        })
        const add_question = () => document.getElementById('question_file')?.click()

        return {
            request, refStudent, refSubject, validation, save,
            add_question, question_change, remove_question,
            back: () => router.back(),
        }
    }
})
</script>

<style scoped>

</style>