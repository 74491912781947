import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card mb-2 assessment-item" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "d-flex align-items-center mb-2" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "fw-bold ml-2",
  style: {"flex":"1"}
}
const _hoisted_6 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { key: 2 }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "d-flex align-items-center" }
const _hoisted_17 = {
  class: "form-group pl-0 py-0",
  style: {"flex":"1"}
}
const _hoisted_18 = ["readonly"]
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "d-flex align-items-center mt-2" }
const _hoisted_21 = {
  class: "form-group pl-0 py-0",
  style: {"flex":"1"}
}
const _hoisted_22 = ["readonly"]
const _hoisted_23 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "avatar-img rounded-circle",
          style: {"width":"35px","height":"35px"},
          src: _ctx.getPhoto(_ctx.assignment.student_photo)
        }, null, 8, _hoisted_4),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.assignment.student_name), 1),
        (_ctx.assignment.status === 'done')
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Scored"))
          : _createCommentVNode("", true)
      ]),
      (_ctx.assignment.submitted_at === null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[5] || (_cache[5] = [
            _createElementVNode("span", { class: "text-danger" }, " The student has not been submitted an answer yet. ", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_ctx.showAnswer)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_ctx.assignment.answer)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _cache[6] || (_cache[6] = _createElementVNode("b", null, "Answer", -1)),
                        _createElementVNode("div", {
                          innerHTML: _ctx.assignment.answer
                        }, null, 8, _hoisted_11)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.assignment.link)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _cache[7] || (_cache[7] = _createElementVNode("b", null, "Link", -1)),
                        _createElementVNode("a", {
                          class: "d-block",
                          href: _ctx.assignment.link,
                          target: "_blank"
                        }, _toDisplayString(_ctx.assignment.link), 9, _hoisted_13)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.assignment.attachments?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("b", null, "Attachments (" + _toDisplayString(_ctx.assignment.attachments.length) + " file)", 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignment.attachments, (a) => {
                          return (_openBlock(), _createElementBlock("a", {
                            href: a.url,
                            class: "d-block"
                          }, _toDisplayString(a.file_name), 9, _hoisted_15))
                        }), 256))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              class: "pointer underline text-success",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAnswer = !_ctx.showAnswer))
            }, _toDisplayString(_ctx.showAnswer ? 'Hide Answer' : 'Show Answer'), 1)
          ])),
      _cache[10] || (_cache[10] = _createElementVNode("div", {
        class: "my-2",
        style: {"background":"#eee"}
      }, null, -1)),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _withDirectives(_createElementVNode("input", {
            type: "number",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assignment.score) = $event)),
            class: "form-control form-control-sm",
            readonly: _ctx.assignment.saving,
            placeholder: "Score"
          }, null, 8, _hoisted_18), [
            [_vModelText, _ctx.assignment.score]
          ])
        ]),
        (!_ctx.done)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-sm btn-success",
              disabled: _ctx.assignment.saving,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("i", { class: "fa fa-save" }, null, -1)
            ]), 8, _hoisted_19))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.assignment.note) = $event)),
            class: "form-control form-control-sm",
            readonly: _ctx.assignment.saving_note,
            placeholder: "Note"
          }, null, 8, _hoisted_22), [
            [_vModelText, _ctx.assignment.note]
          ])
        ]),
        (!_ctx.done)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-sm btn-success",
              disabled: _ctx.assignment.saving_note,
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.saveNote && _ctx.saveNote(...args)))
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("i", { class: "fa fa-save" }, null, -1)
            ]), 8, _hoisted_23))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}