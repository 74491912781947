import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["innerHTML"]

import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";
import {onMounted, ref} from "vue";
import Modal from "@/components/partials/Modal.vue";
import {useRouter} from "vue-router";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export interface IInformation {
  id: number,
  image: string,
  title: string,
  body: string,
  created_by: number,
  created_at: string,
  creator_name?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Information',
  setup(__props) {


const informations = ref<IInformation[]>([])
const showModal = ref(false)
const modalData = ref('')
const router = useRouter()

onMounted(async () => {
  const t = loading('Mendapatkan data')
  const r = await Api.get(`information`)
  t.remove()

  if (r.success) {
    informations.value = r.data as IInformation[]
  }
  else {
    error(r.message ?? 'gagal')
  }
})

const add = () => router.push('/information/add')

const edit = (id: string|number) => router.push(`/information/edit/${id}`)

const destroy = async (id: string|number) => {
  if (!confirm('Yakin hapus data ini?')) {
    return
  }

  const t = loading('Menghapus data')
  const r = await Api.get(`information/delete/${id}`)
  t.remove()
  if (r.success) {
    informations.value = r.data as IInformation[]
  }
}

const detail = (detail: string) => {
  showModal.value = true
  modalData.value = detail
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("div", { class: "card-head-row" }, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, "Information", -1)),
                  _createElementVNode("div", { class: "card-tools" }, [
                    _createElementVNode("button", {
                      onClick: add,
                      class: "b-time btn btn-success btn-round mr-2"
                    }, " Add ")
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(PaginatedTable, {
                  data: informations.value,
                  class: "mt-3"
                }, {
                  thead: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("th", null, "No", -1),
                    _createElementVNode("th", null, "Created", -1),
                    _createElementVNode("th", null, "Image", -1),
                    _createElementVNode("th", null, "Title", -1),
                    _createElementVNode("th", null, "Body", -1),
                    _createElementVNode("th", null, "Aksi", -1)
                  ])),
                  tbody: _withCtx(({row, index}) => [
                    _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString(_unref(toIndoDateNameTime)(row.data.created_at)) + " ", 1),
                      (row.data.creator_name)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, " By " + _toDisplayString(row.data.creator_name), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("img", {
                        src: _unref(getPhoto)(row.data.image),
                        class: "img",
                        alt: ""
                      }, null, 8, _hoisted_8)
                    ]),
                    _createElementVNode("td", null, _toDisplayString(row.data.title), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("button", {
                        class: "btn btn-success btn-sm",
                        onClick: ($event: any) => (detail(row.data.body))
                      }, " Show Detail ", 8, _hoisted_9)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("button", {
                        class: "btn btn-warning btn-sm mr-2",
                        onClick: ($event: any) => (edit(row.data.id))
                      }, _cache[3] || (_cache[3] = [
                        _createElementVNode("i", { class: "fa fa-pencil" }, null, -1)
                      ]), 8, _hoisted_10),
                      _createElementVNode("button", {
                        class: "btn btn-danger btn-sm",
                        onClick: ($event: any) => (destroy(row.data.id))
                      }, _cache[4] || (_cache[4] = [
                        _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                      ]), 8, _hoisted_11)
                    ])
                  ]),
                  _: 1
                }, 8, ["data"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(Modal, {
      show: showModal.value,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (showModal.value = false)),
      title: "Message Detail"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", { innerHTML: modalData.value }, null, 8, _hoisted_12)
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})