import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  class: "card-body",
  style: {"background-color":"#F9FBFD"}
}
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "table-hover" }
const _hoisted_7 = { class: "table table-hover" }
const _hoisted_8 = { style: {"width":"20px"} }
const _hoisted_9 = { style: {"width":"200px"} }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { style: {"width":"200px"} }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { style: {"width":"80px"} }
const _hoisted_14 = { style: {"width":"20px"} }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-6 col-sm-6 col-md-3" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "card" }
const _hoisted_20 = { class: "card-body" }
const _hoisted_21 = { class: "d-flex align-items-center" }
const _hoisted_22 = { class: "table table-hover" }
const _hoisted_23 = { style: {"width":"20px"} }
const _hoisted_24 = { style: {"width":"200px"} }
const _hoisted_25 = { class: "form-group" }
const _hoisted_26 = { style: {"width":"200px"} }
const _hoisted_27 = { class: "form-group" }
const _hoisted_28 = { class: "form-group" }
const _hoisted_29 = ["onUpdate:modelValue"]
const _hoisted_30 = { style: {"width":"20px"} }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "card" }
const _hoisted_33 = { class: "card-body" }
const _hoisted_34 = { class: "d-flex align-items-center" }
const _hoisted_35 = { class: "table table-hover" }
const _hoisted_36 = { style: {"width":"20px"} }
const _hoisted_37 = { style: {"width":"200px"} }
const _hoisted_38 = { class: "form-group" }
const _hoisted_39 = { style: {"width":"200px"} }
const _hoisted_40 = { class: "form-group" }
const _hoisted_41 = { class: "form-group" }
const _hoisted_42 = ["onUpdate:modelValue"]
const _hoisted_43 = { style: {"width":"20px"} }
const _hoisted_44 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_Attendance = _resolveComponent("Attendance")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "fw-bold mb-0" }, "II. Santri Attendance")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("label", {
              class: "fw-bold",
              style: {"flex":"1"}
            }, "a. Absence", -1)),
            _createElementVNode("button", {
              class: "btn btn-success btn-round btn-sm",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addAbsent && _ctx.addAbsent(...args)))
            }, " Add ")
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("table", _hoisted_7, [
              _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "No"),
                  _createElementVNode("th", null, "Grade"),
                  _createElementVNode("th", null, "Name"),
                  _createElementVNode("th", null, "Status"),
                  _createElementVNode("th")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request!.student.absent, (v, k) => {
                  return (_openBlock(), _createElementBlock("tr", null, [
                    _createElementVNode("td", _hoisted_8, _toDisplayString(k + 1), 1),
                    _createElementVNode("td", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_Multiselect, {
                          modelValue: v.grade_id,
                          "onUpdate:modelValue": ($event: any) => ((v.grade_id) = $event),
                          options: _ctx.refGrade,
                          searchable: true,
                          "can-deselect": false,
                          "can-clear": false,
                          onChange: () => v.person_id = 0
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_Multiselect, {
                          modelValue: v.person_id,
                          "onUpdate:modelValue": ($event: any) => ((v.person_id) = $event),
                          options: _ctx.refStudent.filter(x => x.student_class.find(z => +z.trx_class?.id  === +v.grade_id)),
                          searchable: true,
                          "can-deselect": false,
                          "can-clear": false,
                          onChange: _ctx.studentAbsentChange
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_13, [
                      (_openBlock(), _createBlock(_component_Attendance, {
                        key: `att${v.person_id}`,
                        status: v.status,
                        onStatusChange: (s) => _ctx.studentStatusChange(s, +k)
                      }, null, 8, ["status", "onStatusChange"]))
                    ]),
                    _createElementVNode("td", _hoisted_14, [
                      _createElementVNode("button", {
                        class: "btn btn-sm btn-danger",
                        onClick: ($event: any) => (_ctx.removeAbsent(k))
                      }, _cache[5] || (_cache[5] = [
                        _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                      ]), 8, _hoisted_15)
                    ])
                  ]))
                }), 256))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _cache[7] || (_cache[7] = _createElementVNode("label", null, "Absent", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.request!.student_absent) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.request!.student_absent]
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _cache[8] || (_cache[8] = _createElementVNode("label", {
              class: "fw-bold",
              style: {"flex":"1"}
            }, "b. Come Late", -1)),
            _createElementVNode("button", {
              class: "btn btn-success btn-round btn-sm",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addComeLate && _ctx.addComeLate(...args)))
            }, " Add ")
          ]),
          _createElementVNode("table", _hoisted_22, [
            _cache[10] || (_cache[10] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "No"),
                _createElementVNode("th", null, "Grade"),
                _createElementVNode("th", null, "Name"),
                _createElementVNode("th", null, "Note"),
                _createElementVNode("th")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request!.student.come_late, (v, k) => {
                return (_openBlock(), _createElementBlock("tr", null, [
                  _createElementVNode("td", _hoisted_23, _toDisplayString(k + 1), 1),
                  _createElementVNode("td", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(_component_Multiselect, {
                        modelValue: v.grade_id,
                        "onUpdate:modelValue": ($event: any) => ((v.grade_id) = $event),
                        options: _ctx.refGrade,
                        searchable: true,
                        "can-deselect": false,
                        "can-clear": false,
                        onChange: () => v.person_id = 0
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_Multiselect, {
                        modelValue: v.person_id,
                        "onUpdate:modelValue": ($event: any) => ((v.person_id) = $event),
                        options: _ctx.refStudent.filter(x => x.student_class.find(z => +z.trx_class?.id === +v.grade_id)),
                        searchable: true,
                        "can-deselect": false,
                        "can-clear": false
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_28, [
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((v.note) = $event)
                      }, null, 8, _hoisted_29), [
                        [_vModelText, v.note]
                      ])
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_30, [
                    _createElementVNode("button", {
                      class: "btn btn-sm btn-danger",
                      onClick: ($event: any) => (_ctx.removeComeLate(+k))
                    }, _cache[9] || (_cache[9] = [
                      _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                    ]), 8, _hoisted_31)
                  ])
                ]))
              }), 256))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _cache[11] || (_cache[11] = _createElementVNode("label", {
              class: "fw-bold",
              style: {"flex":"1"}
            }, "b. Leave Early", -1)),
            _createElementVNode("button", {
              class: "btn btn-success btn-round btn-sm",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addLeaveEarly && _ctx.addLeaveEarly(...args)))
            }, " Add ")
          ]),
          _createElementVNode("table", _hoisted_35, [
            _cache[13] || (_cache[13] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "No"),
                _createElementVNode("th", null, "Grade"),
                _createElementVNode("th", null, "Name"),
                _createElementVNode("th", null, "Note"),
                _createElementVNode("th")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request!.student.leave_early, (v, k) => {
                return (_openBlock(), _createElementBlock("tr", null, [
                  _createElementVNode("td", _hoisted_36, _toDisplayString(k + 1), 1),
                  _createElementVNode("td", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      _createVNode(_component_Multiselect, {
                        modelValue: v.grade_id,
                        "onUpdate:modelValue": ($event: any) => ((v.grade_id) = $event),
                        options: _ctx.refGrade,
                        searchable: true,
                        "can-deselect": false,
                        "can-clear": false,
                        onChange: () => v.person_id = 0
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                      _createVNode(_component_Multiselect, {
                        modelValue: v.person_id,
                        "onUpdate:modelValue": ($event: any) => ((v.person_id) = $event),
                        options: _ctx.refStudent.filter(x => x.student_class.find(z => +z.trx_class?.id === +v.grade_id)),
                        searchable: true,
                        "can-deselect": false,
                        "can-clear": false
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_41, [
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((v.note) = $event)
                      }, null, 8, _hoisted_42), [
                        [_vModelText, v.note]
                      ])
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_43, [
                    _createElementVNode("button", {
                      class: "btn btn-sm btn-danger",
                      onClick: ($event: any) => (_ctx.removeLeaveEarly(+k))
                    }, _cache[12] || (_cache[12] = [
                      _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                    ]), 8, _hoisted_44)
                  ])
                ]))
              }), 256))
            ])
          ])
        ])
      ])
    ])
  ]))
}