<template>
    <footer class="footer">
        <div class="container-fluid">
            <nav class="pull-left">
                <ul class="nav">
                    <li class="nav-item">
                        <a class="nav-link" href="#">
                            Bumi Cendekia
                        </a>
                    </li>
                </ul>
            </nav>
            <div class="copyright ml-auto">
                2021 - {{ (new Date()).getFullYear() }}, made with <i class="fa fa-heart heart text-danger"></i> by Bumi Cendekia
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "Footer"
})
</script>
