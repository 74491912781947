<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card assessment-item">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Assesment / Assignment Evaluation</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="done">
                                <div class="col-sm-12 col-md-6">
                                    <b>Guru Pembuat</b>
                                    <p>{{ assignment.teacher_name }}</p>
                                    <b>Subject</b>
                                    <p>{{ assignment.subject_name }}</p>
                                    <b>Judul Tugas</b>
                                    <p>{{ assignment.title }}</p>
                                    <b>Tanggal & Waktu Pengerjaan</b>
                                    <p>{{ toIndoDateNameTime(assignment.completion_date) }}</p>
                                    <b>Tanggal & Waktu Pengumpulan</b>
                                    <p>{{ toIndoDateNameTime(assignment.collection_date) }}</p>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <b>Kelas</b>
                                    <p>{{ assignment.grades.map(v => v.nama_kelas).join(', ') }}</p>
                                    <b>Siswa</b>
                                    <p>{{ assignment.students.map(v => v.nama).join(', ') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" v-if="false">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Siswa</th>
                                        <th>Jawaban</th>
                                        <th>Link</th>
                                        <th>Lampiran</th>
                                        <th>Nilai</th>
                                        <th>Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v, k) in assignment.assignment_students">
                                        <td>{{ k + 1 }}</td>
                                        <td><span>{{ v.student_name }}</span></td>
                                        <td><div v-html="v.answer"></div></td>
                                        <td><a v-if="v.link" :href="v.link" target="_blank">{{ v.link }}</a> </td>
                                        <td>
                                            <div v-if="v.attachments.length">{{ v.attachments.length }} file:</div>
                                            <a v-for="a in v.attachments" :href="a.url" class="d-block">{{ a.file_name }}</a>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" class="form-control form-control-sm" v-model="v.score" :readonly="v.saving">
                                            </div>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-success" @click="save(v)" :disabled="v.saving">
                                                <i class="fa fa-save"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-danger" @click="back">Kembali</button>
                        </div>
                    </div>

                    <div>
                        <EvaluationMobile
                            v-for="(v, k) in assignment.assignment_students"
                            :assignment="v"
                            :done="!!doneScoring"
                            @onChange="(n) => onChange(k, n)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {IAssignment, IAssignmentStudent} from "@/utils/interface/Assignment";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {getPhoto, isMobile, toIndoDateNameTime} from "@/utils/Helpers";
import EvaluationMobile from "@/components/assignment/partials/EvaluationMobile.vue";

export default defineComponent({
    name: "AssignmentEvaluation",
    components: {EvaluationMobile},
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id
        const doneScoring = route.params.done ?? '0'
        const done = ref(false)
        const assignment = reactive<IAssignment>({
            teacher_id: 0,
            subject_id: 0,
            title: '',
            grades: [],
            grade_options: [],
            students: [],
            student_options: [],
            completion_date: '',
            collection_date: '',
            questions: [],
            link: '',
            description: '',
        })

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r2 = await Api.get(`assignment/${id}`)
            t.remove()

            if (r2.success) {
                const a = r2.data as IAssignment

                assignment.teacher_id = a.teacher_id
                assignment.teacher_name = a.teacher_name
                assignment.subject_id = a.subject_id
                assignment.subject_name = a.subject_name
                assignment.title = a.title
                assignment.grades = a.grades
                assignment.grade_options = a.grades.map(v => v.id)
                assignment.students = a.students
                assignment.student_options = a.students.map(v => v.id)
                assignment.completion_date = a.completion_date
                assignment.collection_date = a.collection_date
                assignment.link = a.link
                assignment.description = a.description
                assignment.questions = a.questions
                assignment.assignment_students = a.assignment_students
                done.value = true
            }
            else {
                error(r2.message ?? 'gagal')
            }
        })

        const save = async (v: IAssignmentStudent) => {
            if (!v.score) {
                error('Nilai belum diinput')
                return
            }

            v.saving = true
            const r = await Api.post(`assignment/score/${v.id}/${v.score}`, {})
            if (r.success) {
                success('Berhasil menyimpan nilai')
            }
            else {
                error(r.message ?? 'Gagal menyimpan nilai')
            }
            v.saving = false
        }

        const onChange = (k: number, v: IAssignmentStudent) => {
            assignment.assignment_students![k] = v
        }

        return {
            assignment, done, toIndoDateNameTime, getPhoto, save,
            back: () => router.back(),
            isMobile, onChange, doneScoring
        }
    }
})
</script>

<style scoped>
.assessment-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>