<template>
    <div class="row mt-2">
        <div class="col-sm-12">
            <div class="d-flex align-items-center justify-center mb-8">
                <img alt="profil" src="https://dn0vai85ainxm.cloudfront.net/assets/pijar-sekolah-v3/images/il_profil_sekolah.svg">
                <div class="text-center ml-4">
                    <h3 class="fw-bold">SMP Bumi Cendekia</h3>
                    <p class="text-sm">70015076</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
            <div class="form-group">
                <label>Nama Sekolah</label>
                <input type="text" class="form-control" v-model="school.name">
            </div>
            <div class="form-group">
                <label>NPSN</label>
                <input type="text" class="form-control" v-model="school.npsn">
            </div>
            <div class="form-group">
                <label>Lembaga</label>
                <input type="text" class="form-control" v-model="school.lembaga">
            </div>
            <div class="form-group">
                <label>Status Pendidikan</label>
                <input type="text" class="form-control" v-model="school.status">
            </div>
            <div class="form-group">
                <label>Jenjang</label>
                <input type="text" class="form-control" v-model="school.jenjang">
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="form-group">
                <label>Alamat</label>
                <textarea class="form-control" v-model="school.alamat" rows="4"></textarea>
            </div>
            <div class="form-group">
                <label>Provinsi</label>
                <input type="text" class="form-control" v-model="school.provinsi">
            </div>
            <div class="form-group">
                <label>Kota/Kabupaten</label>
                <input type="text" class="form-control" v-model="school.kota">
            </div>
        </div>
    </div>
    <div class="row mt-4 d-flex flex-row-reverse">
        <button class="btn btn-success" @click="save">
            Simpan
        </button>
    </div>
</template>

<script lang="ts">
import {defineComponent, reactive} from "vue";
import {School} from "@/utils/Models"
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import Session from "@/utils/Session";

export default defineComponent({
    name: "TabProfilSekolah",
    setup() {
        const school = reactive<School>(Session.getSchool())

        const save = async () => {
            const t = loading()
            const r = await Api.post('master/school/update', {...school})
            t.remove()

            if (r.success) {
                school.name = r.data.name
                school.npsn = r.data.npsn
                school.lembaga = r.data.lembaga
                school.status = r.data.status
                school.jenjang = r.data.jenjang
                school.alamat = r.data.alamat
                school.provinsi = r.data.provinsi
                school.kota = r.data.kota

                Session.setSchool(school)

                success('Berhasil mengedit data sekolah')
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        return {
            school, save
        }
    }
})
</script>

<style scoped>

</style>
