<template>
    <div class="card">
        <div class="card-header">
            <h4 class="fw-bold mb-0">Santri Attendance</h4>
        </div>
        <div class="card-body">
            <div class="d-flex align-items-center">
                <span style="flex: 1"></span>
                <div class="b-hadir"></div> <span>Hadir</span>
                <div class="b-izin"></div> <span>Izin</span>
                <div class="b-sakit"></div> <span>Sakit</span>
                <div class="b-alpha"></div> <span>Alpha</span>
                <div class="b-tugas"></div> <span>Tugas</span>
            </div>
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Note</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v, k) in attendances">
                        <td style="width: 40px">{{ k + 1 }}</td>
                        <td>{{ v.student_name }}</td>
                        <td style="width: 80px"><Attendance :key="`std${v.student_id}`" :status="v.status" :is-student="true" @statusChange="s => onStatusChange(s, k)"/></td>
                        <td style="width: 300px">
                            <div class="form-group">
                                <textarea v-model="v.note" class="form-control"></textarea>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import Attendance from "@/components/partials/Attendance.vue"
import Multiselect from '@vueform/multiselect'
import {StudentAttendance} from "@/utils/interface/ClassroomJournal";

export default defineComponent({
    name: "SantriAttendance",
    components: {
        Attendance,
        Multiselect
    },
    props: {
        attendances: Array as PropType<StudentAttendance[]>
    },
    emits: ['onStatusChange'],
    setup(_, { emit }) {
        return {
            onStatusChange: (s: string, k: number) => {
                emit('onStatusChange', s, k)
            }
        }
    }
})
</script>

<style scoped>
.b-hadir {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #8BC53F;
    margin-left: 12px;
    margin-right: 3px;
}
.b-izin {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #3088C8;
    margin-left: 12px;
    margin-right: 3px;
}
.b-sakit {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #F6931E;
    margin-left: 12px;
    margin-right: 3px;
}
.b-alpha {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #ED1F24;
    margin-left: 12px;
    margin-right: 3px;
}
.b-tugas {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #8d0db0;
    margin-left: 12px;
    margin-right: 3px;
}
</style>
