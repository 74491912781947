<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Detail Assesment / Assignment</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="done">
                                <div class="col-sm-12 col-md-6">
                                    <b>Guru Pembuat</b>
                                    <p>{{ assignment.teacher_name }}</p>
                                    <b>Subject</b>
                                    <p>{{ assignment.subject_name }}</p>
                                    <b>Judul Tugas</b>
                                    <p>{{ assignment.title }}</p>
                                    <b>Tanggal & Waktu Pengerjaan</b>
                                    <p>{{ toIndoDateNameTime(assignment.completion_date) }}</p>
                                    <b>Tanggal & Waktu Pengumpulan</b>
                                    <p>{{ toIndoDateNameTime(assignment.collection_date) }}</p>
                                    <b>Link</b>
                                    <p>{{ assignment.link ?? '-' }}</p>
                                    <b>Deskripsi</b>
                                    <p>{{ assignment.description ?? '-' }}</p>
                                    <b>Soal</b>
                                    <p>
                                        <a v-for="v in assignment.questions" :href="v.url" target="_blank" class="d-block">{{ v.file_name }}</a>
                                    </p>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <b>Kelas</b>
                                    <p>{{ assignment.grades.map(v => v.nama_kelas).join(', ') }}</p>
                                    <b>Siswa</b>
                                    <p>{{ assignment.students.map(v => v.nama).join(', ') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {IAssignment} from "@/utils/interface/Assignment";
import Api from "@/utils/Api";
import {toIndoDateNameTime} from "@/utils/Helpers";
import {error, loading} from "@/utils/Toast";

export default defineComponent({
    name: "DetailAssignment",
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id
        const done = ref(false)
        const assignment = reactive<IAssignment>({
            teacher_id: 0,
            subject_id: 0,
            title: '',
            grades: [],
            grade_options: [],
            students: [],
            student_options: [],
            completion_date: '',
            collection_date: '',
            questions: [],
            link: '',
            description: '',
        })

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r2 = await Api.get(`assignment/${id}`)
            t.remove()

            if (r2.success) {
                const a = r2.data as IAssignment

                assignment.teacher_id = a.teacher_id
                assignment.teacher_name = a.teacher_name
                assignment.subject_id = a.subject_id
                assignment.subject_name = a.subject_name
                assignment.title = a.title
                assignment.grades = a.grades
                assignment.grade_options = a.grades.map(v => v.id)
                assignment.students = a.students
                assignment.student_options = a.students.map(v => v.id)
                assignment.completion_date = a.completion_date
                assignment.collection_date = a.collection_date
                assignment.link = a.link
                assignment.description = a.description
                assignment.questions = a.questions
                done.value = true
            }
            else {
                error(r2.message ?? 'gagal')
            }
        })

        return {
            assignment, done, toIndoDateNameTime,
            back: () => router.back()
        }
    }
})
</script>

<style scoped>

</style>