import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "d-flex align-items-end" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedTable = _resolveComponent("PaginatedTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-success mb-2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)))
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "fa fa-plus" }, null, -1),
          _createTextVNode(" Tambah")
        ])),
        _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"form-group ml-4\" style=\"width:200px;\"><label>Tipe Subject</label><select class=\"form-control\"><option value=\"\">--Semua--</option><option value=\"Karakter\">Karakter</option><option value=\"Literasi\">Literasi</option><option value=\"Numerasi\">Numerasi</option></select></div>", 1))
      ]),
      _createVNode(_component_PaginatedTable, {
        data: _ctx.pelajaran,
        class: "mt-3"
      }, {
        thead: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("th", null, "No", -1),
          _createElementVNode("th", null, "Kode Subject", -1),
          _createElementVNode("th", null, "Nama Subject", -1),
          _createElementVNode("th", null, "Tipe Subject", -1),
          _createElementVNode("th", null, "Aksi", -1)
        ])),
        tbody: _withCtx(({row, index}) => [
          _createElementVNode("td", null, _toDisplayString(index + 1), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.kode), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.nama), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.tipe), 1),
          _createElementVNode("td", null, [
            _createElementVNode("button", {
              class: "btn btn-sm btn-success mr-1",
              onClick: ($event: any) => (_ctx.detail(row.data.id))
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("i", { class: "fa fa-eye" }, null, -1)
            ]), 8, _hoisted_4),
            _createElementVNode("button", {
              class: "btn btn-sm btn-warning mr-1",
              onClick: ($event: any) => (_ctx.edit(row.data.id))
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("i", { class: "fa fa-pencil" }, null, -1)
            ]), 8, _hoisted_5),
            _createElementVNode("button", {
              class: "btn btn-sm btn-danger",
              onClick: ($event: any) => (_ctx.destroy(row.data.id))
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
            ]), 8, _hoisted_6)
          ])
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ]))
}