import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav nav-primary" }
const _hoisted_2 = {
  class: "collapse",
  id: "daily-journal"
}
const _hoisted_3 = { class: "nav nav-collapse" }
const _hoisted_4 = {
  class: "collapse",
  id: "classroom-journal"
}
const _hoisted_5 = { class: "nav nav-collapse" }
const _hoisted_6 = {
  class: "collapse",
  id: "assignment"
}
const _hoisted_7 = { class: "nav nav-collapse" }
const _hoisted_8 = {
  class: "collapse",
  id: "academic"
}
const _hoisted_9 = { class: "nav nav-collapse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _cache[23] || (_cache[23] = _createElementVNode("li", { class: "nav-section" }, [
      _createElementVNode("span", { class: "sidebar-mini-icon" }, [
        _createElementVNode("i", { class: "fa fa-ellipsis-h" })
      ]),
      _createElementVNode("h4", { class: "text-section" }, "Academic")
    ], -1)),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath === '/'}])
    }, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fas fa-home" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "Dashboard ", -1)
        ])),
        _: 1
      })
    ], 2),
    (+_ctx.role === _ctx.Role.Kepala)
      ? (_openBlock(), _createElementBlock("li", {
          key: 0,
          class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/master-data')}])
        }, [
          _createVNode(_component_router_link, { to: "/master-data" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "fas fa-school" }, null, -1),
              _createElementVNode("span", { class: "sub-item" }, "Master Data ", -1)
            ])),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item submenu", {active: _ctx.route.fullPath.startsWith('/daily-journal')}])
    }, [
      _cache[5] || (_cache[5] = _createElementVNode("a", {
        "data-toggle": "collapse",
        href: "#daily-journal"
      }, [
        _createElementVNode("i", { class: "fas fa-book" }),
        _createElementVNode("span", { class: "sub-item" }, "Daily Journal "),
        _createElementVNode("span", { class: "caret" })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/daily-journal/smp')})
          }, [
            _createVNode(_component_router_link, { to: "/daily-journal/smp" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("span", { class: "sub-item" }, "SMP ", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/daily-journal/sma')})
          }, [
            _createVNode(_component_router_link, { to: "/daily-journal/sma" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("span", { class: "sub-item" }, "SMA ", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/daily-journal/pesantren')})
          }, [
            _createVNode(_component_router_link, { to: "/daily-journal/pesantren" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("span", { class: "sub-item" }, "Pesantren ", -1)
              ])),
              _: 1
            })
          ], 2)
        ])
      ])
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item submenu", {active: _ctx.route.fullPath.startsWith('/classroom-journal')}])
    }, [
      _cache[9] || (_cache[9] = _createElementVNode("a", {
        "data-toggle": "collapse",
        href: "#classroom-journal"
      }, [
        _createElementVNode("i", { class: "fas fa-bookmark" }),
        _createElementVNode("span", { class: "sub-item" }, "Classroom Journal "),
        _createElementVNode("span", { class: "caret" })
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/classroom-journal/smp')})
          }, [
            _createVNode(_component_router_link, { to: "/classroom-journal/smp" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("span", { class: "sub-item" }, "SMP ", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/classroom-journal/sma')})
          }, [
            _createVNode(_component_router_link, { to: "/classroom-journal/sma" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("span", { class: "sub-item" }, "SMA ", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/classroom-journal/pesantren')})
          }, [
            _createVNode(_component_router_link, { to: "/classroom-journal/pesantren" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("span", { class: "sub-item" }, "Pesantren ", -1)
              ])),
              _: 1
            })
          ], 2)
        ])
      ])
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item submenu", {active: _ctx.route.fullPath.startsWith('/assignment')}])
    }, [
      _cache[14] || (_cache[14] = _createElementVNode("a", {
        "data-toggle": "collapse",
        href: "#assignment"
      }, [
        _createElementVNode("i", { class: "fas fa-user-graduate" }),
        _createElementVNode("span", { class: "sub-item" }, "Assignment / Assessment "),
        _createElementVNode("span", { class: "caret" })
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/assignment') && !_ctx.route.fullPath.includes('scoring') && !_ctx.route.fullPath.includes('history') && !_ctx.route.fullPath.includes('report') && !_ctx.route.fullPath.includes('evaluation')})
          }, [
            _createVNode(_component_router_link, { to: "/assignment" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("span", { class: "sub-item" }, "Assignment / Assessment ", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/assignment/scoring')})
          }, [
            _createVNode(_component_router_link, { to: "/assignment/scoring" }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("span", { class: "sub-item" }, "Scoring ", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/assignment/history')})
          }, [
            _createVNode(_component_router_link, { to: "/assignment/history" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createElementVNode("span", { class: "sub-item" }, "History ", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/assignment/report')})
          }, [
            _createVNode(_component_router_link, { to: "/assignment/report" }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createElementVNode("span", { class: "sub-item" }, "Report", -1)
              ])),
              _: 1
            })
          ], 2)
        ])
      ])
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item submenu", {active: _ctx.route.fullPath.startsWith('/academic')}])
    }, [
      _cache[19] || (_cache[19] = _createElementVNode("a", {
        "data-toggle": "collapse",
        href: "#academic"
      }, [
        _createElementVNode("i", { class: "fas fa-graduation-cap" }),
        _createElementVNode("span", { class: "sub-item" }, "Academic "),
        _createElementVNode("span", { class: "caret" })
      ], -1)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("ul", _hoisted_9, [
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/academic/syllabus')})
          }, [
            _createVNode(_component_router_link, { to: "/academic/syllabus" }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createElementVNode("span", { class: "sub-item" }, "Syllabus / Lesson Plan", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/academic/calendar')})
          }, [
            _createVNode(_component_router_link, { to: "/academic/calendar" }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createElementVNode("span", { class: "sub-item" }, "Academic Calender", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/academic/daily-schedule')})
          }, [
            _createVNode(_component_router_link, { to: "/academic/daily-schedule" }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createElementVNode("span", { class: "sub-item" }, "Daily Schedule", -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass({active: _ctx.route.fullPath.startsWith('/academic/progress')})
          }, [
            _createVNode(_component_router_link, { to: "/academic/progress" }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createElementVNode("span", { class: "sub-item" }, "Student Academic Progress / Achievements", -1)
              ])),
              _: 1
            })
          ], 2)
        ])
      ])
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/announcement')}])
    }, [
      _createVNode(_component_router_link, { to: "/announcement" }, {
        default: _withCtx(() => _cache[20] || (_cache[20] = [
          _createElementVNode("i", { class: "fas fa-bullhorn" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "Announcement ", -1)
        ])),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/information')}])
    }, [
      _createVNode(_component_router_link, { to: "/information" }, {
        default: _withCtx(() => _cache[21] || (_cache[21] = [
          _createElementVNode("i", { class: "fas fa-circle-info" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "Information ", -1)
        ])),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/report')}])
    }, [
      _createVNode(_component_router_link, { to: "/report" }, {
        default: _withCtx(() => _cache[22] || (_cache[22] = [
          _createElementVNode("i", { class: "far fa-chart-bar" }, null, -1),
          _createElementVNode("span", { class: "sub-item" }, "Report", -1)
        ])),
        _: 1
      })
    ], 2)
  ]))
}