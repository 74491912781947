import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "dropdown-menu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown-divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_ctx.show)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "account-menu",
        key: `ao${_ctx.key}`,
        style: _normalizeStyle({top: _ctx.top + 'px', left: _ctx.left +'px'}),
        tabindex: "0"
      }, [
        _createElementVNode("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(Object.entries(_ctx.statuses)), ([i, [k, v]]) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              _createElementVNode("li", {
                onClick: ($event: any) => (_ctx.handleChoose(k))
              }, [
                _createElementVNode("span", _hoisted_3, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["circle mr-1", `circle-${k}`])
                  }, _toDisplayString(k.toUpperCase()), 3),
                  _createTextVNode(" " + _toDisplayString(v), 1)
                ])
              ], 8, _hoisted_2),
              (i < Object.keys(_ctx.statuses).length - 1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ])
      ], 4)), [
        [_directive_click_outside, _ctx.handleFocusOut]
      ])
    : _createCommentVNode("", true)
}