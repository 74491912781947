import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '../../assets/img/e_s.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card assessment-item" }
const _hoisted_6 = {
  key: 0,
  class: "card-body"
}
const _hoisted_7 = {
  key: 1,
  class: "card-body"
}
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "avatar-sm" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "d-flex flex-column ml-2" }
const _hoisted_12 = { class: "fw-bold" }
const _hoisted_13 = {
  style: {"font-size":"12px"},
  class: "text-muted"
}
const _hoisted_14 = {
  class: "d-flex align-items-center text-success mt-2",
  style: {"flex":"1"}
}
const _hoisted_15 = { class: "ml-2" }
const _hoisted_16 = {
  class: "fw-bold mt-2",
  style: {"font-size":"18px"}
}
const _hoisted_17 = { class: "text-muted mt-2" }
const _hoisted_18 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_19 = ["href"]
const _hoisted_20 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_21 = { style: {"font-size":"12px","font-weight":"bold"} }
const _hoisted_22 = ["href"]
const _hoisted_23 = { class: "d-flex align-items-center" }
const _hoisted_24 = { style: {"width":"35px","height":"35px"} }
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "d-flex flex-column ml-2" }
const _hoisted_27 = { class: "fw-bold" }
const _hoisted_28 = { class: "form-group px-0" }
const _hoisted_29 = { class: "form-group" }
const _hoisted_30 = { class: "form-group" }
const _hoisted_31 = { class: "form-control" }
const _hoisted_32 = {
  class: "form-control mb-2 d-flex align-items-center",
  style: {"padding":"0.6rem"}
}
const _hoisted_33 = { style: {"flex":"1","min-width":"0"} }
const _hoisted_34 = ["href"]
const _hoisted_35 = { key: 1 }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "d-flex flex-row-reverse" }
const _hoisted_38 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_ModalConfirmation = _resolveComponent("ModalConfirmation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (!_ctx.assessment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[6] || (_cache[6] = [
                    _createElementVNode("div", {
                      class: "d-flex justify-content-center align-items-center flex-column",
                      style: {"height":"calc(100vh - 80px)"}
                    }, [
                      _createElementVNode("img", {
                        src: _imports_0,
                        width: "200"
                      }),
                      _createElementVNode("span", { style: {"font-style":"italic"} }, "Mendapatkan data tugas")
                    ], -1)
                  ])))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("img", {
                          class: "avatar-img rounded-circle",
                          style: {"width":"35px","height":"35px"},
                          src: _ctx.getPhoto(_ctx.assessment.assignment.teacher_photo)
                        }, null, 8, _hoisted_10)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.assessment.assignment.teacher_name ?? '-'), 1),
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.assessment.assignment.created_at)), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fa fa-book" }, null, -1)),
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.assessment.assignment.subject_name), 1)
                    ]),
                    _cache[16] || (_cache[16] = _createElementVNode("div", {
                      style: {"height":"1px","background":"#eee"},
                      class: "my-2"
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.assessment.assignment.title), 1),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.assessment.assignment.description), 1),
                    (_ctx.assessment.assignment.link)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _cache[8] || (_cache[8] = _createElementVNode("span", { style: {"font-size":"12px","font-weight":"bold"} }, "Link:", -1)),
                          _createElementVNode("a", {
                            href: _ctx.assessment.assignment.link,
                            target: "_blank",
                            class: "d-block"
                          }, _toDisplayString(_ctx.assessment.assignment.link), 9, _hoisted_19)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.assessment.assignment.questions.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("span", _hoisted_21, "Unduh Soal (" + _toDisplayString(_ctx.assessment.assignment.questions.length) + " file):", 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessment.assignment.questions, (q) => {
                            return (_openBlock(), _createElementBlock("a", {
                              href: q.url,
                              target: "_blank",
                              class: "d-block"
                            }, _toDisplayString(q.file_name), 9, _hoisted_22))
                          }), 256))
                        ]))
                      : _createCommentVNode("", true),
                    _cache[17] || (_cache[17] = _createElementVNode("div", {
                      style: {"height":"1px","background":"#eee"},
                      class: "my-2"
                    }, null, -1)),
                    _cache[18] || (_cache[18] = _createElementVNode("div", {
                      style: {"font-size":"12px"},
                      class: "fw-bold"
                    }, "Waktu Pengerjaan:", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.assessment.assignment.completion_date)) + " - " + _toDisplayString(_ctx.toIndoDateNameTime(_ctx.assessment.assignment.collection_date)), 1),
                    _cache[19] || (_cache[19] = _createElementVNode("div", {
                      style: {"height":"1px","background":"#eee"},
                      class: "mt-2 mb-4"
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("img", {
                          class: "avatar-img rounded-circle",
                          style: {"width":"35px","height":"35px"},
                          src: _ctx.getPhoto(_ctx.user)
                        }, null, 8, _hoisted_25)
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.user.name ?? '-'), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_ckeditor, {
                        editor: _ctx.editor,
                        modelValue: _ctx.assessment.answer,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assessment.answer) = $event)),
                        config: _ctx.editorConfig
                      }, null, 8, ["editor", "modelValue", "config"])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _cache[9] || (_cache[9] = _createElementVNode("label", null, "Link", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assessment.link) = $event)),
                        class: "form-control"
                      }, null, 512), [
                        [_vModelText, _ctx.assessment.link]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _cache[12] || (_cache[12] = _createElementVNode("label", null, "Lampiran", -1)),
                      _createElementVNode("div", _hoisted_31, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessment.attachments.filter(v => v.deleted !== true), (v) => {
                          return (_openBlock(), _createElementBlock("div", _hoisted_32, [
                            _createElementVNode("span", _hoisted_33, [
                              (v.url)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: v.url ?? '',
                                    target: "_blank"
                                  }, _toDisplayString(v.file_name), 9, _hoisted_34))
                                : (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(v.file?.name), 1))
                            ]),
                            _createElementVNode("button", {
                              class: "btn btn-sm btn-danger ml-1",
                              onClick: () => v.deleted = true
                            }, _cache[10] || (_cache[10] = [
                              _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                            ]), 8, _hoisted_36)
                          ]))
                        }), 256)),
                        _createElementVNode("input", {
                          type: "file",
                          ref: "question_file",
                          id: "question_file",
                          accept: "image/*, .mp3, video/mp4, video/x-m4v, video/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .zip, .rar, .7zip",
                          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.question_change && _ctx.question_change(...args))),
                          style: {"display":"none"}
                        }, null, 544),
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("button", {
                            type: "button",
                            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.add_question && _ctx.add_question(...args))),
                            class: "btn btn-sm btn-success"
                          }, _cache[11] || (_cache[11] = [
                            _createElementVNode("i", { class: "fa fa-plus" }, null, -1),
                            _createTextVNode(" Tambah File ")
                          ]))
                        ])
                      ]),
                      _cache[13] || (_cache[13] = _createElementVNode("p", { class: "mt-1 mb-0" }, "Tipe file yang diizinkan adalah .mp3, .mp4, .pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .xlsx, .xls, .rar, .zip, .ppt dengan maksimal 5 MB.", -1))
                    ]),
                    _cache[20] || (_cache[20] = _createElementVNode("div", {
                      style: {"height":"1px","background":"#eee"},
                      class: "mt-2 mb-4"
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_38, [
                      _cache[14] || (_cache[14] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
                      _createElementVNode("button", {
                        class: "btn btn-success",
                        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
                      }, "Submit"),
                      _cache[15] || (_cache[15] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1))
                    ])
                  ]))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_ModalConfirmation, {
      show: _ctx.showConfirm,
      onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showConfirm = false)),
      onOk: _ctx.onOk
    }, {
      body: _withCtx(() => _cache[21] || (_cache[21] = [
        _createTextVNode(" Pastikan semua jawaban kamu sudah sesuai. Lanjut submit? ")
      ])),
      _: 1
    }, 8, ["show", "onOk"])
  ], 64))
}