import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = {
  key: 0,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "modal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(["modal-container", {sm: _ctx.size === 'sm', lg: _ctx.size === 'lg'}])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "header", {}, () => [
                    _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.title), 1)
                  ], true)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["modal-body", {'p-0': _ctx.removeBodyPadding}])
                }, [
                  _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                ], 2),
                (!_ctx.noFooter)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _renderSlot(_ctx.$slots, "footer", {}, () => [
                        _createElementVNode("button", {
                          class: "btn btn-success btn-sm",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                        }, " OK ")
                      ], true)
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ])
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}