<template>
    <div class="d-flex">
        <div v-for="(v, k) in tabs"
             class="d-flex flex-column py-2 px-4 tab"
             :class="{active: k === index}"
             @click="changeIndex(k)"
        >
            <h5><b>{{ v }}</b></h5>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

export default defineComponent({
    name: "Nav",
    props: {
        tabs: Array as PropType<String[]>,
        index: Number
    },
    emits: ['changeIndex'],
    setup(props, {emit}) {
        const changeIndex = (i: number) => emit('changeIndex', i)

        return { changeIndex }
    }
})
</script>

<style scoped>
.tab {
    cursor: pointer;
}
.tab.active {
    color: #2BB930;
    border-bottom: 4px solid #2BB930;
}
</style>
