import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card mb-0" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-head-row" }
const _hoisted_8 = { class: "card-tools" }
const _hoisted_9 = {
  class: "card-body p-0",
  style: {"height":"calc(100vh - 257px)"}
}
const _hoisted_10 = { style: {"width":"100%","height":"100%"} }
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "avatar-sm mr-2" }
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InboxDesktop = _resolveComponent("InboxDesktop")!
  const _component_InboxMobile = _resolveComponent("InboxMobile")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-title" }, "Inbox", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    (_ctx.user.role_id === 54)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openNewChat && _ctx.openNewChat(...args))),
                          class: "b-time btn btn-success btn-round mr-2"
                        }, " Start New Chat "))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  (!_ctx.isMobile())
                    ? (_openBlock(), _createBlock(_component_InboxDesktop, {
                        key: 0,
                        "chosen-person": _ctx.chosenPerson,
                        load: _ctx.load,
                        onClearChosenPerson: _ctx.clearChosenPerson
                      }, null, 8, ["chosen-person", "load", "onClearChosenPerson"]))
                    : (_openBlock(), _createBlock(_component_InboxMobile, {
                        key: 1,
                        "chosen-person": _ctx.chosenPerson,
                        load: _ctx.load,
                        "load-person": _ctx.loadPerson,
                        onClearChosenPerson: _ctx.clearChosenPerson
                      }, null, 8, ["chosen-person", "load", "load-person", "onClearChosenPerson"]))
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      title: "Search Teacher",
      "remove-body-padding": true,
      "no-footer": true,
      show: _ctx.showModalStart,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModalStart = false))
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refTeacher, (v) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "d-flex align-items-center py-2 px-3 t-item",
              onClick: ($event: any) => (_ctx.choose(v))
            }, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("img", {
                  src: _ctx.getPhoto(v),
                  alt: "",
                  class: "avatar-img rounded-circle"
                }, null, 8, _hoisted_14)
              ]),
              _createTextVNode(" " + _toDisplayString(v.name), 1)
            ], 8, _hoisted_12))
          }), 256))
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}