<template>
    <div class="row no-gutters" style="height: 100%">
        <div class="col-sm-9 col-md-8 right" style="height: 100%">
            <div class="right-header" v-if="chosenPerson">
                <div class="d-flex align-items-center py-2 px-3">
                    <i class="fa fa-arrow-left mr-3 pointer" style="font-size: 18px" @click="back"></i>
                    <div class="avatar-sm mr-2">
                        <img :src="getPhoto(chosenPerson)" alt="" class="avatar-img rounded-circle">
                    </div>
                    <b>{{ chosenPerson.name }}</b>
                </div>
            </div>
            <div class="right-header" v-else-if="activeChatGroup">
                <div class="d-flex align-items-center py-2 px-3">
                    <i class="fa fa-arrow-left mr-3 pointer" style="font-size: 18px" @click="back"></i>
                    <div class="avatar-sm mr-2">
                        <img :src="getPhoto(getOpponent(activeChatGroup))" alt="" class="avatar-img rounded-circle">
                    </div>
                    <b>{{ getOpponent(activeChatGroup).name }}</b>
                </div>
            </div>
            <div class="chat-container" id="chat-container">
                <ChatItem v-for="(v, k) in chats" :chat="v" :class="{'mt-1': k === 0}"/>
            </div>
            <div v-if="activeChatGroup || chosenPerson" class="chat-maker p-3">
                <input type="text" class="form-control mr-3" v-model="chat" style="flex: 1" @keyup.enter="send" placeholder="Send message...">
                <i class="fa fa-paper-plane send mr-2" @click="send"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import {getOpponent, IChat, IChatGroup} from "@/utils/interface/Chat";
import {User} from "@/utils/Models";
import {getPhoto, toSqlDateTime} from "@/utils/Helpers";
import Api from "@/utils/Api";
import ChatItem from "@/components/inbox/ChatItem.vue";
import {useRouter} from "vue-router";
import Session from "@/utils/Session";

export default defineComponent({
    name: "InboxMobileChat",
    props: {
        chatGroups: Object as PropType<IChatGroup[]>,
        activeChatGroup: Object as PropType<IChatGroup>,
        chosenPerson: Object as PropType<User | null>,
        loadPerson: Number,
    },
    components: {
        ChatItem
    },
    emits: ['back', 'chatGroupsChange'],
    setup(props, {emit}) {
        const user = Session.getCurrentUser()!
        const chat = ref('')
        const chats = ref<IChat[]>([])

        const loadChatPerson = async (v: any|User|null) => {
            chats.value = []
            if (v && v.id) {
                let user_id_1: number, user_id_2: number
                let u1 = user.id, u2 = v.id
                if (u1 < u2) {
                    user_id_1 = u1
                    user_id_2 = u2
                }
                else {
                    user_id_1 = u2
                    user_id_2 = u1
                }

                const r = await Api.get(`chat/chats/${user_id_1}/${user_id_2}`)
                if (r.success) {
                    chats.value = r.data as IChat[]
                    setTimeout(() => scroll(), 100)
                }
                else {

                }
            }
        }

        loadChatPerson(props.chosenPerson)
        watch(() => props.chosenPerson, (v: any, w: any) => {
            loadChatPerson(v)
        })
        watch(() => props.loadPerson, (v: any, w: any) => {
            loadChatPerson(props.chosenPerson)
        })

        const getChat = async () => {
            if (!props.activeChatGroup) {
                return
            }

            chats.value = []
            const r = await Api.get(`chat/chats/${props.activeChatGroup.id}`)
            if (r.success) {
                chats.value = r.data as IChat[]
                setTimeout(() => scroll(), 100)
            }
            else {

            }
        }

        const send = async () => {
            if (!chat.value) {
                return
            }

            let user_id_1: number, user_id_2: number, to_id: number
            if (props.chosenPerson) {
                if (props.chosenPerson!.id < user.id) {
                    user_id_1 = props.chosenPerson!.id
                    user_id_2 = user.id
                }
                else {
                    user_id_1 = user.id
                    user_id_2 = props.chosenPerson!.id
                }
                to_id = props.chosenPerson!.id
            }
            else {
                const u = getOpponent(props.activeChatGroup!)
                if (u.id < user.id) {
                    user_id_1 = u.id
                    user_id_2 = user.id
                }
                else {
                    user_id_1 = user.id
                    user_id_2 = u.id
                }
                to_id = u.id
            }
            const c = chat.value
            chat.value = ''

            const ts = new Date().getTime()
            chats.value.push({
                from_id: user.id,
                to_id: to_id,
                chat: c,
                sent: toSqlDateTime(new Date()),
                ts: ts,
                done: false
            })
            setTimeout(() => scroll(), 100)

            const r = await Api.post('chat/send', {
                user_id_1,
                user_id_2,
                chat: c,
                sent: toSqlDateTime(new Date()),
                from_id: user.id,
                to_id,
                ts,
            })

            if (r.success) {
                let newChatGroups = (r.data.chat_group as IChatGroup[]).map(v => ({...v, active: v.id === props.activeChatGroup?.id}))
                emit('chatGroupsChange', newChatGroups)
                chats.value = chats.value.map(v => {
                    return ({...v, done: !v.done ? v.ts === ts : v.done })
                })
            }
            else {

            }
        }

        const scroll = () => {
            let container = document.querySelector("#chat-container");
            if (container) {
                container.scrollTop = container.scrollHeight
            }
        }

        onMounted(() => getChat())
        watch(() => props.activeChatGroup, () => {
            getChat()
        })

        // window.onpopstate = function(event) {
        //     console.log('bac')
        // }

        const router = useRouter()
        router.beforeEach((to, from, next) => {
            if (window.event?.type == 'popstate') {
                emit('back')
            }
            else {
                next()
            }
        });

        const back = () => emit('back')

        return {
            getPhoto, getOpponent, chat, chats, send, user, back
        }
    }
})
</script>

<style scoped>
.right {
    background: #F9FBFD;
    height: 100%;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
}
.right-header {
    background: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.chat-container {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}
.chat-maker {
    background: white;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    border-bottom-right-radius: 20px;
}
.send {
    cursor: pointer;
    font-size: 24px;
    color: #31CE36;
}
</style>