<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="fw-bold">Edit Guru</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="profile-picture mb-1">
                                        <div class="avatar avatar-xl">
                                            <img :src="photo_url ?? getPhoto(guru)" alt="..." class="avatar-img rounded-circle" id="photo-display">
                                        </div>
                                        <input type="file" ref="photo_file" id="photo_file" accept="image/*" @change="image_change" style="display: none">
                                        <button type="button" @click="choose_photo" class="btn btn-round btn-sm btn-outline-success ml-2">Pilih Photo</button>
                                    </div>
                                    <div class="form-group">
                                        <label>Nama Guru <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="name" placeholder="Masukkan Nama Guru" required>
                                        <span v-if="nameErr" class="text-danger">{{ nameErr }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Username <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="username" placeholder="Masukkan Username" required autocomplete="current-password">
                                        <span v-if="usernameErr" class="text-danger">{{ usernameErr }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Password <b class="text-danger">* <i>Isi password hanya jika ingin dirubah</i></b> </label>
                                        <input type="password" class="form-control" v-model="password" placeholder="Masukkan Password" required autocomplete="current-password">
                                        <span v-if="passwordErr" class="text-danger">{{ passwordErr }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" class="form-control" v-model="email" placeholder="Masukkan Email">
                                    </div>
                                    <div class="form-group">
                                        <label>NIP <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="nip" placeholder="Masukkan NIP" required>
                                        <span v-if="nipErr" class="text-danger">{{ nipErr }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>NIK <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="nik" placeholder="Masukkan NIK" required>
                                        <span v-if="nikErr" class="text-danger">{{ nikErr }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Tempat Lahir <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="birth_place" placeholder="Masukkan Tempat Lahir" required>
                                        <span v-if="birth_placeErr" class="text-danger">{{ birth_placeErr }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Tanggal Lahir <b class="text-danger">*</b></label>
                                        <input type="date" class="form-control" v-model="birth_date" placeholder="Masukkan Tanggal Lahir" required>
                                        <span v-if="birth_dateErr" class="text-danger">{{ birth_dateErr }}</span>
                                    </div>
                                    <div class="form-check">
                                        <label>Jenis Kelamin</label><br>
                                        <label class="form-radio-label">
                                            <input class="form-radio-input" type="radio" value="L" v-model="gender">
                                            <span class="form-radio-sign">Laki-Laki</span>
                                        </label>
                                        <label class="form-radio-label ml-3">
                                            <input class="form-radio-input" type="radio" value="P" v-model="gender">
                                            <span class="form-radio-sign">Perempuan</span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label>Alamat</label>
                                        <textarea class="form-control" v-model="address" rows="4" placeholder="Masukkan Alamat Lengkap"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Nomor Telepon <b class="text-danger">*</b></label>
                                        <input type="text" class="form-control" v-model="phone" placeholder="Masukkan Nomor Telepon" required>
                                        <span v-if="phoneErr" class="text-danger">{{ phoneErr }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Subject <b class="text-danger">*</b></label>
                                        <Multiselect
                                            v-model="selected_pelajaran"
                                            :options="pelajaran"
                                            mode="tags"
                                            :searchable="true"
                                            :create-option="true"
                                        />
                                        <span v-if="selected_pelajaranErr" class="text-danger">{{ selected_pelajaranErr }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row justify-content-end">
                            <button class="btn btn-danger mr-2" @click="cancel">Batal</button>
                            <button class="btn btn-success" @click="save">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Api from "@/utils/Api";
import {Pelajaran, User} from "@/utils/Models";
import Multiselect from '@vueform/multiselect'
import {file2Base64, getPhoto} from "@/utils/Helpers";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "UpdateGuru",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id

        const pelajaran = ref<{}[]>([])
        const selected_pelajaran = ref<number[]>([])
        const selected_pelajaranErr = ref('')
        const photo_file = ref(null)
        const photo_url = ref<string|null>(null)
        const photo_b64 = ref<string|null>(null)
        const guru = ref<User|null>(null)

        const name = ref('')
        const username = ref('')
        const email = ref('')
        const password = ref('')
        const nip = ref('')
        const birth_place = ref('')
        const birth_date = ref('')
        const gender = ref('L')
        const nik = ref('')
        const address = ref('')
        const phone = ref('')
        const nameErr = ref('')
        const usernameErr = ref('')
        const passwordErr = ref('')
        const nipErr = ref('')
        const birth_placeErr = ref('')
        const birth_dateErr = ref('')
        const genderErr = ref('')
        const nikErr = ref('')
        const addressErr = ref('')
        const phoneErr = ref('')

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`master/guru/${id}`)
            t.remove()

            if (r.success) {
                const u = r.data as User
                guru.value = u

                name.value = guru.value.name
                username.value = guru.value.username
                email.value = guru.value.email
                nip.value = guru.value.nip ?? ''
                birth_place.value = guru.value.birth_place ?? ''
                birth_date.value = guru.value.birth_date ?? ''
                gender.value = guru.value.gender ?? ''
                nik.value = guru.value.nik ?? ''
                address.value = guru.value.address ?? ''
                phone.value = guru.value.phone ?? ''
                selected_pelajaran.value = u.pelajaran?.map(v => v.id) ?? []
            }
            else {
                error('Gagal mendapatkan data')
            }

            const res = await Api.get('master/pelajaran')
            if (res.success) {
                pelajaran.value = (res.data as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
            }
            else {
                error(res.message ?? 'Gagal medapatkan data')
            }
        })

        const cancel = () => router.back()
        const save = async () => {
            nameErr.value = ''
            usernameErr.value = ''
            nipErr.value = ''
            birth_placeErr.value = ''
            birth_dateErr.value = ''
            genderErr.value = ''
            nikErr.value = ''
            addressErr.value = ''
            phoneErr.value = ''
            selected_pelajaranErr.value = ''
            if (!name.value) {
                nameErr.value = '* Masukkan nama guru'
                return
            }
            if (!username.value) {
                usernameErr.value = '* Masukkan username'
                return
            }
            if (!nip.value) {
                nipErr.value = '* Masukkan NIP'
                return
            }
            if (!birth_place.value) {
                birth_placeErr.value = '* Masukkan tempat lahir'
                return
            }
            if (!birth_date.value) {
                birth_dateErr.value = '* Masukkan tanggal lahir'
                return
            }
            if (!gender.value) {
                genderErr.value = '* Masukkan jenis kelamin'
                return
            }
            if (!nik.value) {
                nikErr.value = '* Masukkan NIK'
                return
            }
            if (!phone.value) {
                phoneErr.value = '* Masukkan no telepon'
                return
            }

            const t = loading('Menyimpan data')
            const r = await Api.put(`master/guru/update/${id}`, {
                name: name.value,
                username: username.value,
                email: email.value,
                password: password.value,
                nip: nip.value,
                birth_place: birth_place.value,
                birth_date: birth_date.value,
                gender: gender.value,
                nik: nik.value,
                address: address.value,
                phone: phone.value,
                pelajaran: selected_pelajaran.value,
                photo_file: photo_b64.value
            })
            t.remove()

            if (r.success) {
                router.back()
                success('Berhasil menyimpan data')
            }
            else {
                error(r.message ?? 'Gagal')
            }
        }
        const choose_photo = () => {
            let fileUpload = document.getElementById('photo_file')
            if (fileUpload != null) {
                fileUpload.click()
            }
        }
        const image_change = async (event: any) => {
            const file = event.target.files[0]
            if (file.size > 1000000) {
                error('Maksimal ukuran gambar 1 MB')
                return
            }

            photo_url.value = URL.createObjectURL(file)
            photo_b64.value = await file2Base64(file)
        }

        return { getPhoto, guru, cancel, save, choose_photo, image_change, photo_file, photo_url, pelajaran, selected_pelajaran, selected_pelajaranErr, name, username, email, password, nip, birth_place, birth_date, gender, nik, address, phone, nameErr, usernameErr, passwordErr, nipErr, birth_placeErr, birth_dateErr, genderErr, nikErr, addressErr, phoneErr, }
    }
})
</script>

<style scoped>
.multiselect-option span {
    font-size: 10px;
}
</style>
