import {School, User} from "@/utils/Models";
import {crypt, decrypt} from "@/utils/Crypt";

export default class Session {

    static isLogin(): boolean {
        const u = localStorage.getItem('user')
        return !!u
    }

    static getToken(): string {
        return localStorage.getItem('token') ?? ''
    }

    static setPassword(rawPassword: string) {
        localStorage.setItem('none', crypt('wkwk', rawPassword))
    }

    static getPassword(): string | undefined {
        const pwd = localStorage.getItem('none')
        if (!pwd || pwd === 'undefined') {
            return undefined
        }

        return decrypt('wkwk', pwd)
    }

    static setSchool(school: School) {
        localStorage.setItem('school', JSON.stringify(school))
    }

    static getSchool() {
        const json = localStorage.getItem('school') as string
        return JSON.parse(json) as School
    }

    static setCurrentUser(u: User) {
        localStorage.setItem('user', JSON.stringify(u))
    }

    static getCurrentUser(): User | undefined {
        const u = localStorage.getItem('user')
        if (!u || u === 'undefined') {
            return undefined
        }

        return JSON.parse(u) as User
    }

    static setIsAndroid(v: boolean) {
        localStorage.setItem('a', v ? '1' : '')
    }

    static getIsAndroid() {
        return !!localStorage.getItem('a')
    }

    static login({password, user, school, token}: {password: string, user: string, school?: string, token: string}) {
        localStorage.setItem('none', password)
        localStorage.setItem('user', user)
        localStorage.setItem('school', school ?? '{}')
        localStorage.setItem('token', `Bearer ${token}`)
    }

    static logout() {
        localStorage.setItem('user', '')
        localStorage.setItem('school', '')
        localStorage.setItem('none', '')
        localStorage.setItem('token', '')
        localStorage.setItem('a', '')
    }
}
