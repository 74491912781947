import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card timeline-item" }
const _hoisted_2 = { class: "card-body pb-0" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "avatar-sm" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex flex-column ml-2" }
const _hoisted_7 = { class: "fw-bold" }
const _hoisted_8 = {
  class: "text-muted",
  style: {"font-size":"12px"}
}
const _hoisted_9 = { class: "card-header text-success d-flex flex-column" }
const _hoisted_10 = {
  class: "d-flex align-items-center",
  style: {"flex":"1"}
}
const _hoisted_11 = { class: "ml-2" }
const _hoisted_12 = {
  class: "d-flex align-items-center",
  style: {"flex":"1","margin-top":"4px"}
}
const _hoisted_13 = { class: "card-header" }
const _hoisted_14 = { style: {"white-space":"pre-wrap"} }
const _hoisted_15 = {
  key: 0,
  class: "card-header"
}
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "card-header d-none" }
const _hoisted_18 = { class: "mb-3" }
const _hoisted_19 = { class: "d-flex align-items-center" }
const _hoisted_20 = { class: "avatar-sm" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "d-flex flex-column ml-2" }
const _hoisted_23 = {
  class: "fw-bold",
  style: {"font-size":"12px"}
}
const _hoisted_24 = {
  style: {"font-size":"11px"},
  class: "text-muted"
}
const _hoisted_25 = { style: {"margin-left":"48px"} }
const _hoisted_26 = { class: "d-flex align-items-center" }
const _hoisted_27 = {
  class: "form-group px-0 mr-3",
  style: {"flex":"1"}
}
const _hoisted_28 = ["readonly"]
const _hoisted_29 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "avatar-img rounded-circle",
            src: _ctx.getPhoto(_ctx.progress.teacher)
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.progress.teacher?.name ?? '-'), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.progress.created_at)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa fa-book" }, null, -1)),
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.progress.subject_name) + " - Semester " + _toDisplayString(_ctx.progress.semester_name), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-user-graduate" }, null, -1)),
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "ml-2" }, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.progress.student_name), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.progress.note), 1)
    ]),
    (_ctx.progress.attachments.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _cache[7] || (_cache[7] = _createElementVNode("b", null, "Attachments:", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progress.attachments, (a) => {
            return (_openBlock(), _createElementBlock("a", {
              href: a.url,
              target: "_blank",
              class: "d-block"
            }, _toDisplayString(a.file_name), 9, _hoisted_16))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_17, [
      (_ctx.expanded)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.progress.comments, (c) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("img", {
                    class: "avatar-img rounded-circle",
                    style: {"width":"35px","height":"35px"},
                    src: _ctx.getPhoto(c.creator_photo)
                  }, null, 8, _hoisted_21)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("span", _hoisted_23, _toDisplayString(c.creator_name ?? '-'), 1),
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.toIndoDateNameTime(c.created_at)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_25, _toDisplayString(c.comment), 1)
            ]))
          }), 256))
        : _createCommentVNode("", true),
      (!_ctx.expanded)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "text-success pointer underline",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpanded && _ctx.toggleExpanded(...args)))
          }, _toDisplayString(_ctx.progress.comments?.length ?? 0) + " Komentar ", 1))
        : (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: "text-success pointer underline",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleExpanded && _ctx.toggleExpanded(...args)))
          }, " Sembunyikan ")),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newComment.comment) = $event)),
            placeholder: "Tambahkan komentar...",
            class: "form-control",
            readonly: _ctx.loading
          }, null, 8, _hoisted_28), [
            [_vModelText, _ctx.newComment.comment]
          ])
        ]),
        _createElementVNode("button", {
          class: "btn btn-success btn-sm btn-rounded",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args))),
          disabled: _ctx.loading
        }, [
          _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fas fa-paper-plane" }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.loading ? 'Sending...' : 'Send'), 1)
        ], 8, _hoisted_29)
      ])
    ])
  ]))
}