export interface IAgenda {
    id?: number,
    startDate?: Date,
    start_date?: string,
    endDate?: Date,
    end_date?: string,
    title: string,
    customData?: {
        title?: string,
        color?: string,
        created_by?: number,
        creator_name?: string,
    },
}

export interface AgendaResponse {
    id: number,
    start_date: string,
    end_date: string,
    title: string,
    color: string,
    created_by: number,
    creator_name: string,
}

export const agendaResponseToI = (r: AgendaResponse) => {
    return {
        id: r.id,
        startDate: new Date(r.start_date),
        start_date: r.start_date,
        endDate: new Date(r.end_date),
        end_date: r.end_date,
        title: r.title,
        customData: {
            title: r.title,
            color: r.color,
            created_by: r.created_by,
            creator_name: r.creator_name,
        },
    } as IAgenda
}