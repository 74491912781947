import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "table table-bordered" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DailyScheduleViewTime = _resolveComponent("DailyScheduleViewTime")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tbody", null, [
      _createElementVNode("tr", null, [
        _cache[0] || (_cache[0] = _createElementVNode("td", {
          rowspan: "2",
          class: "fw-bold"
        }, "Day", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("td", {
          rowspan: "2",
          class: "fw-bold"
        }, "Time", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.compRefGrade).length, (g) => {
          return (_openBlock(), _createElementBlock("td", {
            colspan: _ctx.compRefGrade[g].length,
            class: "fw-bold"
          }, _toDisplayString(_ctx.compRefGrade[g][0].ref_class.name), 9, _hoisted_2))
        }), 256))
      ]),
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refGrade, (g) => {
          return (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(g.nama_kelas), 1))
        }), 256))
      ]),
      _createVNode(_component_DailyScheduleViewTime, {
        times: _ctx.mondayTimes,
        day: `Monday`,
        "ref-grade": _ctx.refGrade
      }, null, 8, ["times", "ref-grade"]),
      _createVNode(_component_DailyScheduleViewTime, {
        times: _ctx.tuesdayTimes,
        day: `Tuesday`,
        "ref-grade": _ctx.refGrade
      }, null, 8, ["times", "ref-grade"]),
      _createVNode(_component_DailyScheduleViewTime, {
        times: _ctx.wednesdayTimes,
        day: `Wednesday`,
        "ref-grade": _ctx.refGrade
      }, null, 8, ["times", "ref-grade"]),
      _createVNode(_component_DailyScheduleViewTime, {
        times: _ctx.thursdayTimes,
        day: `Thursday`,
        "ref-grade": _ctx.refGrade
      }, null, 8, ["times", "ref-grade"]),
      _createVNode(_component_DailyScheduleViewTime, {
        times: _ctx.fridayTimes,
        day: `Friday`,
        "ref-grade": _ctx.refGrade
      }, null, 8, ["times", "ref-grade"])
    ])
  ]))
}