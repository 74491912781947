import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-head-row" }
const _hoisted_8 = { class: "card-tools" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { style: {"width":"100%","max-width":"500px","max-height":"80px","overflow":"auto"} }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedTable = _resolveComponent("PaginatedTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, "Student Academic Progress / Achievements", -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
                    class: "b-time btn btn-success btn-round mr-2"
                  }, " Add ")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_PaginatedTable, {
                data: _ctx.progress,
                class: "mt-3"
              }, {
                thead: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("th", null, "No", -1),
                  _createElementVNode("th", null, "Siswa", -1),
                  _createElementVNode("th", null, "Subject", -1),
                  _createElementVNode("th", null, "Semester", -1),
                  _createElementVNode("th", null, "Catatan", -1),
                  _createElementVNode("th", null, "Attachments", -1),
                  _createElementVNode("th", null, "Aksi", -1)
                ])),
                tbody: _withCtx(({row, index}) => [
                  _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                  _createElementVNode("td", null, _toDisplayString(row.data.student_name), 1),
                  _createElementVNode("td", null, _toDisplayString(row.data.subject_name), 1),
                  _createElementVNode("td", null, _toDisplayString(row.data.semester_name), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("pre", null, _toDisplayString(row.data.note), 1)
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.data.attachments, (a) => {
                      return (_openBlock(), _createElementBlock("a", {
                        target: "_blank",
                        href: a.url,
                        class: "d-block"
                      }, _toDisplayString(a.file_name), 9, _hoisted_11))
                    }), 256))
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("button", {
                      class: "btn btn-warning btn-sm mr-1",
                      onClick: ($event: any) => (_ctx.edit(row.data.id))
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("i", { class: "fa fa-pencil" }, null, -1)
                    ]), 8, _hoisted_12),
                    _createElementVNode("button", {
                      class: "btn btn-danger btn-sm",
                      onClick: ($event: any) => (_ctx.destroy(row.data.id))
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                    ]), 8, _hoisted_13)
                  ])
                ]),
                _: 1
              }, 8, ["data"])
            ])
          ])
        ])
      ])
    ])
  ]))
}