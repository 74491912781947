import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "tab-content mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Nav, {
                index: _ctx.index,
                tabs: _ctx.tabs.map(v => v.title),
                onChangeIndex: _ctx.onIndexChange
              }, null, 8, ["index", "tabs", "onChangeIndex"]),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider" }, null, -1)),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(), _createBlock(_resolveDynamicComponent({..._ctx.tabs[_ctx.index].component})))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}