import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedTable = _resolveComponent("PaginatedTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "d-flex align-items-end" }, null, -1)),
      _createVNode(_component_PaginatedTable, {
        data: _ctx.ortu,
        class: "mt-3"
      }, {
        thead: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("th", null, "No", -1),
          _createElementVNode("th", null, "Nama", -1),
          _createElementVNode("th", null, "Username", -1),
          _createElementVNode("th", null, "No HP", -1),
          _createElementVNode("th", null, "Alamat", -1),
          _createElementVNode("th", null, "Anak", -1)
        ])),
        tbody: _withCtx(({row, index}) => [
          _createElementVNode("td", null, _toDisplayString(index + 1), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.nama), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.username), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.no_hp), 1),
          _createElementVNode("td", null, _toDisplayString(row.data.alamat), 1),
          _createElementVNode("td", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.data.anak, (a) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _cache[1] || (_cache[1] = _createTextVNode("- ")),
                _createElementVNode("b", {
                  class: "text-success",
                  onClick: ($event: any) => (_ctx.detail(a.id))
                }, _toDisplayString(a.nama), 9, _hoisted_3)
              ]))
            }), 256)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.data.anak2, (a) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _cache[2] || (_cache[2] = _createTextVNode("- ")),
                _createElementVNode("b", {
                  class: "text-success",
                  onClick: ($event: any) => (_ctx.detail(a.id))
                }, _toDisplayString(a.nama), 9, _hoisted_4)
              ]))
            }), 256))
          ])
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ]))
}