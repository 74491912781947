import Syllabus from "@/components/academic/syllabus/Syllabus.vue";
import AddSyllabus from "@/components/academic/syllabus/AddSyllabus.vue";
import UpdateSyllabus from "@/components/academic/syllabus/UpdateSyllabus.vue";
import DetailSyllabus from "@/components/academic/syllabus/DetailSyllabus.vue";
import AcademicCalendar from "@/components/academic/calendar/AcademicCalendar.vue";
import DailySchedule from "@/components/academic/daily_schedule/DailySchedule.vue";
import AddDailySchedule from "@/components/academic/daily_schedule/AddDailySchedule.vue";
import UpdateDailySchedule from "@/components/academic/daily_schedule/UpdateDailySchedule.vue";
import DetailDailySchedule from "@/components/academic/daily_schedule/DetailDailySchedule.vue";
import Progress from "@/components/academic/progress/Progress.vue";
import AddProgress from "@/components/academic/progress/AddProgress.vue";
import UpdateProgress from "@/components/academic/progress/UpdateProgress.vue";

export default [
  {
    path: '/academic/syllabus',
    name: 'syllabus',
    component: Syllabus,
    meta: {
      title: 'Syllabus'
    }
  },
  {
    path: '/academic/syllabus/add',
    name: 'syllabus/add',
    component: AddSyllabus,
    meta: {
      title: 'Add Syllabus'
    }
  },
  {
    path: '/academic/syllabus/edit/:id',
    name: 'syllabus/edit',
    component: UpdateSyllabus,
    meta: {
      title: 'Update Syllabus'
    }
  },
  {
    path: '/academic/syllabus/:id',
    name: 'syllabus/detail',
    component: DetailSyllabus,
    meta: {
      title: 'Detail Syllabus'
    }
  },
  {
    path: '/academic/calendar',
    name: 'academic/calendar',
    component: AcademicCalendar,
    meta: {
      title: 'Academic Calendar'
    }
  },
  {
    path: '/academic/daily-schedule',
    name: 'academic/daily-schedule',
    component: DailySchedule,
    meta: {
      title: 'Daily Schedule'
    }
  },
  {
    path: '/academic/daily-schedule/add',
    name: 'academic/daily-schedule/add',
    component: AddDailySchedule,
    meta: {
      title: 'Add Daily Schedule'
    }
  },
  {
    path: '/academic/daily-schedule/edit/:id',
    name: 'academic/daily-schedule/edit',
    component: UpdateDailySchedule,
    meta: {
      title: 'Update Daily Schedule'
    }
  },
  {
    path: '/academic/daily-schedule/:id',
    name: 'academic/daily-schedule/detail',
    component: DetailDailySchedule,
    meta: {
      title: 'Detail Daily Schedule'
    }
  },
  {
    path: '/academic/progress',
    name: 'academic/progress',
    component: Progress,
    meta: {
      title: 'Progress'
    }
  },
  {
    path: '/academic/progress/add',
    name: 'academic/progress/add',
    component: AddProgress,
    meta: {
      title: 'Add Progress'
    }
  },
  {
    path: '/academic/progress/edit/:id',
    name: 'academic/progress/edit',
    component: UpdateProgress,
    meta: {
      title: 'Update Progress'
    }
  },
]