<template>
    <calendar-view
        :items="agendas"
        :show-date="showDate"
        class="theme-default holiday-us-traditional holiday-us-official">

        <template #header="{ headerProps }">
            <div class="d-flex flex-row items-center justify-content-center mb-1 mt-1" style="height: 40px">
                <span style="flex: 1"></span>
                <span @click="setShowDate(headerProps.previousPeriod)" style="font-size: 18px; cursor: pointer">
                    <i class="fa fa-chevron-left"></i>
                </span>
                <span class="fw-bold mx-4" style="font-size: 30px">
                    {{ headerProps.periodLabel }}
                </span>
                <span @click.prevent="setShowDate(headerProps.nextPeriod)" style="font-size: 18px; cursor: pointer">
                    <i class="fa fa-chevron-right"></i>
                </span>
                <span style="flex: 1"></span>
            </div>
        </template>

        <template #dayContent="{ day }">
            <div class="my-cv-day-number"
                 :class="{
                     'my-cv-today': isToday(day),
                     'my-cv-day-number-hoverable': !!onDateClick,
                     pointer: !!onDateClick,
                 }"
                 @click="onDateClick?.(day)"
            >
                {{ day.getDate() }}
            </div>
        </template>

        <template #item="{ value: item, top, weekStartDate }">
            <div draggable="false"
                 :class="[
                     ...item.classes, 'cv-item', 'my-cv-item', `my-cv-item-${item.originalItem.customData.color}`,
                     {pointer: !!onAgendaClick}
                 ]"
                 :title="item.title"
                 @click="onAgendaClick?.(item.originalItem)"
                 style="height: 30px; padding-top: 6px; padding-left: 10px"
                 :style="`top: ${top.slice(0, top.indexOf(')')) + ` + 16px + ${item.itemRow * 12}px` + top.slice(top.indexOf(')'))};`"
            >
                {{ item.title }}
            </div>
        </template>
    </calendar-view>
    <div v-if="nearest" class="mt-2 d-flex" :class="{'py-2 px-3': isMobile()}">
        <span style="flex: 1"></span>
        <span class="a" @click="openModal"><i>See Nearest Agenda</i></span>
        <span style="flex: 1"></span>
    </div>
    <Modal :show="showModal" @close="showModal = false" title="Nearest Agenda">
        <template #body>
            <div v-if="!nearest.length">
                <i>No Agenda</i>
            </div>
            <div v-else v-for="(v, k) in nearest" class="card mb-0" :class="{'mt-2': k > 0}">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div :class="`my-cv-item-${v.customData?.color}`" style="width: 15px; height: 15px; border-radius: 20px"></div>
                        <h4 class="fw-bold ml-2 mb-0">{{ v.title }}</h4>
                    </div>
                    <b>{{ toIndoDateName(v.start_date) }} - {{ toIndoDateName(v.end_date) }}</b>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue"
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
import "@/../node_modules/vue-simple-calendar/dist/style.css"
import "@/../node_modules/vue-simple-calendar/dist/css/default.css"
import "@/../node_modules/vue-simple-calendar/dist/css/holidays-us.css"
import {IAgenda} from "@/utils/interface/Agenda";
import {isMobile, toIndoDateName} from "@/utils/Helpers";
import Modal from "@/components/partials/Modal.vue";

export default defineComponent({
    name: "Calendar",
    components: {
        Modal,
        CalendarView,
        CalendarViewHeader,
    },
    props: {
        agendas: Array as PropType<IAgenda[]>,
        nearest: {
            type: Array as PropType<IAgenda[]>,
            default: null
        },
        onDateClick: {
            type: Function,
            default: null,
        },
        onAgendaClick: {
            type: Function,
            default: null,
        }
    },
    setup() {
        const showDate = ref(new Date())
        const showModal = ref(false)

        return {
            showDate,
            setShowDate: (d: Date) => showDate.value = d,
            isToday, toIndoDateName, isMobile, showModal,
            openModal: () => {
                showModal.value = true
                return false
            }
        }
    }
})

function isToday(date: Date) {
    const today = new Date();
    return today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate();
}

</script>

<style scoped>
.a {
    color: #1572e8;
    text-decoration: underline;
    cursor: pointer;
}
</style>