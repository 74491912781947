import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withKeys as _withKeys, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/img/bc-horizontal-new.png'


const _hoisted_1 = { class: "py-4" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "col-md-8" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "form-group row" }
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "form-group row" }
const _hoisted_10 = { class: "col-md-6" }
const _hoisted_11 = { class: "form-group row mb-0" }
const _hoisted_12 = { class: "col-md-8 offset-md-4" }
const _hoisted_13 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[7] || (_cache[7] = _createStaticVNode("<nav class=\"navbar navbar-expand-md navbar-light bg-white shadow-sm\"><div class=\"container\"><img src=\"" + _imports_0 + "\" height=\"40\" alt=\"navbar brand\" class=\"navbar-brand\"><button class=\"navbar-toggler\" type=\"button\" data-toggle=\"collapse\" data-target=\"#navbarSupportedContent\" aria-controls=\"navbarSupportedContent\" aria-expanded=\"false\"><span class=\"navbar-toggler-icon\"></span></button><div class=\"collapse navbar-collapse\" id=\"navbarSupportedContent\"></div></div></nav>", 1)),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header" }, "Login", -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", { class: "col-md-4 col-form-label text-md-right" }, "Username", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                      type: "text",
                      class: "form-control",
                      name: "username",
                      required: "",
                      autofocus: ""
                    }, null, 512), [
                      [_vModelText, _ctx.username]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", { class: "col-md-4 col-form-label text-md-right" }, "Password", -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                      type: "password",
                      class: "form-control",
                      name: "password",
                      required: "",
                      autocomplete: "current-password",
                      onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["enter"]))
                    }, null, 544), [
                      [_vModelText, _ctx.password]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-primary",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args))),
                      disabled: _ctx.loading
                    }, _toDisplayString(_ctx.loading ? 'Signing in...' : 'Sign In'), 9, _hoisted_13)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}