import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../assets/img/e_s.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center flex-column",
  style: {"height":"calc(100vh - 300px)"}
}
const _hoisted_6 = {
  key: 0,
  style: {"font-style":"italic"}
}
const _hoisted_7 = {
  key: 1,
  style: {"font-style":"italic"}
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "d-flex align-items-center mb-2" }
const _hoisted_11 = {
  class: "d-flex flex-column",
  style: {"flex":"1"}
}
const _hoisted_12 = { class: "d-block mb-2" }
const _hoisted_13 = { class: "d-block mb-2" }
const _hoisted_14 = { class: "d-block mb-2" }
const _hoisted_15 = { class: "d-block mb-2" }
const _hoisted_16 = { class: "d-block" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "d-block mb-2" }
const _hoisted_19 = { class: "d-block mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.healths.length || !_ctx.done)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  src: _imports_0,
                  width: "200"
                }, null, -1)),
                (_ctx.done)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Tidak ada data"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, "Mendapatkan data"))
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.healths, (v) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "card timeline-item",
              onClick: () => _ctx.detail(v)
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "faw mr-2" }, [
                    _createElementVNode("i", { class: "fa fa-user-nurse" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("i", null, _toDisplayString(_ctx.toIndoDateNameTime(v.created_at)), 1),
                    _createElementVNode("b", null, "Dokter telah memeriksa " + _toDisplayString(v.student?.nama) + " dengan detail sebagai berikut:", 1)
                  ])
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("b", { class: "d-block" }, "Keluhan:", -1)),
                _createElementVNode("span", _hoisted_12, _toDisplayString(v.keluhan), 1),
                _cache[3] || (_cache[3] = _createElementVNode("b", { class: "d-block" }, "Diagnosa:", -1)),
                _createElementVNode("span", _hoisted_13, _toDisplayString(v.diagnosa), 1),
                _cache[4] || (_cache[4] = _createElementVNode("b", { class: "d-block" }, "Tindakan:", -1)),
                _createElementVNode("span", _hoisted_14, _toDisplayString(v.tindakan), 1),
                _cache[5] || (_cache[5] = _createElementVNode("b", { class: "d-block" }, "Obat:", -1)),
                _createElementVNode("span", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.obat, (o) => {
                    return (_openBlock(), _createElementBlock("span", _hoisted_16, [
                      (o && o.nama)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(o.nama) + " @ " + _toDisplayString(o.jumlah) + " [" + _toDisplayString(o.signa) + "] ", 1))
                        : _createCommentVNode("", true)
                    ]))
                  }), 256))
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("b", { class: "d-block" }, "Tindak Lanjut:", -1)),
                _createElementVNode("span", _hoisted_18, _toDisplayString(v.tindak_lanjut), 1),
                _cache[7] || (_cache[7] = _createElementVNode("b", { class: "d-block" }, "Total Biaya:", -1)),
                _createElementVNode("span", _hoisted_19, "Rp " + _toDisplayString(_ctx.format(v.total_biaya)), 1)
              ])
            ], 8, _hoisted_8))
          }), 256))
        ])
      ])
    ])
  ]))
}