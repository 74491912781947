import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-head-row" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "table table-bordered mt-2" }
const _hoisted_11 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, "Teacher Attendance: " + _toDisplayString(_ctx.teacher?.name), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"d-flex align-items-center\" data-v-568a5431><span style=\"flex:1;\" data-v-568a5431></span><div class=\"b-hadir\" data-v-568a5431></div> <span data-v-568a5431>Hadir</span><div class=\"b-izin\" data-v-568a5431></div> <span data-v-568a5431>Izin</span><div class=\"b-sakit\" data-v-568a5431></div> <span data-v-568a5431>Sakit</span><div class=\"b-alpha\" data-v-568a5431></div> <span data-v-568a5431>Alpha</span><div class=\"b-tugas\" data-v-568a5431></div> <span data-v-568a5431>Tugas</span></div>", 1)),
              _createElementVNode("table", _hoisted_10, [
                _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "No"),
                    _createElementVNode("th", null, "Date"),
                    _createElementVNode("th", null, "Status"),
                    _createElementVNode("th", null, "Note")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attendances, (v, k) => {
                    return (_openBlock(), _createElementBlock("tr", null, [
                      _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                      _createElementVNode("td", null, _toDisplayString(v.date), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("span", _hoisted_11, [
                          _createElementVNode("span", {
                            class: _normalizeClass(["circle mr-1", `circle-${v.status}`])
                          }, _toDisplayString(v.status.toUpperCase()), 3)
                        ])
                      ]),
                      _createElementVNode("td", null, _toDisplayString(v.note), 1)
                    ]))
                  }), 256))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}