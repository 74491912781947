<template>
    <div :class="{'my-chat': chat.from_id === user.id, 'their-chat': chat.from_id !== user.id}"
         class="chat"
         :style="{'max-width': isMobile() ? '300px' : '400px'}"
    >
        <span>{{ chat.chat }}</span>
        <span class="d-flex align-items-center ml-auto" v-if="chat.from_id === user.id">
            <span style="font-size: 12px; color: #888888">{{ chat.sent }}</span>
            <i v-if="!chat.done && chat.ts" class="fa fa-clock ml-1" style="color: #aaaaaa"></i>
            <i v-else class="fa fa-check ml-1" style="color: #aaaaaa"></i>
        </span>
        <span v-else class="ml-auto">
            <span style="font-size: 12px; color: #888888">{{ chat.sent }}</span>
        </span>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {IChat} from "@/utils/interface/Chat";
import Session from "@/utils/Session";
import {isMobile} from "@/utils/Helpers";

export default defineComponent({
    name: "ChatItem",
    props: {
        chat: Object as PropType<IChat>
    },
    setup() {
        const user = Session.getCurrentUser()!

        return {
            user, isMobile
        }
    }
})
</script>

<style scoped>
.chat {
    background: white;
    border-radius: 8px;
    margin: 4px 10px;
    padding: 10px;
    box-shadow: 2px 2px 8px 0 rgba(69,65,78,.1);
    display: flex;
    flex-direction: column;
    align-items: start;
}
.my-chat {
    margin-left: auto;
    background: #cbf8bf;
}
.their-chat {
    margin-right: auto;
}
</style>