import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressItem = _resolveComponent("ProgressItem")!
  const _component_JournalItem = _resolveComponent("JournalItem")!

  return (_ctx.dashboardItem.type === 'progress')
    ? (_openBlock(), _createBlock(_component_ProgressItem, {
        key: 0,
        progress: _ctx.dashboardItem.data
      }, null, 8, ["progress"]))
    : (_openBlock(), _createBlock(_component_JournalItem, {
        key: 1,
        attendance: _ctx.dashboardItem.data
      }, null, 8, ["attendance"]))
}