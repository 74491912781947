import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-head-row" }
const _hoisted_8 = { class: "card-tools" }
const _hoisted_9 = { class: "card-header" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-sm-12 col-md-6 col-lg-4" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "col-sm-12 col-md-6 col-lg-4" }
const _hoisted_14 = { class: "card-body" }
const _hoisted_15 = { class: "table-responsive" }
const _hoisted_16 = { class: "table table-bordered" }
const _hoisted_17 = { class: "d-block" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-title" }, "Health", -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
                    class: "b-time btn btn-success btn-round mr-2"
                  }, " Add Data "),
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.excel && _ctx.excel(...args))),
                    class: "b-time btn btn-warning btn-round mr-2"
                  }, " Download Excel ")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", null, "Tanggal", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "date",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.date]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("button", {
                    class: "btn btn-success",
                    style: {"margin-top":"38px"},
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
                  }, "Tampilkan")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("table", _hoisted_16, [
                  _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "No"),
                      _createElementVNode("th", null, "Date"),
                      _createElementVNode("th", null, "Student"),
                      _createElementVNode("th", null, "Keluhan"),
                      _createElementVNode("th", null, "Diagnosa"),
                      _createElementVNode("th", null, "Tindakan"),
                      _createElementVNode("th", null, "Obat"),
                      _createElementVNode("th", null, "Tindak Lanjut"),
                      _createElementVNode("th", null, "Total Biaya"),
                      _createElementVNode("th", null, "Aksi")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.health, (v, k) => {
                      return (_openBlock(), _createElementBlock("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                        _createElementVNode("td", null, _toDisplayString(_ctx.toIndoDateName(v.due_date)), 1),
                        _createElementVNode("td", null, _toDisplayString(v.student.nama), 1),
                        _createElementVNode("td", null, _toDisplayString(v.keluhan), 1),
                        _createElementVNode("td", null, _toDisplayString(v.diagnosa), 1),
                        _createElementVNode("td", null, _toDisplayString(v.tindakan), 1),
                        _createElementVNode("td", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.obat, (o) => {
                            return (_openBlock(), _createElementBlock("span", _hoisted_17, [
                              _createElementVNode("b", null, _toDisplayString(o.nama), 1),
                              _createTextVNode(" : " + _toDisplayString(o.jumlah) + " ", 1),
                              (o.signa && o.signa !== '-')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_18, "[" + _toDisplayString(o.signa) + "]", 1))
                                : _createCommentVNode("", true)
                            ]))
                          }), 256))
                        ]),
                        _createElementVNode("td", null, _toDisplayString(v.tindak_lanjut), 1),
                        _createElementVNode("td", null, "Rp " + _toDisplayString(_ctx.format(v.total_biaya)), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("button", {
                            class: "btn btn-danger btn-sm",
                            onClick: ($event: any) => (_ctx.destroy(v.id))
                          }, _cache[6] || (_cache[6] = [
                            _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                          ]), 8, _hoisted_19)
                        ])
                      ]))
                    }), 256))
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}