import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "col-md-6" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "form-control" }
const _hoisted_17 = {
  key: 0,
  class: "form-control mb-2 d-flex align-items-center",
  style: {"padding":"0.6rem"}
}
const _hoisted_18 = { style: {"flex":"1"} }
const _hoisted_19 = {
  key: 1,
  class: "d-flex flex-row-reverse"
}
const _hoisted_20 = { class: "card-footer d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_ckeditor = _resolveComponent("ckeditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Add Announcement")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", null, [
                      _createTextVNode("Title "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, _ctx.title]
                    ]),
                    (_ctx.validation.title)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.validation.title), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", null, [
                      _createTextVNode("Message "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, _ctx.message]
                    ]),
                    (_ctx.validation.message)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.validation.message), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", null, "Kelas ", -1)),
                    _createVNode(_component_Multiselect, {
                      mode: "tags",
                      modelValue: _ctx.grades,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.grades) = $event)),
                      options: _ctx.refGrade,
                      searchable: true,
                      multiple: true
                    }, null, 8, ["modelValue", "options"]),
                    _cache[12] || (_cache[12] = _createElementVNode("i", { class: "mt-2 d-block" }, "* Pilih kelas hanya jika ingin menambahkan pengumuman pada kelas yang dipilih. Jika ingin menambahkan pengumuman untuk semua kelas, biarkan kosong saja :)", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_ckeditor, {
                    editor: _ctx.editor,
                    modelValue: _ctx.editorData,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editorData) = $event)),
                    config: _ctx.editorConfig
                  }, null, 8, ["editor", "modelValue", "config"]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", null, "Lampiran", -1)),
                    _createElementVNode("div", _hoisted_16, [
                      (_ctx.attachment_file)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.attachment_file.name), 1),
                            _createElementVNode("button", {
                              class: "btn btn-sm btn-danger",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.remove_attachment()))
                            }, _cache[13] || (_cache[13] = [
                              _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                            ]))
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("input", {
                        type: "file",
                        ref: "question_file",
                        id: "question_file",
                        accept: "application/pdf",
                        onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.attachment_change && _ctx.attachment_change(...args))),
                        style: {"display":"none"}
                      }, null, 544),
                      (!_ctx.attachment_file)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.add_attachment && _ctx.add_attachment(...args))),
                              class: "btn btn-sm btn-success"
                            }, _cache[14] || (_cache[14] = [
                              _createElementVNode("i", { class: "fa fa-plus" }, null, -1),
                              _createTextVNode(" Pilih Lampiran ")
                            ]))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _cache[16] || (_cache[16] = _createElementVNode("p", { class: "mt-1 mb-0" }, "Tipe file yang diizinkan adalah .pdf dengan maksimal 5 MB.", -1))
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
              }, "Simpan"),
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}