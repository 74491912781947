<template>
    <InboxMobileChatGroup
        v-if="!activeChatGroup && !chosenPerson"
        :chosen-person="chosenPerson"
        :load="load"
        @set-active-group="setActiveGroup"
    />
    <InboxMobileChat
        v-else
        :load-person="loadPerson"
        :chosen-person="chosenPerson"
        :active-chat-group="activeChatGroup"
        @chat-groups-change="chatGroupsChange"
        @back="back"
    />
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {User} from "@/utils/Models";
import InboxMobileChatGroup from "@/components/inbox/InboxMobileChatGroup.vue";
import {IChatGroup} from "@/utils/interface/Chat";
import InboxMobileChat from "@/components/inbox/InboxMobileChat.vue";
import {useRouter} from "vue-router";

export default defineComponent({
    name: "InboxMobile",
    components: {InboxMobileChat, InboxMobileChatGroup},
    props: {
        chosenPerson: Object as PropType<User|null>,
        load: Number,
        loadPerson: Number,
    },
    emits: ['clearChosenPerson'],
    setup(_,{emit}) {
        const activeChatGroup = ref<IChatGroup|null>(null)
        const setActiveGroup = (cg: IChatGroup) => {
            activeChatGroup.value = cg
        }
        const router = useRouter()

        const chatGroupsChange = (cg: IChatGroup[]) => {

        }

        return {
            setActiveGroup, activeChatGroup,
            back: () => {
                activeChatGroup.value = null
                emit('clearChosenPerson')
                setTimeout(() => router.replace('/inbox'), 1000)
            },
            chatGroupsChange
        }
    }
})
</script>

<style scoped>

</style>