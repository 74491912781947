import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "content"
}
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-head-row" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-tools" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "row mb-4" }
const _hoisted_12 = { class: "col-sm-12 col-md-6 col-lg-4" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "table-responsive" }
const _hoisted_15 = { class: "table table-bordered" }
const _hoisted_16 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassroomJournalList = _resolveComponent("ClassroomJournalList")!
  const _component_ClassroomJournalPreview = _resolveComponent("ClassroomJournalPreview")!

  return (_ctx.selectedGroup)
    ? (_openBlock(), _createBlock(_component_ClassroomJournalList, {
        key: 0,
        "classroom-journal-group": _ctx.selectedGroup,
        level: _ctx.level as string,
        onBack: _ctx.reload
      }, null, 8, ["classroom-journal-group", "level", "onBack"]))
    : (_ctx.previewedGroup)
      ? (_openBlock(), _createBlock(_component_ClassroomJournalPreview, {
          key: 1,
          "classroom-journal-group": _ctx.previewedGroup,
          onBack: _cache[0] || (_cache[0] = () => _ctx.previewedGroup = null)
        }, null, 8, ["classroom-journal-group"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, "Classroom Journal " + _toDisplayString(_ctx.level === 'smp' ? 'SMP' : _ctx.level === 'sma' ? 'SMA' : 'Pesantren'), 1),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("button", {
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
                          class: "b-time btn btn-success btn-round mr-2"
                        }, " Add Journal ")
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _cache[4] || (_cache[4] = _createElementVNode("label", null, "Date", -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "date",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event)),
                            class: "form-control",
                            onChange: _cache[3] || (_cache[3] = () => _ctx.reloadData())
                          }, null, 544), [
                            [_vModelText, _ctx.date]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("table", _hoisted_15, [
                        _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _cache[5] || (_cache[5] = _createElementVNode("th", null, "No", -1)),
                            _cache[6] || (_cache[6] = _createElementVNode("th", null, "Date", -1)),
                            _cache[7] || (_cache[7] = _createElementVNode("th", null, "Grade", -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lessonHours, (v) => {
                              return (_openBlock(), _createElementBlock("th", null, _toDisplayString(v), 1))
                            }), 256))
                          ])
                        ]),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (v, k) => {
                            return (_openBlock(), _createElementBlock("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                              _createElementVNode("td", null, _toDisplayString(v.date), 1),
                              _createElementVNode("td", null, _toDisplayString(v.grade_name), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lessonHours, (lh) => {
                                return (_openBlock(), _createElementBlock("td", null, [
                                  _createElementVNode("b", null, _toDisplayString(v.data.find(d => d.lesson_hours === lh)?.subject_name ?? '-'), 1),
                                  _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                                  (v.data.find(d => d.lesson_hours === lh))
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        class: "btn btn-sm btn-success btn-round",
                                        onClick: ($event: any) => (_ctx.detail(v.data.find(d => d.lesson_hours === lh)!.id))
                                      }, " Lihat ", 8, _hoisted_16))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 256))
                            ]))
                          }), 256))
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
}