import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, vModelRadio as _vModelRadio } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "col-sm-12 col-md-6" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "form-check" }
const _hoisted_17 = { class: "form-radio-label" }
const _hoisted_18 = { class: "form-radio-label ml-3" }
const _hoisted_19 = { class: "card-footer d-flex flex-row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "fw-bold" }, "Detail Subject")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", null, [
                      _createTextVNode("Kode Subject "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kode) = $event)),
                      placeholder: "Masukkan Kode Subject",
                      required: "",
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _ctx.kode]
                    ]),
                    (_ctx.kodeErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.kodeErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", null, [
                      _createTextVNode("Nama Subject "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nama) = $event)),
                      placeholder: "Masukkan Nama Subject",
                      required: "",
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _ctx.nama]
                    ]),
                    (_ctx.namaErr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.namaErr), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", null, [
                      _createTextVNode("Tipe Subject "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tipe) = $event)),
                      disabled: "",
                      readonly: ""
                    }, _cache[9] || (_cache[9] = [
                      _createElementVNode("option", { value: "Karakter" }, "Karakter", -1),
                      _createElementVNode("option", { value: "Literasi" }, "Literasi", -1),
                      _createElementVNode("option", { value: "Numerasi" }, "Numerasi", -1)
                    ]), 512), [
                      [_vModelSelect, _ctx.tipe]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", null, "Deskripsi (opsional)", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.deskripsi) = $event)),
                      rows: "4",
                      placeholder: "Masukkan Deskripsi",
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _ctx.deskripsi]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", null, "Kategori Subject", -1)),
                    _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("label", _hoisted_17, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-radio-input",
                        type: "radio",
                        value: "Sekolahan",
                        checked: "",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.bc_type) = $event)),
                        disabled: ""
                      }, null, 512), [
                        [_vModelRadio, _ctx.bc_type]
                      ]),
                      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "form-radio-sign" }, "Sekolahan", -1))
                    ]),
                    _createElementVNode("label", _hoisted_18, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-radio-input",
                        type: "radio",
                        value: "Pondok",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.bc_type) = $event)),
                        disabled: ""
                      }, null, 512), [
                        [_vModelRadio, _ctx.bc_type]
                      ]),
                      _cache[13] || (_cache[13] = _createElementVNode("span", { class: "form-radio-sign" }, "Pondok", -1))
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}