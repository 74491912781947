<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="d-flex align-items-end">

            </div>
			<PaginatedTable :data="ortu" class="mt-3">
				<template #thead>
					<th>No</th>
					<th>Nama</th>
					<th>Username</th>
					<th>No HP</th>
					<th>Alamat</th>
					<th>Anak</th>
				</template>
				<template #tbody="{row, index}">
					<td>{{ index + 1 }}</td>
					<td>{{ row.data.nama }}</td>
					<td>{{ row.data.username }}</td>
					<td>{{ row.data.no_hp }}</td>
					<td>{{ row.data.alamat }}</td>
					<td>
						<div v-for="a in row.data.anak">- <b class="text-success" @click="detail(a.id)">{{ a.nama }}</b></div>
						<div v-for="a in row.data.anak2">- <b class="text-success" @click="detail(a.id)">{{ a.nama }}</b></div>
					</td>
				</template>
			</PaginatedTable>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Api from "@/utils/Api";
import {Ortu} from "@/utils/Models";
import {useRouter} from "vue-router";
import {error, loading} from "@/utils/Toast";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "TabOrangTua",
	components: {PaginatedTable},
    setup() {
        const router = useRouter()
        const ortu = ref<Ortu[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/ortu')
            t.remove()

            if (r.success) {
                ortu.value = r.data as Ortu[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const detail = (id: number) => {
            router.push(`/master-data/detail-siswa/${id}`)
        }

        return { ortu, detail }
    }
})
</script>

<style scoped>
b {
    cursor: pointer;
}
</style>
