import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"
import _imports_0 from '../../assets/img/ph.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "profile-picture mb-1" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 2,
  src: _imports_0,
  alt: "...",
  class: "img",
  id: "photo-display"
}
const _hoisted_13 = {
  key: 3,
  class: "text-danger"
}
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_16 = { class: "col-md-6" }
const _hoisted_17 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_18 = { class: "card-footer d-flex flex-row-reverse" }

import {useRoute, useRouter} from "vue-router";
// @ts-ignore
import UploadAdapter from "@/utils/UploadAdapter";
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {onMounted, reactive, ref} from "vue";
import {file2Base64, getPhoto} from "@/utils/Helpers";
import {IInformation} from "@/components/information/Information.vue";
import Session from "@/utils/Session";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddInformation',
  setup(__props) {

const ckeditor = CKEditor.component
const router = useRouter()
const route = useRoute()
const title = ref('')
const editorData = ref('')
const validation = reactive({
  image: '',
  title: '',
  body: '',
})
const photo_file = ref(null)
const photo_url = ref<string|null>(null)
const photo_b64 = ref<string|null>(null)
const information = ref<IInformation|null>(null)
const user = Session.getCurrentUser()!

onMounted(async () => {
  const id = route.params.id
  if (id) {
    const t = loading('Mendapatkan data')
    const r = await Api.get(`information/${id}`)
    t.remove()
    if (r.success) {
      information.value = r.data as IInformation
      title.value = information.value!.title
      editorData.value = information.value!.body
    }
  }
})

function uploader(editor: any) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
    return new UploadAdapter( loader );
  }
}

const choose_photo = () => {
  let fileUpload = document.getElementById('photo_file')
  if (fileUpload != null) {
    fileUpload.click()
  }
}

const image_change = async (event: any) => {
  const file = event.target.files[0]
  if (file.size > 1000000) {
    error('Maksimal ukuran gambar 1 MB')
    return
  }

  photo_url.value = URL.createObjectURL(file)
  photo_b64.value = await file2Base64(file)
}

const validate = () => {
  validation.image = ''
  validation.title = ''
  validation.body = ''

  if (!information.value && !photo_url.value) {
    validation.image = 'Pilih gambar'
    return false
  }
  if (!title.value) {
    validation.title = 'Masukkan title'
    return false
  }
  if (!editorData.value) {
    validation.body = 'Masukkan body'
    return false
  }
  return true
}

const save = async () => {
  if (!validate()) {
    return
  }

  let data = new FormData()
  data.append('title', title.value)
  data.append('body', editorData.value)
  data.append('body', editorData.value)

  const t = loading('Menyimpan data')
  const url = information.value ? `information/edit/${information.value!.id}` : `information/create`
  const r = await Api.post(url, {
    title: title.value,
    body: editorData.value,
    image: photo_b64.value,
    created_by: user.id
  })
  t.remove()

  if (r.success) {
    success('Berhasil menyimpan data')
    router.back()
  }
  else {
    error(r.message ?? 'gagal')
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Add Announcement")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (photo_url.value)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: photo_url.value,
                          alt: "...",
                          class: "img",
                          id: "photo-display"
                        }, null, 8, _hoisted_10))
                      : (information.value)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: _unref(getPhoto)(information.value!.image),
                            alt: "...",
                            class: "img",
                            id: "photo-display"
                          }, null, 8, _hoisted_11))
                        : (_openBlock(), _createElementBlock("img", _hoisted_12)),
                    (validation.image)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(validation.image), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("input", {
                      type: "file",
                      ref_key: "photo_file",
                      ref: photo_file,
                      id: "photo_file",
                      accept: "image/*",
                      onChange: image_change,
                      style: {"display":"none"}
                    }, null, 544),
                    _createElementVNode("div", { class: "d-flex flex-column align-items-center mt-2" }, [
                      _createElementVNode("button", {
                        type: "button",
                        onClick: choose_photo,
                        class: "btn btn-sm btn-outline-success ml-2"
                      }, "Pilih Gambar")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[3] || (_cache[3] = _createElementVNode("label", null, [
                      _createTextVNode("Title "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((title).value = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, title.value]
                    ]),
                    (validation.title)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(validation.title), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_unref(ckeditor), {
                    editor: _unref(ClassicEditor),
                    modelValue: editorData.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editorData).value = $event)),
                    config: {extraPlugins: [uploader]}
                  }, null, 8, ["editor", "modelValue", "config"]),
                  (validation.body)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(validation.body), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: save
              }, "Simpan"),
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[2] || (_cache[2] = () => _unref(router).back())
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})