<template>
    <div class="card timeline-item">
        <div class="card-header d-flex">
            <i style="flex: 1">{{toIndoDateNameTime(announcement.created_at)}}</i>
            <label v-if="dashboard" class="badge badge-danger text-white">{{'Announcement'}}</label>
        </div>
        <div class="card-body">
            <h3 class="fw-bold mb-0">{{announcement.title}}</h3>
            <span>{{announcement.message}}</span>
            <div v-if="announcement.action_detail" class="ctr">
                <div v-if="expanded" v-html="announcement.action_detail"/>
                <div class="d-flex justify-content-center">
                    <i @click="() => expanded = !expanded" class="toggle">{{expanded ? 'Hide Details' : 'Show Details'}}</i>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-2">
                <a class="btn btn-success btn-sm mt-2" v-if="announcement.pdf" :href="announcement.pdf" target="_blank">
                    Download Attachment
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {IAnnouncement} from "@/utils/interface/Announcement";
import {toIndoDateNameTime} from "@/utils/Helpers";

export default defineComponent({
    name: "MobileAnnouncementItem",
    props: {
        announcement: Object as PropType<IAnnouncement>,
        dashboard: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const expanded = ref(false)

        return {
            toIndoDateNameTime, expanded
        }
    }
})
</script>

<style scoped>
.ctr {
    padding: 10px;
    background: #f6f6f6;
    margin-top: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.ctr-toggle {
    padding: 10px;
    background: #f6f6f6;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.toggle {
    text-decoration: underline;
    color: #1572e8;
}
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
.bg {
    color: white;
    background: linear-gradient(to right, #f52020, #f18f8f);
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
</style>

<style>
.ctr div embed {
    display: none !important;
    height: 0 !important;
}
</style>