<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Health</div>
                                <div class="card-tools">
                                    <button @click="add" class="b-time btn btn-success btn-round mr-2">
                                        Add Data
                                    </button>
                                    <button @click="excel" class="b-time btn btn-warning btn-round mr-2">
                                        Download Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-header">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Tanggal</label>
                                        <input type="date" class="form-control" v-model="date">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4">
                                    <button class="btn btn-success" style="margin-top: 38px" @click="reload">Tampilkan</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Date</th>
                                        <th>Student</th>
                                        <th>Keluhan</th>
                                        <th>Diagnosa</th>
                                        <th>Tindakan</th>
                                        <th>Obat</th>
                                        <th>Tindak Lanjut</th>
                                        <th>Total Biaya</th>
                                        <th>Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v, k) in health">
                                        <td>{{ k + 1 }}</td>
                                        <td>{{ toIndoDateName(v.due_date) }}</td>
                                        <td>{{ v.student.nama }}</td>
                                        <td>{{ v.keluhan }}</td>
                                        <td>{{ v.diagnosa }}</td>
                                        <td>{{ v.tindakan }}</td>
                                        <td>
                                            <span v-for="o in v.obat" class="d-block">
                                                <b>{{ o.nama }}</b> :
                                                {{ o.jumlah }} <span v-if="o.signa && o.signa !== '-'">[{{ o.signa }}]</span>
                                            </span>
                                        </td>
                                        <td>{{ v.tindak_lanjut }}</td>
                                        <td>Rp {{ format(v.total_biaya) }}</td>
                                        <td>
<!--                                            <button class="btn btn-success btn-sm" @click="detail(v.id)">-->
<!--                                                <i class="fa fa-eye"></i>-->
<!--                                            </button>-->
                                            <button class="btn btn-danger btn-sm" @click="destroy(v.id)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {IHealth} from "@/utils/interface/Health";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import {format, toIndoDateName} from "@/utils/Helpers";
import moment from "moment";

export default defineComponent({
    name: "Health",
    setup() {
        const router = useRouter()
        const health = ref<IHealth[]>([])
        const date = ref(moment().format('YYYY-MM-DD'))

        const reload = async () => {
            health.value = []

            const t = loading()
            const r = await Api.get(`health/filter/${date.value}`)
            t.remove()

            if (r.success) {
                health.value = r.data as IHealth[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        onMounted(() => {
            reload()
        })

        const excel = async () => {
            const t = loading()
            await Api.getDownload(`health/excel/${date.value}`, `Health-Report-${date.value}.xlsx`)
            t.remove()
        }

        const destroy = async (id: number) => {
          if (confirm('Yakin hapus data ini?')) {
            const t = loading('Menghapus data')
            await Api.get(`health/delete/${id}`)
            t.remove()
            await reload()
          }
        }

        return {
            health, toIndoDateName, format, date, reload, excel,
            add: () => router.push('/health/add'), destroy
        }
    }
})
</script>

<style scoped>

</style>