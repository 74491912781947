<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div id="print" class="card-body" v-if="classroomJournalGroup">
                            <div class="d-flex">
                                <div style="flex: 1">
                                    <img src="../../assets/img/bc-horizontal-new.png" height="50" alt="navbar brand" class="navbar-brand">
                                </div>
                                <div class="d-flex flex-column text-center">
                                    <h3 class="fw-bold mb-0">CLASSROOM JOURNAL</h3>
                                    <h3 class="fw-bold mb-0">{{ school.name }}</h3>
                                    <h4 class="fw-bold mb-0">Academic Year {{ classroomJournalGroup.data[0]?.academic_year ?? '-' }}</h4>
                                </div>
                                <div style="flex: 1" class="text-right">
                                    <img src="../../assets/img/daily.png" height="50">
                                </div>
                            </div>
                            <hr>
                            <b style="font-size: 16px">Date: {{toIndoDateName(classroomJournalGroup.date)}}</b>
                            <table class="table-bordered table mt-2">
                                <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Time</th>
                                    <th>teacher</th>
                                    <th>Subject</th>
                                    <th>Topic</th>
                                    <th>Activities</th>
                                    <th>Note</th>
                                    <th>Absent Students</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v, k) in classroomJournalGroup.data">
                                    <td>{{k + 1}}</td>
                                    <td>{{v.lesson_hours}}</td>
                                    <td>{{v.teacher_name}}</td>
                                    <td>{{v.subject_name}}</td>
                                    <td>{{v.materi_pembelajaran}}</td>
                                    <td>{{v.kegiatan_pembelajaran}}</td>
                                    <td>{{v.catatan}}</td>
                                    <td>{{v.attendances.filter(x => x.status !== 'h').map(x => x.student_name).join(', ')}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                            <button class="btn btn-success" @click="download">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {IClassroomJournalGroup} from "@/utils/interface/ClassroomJournal";
import {toIndoDateName} from "@/utils/Helpers";
import Session from "@/utils/Session";

export default defineComponent({
    name: "ClassroomJournalPreview",
    props: {
        classroomJournalGroup: Object as PropType<IClassroomJournalGroup>
    },
    emits: ['back'],
    setup(_, {emit}) {
        const school = Session.getSchool()!
        return {
            back: () => emit('back'),
            download, toIndoDateName, school
        }
    }
})

const download = () => {
    const prtHtml = document.getElementById('print')!.innerHTML;
    let stylesHtml = '';
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
    }
    stylesHtml += '<style>.table .table{background-color: transparent !important;}*{font-family:"Times New Roman",serif !important;color:#000!important}.table td,.table th{height:auto!important;padding:6px!important}.border-none{border-collapse:collapse;border:none}.border-none td{border:1px solid #000}.border-none tr:first-child td{border-top:none}.border-none tr:last-child td{border-bottom:none}.border-none tr td:first-child{border-left:none}.border-none tr td:last-child{border-right:none}</style>'

    const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0') as WindowProxy;
    WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body style="padding: 10px; font-size: 150% !important;">
            ${prtHtml}
          </body>
        </html>`
    );

    WinPrint.document.close();
    WinPrint.focus();
    setTimeout(() => WinPrint.print(), 100)
}
</script>

<style scoped>

</style>