<script setup lang="ts">

import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";
import {onMounted, ref} from "vue";
import Modal from "@/components/partials/Modal.vue";
import {useRouter} from "vue-router";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export interface IInformation {
  id: number,
  image: string,
  title: string,
  body: string,
  created_by: number,
  created_at: string,
  creator_name?: string,
}

const informations = ref<IInformation[]>([])
const showModal = ref(false)
const modalData = ref('')
const router = useRouter()

onMounted(async () => {
  const t = loading('Mendapatkan data')
  const r = await Api.get(`information`)
  t.remove()

  if (r.success) {
    informations.value = r.data as IInformation[]
  }
  else {
    error(r.message ?? 'gagal')
  }
})

const add = () => router.push('/information/add')

const edit = (id: string|number) => router.push(`/information/edit/${id}`)

const destroy = async (id: string|number) => {
  if (!confirm('Yakin hapus data ini?')) {
    return
  }

  const t = loading('Menghapus data')
  const r = await Api.get(`information/delete/${id}`)
  t.remove()
  if (r.success) {
    informations.value = r.data as IInformation[]
  }
}

const detail = (detail: string) => {
  showModal.value = true
  modalData.value = detail
}

</script>

<template>
  <div class="content">
    <div class="page-inner">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-head-row">
                <div class="card-title">Information</div>
                <div class="card-tools">
                  <button @click="add" class="b-time btn btn-success btn-round mr-2">
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
				<PaginatedTable :data="informations" class="mt-3">
					<template #thead>
						<th>No</th>
						<th>Created</th>
						<th>Image</th>
						<th>Title</th>
						<th>Body</th>
						<th>Aksi</th>
					</template>
					<template #tbody="{row, index}">
						<td>{{ index + 1 }}</td>
						<td>
							{{ toIndoDateNameTime(row.data.created_at) }}
							<div v-if="row.data.creator_name">
								By {{ row.data.creator_name }}
							</div>
						</td>
						<td>
							<img :src="getPhoto(row.data.image)" class="img" alt="">
						</td>
						<td>{{ row.data.title }}</td>
						<td>
							<button class="btn btn-success btn-sm" @click="detail(row.data.body)">
								Show Detail
							</button>
						</td>
						<td>
							<button class="btn btn-warning btn-sm mr-2" @click="edit(row.data.id)">
								<i class="fa fa-pencil"></i>
							</button>
							<button class="btn btn-danger btn-sm" @click="destroy(row.data.id)">
								<i class="fa fa-trash"></i>
							</button>
						</td>
					</template>
				</PaginatedTable>
<!--              <table class="table table-bordered">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <th>No</th>-->
<!--                  <th>Created</th>-->
<!--                  <th>Image</th>-->
<!--                  <th>Title</th>-->
<!--                  <th>Body</th>-->
<!--                  <th>Aksi</th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                <tr v-for="(v, k) in informations">-->
<!--                  <td>{{ k + 1 }}</td>-->
<!--                  <td>-->
<!--                    {{ toIndoDateNameTime(v.created_at) }}-->
<!--                    <div v-if="v.creator_name">-->
<!--                      By {{ v.creator_name }}-->
<!--                    </div>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <img :src="getPhoto(v.image)" class="img" alt="">-->
<!--                  </td>-->
<!--                  <td>{{ v.title }}</td>-->
<!--                  <td>-->
<!--                    <button class="btn btn-success btn-sm" @click="detail(v.body)">-->
<!--                      Show Detail-->
<!--                    </button>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <button class="btn btn-warning btn-sm mr-2" @click="edit(v.id)">-->
<!--                      <i class="fa fa-pencil"></i>-->
<!--                    </button>-->
<!--                    <button class="btn btn-danger btn-sm" @click="destroy(v.id)">-->
<!--                      <i class="fa fa-trash"></i>-->
<!--                    </button>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </table>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :show="showModal"
    @close="showModal = false"
    title="Message Detail"
  >
    <template #body>
      <div v-html="modalData"></div>
    </template>
  </Modal>
</template>

<style scoped>
.img {
  border-radius: 8px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 8px;
}
</style>