import Timeline from "@/components/parent/Timeline.vue";
import OrtuAcademicCalendar from "@/components/parent/AcademicCalendar.vue";
import ScoreList from "@/components/parent/ScoreList.vue";
import StudentClassroom from "@/components/parent/StudentClassroom.vue";
import Development from "@/components/parent/Development.vue";
import ParentReport from "@/components/parent/ParentReport.vue";
import Notification from "@/components/parent/Notification.vue";
import StudentHealth from "@/components/parent/StudentHealth.vue";
import MobileAnnouncement from "@/components/partials/MobileAnnouncement.vue";

export default [
  {
    path: '/timeline',
    name: 'timeline',
    component: Timeline,
    meta: {
      title: 'Dashboard'
    }
  },
  {
    path: '/academic-calendar',
    name: 'AcademicCalendar',
    component: OrtuAcademicCalendar,
    meta: {
      title: 'Academic Calendar'
    }
  },
  {
    path: '/score-list',
    name: 'ScoreList',
    component: ScoreList,
    meta: {
      title: 'Score List'
    }
  },
  {
    path: '/student-classroom',
    name: 'student-classroom',
    component: StudentClassroom,
    meta: {
      title: 'Classroom Journal'
    }
  },
  {
    path: '/academic-development',
    name: 'Progress / Achievements',
    component: Development,
    meta: {
      title: 'Progress / Achievements'
    }
  },
  {
    path: '/parent-report',
    name: 'ParentReport',
    component: ParentReport,
    meta: {
      title: 'Report'
    }
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification,
    meta: {
      title: 'Notification'
    }
  },
  {
    path: '/student-health',
    name: 'StudentHealth',
    component: StudentHealth,
    meta: {
      title: 'Health'
    }
  },
  {
    path: '/announcements',
    name: 'announcements',
    component: MobileAnnouncement,
    meta: {
      title: 'Announcement'
    }
  },
]