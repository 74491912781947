<template>
    <div class="card">
        <div class="card-header">
            <h4 class="fw-bold mb-0">III. Cases And Solution</h4>
        </div>
        <div class="card-body">
            <div class="form-group">
                <textarea class="form-control" rows="4" :value="cases" @change="change"></textarea>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "CasesAndSolution",
    props: {
        cases: String
    },
    emits: ['change'],
    setup(_, { emit }) {
        return {
            change: (e: Event) => {
               emit('change', (e.target as HTMLTextAreaElement).value)
            }
        }
    }
})
</script>

<style scoped>

</style>
