<template>
    <transition name="modal" v-if="show">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container" :class="{sm: size === 'sm', lg: size === 'lg'}">

                    <div class="modal-header">
                        <slot name="header">
                            <h4 class="mb-0">{{ title }}</h4>
                        </slot>
                    </div>

                    <div class="modal-body" :class="{'p-0': removeBodyPadding}">
                        <slot name="body">

                        </slot>
                    </div>

                    <div class="modal-footer" v-if="!noFooter">
                        <slot name="footer">
                            <button class="btn btn-success btn-sm" @click="$emit('close')">
                                OK
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "Modal",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        removeBodyPadding: {
            type: Boolean,
            default: false,
        },
        noFooter: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        }
    },
    setup() {

    }
})
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.sm {
    max-width: 500px;
}

.lg {
    max-width: 750px;
}

.modal-header * {
    font-weight: bold;
}

.modal-body {
    margin: 0;
    max-height: calc(100vh - 200px);
    overflow: auto;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>