import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/img/bc-horizontal-new.png'
import _imports_1 from '../../assets/img/logo_smp_sma.png'


const _hoisted_1 = {
  key: 0,
  class: "main-header"
}
const _hoisted_2 = {
  class: "logo-header",
  "data-background-color": "white",
  style: {"padding-left":"15px"}
}
const _hoisted_3 = {
  href: "/",
  class: "logo"
}
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  height: "50",
  alt: "",
  class: "navbar-brand"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  height: "50",
  alt: "",
  class: "navbar-brand"
}
const _hoisted_6 = {
  class: "navbar navbar-header navbar-expand-lg",
  "data-background-color": "green2"
}
const _hoisted_7 = { class: "container-fluid" }
const _hoisted_8 = { class: "navbar-nav topbar-nav ml-md-auto align-items-center" }
const _hoisted_9 = { class: "nav-item dropdown hidden-caret" }
const _hoisted_10 = {
  class: "dropdown-toggle profile-pic",
  "data-toggle": "dropdown",
  href: "#",
  "aria-expanded": "false"
}
const _hoisted_11 = { class: "position-relative" }
const _hoisted_12 = { class: "avatar-sm" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 0,
  class: "notif-badge notif-badge-abs"
}
const _hoisted_15 = { class: "dropdown-menu dropdown-user animated fadeIn" }
const _hoisted_16 = { class: "dropdown-user-scroll scrollbar-outer" }
const _hoisted_17 = { class: "user-box" }
const _hoisted_18 = { class: "avatar-lg" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "u-text" }
const _hoisted_21 = { class: "text-muted" }
const _hoisted_22 = {
  key: 0,
  class: "notif-badge d-inline-block ml-1"
}
const _hoisted_23 = {
  key: 0,
  class: "dropdown-divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", _hoisted_3, [
            (!_ctx.isAndroid)
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : (_openBlock(), _createElementBlock("img", _hoisted_5))
          ]),
          _createElementVNode("button", {
            class: "navbar-toggler sidenav-toggler ml-auto",
            type: "button",
            "data-toggle": "collapse",
            "data-target": "collapse",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("span", { class: "navbar-toggler-icon" }, [
              _createElementVNode("i", { class: "icon-menu" })
            ], -1)
          ])),
          _createElementVNode("button", {
            class: "topbar-toggler more",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleBar && _ctx.toggleBar(...args)))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("i", { class: "icon-options-vertical" }, null, -1)
          ])),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "nav-toggle" }, [
            _createElementVNode("button", { class: "btn btn-toggle toggle-sidebar" }, [
              _createElementVNode("i", { class: "icon-menu" })
            ])
          ], -1))
        ]),
        _createElementVNode("nav", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"collapse\" id=\"search-nav\" data-v-90ef01b4><form class=\"navbar-left navbar-form nav-search mr-md-3\" data-v-90ef01b4><div class=\"input-group\" data-v-90ef01b4><div class=\"input-group-prepend\" data-v-90ef01b4><button type=\"submit\" class=\"btn btn-search pr-1\" data-v-90ef01b4><i class=\"fa fa-search search-icon\" data-v-90ef01b4></i></button></div><input type=\"text\" placeholder=\"Search ...\" class=\"form-control\" data-v-90ef01b4></div></form></div>", 1)),
            _createElementVNode("ul", _hoisted_8, [
              _cache[10] || (_cache[10] = _createElementVNode("li", { class: "nav-item toggle-nav-search hidden-caret" }, [
                _createElementVNode("a", {
                  class: "nav-link",
                  "data-toggle": "collapse",
                  href: "#search-nav",
                  role: "button",
                  "aria-expanded": "false",
                  "aria-controls": "search-nav"
                }, [
                  _createElementVNode("i", { class: "fa fa-search" })
                ])
              ], -1)),
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("a", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("img", {
                        src: _ctx.getPhoto(_ctx.user),
                        alt: "...",
                        class: "avatar-img rounded-circle"
                      }, null, 8, _hoisted_13)
                    ]),
                    (_ctx.adaChat)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("ul", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("li", null, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("img", {
                            src: _ctx.getPhoto(_ctx.user),
                            alt: "image profile",
                            class: "avatar-img rounded"
                          }, null, 8, _hoisted_19)
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.user.name), 1),
                          _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.user.email || _ctx.user.username), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("li", null, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "dropdown-divider" }, null, -1)),
                      _createVNode(_component_router_link, {
                        class: "dropdown-item",
                        to: "/profile"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Edit Profile ")
                        ])),
                        _: 1
                      }),
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "dropdown-divider" }, null, -1)),
                      _createVNode(_component_router_link, {
                        class: "dropdown-item",
                        to: "/inbox"
                      }, {
                        default: _withCtx(() => [
                          _cache[7] || (_cache[7] = _createTextVNode(" Inbox ")),
                          (_ctx.adaChat)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_22))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isAndroid)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23))
                        : _createCommentVNode("", true),
                      (!_ctx.isAndroid)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
                            class: "dropdown-item"
                          }, "Logout"))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}