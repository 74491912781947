<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Edit Assesment / Assignment</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Guru Pembuat <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.teacher_id" :options="refTeacher" :searchable="true" :can-clear="false" :can-deselect="false"/>
                                        <span class="text-danger" v-if="validation.teacher">{{ validation.teacher }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Subject <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.subject_id" :options="refSubject" :searchable="true" :can-clear="false" :can-deselect="false"/>
                                        <span class="text-danger" v-if="validation.subject">{{ validation.subject }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Judul Tugas <b class="text-danger">*</b></label>
                                        <input type="text" v-model="request.title" class="form-control" id="judul-tugas"/>
                                        <span class="text-danger" v-if="validation.title">{{ validation.title }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Tipe Tugas <b class="text-danger">*</b></label>
                                        <Multiselect v-model="request.type" :options="refType" :searchable="true" :can-clear="false" :can-deselect="false"/>
                                        <span class="text-danger" v-if="validation.type">{{ validation.type }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Kelas <b class="text-danger">*</b></label>
                                        <Multiselect
                                            mode="tags"
                                            v-model="request.grade_options"
                                            :options="refGrade"
                                            :searchable="true"
                                            :multiple="true"
                                            @change="on_grade_change"
                                        />
                                        <span class="text-danger" v-if="validation.grade">{{ validation.grade }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Siswa <b class="text-danger">*</b></label>
                                        <Multiselect
                                            v-model="request.student_options"
                                            mode="multiple"
                                            :groups="true"
                                            :searchable="true"
                                            :hide-selected="false"
                                            :create-option="false"
                                            :close-on-select="false"
                                            :group-select="false"
                                            :options="refStudent"
                                            :no-options-text="request.grade_options?.length ? 'Tidak ada siswa di kelas terpilih' : 'Pilih kelas dulu'"
                                            @clear="on_student_clear"
                                        >
                                            <template v-slot:grouplabel="{ group, isPointed, isSelected }">
                                                <div class="d-flex" style="width: 100%; padding-top: 10px; padding-bottom: 10px">
                                                    <span>{{ group.label }} {{ group.value }}</span>
                                                    <div style="flex: 1"></div>
                                                    <span style="font-weight: 400; cursor: pointer" @click="toggle_select_all(group.value, group.student_complete)" class="text-success">
                                                        {{ group.student_complete ? 'Hapus semua' : 'Pilih semua' }}
                                                    </span>
                                                </div>
                                            </template>
                                            <template v-slot:option="{ option, isSelected }">
                                                <input type="checkbox" :checked="isSelected(option)"> <span class="ml-1">{{ option.label }}</span>
                                            </template>
                                            <template v-slot:multiplelabel="{ values }">
                                                <div class="multiselect-multiple-label" :style="`white-space: nowrap; overflow: hidden; width: ${width - 60}px; text-overflow: ellipsis;`">
                                                    {{ values.length }} student{{ values.length > 1 ? 's' : '' }}
                                                </div>
                                            </template>
                                        </Multiselect>
                                        <span class="text-danger" v-if="validation.student">{{ validation.student }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Tanggal & Waktu Pengerjaan <b class="text-danger">*</b></label>
                                        <input type="datetime-local" v-model="request.completion_date" class="form-control"/>
                                        <span class="text-danger" v-if="validation.completion_date">{{ validation.completion_date }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Tanggal & Waktu Pengumpulan <b class="text-danger">*</b></label>
                                        <input type="datetime-local" v-model="request.collection_date" class="form-control"/>
                                        <span class="text-danger" v-if="validation.collection_date">{{ validation.collection_date }}</span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Unggah Soal <b class="text-danger">*</b></label>
                                        <div class="form-control">
                                            <div v-for="v in request.questions.filter(v => v.deleted !== true)"
                                                 class="form-control mb-2 d-flex align-items-center"
                                                 style="padding: 0.6rem"
                                            >
                                                <span style="flex: 1; min-width: 0">
                                                    <a v-if="v.url" :href="v.url ?? ''" target="_blank">{{ v.file_name }}</a>
                                                    <span v-else>{{ v.file?.name }}</span>
                                                </span>
                                                <button class="btn btn-sm btn-danger ml-1" @click="() => v.deleted = true">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </div>
                                            <input type="file"
                                                   ref="question_file"
                                                   id="question_file"
                                                   accept="image/*, .mp3, video/mp4, video/x-m4v, video/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .zip, .rar, .7zip"
                                                   @change="question_change"
                                                   style="display: none">
                                            <div class="d-flex flex-row-reverse">
                                                <button type="button" @click="add_question" class="btn btn-sm btn-success">
                                                    <i class="fa fa-plus"></i> Tambah File
                                                </button>
                                            </div>
                                        </div>
                                        <p class="mt-1 mb-0">Tipe file yang diizinkan adalah .mp3, .mp4, .pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .xlsx, .xls, .rar, .zip, .ppt dengan maksimal 5 MB.</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Link File</label>
                                        <input type="text" v-model="request.link" class="form-control"/>
                                    </div>
                                    <div class="form-group">
                                        <label>Deskripsi</label>
                                        <textarea class="form-control" rows="6" v-model="request.description"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save">Simpan</button>
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {IAssignment, IOption, IStudentOption, Question} from "@/utils/interface/Assignment";
import {Kelas, Pelajaran, Siswa, User} from "@/utils/Models";
import Api from "@/utils/Api";
import Session from "@/utils/Session";
import Multiselect from "@vueform/multiselect";
import {error, loading, success} from "@/utils/Toast";

export default defineComponent({
    name: "UpdateAssignment",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id

        const width = ref<number|undefined>(undefined)
        const refTeacher = ref<IOption[]>([])
        const allStudent = ref<Siswa[]>([])
        const refStudent = ref<IStudentOption[]>([])
        const refGrade = ref<IOption[]>([])
        const refSubject = ref<IOption[]>([])
        const refType = ref<IOption[]>([
            { value: 'Assignment', label: 'Assignment' },
            { value: 'Formative Assessment', label: 'Formative Assessment' },
            { value: 'Mid-Semester Assignment', label: 'Mid-Semester Assignment' },
            { value: 'End Assignment', label: 'End Assignment' },
        ])
        const request = reactive<IAssignment>({
            teacher_id: 0,
            subject_id: 0,
            title: '',
            grades: [],
            grade_options: [],
            students: [],
            student_options: [],
            completion_date: '',
            collection_date: '',
            questions: [],
            link: '',
            description: '',
        })
        const validation = reactive({
            teacher: '',
            subject: '',
            title: '',
            type: '',
            grade: '',
            student: '',
            completion_date: '',
            collection_date: '',
        })

        onMounted(async () => {
            width.value = document.getElementById('judul-tugas')?.clientWidth

            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            const r2 = await Api.get(`assignment/${id}`)
            t.remove()

            if (r.success) {
                allStudent.value = r.data.siswa as Siswa[]
                refTeacher.value = (r.data.pic as User[]).map(v => ({value: v.id, label: v.name}))
                refGrade.value = (r.data.grade as Kelas[]).map(v => ({value: v.id, label: v.nama_kelas}))
                refSubject.value = (r.data.mapel as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
            }
            else {
                error(r.message ?? 'gagal')
            }

            if (r2.success) {
                const a = r2.data as IAssignment

                request.teacher_id = a.teacher_id
                request.subject_id = a.subject_id
                request.title = a.title
                request.type = a.type ?? 'Assignment'
                request.grades = a.grades
                request.grade_options = a.grades.map(v => v.id)
                request.students = a.students
                request.student_options = a.students.map(v => v.id)
                request.completion_date = a.completion_date
                request.collection_date = a.collection_date
                request.link = a.link ?? ''
                request.description = a.description ?? ''
                request.questions = a.questions

                refStudent.value = a.grades
                    .map(g => {
                        const options = allStudent.value
                            // .filter(st => g.id === st.trx_class_id)
                            .filter(st => st.student_class.find(x => x.trx_class?.id === g.id))
                            .map(v => ({value: v.id, label: v.nama}))
                        const selected_students_in_this_grade = request.students
                            // .filter(st => g.id === st.trx_class_id)
                            .filter(st => st.student_class.find(x => x.trx_class?.id === g.id))
                            .map(v => v.id)
                        return {
                            value: g.id,
                            label: g.nama_kelas,
                            options: options,
                            student_count: selected_students_in_this_grade.length,
                            student_complete: selected_students_in_this_grade.length === options.length
                        }
                    })
            }
        })

        const validate = () => {
            validation.teacher = ''
            validation.subject = ''
            validation.title = ''
            validation.type = ''
            validation.grade = ''
            validation.student = ''
            validation.completion_date = ''
            validation.collection_date = ''

            if (!request.teacher_id) {
                validation.teacher = 'Pilih guru dulu'
                return false
            }
            if (!request.subject_id) {
                validation.subject = 'Pilih subject dulu'
                return false
            }
            if (!request.title) {
                validation.title = 'Masukkan judul tugas dulu'
                return false
            }
            if (!request.type) {
                validation.type = 'Pilih tipe dulu'
                return false
            }
            if (!request.grade_options.length) {
                validation.grade = 'Pilih kelas dulu'
                return false
            }
            if (!request.student_options.length) {
                validation.student = 'Pilih siswa dulu'
                return false
            }
            if (!request.completion_date) {
                validation.completion_date = 'Pilih tanggal dulu'
                return false
            }
            if (!request.collection_date) {
                validation.collection_date = 'Pilih tanggal dulu'
                return false
            }
            return true
        }
        const save = async () => {
            if (!validate()) {
                return
            }

            let data = new FormData()
            request.questions.forEach(v => {
                if (v.file) {
                    data.append('questions[]', v.file)
                }
                else if (v.deleted) {
                    data.append('deleted[]', v.file_name ?? '')
                }
            })
            request.grade_options.forEach(v => data.append('grades[]', `${v}`))
            request.student_options.forEach(v => data.append('students[]', `${v}`))

            data.append('teacher_id', `${request.teacher_id}`)
            data.append('subject_id', `${request.subject_id}`)
            data.append('title', `${request.title}`)
            data.append('type', `${request.type}`)
            data.append('completion_date', `${request.completion_date}`)
            data.append('collection_date', `${request.collection_date}`)
            data.append('link', `${request.link ?? ''}`)
            data.append('description', `${request.description ?? ''}`)
            data.append('created_by', `${Session.getCurrentUser()!.id}`)

            const t = loading('Menyimpan data')
            const r = await Api.post(`assignment/update/${id}`, data, { 'Content-Type': 'multipart/form-data' })
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        const remove_question = (id: number) => request.questions = request.questions.filter(v => v.id !== id)
        const question_change = (event: any) => request.questions.push({
            id: Math.floor(Math.random() * 10000) + 1,
            file: event.target.files[0],
            type: event.target.files[0].name.split('.').pop(),
        })
        const add_question = () => document.getElementById('question_file')?.click()
        const on_grade_change = (e: any) => {
            refStudent.value = refGrade.value
                .filter(v => e.find((id: number) => id === v.value))
                .map(v => {
                    const options = allStudent.value
                        // .filter(student => student.trx_class_id === v.value)
                        .filter(student => student.student_class.find(x => x.trx_class?.id === v.value))
                        .map(student => ({value: student.id, label: student.nama}))
                    const selected_students_in_this_grade = request.student_options
                        .map(v => allStudent.value.find(s => s.id === v)!)
                        // .filter(st => st.trx_class_id === v.value)
                        .filter(st => st.student_class.find(x => x.trx_class?.id === v.value))
                        .map(v => v.id)
                    return {
                        ...v,
                        options: options,
                        student_count: selected_students_in_this_grade.length,
                        student_complete: selected_students_in_this_grade.length === options.length
                    }
                })
                .filter(v => v.options.length)

            request.student_options = request.student_options
                .map(v => allStudent.value.find(s => s.id === v)!)
                // .filter(v => e.find((id: number) => id === v.trx_class_id))
                .filter(v => v.student_class.find(x => e.find((id: number) => id === x.trx_class?.id)))
                .map(v => v.id)
        }
        const toggle_select_all = (grade_id: number, has_been_complete: boolean) => {
            if (has_been_complete) {
                request.student_options = request.student_options
                    .map(v => allStudent.value.find(s => s.id === v)!)
                    // .filter(v => v.trx_class_id !== grade_id)
                    .filter(v => !v.student_class.find(x => x.trx_class?.id === grade_id))
                    .map(v => v.id)
            }
            else {
                request.student_options = [
                    ...request.student_options
                        .map(v => allStudent.value.find(s => s.id === v)!)
                        // .filter(v => v.trx_class_id !== grade_id)
                        .filter(v => !v.student_class.find(x => x.trx_class?.id === grade_id))
                        .map(v => v.id),
                    ...allStudent.value
                        // .filter(v => v.trx_class_id === grade_id)
                        .filter(v => v.student_class.find(x => x.trx_class?.id === grade_id))
                        .map(v => v.id)
                ]
            }
            refStudent.value = refStudent.value
                .map(v => {
                    const options = allStudent.value
                        // .filter(student => student.trx_class_id === v.value)
                        .filter(student => student.student_class.find(x => x.trx_class?.id === v.value))
                        .map(student => ({value: student.id, label: student.nama}))
                    const selected_students_in_this_grade = request.student_options
                        .map(v => allStudent.value.find(s => s.id === v)!)
                        // .filter(st => st.trx_class_id === v.value)
                        .filter(student => student.student_class.find(x => x.trx_class?.id === v.value))
                        .map(v => v.id)
                    return {
                        ...v,
                        options: options,
                        student_count: selected_students_in_this_grade.length,
                        student_complete: selected_students_in_this_grade.length === options.length
                    }
                })
        }
        const on_student_clear = () => {
            refStudent.value = refStudent.value
                .map(v => {
                    const options = allStudent.value
                        // .filter(student => student.trx_class_id === v.value)
                        .filter(student => student.student_class.find(x => x.trx_class?.id === v.value))
                        .map(student => ({value: student.id, label: student.nama}))
                    return {
                        ...v,
                        options: options,
                        student_count: 0,
                        student_complete: false
                    }
                })
        }

        return {
            width, request, refTeacher, refStudent, refGrade, refSubject, refType,
            add_question, question_change, remove_question,
            on_grade_change, toggle_select_all, on_student_clear,
            validation, save,
            back: () => router.back()
        }
    }
})
</script>

<style scoped>

</style>