<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card timeline-item" v-if="refChildren.length > 1">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Anak</label>
                                <select class="form-control" @change="(e) => reload(+e.target.value, true)">
                                    <option v-for="v in refChildren" :value="v.value">{{v.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="!progress.length || !done" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 300px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada data</span>
                        <span style="font-style: italic" v-else>Mendapatkan data</span>
                    </div>
                    <ProgressItem v-for="(v, k) in progress" :progress="v" @commentChange="commentChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {IProgress, IProgressComment} from "@/utils/interface/Progress";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import Session from "@/utils/Session";
import {IOption} from "@/utils/interface/Assignment";
import {Siswa} from "@/utils/Models";
import ProgressItem from "@/components/parent/partials/ProgressItem.vue";

export default defineComponent({
    name: "Development",
    components: {ProgressItem},
    setup() {
        const user = Session.getCurrentUser()!
        const progress = ref<IProgress[]>([])
        const refChildren = ref<IOption[]>([])
        const child = ref<IOption|null>(null)
        const done = ref(false)

        const reload = async (id: number, withLoading: boolean = false) => {
            done.value = false
            progress.value = []
            let t: any = null
            if (withLoading)
                t = loading('Mendapatkan data')

            const r2 = await Api.post(`parent/progress/${id}`)

            if (r2.success) {
                progress.value = r2.data as IProgress[]
            }
            else {
                error(r2.message ?? 'gagal')
            }

            t?.remove()
            done.value = true
        }

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`parent/children/${user.connection_id}`)

            refChildren.value = (r.data as Siswa[]).map(v => ({value: v.id, label: v.nama}))
            if (refChildren.value.length) {
                child.value = refChildren.value[0]

                await reload(child.value.value, false)
            }

            done.value = true
            t.remove()
        })

        const commentChange = (p: IProgress, c: IProgressComment[]) => {
            for (let v of progress.value) {
                if (v.id === p.id) {
                    v.comments = c
                }
            }
        }

        return {
            progress, refChildren, child, commentChange, done, reload
        }
    }
})
</script>

<style scoped>
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>