<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0">
                        <div class="card-header" >
                            <div class="card-head-row">
                                <div class="card-title">Inbox</div>
                                <div class="card-tools">
<!--                                    <button class="btn btn-success btn-round mr-2" @click="reload">-->
<!--                                        <i class="fa fa-history"></i>-->
<!--                                    </button>-->
                                    <button
                                        v-if="user.role_id === 54"
                                        @click="openNewChat"
                                        class="b-time btn btn-success btn-round mr-2"
                                    >
                                        Start New Chat
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0" style="height: calc(100vh - 257px)">
                            <div style="width: 100%; height: 100%">
                                <InboxDesktop
                                    v-if="!isMobile()"
                                    :chosen-person="chosenPerson"
                                    :load="load"
                                    @clear-chosen-person="clearChosenPerson"
                                />
                                <InboxMobile
                                    v-else
                                    :chosen-person="chosenPerson"
                                    :load="load"
                                    :load-person="loadPerson"
                                    @clear-chosen-person="clearChosenPerson"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal
        title="Search Teacher"
        :remove-body-padding="true"
        :no-footer="true"
        :show="showModalStart"
        @close="showModalStart = false"
    >
        <template #body>
            <div class="d-flex flex-column">
                <div v-for="v in refTeacher"
                     class="d-flex align-items-center py-2 px-3 t-item"
                     @click="choose(v)"
                >
                    <div class="avatar-sm mr-2">
                        <img :src="getPhoto(v)" alt="" class="avatar-img rounded-circle">
                    </div>
                    {{ v.name }}
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import InboxDesktop from "@/components/inbox/InboxDesktop.vue";
import {getPhoto, isMobile} from "@/utils/Helpers";
import InboxMobile from "@/components/inbox/InboxMobile.vue";
import {User} from "@/utils/Models";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import Modal from "@/components/partials/Modal.vue";
import Session from "@/utils/Session";

export default defineComponent({
    name: "Inbox",
    components: {Modal, InboxMobile, InboxDesktop},
    setup() {
        const user = Session.getCurrentUser()!
        const refTeacher = ref<User[]>([])
        const showModalStart = ref(false)
        const chosenPerson = ref<User|null>(null)
        const load = ref(0)
        const loadPerson = ref(0)

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            t.remove()

            if (r.success) {
                refTeacher.value = r.data.pic as User[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const choose = (v: User) => {
            chosenPerson.value = v
            showModalStart.value = false
            loadPerson.value++
        }

        const openNewChat = () => {
            chosenPerson.value = null
            showModalStart.value = true
        }

        return {
            isMobile, refTeacher, showModalStart, getPhoto, choose, chosenPerson, openNewChat, user,
            clearChosenPerson: () => chosenPerson.value = null,
            reload: () => load.value++, load, loadPerson
        }
    }
})
</script>

<style scoped>
.t-item {
    cursor: pointer;
}
.t-item:hover {
    background: #eeeeee;
}
@media screen and (max-width: 476px) {
    .card .card-header .card-head-row:not(.card-tools-still-right) {
         flex-direction: row;
         align-items: center;
    }
}
@media screen and (max-width: 476px) {
    .card .card-header .card-head-row:not(.card-tools-still-right) .card-tools {
        margin-left: auto;
        padding-top: 0;
    }
}
</style>