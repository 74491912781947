<template>
    <div class="row">
        <div class="col-12">
            <div class="card pointer" @click="modalTeacherShow = true">
                <div class="card-body d-flex align-items-center">
                    <img alt="profil" src="https://dn0vai85ainxm.cloudfront.net/assets/pijar-sekolah-v3/images/il_profil_sekolah.svg">
                    <div class="d-flex flex-column ml-3 mr-2" style="flex: 1;">
                        <h4 class="fw-bold mb-0" style="color: #2BB930">Teacher Attendance</h4>
                        <i class="text-danger" v-if="done && !dailyJournal">Has not been updated today</i>
                    </div>
                    <i class="fa fa-chevron-right fw-bold" style="font-size: 18px; color: #2BB930"></i>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card pointer" @click="modalStudentShow = true">
                <div class="card-body d-flex align-items-center">
                    <img alt="profil" src="https://dn0vai85ainxm.cloudfront.net/assets/pijar-sekolah-v3/images/il_profil_sekolah.svg">
                    <div class="d-flex flex-column ml-3 mr-2" style="flex: 1;">
                        <h4 class="fw-bold mb-0" style="color: #2BB930">Student Attendance</h4>
                        <i class="text-danger" v-if="done && !dailyJournal">Has not been updated today</i>
                    </div>
                    <i class="fa fa-chevron-right fw-bold" style="font-size: 18px; color: #2BB930"></i>
                </div>
            </div>
        </div>
    </div>
    <Modal :show="modalTeacherShow" @close="modalTeacherShow = false" title="Teacher Attendance Today">
        <template #body>
            <div v-if="done && !dailyJournal">
                <i class="fw-bold">No Data</i>
            </div>
            <div v-else>
                <h4 class="fw-bold">Present ({{ teachers.length - dailyJournal.teacher.length }})</h4>
                <ul>
                    <li v-for="v in teachers.filter(x => !dailyJournal.teacher.find(t => t.person_id === x.id))">
                        {{ capitalize(v.name) }}
                    </li>
                </ul>
                <h4 class="fw-bold">Absent ({{ dailyJournal.teacher.length }})</h4>
                <ul>
                    <li v-for="v in dailyJournal.teacher">{{ capitalize(v.name) }}</li>
                </ul>
            </div>
        </template>
    </Modal>
    <Modal :show="modalStudentShow" @close="modalStudentShow = false" title="Student Attendance Today">
        <template #body>
            <div v-if="done && !dailyJournal">
                <i class="fw-bold">No Data</i>
            </div>
            <div v-else>
                <h4 class="fw-bold">Absent ({{ dailyJournal.student.absent.length }})</h4>
                <ul>
                    <li v-for="v in dailyJournal.student.absent">{{ capitalize(v.name) }}</li>
                </ul>
                <h4 class="fw-bold">Come Late ({{ dailyJournal.student.come_late.length }})</h4>
                <ul>
                    <li v-for="v in dailyJournal.student.come_late">{{ capitalize(v.name) }}</li>
                </ul>
                <h4 class="fw-bold">Leave Early ({{ dailyJournal.student.leave_early.length }})</h4>
                <ul>
                    <li v-for="v in dailyJournal.student.leave_early">{{ capitalize(v.name) }}</li>
                </ul>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Modal from "@/components/partials/Modal.vue";
import DailyJournalRequest, {Teacher} from "@/utils/interface/DailyJournal";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import {capitalize} from "@/utils/Helpers";

export default defineComponent({
    name: "DashboardAttendance",
    components: {Modal},
    setup() {
        const done = ref(false)
        const modalTeacherShow = ref(false)
        const modalStudentShow = ref(false)
        const dailyJournal = ref<DailyJournalRequest|null>(null)
        const teachers = ref<Teacher[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('daily-journal/today')
            t.remove()

            if (r.success) {
                done.value = true
                dailyJournal.value = r.data.data as DailyJournalRequest
                teachers.value = r.data.teachers
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        return {
            modalTeacherShow, modalStudentShow, dailyJournal, done, teachers, capitalize
        }
    }
})
</script>

<style scoped>

</style>