import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card timeline-item" }
const _hoisted_2 = { class: "card-body pb-0" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "avatar-sm" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "d-flex flex-column ml-2",
  style: {"flex":"1"}
}
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = {
  style: {"flex":"1"},
  class: "fw-bold"
}
const _hoisted_9 = {
  key: 0,
  class: "badge badge-primary text-white"
}
const _hoisted_10 = {
  class: "text-muted",
  style: {"font-size":"12px"}
}
const _hoisted_11 = { class: "card-header text-success d-flex flex-column" }
const _hoisted_12 = {
  class: "d-flex align-items-center",
  style: {"flex":"1"}
}
const _hoisted_13 = { class: "ml-2" }
const _hoisted_14 = {
  class: "d-flex align-items-center",
  style: {"flex":"1","margin-top":"4px"}
}
const _hoisted_15 = { class: "card-header" }
const _hoisted_16 = { style: {"font-size":"16px"} }
const _hoisted_17 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_18 = { style: {"font-size":"16px"} }
const _hoisted_19 = { class: "ctr" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "avatar-img rounded-circle",
            src: _ctx.getPhoto(_ctx.attendance.classroom_journal.teacher_photo)
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.attendance.classroom_journal.teacher_name ?? '-'), 1),
            (_ctx.showTag)
              ? (_openBlock(), _createElementBlock("label", _hoisted_9, "Classroom"))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.attendance.created_at)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-book" }, null, -1)),
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.attendance.classroom_journal.subject_name) + " (" + _toDisplayString(_ctx.toIndoDateName(_ctx.attendance.classroom_journal.date)) + " " + _toDisplayString(_ctx.attendance.classroom_journal.lesson_hours) + ")", 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-user-graduate" }, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "ml-2" }, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.attendance.student_name), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _cache[15] || (_cache[15] = _createElementVNode("b", null, "Status:", -1)),
      _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.attendanceStatus(_ctx.attendance.status)), 1),
      (_ctx.attendance.note)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _cache[4] || (_cache[4] = _createElementVNode("b", null, "Note:", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.attendance.note), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_19, [
        (_ctx.expanded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _cache[6] || (_cache[6] = _createElementVNode("b", null, "Materi Pembelajaran", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.attendance.classroom_journal?.materi_pembelajaran), 1),
              _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "my-2" }, null, -1)),
              _cache[9] || (_cache[9] = _createElementVNode("b", null, "Kegiatan Pembelajaran", -1)),
              _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.attendance.classroom_journal?.kegiatan_pembelajaran), 1),
              _cache[11] || (_cache[11] = _createElementVNode("hr", { class: "my-2" }, null, -1)),
              _cache[12] || (_cache[12] = _createElementVNode("b", null, "Catatan", -1)),
              _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.attendance.classroom_journal?.catatan), 1),
              _cache[14] || (_cache[14] = _createElementVNode("hr", { class: "my-2" }, null, -1))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("i", {
            onClick: _cache[0] || (_cache[0] = () => _ctx.expanded = !_ctx.expanded),
            class: "toggle"
          }, _toDisplayString(_ctx.expanded ? 'Hide Details' : 'Show Details'), 1)
        ])
      ])
    ])
  ]))
}