<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <Nav :index="index" :tabs="tabs.map(v => v.title)" @changeIndex="onIndexChange"/>
                            <div class="divider"/>
                            <div class="tab-content mt-4">
                                <component :is="{...tabs[index].component}"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {Component, defineComponent, ref, markRaw} from "vue"
import Nav from "@/components/partials/Nav.vue"
import TabGuru from "./TabGuru.vue"
import TabKelas from "./TabKelas.vue"
import TabMataPelajaran from "./TabMataPelajaran.vue"
import TabOrangTua from "./TabOrangTua.vue"
import TabProfilSekolah from "./TabProfilSekolah.vue"
import TabSiswa from "./TabSiswa.vue"
import {getMasterIndex, setMasterIndex} from "@/utils/Helpers";

type TabType = {
    title: string,
    component: Component
}

export default defineComponent({
    name: "MasterData",
    components: {
        Nav
    },
    setup() {
        const index = ref(getMasterIndex())
        const tabs = markRaw<TabType[]>([
            { title: 'Profil Sekolah', component: TabProfilSekolah },
            { title: 'Subject', component: TabMataPelajaran },
            { title: 'Data Guru', component: TabGuru },
            { title: 'Data Kelas', component: TabKelas },
            { title: 'Data Siswa', component: TabSiswa },
            { title: 'Data Orang Tua', component: TabOrangTua },
        ])

        const onIndexChange = (i: number) => {
            index.value = i
            setMasterIndex(i)
        }

        return { index, tabs, onIndexChange }
    },
})
</script>

<style scoped>
.divider {
    height: 1px;
    background-color: #f3f3f3;
}
</style>
