<template>

</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "StudentList"
})
</script>

<style scoped>

</style>