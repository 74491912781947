<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="d-flex align-items-end">
                <button class="btn btn-success mb-2" @click="add"><i class="fa fa-plus"></i> Tambah</button>
            </div>
			<PaginatedTable :data="guru" class="mt-2">
				<template #thead>
					<th>No</th>
					<th>Nama Guru</th>
					<th>Tipe</th>
					<th>Jenis Kelamin</th>
					<th>Tanggal Lahir</th>
					<th>Username</th>
					<th>Subject</th>
					<th>Aksi</th>
				</template>
				<template #tbody="{row, index}">
					<td>{{ index + 1 }}</td>
					<td>
						<div class="d-flex align-items-center">
							<div class="avatar-sm">
								<img :src="getPhoto(row.data)" alt="..." class="avatar-img rounded-circle">
							</div>
							<span class="ml-2">{{ row.data.name }}</span>
						</div>
					</td>
					<td>{{ row.data.role_name }}</td>
					<td>{{ row.data.gender === 'L' ? 'Laki-Laki' : 'Perempuan' }}</td>
					<td>{{ toIndoDateName(row.data.birth_date) }}</td>
					<td>{{ row.data.username }}</td>
					<td><div v-for="p in row.data.pelajaran"><span>{{ p.nama }}</span></div></td>
					<td>
						<button class="btn btn-sm btn-success mr-1" @click="detail(row.data.id)"><i class="fa fa-eye"></i> </button>
						<button class="btn btn-sm btn-warning mr-1" @click="edit(row.data.id)"><i class="fa fa-pencil"></i> </button>
						<button class="btn btn-sm btn-danger" @click="destroy(row.data.id)"><i class="fa fa-trash"></i> </button>
					</td>
				</template>
			</PaginatedTable>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {User} from "@/utils/Models";
import Api from "@/utils/Api";
import {toIndoDateName} from "@/utils/Helpers";
import {getPhoto} from '@/utils/Helpers'
import {error, loading, success} from "@/utils/Toast";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "TabGuru",
	components: { PaginatedTable },
    setup() {
        const router = useRouter()
        const guru = ref<User[]>([])

        const reload = async () => {
            const res = await Api.get('master/guru')
            if (res.success) {
                guru.value = res.data as User[]
            }
            else {
                error(res.message ?? 'Gagal medapatkan data')
            }
        }

        onMounted(async () => {
            await reload()
        })

        const add = () => router.push('/master-data/add-guru')
        const edit = (id: number) => router.push(`/master-data/edit-guru/${id}`)
        const detail = (id: number) => router.push(`/master-data/detail-guru/${id}`)
        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.delete(`master/guru/destroy/${id}`)
                t.remove()

                if (r.success) {
                    success('Berhasil menghapus data')
                    await reload()
                }
                else {
                    error(r.message ?? 'Gagal')
                }
            }
        }

        return { guru, getPhoto, add, destroy, edit, detail, toIndoDateName }
    }
})
</script>

<style scoped>

</style>
