<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="profile-picture mb-1">
                                <div class="avatar avatar-xl">
                                    <img :src="photo_url ?? getPhoto(user)" alt="..." class="avatar-img rounded-circle" id="photo-display">
                                </div>
                                <input type="file" ref="photo_file" id="photo_file" accept="image/*" @change="image_change" style="display: none">
                                <button type="button" @click="choose_photo" class="btn btn-round btn-sm btn-outline-success ml-2">Pilih Photo</button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Nama</label>
                                        <input type="text" class="form-control" placeholder="Masukkan Nama" name="name" v-model="request.name" required>
                                        <span class="text-danger" v-if="validation.name">{{validation.name}}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Username</label>
                                        <input type="text" class="form-control" placeholder="Masukkan Username" name="username" v-model="request.username" required>
                                        <span class="text-danger" v-if="validation.username">{{validation.username}}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input type="text" class="form-control" placeholder="Masukkan Password" name="password" v-model="request.password">
                                        <span class="text-danger" v-if="validation.password">{{validation.password}}</span>
                                        <small id="emailHelp2" class="form-text text-muted">Isi password hanya jika ingin mengubahnya. Jika tidak ingin mengubahnya, biarkan kosong aja ya kak :)</small>
                                    </div>
                                </div>
                                <div class="col-sm-12" v-if="+user.role_id === Role.Kepala || +user.role_id === Role.Guru || +user.role_id === Role.Musrif">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="email" class="form-control" placeholder="Masukkan Email" v-model="request.email">
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label>NIP</label>
                                                <input type="email" class="form-control" placeholder="Masukkan NIP" v-model="request.nip">
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label>NIK</label>
                                                <input type="text" class="form-control" v-model="request.nik" placeholder="Masukkan NIK">
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label>Tempat Lahir</label>
                                                <input type="text" class="form-control" v-model="request.birth_place" placeholder="Masukkan Tempat Lahir" required>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label>Tanggal Lahir</label>
                                                <input type="date" class="form-control" v-model="request.birth_date" placeholder="Masukkan Tanggal Lahir" required>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-check">
                                                <label>Jenis Kelamin</label><br>
                                                <label class="form-radio-label">
                                                    <input class="form-radio-input" type="radio" value="L" v-model="request.gender">
                                                    <span class="form-radio-sign">Laki-Laki</span>
                                                </label>
                                                <label class="form-radio-label ml-3">
                                                    <input class="form-radio-input" type="radio" value="P" v-model="request.gender">
                                                    <span class="form-radio-sign">Perempuan</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label>Alamat</label>
                                                <textarea class="form-control" v-model="request.address" rows="4" placeholder="Masukkan Alamat Lengkap"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label>Nomor Telepon</label>
                                                <input type="text" class="form-control" v-model="request.phone" placeholder="Masukkan Nomor Telepon" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        style="margin-top: 36px"
                                        @click="save"
                                    >
                                        <i class="fas fa-save"></i> Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, reactive, ref} from "vue";
import Session from "@/utils/Session";
import {file2Base64, getPhoto} from "@/utils/Helpers";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {User} from "@/utils/Models";
import Role from "@/utils/Role";

export default defineComponent({
    name: "Profile",
    setup() {
        const user = Session.getCurrentUser()!
        const photo_file = ref(null)
        const photo_url = ref<string|null>(null)
        const photo_b64 = ref<string|null>(null)
        const request = reactive({
            name: user.name,
            username: user.username,
            password: '',
            email: user.email,
            nip: user.nip,
            birth_place: user.birth_place,
            birth_date: user.birth_date,
            gender: user.gender,
            nik: user.nik,
            address: user.address,
            phone: user.phone,
        })
        const validation = reactive({
            name: '',
            username: '',
        })

        const choose_photo = () => {
            let fileUpload = document.getElementById('photo_file')
            if (fileUpload != null) {
                fileUpload.click()
            }
        }
        const image_change = async (event: any) => {
            const file = event.target.files[0]
            if (file.size > 1000000) {
                error('Maksimal ukuran gambar 1 MB')
                return
            }

            photo_url.value = URL.createObjectURL(file)
            photo_b64.value = await file2Base64(file)
        }
        const valid = () => {
            validation.name = ''
            validation.username = ''

            if (!request.name) {
                validation.name = 'Masukkan nama'
                return false
            }
            if (!request.username) {
                validation.username = 'Masukkan username'
                return false
            }
            return true
        }
        const save = async () => {
            if (!valid()) {
                return
            }

            const t = loading('Mengedit profile')
            const r = await Api.post('profile/update', {
                id: user.id,
                ...request,
                photo_file: photo_b64.value
            })

            t.remove()
            if (r.success) {
                success('Berhasil edit profile')
                Session.setCurrentUser(r.data as User)
                if (request.password) {
                    Session.setPassword(request.password)
                    request.password = ''
                }
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        return {
            user, getPhoto, request, photo_file, photo_url, photo_b64,
            choose_photo, image_change, save, validation, Role
        }
    }
})
</script>

<style scoped>

</style>