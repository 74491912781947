import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-6 col-lg-4" }
const _hoisted_9 = { class: "col-sm-12 col-md-6 col-lg-4" }
const _hoisted_10 = { class: "col-sm-12 col-md-6 col-lg-4" }
const _hoisted_11 = { class: "card-footer d-flex flex-row-reverse" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-md-12" }
const _hoisted_14 = { class: "card" }
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = { class: "table-responsive" }
const _hoisted_17 = { class: "table table-bordered" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = ["id", "onChange"]
const _hoisted_20 = { class: "d-flex flex-row align-items-center" }
const _hoisted_21 = { style: {"flex":"1"} }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = ["href"]
const _hoisted_24 = ["onClick", "disabled"]
const _hoisted_25 = {
  key: 0,
  class: "badge badge-danger"
}
const _hoisted_26 = {
  key: 1,
  class: "badge badge-success"
}
const _hoisted_27 = ["disabled", "onClick"]
const _hoisted_28 = { class: "card-footer d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Detail Master Report")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[2] || (_cache[2] = _createElementVNode("b", null, "Angkatan", -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.report.angkatan), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _cache[3] || (_cache[3] = _createElementVNode("b", null, "Kelas", -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.report.nama_kelas), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[4] || (_cache[4] = _createElementVNode("b", null, "Semester", -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.report.semester_name), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn btn-danger",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Student Report")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("table", _hoisted_17, [
                  _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "No"),
                      _createElementVNode("th", null, "Siswa"),
                      _createElementVNode("th", null, "Rapot"),
                      _createElementVNode("th", null, "Terupload"),
                      _createElementVNode("th", null, "Aksi")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.master_report_student, (v, k) => {
                      return (_openBlock(), _createElementBlock("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                        _createElementVNode("td", null, _toDisplayString(v.student_name), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["form-control", {'form-control-saving': v.saving}])
                            }, [
                              _createElementVNode("input", {
                                type: "file",
                                ref_for: true,
                                ref: "question_file",
                                id: `question_file_${k}`,
                                accept: "application/pdf",
                                onChange: (e) => _ctx.attachment_change(e, v),
                                style: {"display":"none"}
                              }, null, 40, _hoisted_19),
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, [
                                  (v.report_file)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(v.report_file.name), 1))
                                    : (v.report?.name)
                                      ? (_openBlock(), _createElementBlock("a", {
                                          key: 1,
                                          href: v.report?.url,
                                          target: "_blank"
                                        }, _toDisplayString(v.report?.name), 9, _hoisted_23))
                                      : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("button", {
                                  type: "button",
                                  onClick: ($event: any) => (_ctx.add_attachment(k)),
                                  class: "btn btn-sm btn-success ml-1",
                                  disabled: v.saving
                                }, _toDisplayString(v.saving ? 'Menyimpan' : (v.report_file || v.report?.name) ? 'Ganti' : 'Pilih') + " Raport ", 9, _hoisted_24)
                              ])
                            ], 2)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          (!v.report?.name)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_25, "Belum"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_26, "Sudah"))
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("button", {
                            class: "btn btn-success btn-sm",
                            disabled: v.saving,
                            onClick: ($event: any) => (_ctx.save(v, k))
                          }, _cache[6] || (_cache[6] = [
                            _createElementVNode("i", { class: "fa fa-save" }, null, -1)
                          ]), 8, _hoisted_27)
                        ])
                      ]))
                    }), 256))
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("button", {
                class: "btn btn-danger",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}