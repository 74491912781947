import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/img/bc-horizontal-new.png'
import _imports_1 from '../../assets/img/daily.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = {
  key: 0,
  id: "print",
  class: "card-body"
}
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "d-flex flex-column text-center" }
const _hoisted_9 = { class: "fw-bold mb-0" }
const _hoisted_10 = { class: "table-bordered table mt-2" }
const _hoisted_11 = { class: "table-bordered table mt-2" }
const _hoisted_12 = {
  colspan: "3",
  style: {"padding":"0 !important"}
}
const _hoisted_13 = { class: "table table-bordered mb-0 border-none" }
const _hoisted_14 = { class: "table-bordered table mt-2" }
const _hoisted_15 = {
  colspan: "4",
  style: {"padding":"0 !important"}
}
const _hoisted_16 = { class: "table table-bordered mb-0 border-none" }
const _hoisted_17 = {
  colspan: "2",
  style: {"padding":"0 !important"}
}
const _hoisted_18 = { class: "table table-bordered mb-0 border-none" }
const _hoisted_19 = {
  colspan: "2",
  style: {"padding":"0 !important"}
}
const _hoisted_20 = { class: "table table-bordered mb-0 border-none" }
const _hoisted_21 = { class: "table-responsive" }
const _hoisted_22 = { class: "table-bordered table mt-2" }
const _hoisted_23 = { class: "table-bordered table mt-2" }
const _hoisted_24 = { class: "card-footer text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.dailyJournal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"flex":"1"} }, [
                      _createElementVNode("img", {
                        src: _imports_0,
                        height: "50",
                        alt: "navbar brand",
                        class: "navbar-brand"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "fw-bold mb-0" }, "DAILY JOURNAL", -1)),
                      _createElementVNode("h4", _hoisted_9, "Academic Year " + _toDisplayString(_ctx.dailyJournal?.date.split('-')[0] ?? ''), 1)
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("div", {
                      style: {"flex":"1"},
                      class: "text-right"
                    }, [
                      _createElementVNode("img", {
                        src: _imports_1,
                        height: "50"
                      })
                    ], -1))
                  ]),
                  _createElementVNode("table", _hoisted_10, [
                    _createElementVNode("tr", null, [
                      _cache[5] || (_cache[5] = _createElementVNode("td", null, "Day :", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.dailyJournal.day), 1),
                      _cache[6] || (_cache[6] = _createElementVNode("td", null, "PIC of The Day :", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.dailyJournal.pic_name), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[7] || (_cache[7] = _createElementVNode("td", null, "Date :", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.toIndoDateName(_ctx.dailyJournal.date)), 1),
                      _cache[8] || (_cache[8] = _createElementVNode("td", null, "Lesson Hours :", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.dailyJournal.lesson_hour), 1)
                    ])
                  ]),
                  _createElementVNode("table", _hoisted_11, [
                    _cache[14] || (_cache[14] = _createElementVNode("tr", null, [
                      _createElementVNode("td", {
                        rowspan: "6",
                        style: {"vertical-align":"top !important","width":"36px"}
                      }, "I."),
                      _createElementVNode("td", { colspan: "3" }, "Teachers Attendance")
                    ], -1)),
                    _cache[15] || (_cache[15] = _createElementVNode("tr", null, [
                      _createElementVNode("td"),
                      _createElementVNode("td", { class: "text-center" }, "Total"),
                      _createElementVNode("td", { class: "text-center" }, "Note")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _cache[9] || (_cache[9] = _createElementVNode("td", null, "a. Present", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.dailyJournal.teacher_present), 1),
                      _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1))
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[11] || (_cache[11] = _createElementVNode("td", null, "b. Absent", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.dailyJournal.teacher_absent), 1),
                      _cache[12] || (_cache[12] = _createElementVNode("td", null, null, -1))
                    ]),
                    _cache[16] || (_cache[16] = _createElementVNode("tr", null, [
                      _createElementVNode("td", {
                        colspan: "3",
                        class: "text-center"
                      }, "Substitute Teacher")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_12, [
                        _createElementVNode("table", _hoisted_13, [
                          _cache[13] || (_cache[13] = _createElementVNode("tr", null, [
                            _createElementVNode("td", null, "Name"),
                            _createElementVNode("td", null, "Subject"),
                            _createElementVNode("td", null, "Grade"),
                            _createElementVNode("td", null, "Time"),
                            _createElementVNode("td", null, "Replaced By")
                          ], -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailyJournal.teacher, (v) => {
                            return (_openBlock(), _createElementBlock("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(v.name), 1),
                              _createElementVNode("td", null, _toDisplayString(v.subject_name), 1),
                              _createElementVNode("td", null, _toDisplayString(v.grade_name), 1),
                              _createElementVNode("td", null, _toDisplayString(v.time), 1),
                              _createElementVNode("td", null, _toDisplayString(v.substitute_name), 1)
                            ]))
                          }), 256))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("table", _hoisted_14, [
                    _cache[21] || (_cache[21] = _createElementVNode("tr", null, [
                      _createElementVNode("td", {
                        rowspan: "6",
                        style: {"vertical-align":"top !important","width":"36px"}
                      }, "II."),
                      _createElementVNode("td", { colspan: "4" }, "Santri Attendance")
                    ], -1)),
                    _cache[22] || (_cache[22] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { colspan: "4" }, "a. Absence")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_15, [
                        _createElementVNode("table", _hoisted_16, [
                          _cache[18] || (_cache[18] = _createElementVNode("tr", null, [
                            _createElementVNode("td", null, "Grade"),
                            _createElementVNode("td", null, "Name"),
                            _createElementVNode("td", null, "Note")
                          ], -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailyJournal.student.absent, (v) => {
                            return (_openBlock(), _createElementBlock("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(v.grade_name), 1),
                              _createElementVNode("td", null, _toDisplayString(v.name), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.attendanceStatus(v.status)), 1)
                            ]))
                          }), 256)),
                          _createElementVNode("tr", null, [
                            _cache[17] || (_cache[17] = _createElementVNode("td", {
                              colspan: "2",
                              class: "text-right"
                            }, "Total", -1)),
                            _createElementVNode("td", null, _toDisplayString(_ctx.dailyJournal.student_absent ?? _ctx.dailyJournal.student.absent.length), 1)
                          ])
                        ])
                      ])
                    ]),
                    _cache[23] || (_cache[23] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { colspan: "4" })
                    ], -1)),
                    _cache[24] || (_cache[24] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { colspan: "2" }, "b. Come late "),
                      _createElementVNode("td", { colspan: "2" }, "b. Leave early ")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_17, [
                        _createElementVNode("table", _hoisted_18, [
                          _cache[19] || (_cache[19] = _createElementVNode("tr", null, [
                            _createElementVNode("td", { class: "text-center" }, "Name"),
                            _createElementVNode("td", { class: "text-center" }, "Grade"),
                            _createElementVNode("td", { class: "text-center" }, "Note")
                          ], -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailyJournal.student.come_late, (v) => {
                            return (_openBlock(), _createElementBlock("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(v.name), 1),
                              _createElementVNode("td", null, _toDisplayString(v.grade_name), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("pre", null, _toDisplayString(v.note), 1)
                              ])
                            ]))
                          }), 256))
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_19, [
                        _createElementVNode("table", _hoisted_20, [
                          _cache[20] || (_cache[20] = _createElementVNode("tr", null, [
                            _createElementVNode("td", { class: "text-center" }, "Name"),
                            _createElementVNode("td", { class: "text-center" }, "Grade"),
                            _createElementVNode("td", { class: "text-center" }, "Note")
                          ], -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailyJournal.student.leave_early, (v) => {
                            return (_openBlock(), _createElementBlock("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(v.name), 1),
                              _createElementVNode("td", null, _toDisplayString(v.grade_name), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("pre", null, _toDisplayString(v.note), 1)
                              ])
                            ]))
                          }), 256))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("table", _hoisted_22, [
                      _cache[25] || (_cache[25] = _createElementVNode("tr", null, [
                        _createElementVNode("td", {
                          rowspan: "2",
                          style: {"width":"36px"}
                        }, "III."),
                        _createElementVNode("td", null, "Cases and Solution")
                      ], -1)),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", null, [
                          _createElementVNode("pre", null, _toDisplayString(_ctx.dailyJournal.cases), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("table", _hoisted_23, [
                    _cache[26] || (_cache[26] = _createElementVNode("tr", null, [
                      _createElementVNode("td", {
                        rowspan: "2",
                        style: {"width":"36px"}
                      }, "IV."),
                      _createElementVNode("td", null, "Evaluation")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, [
                        _createElementVNode("pre", null, _toDisplayString(_ctx.dailyJournal.evaluation), 1)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali"),
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)))
              }, "Print")
            ])
          ])
        ])
      ])
    ])
  ]))
}