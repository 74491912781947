<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="!notifications.length || !done" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 300px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada data</span>
                        <span style="font-style: italic" v-else>Mendapatkan data</span>
                    </div>
                    <NotificationItem v-for="(v, k) in notifications" :notification="v"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import NotificationItem from "@/components/parent/partials/NotificationItem.vue";
import {INotification} from "@/utils/interface/Notification";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import Session from "@/utils/Session";

export default defineComponent({
    name: "Notification",
    components: {NotificationItem},
    setup() {
        const notifications = ref<INotification[]>([])
        const done = ref(false)
        const user = Session.getCurrentUser()!

        onMounted(async () => {
            const t = loading()
            const r = await Api.get(`notification/${user.connection_id}`)
            t.remove()

            if (r.success) {
                notifications.value = r.data as INotification[]
                done.value = true
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        return {
            notifications, done
        }
    }
})
</script>

<style scoped>

</style>