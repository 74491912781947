import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/img/bc-horizontal-new.png'
import _imports_1 from '../../assets/img/daily.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = {
  key: 0,
  id: "print",
  class: "card-body"
}
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "d-flex flex-column text-center" }
const _hoisted_9 = { class: "fw-bold mb-0" }
const _hoisted_10 = { class: "fw-bold mb-0" }
const _hoisted_11 = { style: {"font-size":"16px"} }
const _hoisted_12 = { class: "table-bordered table mt-2" }
const _hoisted_13 = { class: "card-footer text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.classroomJournalGroup)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"flex":"1"} }, [
                      _createElementVNode("img", {
                        src: _imports_0,
                        height: "50",
                        alt: "navbar brand",
                        class: "navbar-brand"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "fw-bold mb-0" }, "CLASSROOM JOURNAL", -1)),
                      _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.school.name), 1),
                      _createElementVNode("h4", _hoisted_10, "Academic Year " + _toDisplayString(_ctx.classroomJournalGroup.data[0]?.academic_year ?? '-'), 1)
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("div", {
                      style: {"flex":"1"},
                      class: "text-right"
                    }, [
                      _createElementVNode("img", {
                        src: _imports_1,
                        height: "50"
                      })
                    ], -1))
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
                  _createElementVNode("b", _hoisted_11, "Date: " + _toDisplayString(_ctx.toIndoDateName(_ctx.classroomJournalGroup.date)), 1),
                  _createElementVNode("table", _hoisted_12, [
                    _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "No"),
                        _createElementVNode("th", null, "Time"),
                        _createElementVNode("th", null, "teacher"),
                        _createElementVNode("th", null, "Subject"),
                        _createElementVNode("th", null, "Topic"),
                        _createElementVNode("th", null, "Activities"),
                        _createElementVNode("th", null, "Note"),
                        _createElementVNode("th", null, "Absent Students")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classroomJournalGroup.data, (v, k) => {
                        return (_openBlock(), _createElementBlock("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                          _createElementVNode("td", null, _toDisplayString(v.lesson_hours), 1),
                          _createElementVNode("td", null, _toDisplayString(v.teacher_name), 1),
                          _createElementVNode("td", null, _toDisplayString(v.subject_name), 1),
                          _createElementVNode("td", null, _toDisplayString(v.materi_pembelajaran), 1),
                          _createElementVNode("td", null, _toDisplayString(v.kegiatan_pembelajaran), 1),
                          _createElementVNode("td", null, _toDisplayString(v.catatan), 1),
                          _createElementVNode("td", null, _toDisplayString(v.attendances.filter(x => x.status !== 'h').map(x => x.student_name).join(', ')), 1)
                        ]))
                      }), 256))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali"),
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)))
              }, "Print")
            ])
          ])
        ])
      ])
    ])
  ]))
}