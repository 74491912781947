<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="!healths.length || !done" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 300px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada data</span>
                        <span style="font-style: italic" v-else>Mendapatkan data</span>
                    </div>
                    <div v-for="v in healths" class="card timeline-item" @click="() => detail(v)">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-2">
                                <span class="faw mr-2">
                                    <i class="fa fa-user-nurse"></i>
                                </span>
                                <div class="d-flex flex-column" style="flex: 1">
                                    <i>{{toIndoDateNameTime(v.created_at)}}</i>
                                    <b>Dokter telah memeriksa {{v.student?.nama}} dengan detail sebagai berikut:</b>
                                </div>
                            </div>
                            <b class="d-block">Keluhan:</b>
                            <span class="d-block mb-2">{{v.keluhan}}</span>
                            <b class="d-block">Diagnosa:</b>
                            <span class="d-block mb-2">{{v.diagnosa}}</span>
                            <b class="d-block">Tindakan:</b>
                            <span class="d-block mb-2">{{v.tindakan}}</span>
                            <b class="d-block">Obat:</b>
                            <span class="d-block mb-2">
                                <span v-for="o in v.obat" class="d-block">
                                    <span v-if="o && o.nama">
                                        {{o.nama}} @ {{o.jumlah}} [{{o.signa}}]
                                    </span>
                                </span>
                            </span>
                            <b class="d-block">Tindak Lanjut:</b>
                            <span class="d-block mb-2">{{v.tindak_lanjut}}</span>
                            <b class="d-block">Total Biaya:</b>
                            <span class="d-block mb-2">Rp {{format(v.total_biaya)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Session from "@/utils/Session";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import {IHealth} from "@/utils/interface/Health";
import {format, toIndoDateName, toIndoDateNameTime} from "@/utils/Helpers";

export default defineComponent({
    name: "StudentHealth",
    setup() {
        const healths = ref<IHealth[]>([])
        const done = ref(false)
        const user = Session.getCurrentUser()!

        onMounted(async () => {
            const t = loading()
            const r = await Api.get(`health/parent/${user.connection_id}`)
            t.remove()

            if (r.success) {
                healths.value = r.data as IHealth[]
                done.value = true
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        return {
            healths, done, toIndoDateNameTime, format,
            detail: (v: IHealth) => {

            }
        }
    }
})
</script>

<style scoped>
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 600px;
    cursor: pointer;
}
.faw {
    width: 40px;
    height: 40px;
    color: white;
    background: #2BB930;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
</style>