<template>
    <table class="table table-bordered">
        <tbody>
        <tr>
            <td rowspan="2" class="fw-bold">Day</td>
            <td rowspan="2" class="fw-bold">Time</td>
            <td v-for="g in Object.keys(compRefGrade).length" :colspan="compRefGrade[g].length" class="fw-bold">
                {{ compRefGrade[g][0].ref_class.name }}
            </td>
        </tr>
        <tr>
            <td v-for="g in refGrade" class="fw-bold">
                {{ g.nama_kelas }}
            </td>
        </tr>
        <DailyScheduleViewTime
            :times="mondayTimes"
            :day="`Monday`"
            :ref-grade="refGrade"
        />
        <DailyScheduleViewTime
            :times="tuesdayTimes"
            :day="`Tuesday`"
            :ref-grade="refGrade"
        />
        <DailyScheduleViewTime
            :times="wednesdayTimes"
            :day="`Wednesday`"
            :ref-grade="refGrade"
        />
        <DailyScheduleViewTime
            :times="thursdayTimes"
            :day="`Thursday`"
            :ref-grade="refGrade"
        />
        <DailyScheduleViewTime
            :times="fridayTimes"
            :day="`Friday`"
            :ref-grade="refGrade"
        />
        </tbody>
    </table>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {Kelas, Pelajaran} from "@/utils/Models";
import {groupBy} from "@/utils/Helpers";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";
import {IDailySchedule, IDailyScheduleTime} from "@/utils/interface/DailySchedule";
import {IOption} from "@/utils/interface/Assignment";
import DailyScheduleViewTime from "@/components/academic/daily_schedule/partials/DailyScheduleViewTime.vue";

export default defineComponent({
    name: "DailyScheduleView",
    props: {
        dailyScheduleId: Number,
    },
    components: {
        DailyScheduleViewTime
    },
    setup(_) {
        const done = ref(false)
        const schedule = ref<IDailySchedule>({tahun_ajaran: ''})
        const refSubject = ref<IOption[]>([])
        const refGrade = ref<Kelas[]>([])
        const compRefGrade = computed(() => groupBy(refGrade.value, 'ref_class_id'))

        const mondayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Monday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })
        const tuesdayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Tuesday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })
        const wednesdayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Wednesday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })
        const thursdayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Thursday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })
        const fridayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Friday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })

        watch(() => _.dailyScheduleId, (f, s) => {
            reload()
        })

        onMounted(() => {
            reload()
        })

        const reload = async () => {
            done.value = false
            const t = loading('Mendapatkan data')
            const r2 = await Api.get(`daily-schedule/${_.dailyScheduleId}`)
            const r = await Api.get('master/journal')
            t.remove()

            if (r2.success) {
                const a = r2.data as IDailySchedule

                schedule.value.id = a.id
                schedule.value.tahun_ajaran = a.tahun_ajaran
                schedule.value.daily_schedule_times = a.daily_schedule_times
                done.value = true
            }
            else {
                error(r2.message ?? 'gagal')
            }
            if (r.success) {
                refGrade.value = r.data.grade as Kelas[]
                refSubject.value = (r.data.mapel as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
            }
        }

        return {
            compRefGrade, refGrade,
            mondayTimes, tuesdayTimes, wednesdayTimes, thursdayTimes, fridayTimes,
        }
    }
})
</script>

<style scoped>

td {
    text-align: center;
}
.table td, .table th {
    height: auto !important;
    padding-top: 8px !important;
    padding-bottom: 6px !important;
}
</style>