<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">History Assessment / Assignment</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Guru Pembuat</th>
                                        <th>Subject</th>
                                        <th>Judul Tugas</th>
                                        <th>Kelas</th>
                                        <th>Waktu</th>
                                        <th>Penilaian</th>
                                        <th>Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v, k) in assignments">
                                        <td>{{ k + 1 }}</td>
                                        <td>{{ v.teacher_name }}</td>
                                        <td>{{ v.subject_name }}<br>{{ v.type }}</td>
                                        <td>{{ v.title }}</td>
                                        <td><label v-for="g in v.grades" class="d-block">{{ g.nama_kelas }}</label></td>
                                        <td>
                                            Mulai: {{ toIndoDateNameTime(v.completion_date) }}<br>
                                            Akhir: {{ toIndoDateNameTime(v.collection_date) }}
                                        </td>
                                        <td>
                                            <div class="progress progress-sm">
                                                <div class="progress-bar"
                                                     :class="{'bg-success': v.done_count / v.total_count * 100 === 100, 'bg-warning': v.done_count / v.total_count * 100 < 100}"
                                                     :style="{width: `${v.done_count / v.total_count * 100}% !important`}"
                                                     role="progressbar"
                                                     aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            {{ v.done_count }} / {{ v.total_count }}
                                        </td>
                                        <td>
                                            <button class="btn btn-success btn-sm mt-1 mr-1" @click="detail(v.id)" style="width: 41px">
                                                <i class="fa fa-eye"></i>
                                            </button>
                                            <button class="btn btn-warning btn-sm mt-1 mr-1" @click="evaluation(v.id)" style="width: 41px">
                                                <i class="fa fa-graduation-cap"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {IAssignment} from "@/utils/interface/Assignment";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";
import {toIndoDateNameTime} from "@/utils/Helpers";

export default defineComponent({
    name: "History",
    setup() {
        const router = useRouter()
        const assignments = ref<IAssignment[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('assignment/get_for_history')
            t.remove()

            if (r.success) {
                assignments.value = r.data as IAssignment[]
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.get(`assignment/delete/${id}`)
                t.remove()

                if (r.success) {
                    assignments.value = r.data as IAssignment[]
                    success('Berhasil menghapus data')
                }
                else {
                    error(r.message ?? 'gagal')
                }
            }
        }

        return {
            assignments, toIndoDateNameTime,
            add: () => router.push('/assignment/add'),
            edit: (id: number) => router.push(`/assignment/edit/${id}`),
            detail: (id: number) => router.push(`/assignment/${id}`),
            evaluation: (id: number) => router.push(`/assignment/evaluation/${id}/1`),
            destroy
        }
    }
})
</script>

<style scoped>

</style>