import ClassroomJournal from "@/components/classroom_journal/ClassroomJournal.vue";
import AddClassroomJournal from "@/components/classroom_journal/AddClassroomJournal.vue";
import EditClassroomJournal from "@/components/classroom_journal/EditClassroomJournal.vue";
import DetailClassroomJournal from "@/components/classroom_journal/DetailClassroomJournal.vue";

export default [
  {
    path: '/classroom-journal/:level',
    name: 'classroom-journal',
    component: ClassroomJournal,
    meta: {
      title: 'Classroom Journal'
    }
  },
  {
    path: '/classroom-journal/:level/add',
    name: 'classroom-journal/add',
    component: AddClassroomJournal,
    meta: {
      title: 'Add Classroom Journal'
    }
  },
  {
    path: '/classroom-journal/:level/edit/:id',
    name: 'classroom-journal/edit',
    component: EditClassroomJournal,
    meta: {
      title: 'Update Classroom Journal'
    }
  },
  {
    path: '/classroom-journal/:level/:id',
    name: 'classroom-journal/detail',
    component: DetailClassroomJournal,
    meta: {
      title: 'Detail Classroom Journal'
    }
  },
]