import Assignment from "@/components/assignment/Assignment.vue";
import AddAssignment from "@/components/assignment/AddAssignment.vue";
import UpdateAssignment from "@/components/assignment/UpdateAssignment.vue";
import DetailAssignment from "@/components/assignment/DetailAssignment.vue";
import AssignmentEvaluation from "@/components/assignment/AssignmentEvaluation.vue";
import Scoring from "@/components/assignment/Scoring.vue";
import History from "@/components/assignment/History.vue";
import Report from "@/components/assignment/report/Report.vue";
import AddReport from "@/components/assignment/report/AddReport.vue";
import UpdateReport from "@/components/assignment/report/UpdateReport.vue";
import DetailReport from "@/components/assignment/report/DetailReport.vue";

export default [
  {
    path: '/assignment',
    name: 'assignment',
    component: Assignment,
    meta: {
      title: 'Assessment / Assignment'
    }
  },
  {
    path: '/assignment/add',
    name: 'assignment/add',
    component: AddAssignment,
    meta: {
      title: 'Add Assessment / Assignment'
    }
  },
  {
    path: '/assignment/edit/:id',
    name: 'assignment/edit',
    component: UpdateAssignment,
    meta: {
      title: 'Update Assessment / Assignment'
    }
  },
  {
    path: '/assignment/:id',
    name: 'assignment/detail',
    component: DetailAssignment,
    meta: {
      title: 'Detail Assessment / Assignment'
    }
  },
  {
    path: '/assignment/evaluation/:id/:done?',
    name: 'assignment/evaluation',
    component: AssignmentEvaluation,
    meta: {
      title: 'Assessment / Assignment Evaluation'
    }
  },
  {
    path: '/assignment/scoring',
    name: 'assignment/scoring',
    component: Scoring,
    meta: {
      title: 'Assessment / Assignment Scoring'
    }
  },
  {
    path: '/assignment/history',
    name: 'assignment/history',
    component: History,
    meta: {
      title: 'Assessment / Assignment History'
    }
  },
  {
    path: '/assignment/report',
    name: '/assignment/report',
    component: Report,
    meta: {
      title: 'Master Report'
    }
  },
  {
    path: '/assignment/report/add',
    name: '/assignment/report/add',
    component: AddReport,
    meta: {
      title: 'Add Master Report'
    }
  },
  {
    path: '/assignment/report/edit/:id',
    name: '/assignment/report/edit',
    component: UpdateReport,
    meta: {
      title: 'Update Master Report'
    }
  },
  {
    path: '/assignment/report/detail/:id',
    name: '/assignment/report/detail',
    component: DetailReport,
    meta: {
      title: 'Detail Master Report'
    }
  },
]