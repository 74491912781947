<template>
    <tr v-for="(t, k) in times">
        <td v-if="k === 0 || t.first" :rowspan="times.length ? times.length : 1">
            <b>{{ day }}</b>
        </td>
        <td>
            <div v-if="!t.first">
                <span>{{ t.time ?? '-' }}</span>
            </div>
        </td>
        <td v-if="!t.event" v-for="g in refGrade"
            class="fw-bold"
            :style="{background: t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_color ?? ''}"
        >
            {{ t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_name ?? '' }}
        </td>
        <td v-else :colspan="refGrade.length" class="bg-eaea">
            {{ t.event ?? '' }}
        </td>
    </tr>
</template>

<script lang="ts">
import {defineComponent, PropType, watch} from "vue";
import {IDailyScheduleTime} from "@/utils/interface/DailySchedule";
import {Kelas} from "@/utils/Models";

export default defineComponent({
    name: "DailyScheduleViewTime",
    props: {
        times: Object as PropType<IDailyScheduleTime[]>,
        day: String,
        refGrade: Object as PropType<Kelas[]>,
    },
})
</script>

<style scoped>
td {
    text-align: center;
}
.table td, .table th {
    height: auto !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.btn-sm {
    font-size: 10px;
    color: white !important;
    padding: 4px 8px;
    border: 0 solid !important;
}
.btn-success {
    background: linear-gradient(to right, #01bc28, #3ad75b) !important;
}
.btn-warning {
    background: linear-gradient(to right, #FFA325, #fabd69) !important;
}
.btn-danger {
    background: linear-gradient(to right, #ff3ca7, #fa7dbe) !important;
}
.bg-eaea {
    background: #eeeeee !important;
    font-weight: bold;
}
</style>