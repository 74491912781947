import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["rowspan"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.times, (t, k) => {
    return (_openBlock(), _createElementBlock("tr", null, [
      (k === 0 || t.first)
        ? (_openBlock(), _createElementBlock("td", {
            key: 0,
            rowspan: _ctx.times.length ? _ctx.times.length : 1
          }, [
            _createElementVNode("b", null, _toDisplayString(_ctx.day), 1)
          ], 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("td", null, [
        (!t.first)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(t.time ?? '-'), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      (!t.event)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.refGrade, (g) => {
            return (_openBlock(), _createElementBlock("td", {
              class: "fw-bold",
              style: _normalizeStyle({background: t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_color ?? ''})
            }, _toDisplayString(t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_name ?? ''), 5))
          }), 256))
        : (_openBlock(), _createElementBlock("td", {
            key: 2,
            colspan: _ctx.refGrade.length,
            class: "bg-eaea"
          }, _toDisplayString(t.event ?? ''), 9, _hoisted_3))
    ]))
  }), 256))
}