import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12 col-md-6" }
const _hoisted_5 = {
  class: "dataTables_length",
  id: "basic-datatables_length"
}
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "col-sm-12 col-md-6" }
const _hoisted_8 = {
  id: "basic-datatables_filter",
  class: "dataTables_filter d-flex flex-row-reverse"
}
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "table-responsive" }
const _hoisted_13 = { class: "table table-bordered mt-2" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-sm-12 col-md-5" }
const _hoisted_16 = {
  class: "dataTables_info",
  id: "basic-datatables_info",
  role: "status",
  "aria-live": "polite"
}
const _hoisted_17 = { class: "col-sm-12 col-md-7" }
const _hoisted_18 = { class: "d-flex flex-row-reverse" }
const _hoisted_19 = { class: "pagination" }
const _hoisted_20 = ["data-dt-idx", "onClick"]

import {computed, h, PropType, ref, useSlots, watch, ComputedRef} from "vue"

type DataType = (typeof props.data extends (infer U)[] ? U : null)


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginatedTable',
  props: {
	data: { type: Array as PropType<any[]>, required: true },
},
  setup(__props) {

const props = __props

// type DataType = props.data.length ? typeof props.data[0] : null
const getTextsFromNode: (v: any) => string = (node: any) => {
	if (typeof node === 'string') {
		return node
	}
	if (Array.isArray(node)) {
		return node.map((v: any) => getTextsFromNode(v)).join(' ') ?? ''
	}
	return !node.children ? '' : typeof node.children === 'string' ? node.children : (node.children?.map((v: any) => getTextsFromNode(v))?.join(' ') ?? '')
}

const slots = useSlots()
const show = ref('10')
const search = ref('')
const page = ref(1)

//@ts-ignore
const rows = computed(() => props.data.map((v, k) => [...h('div', slots.tbody!({row: r(v), index: k})).children].map(v => getTextsFromNode(v.children)).join(' ')))
const searched: ComputedRef<DataType[]> = computed(() => {
	let satisfied = [] as DataType[]
	rows.value.forEach((v, k) => {
		if (!search.value || JSON.stringify(v).toLowerCase().includes(search.value.toLowerCase())) {
			satisfied.push(props.data[k])
		}
	})
	return satisfied
})
const totalPage = computed(() => Math.ceil(searched.value.length / +show.value))
const paginations = computed(() => {
	const total_page = totalPage.value, each_side = 1, curr_page = +page.value
	let start_page, end_page, return_me = []

	if (total_page <= (2 * each_side) + 5) {
		start_page = 1
		end_page = total_page
	} else if (curr_page <= each_side + 3) {
		start_page = 1
		end_page = (2 * each_side) + 3
	} else if (curr_page >= total_page - (each_side + 2)) {
		start_page = total_page - (2 * each_side) - 2
		end_page = total_page
	} else {
		start_page = curr_page - each_side
		end_page = curr_page + each_side
	}

	if (start_page > 1)
		return_me.push("1")
	if (start_page > 2)
		return_me.push("...")
	for (let x = start_page; x <= end_page; x++)
		return_me.push(x)
	if (end_page < total_page - 1)
		return_me.push("...")
	if (end_page < total_page)
		return_me.push(total_page)

	return return_me
})

watch(() => [search.value, show.value], () => page.value = 1)

const r = (d: DataType) => {
	return {data: d} as { data: DataType }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "mr-1" }, "Show", -1)),
              _withDirectives(_createElementVNode("select", {
                style: {"width":"68px"},
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((show).value = $event)),
                class: "form-control form-control-sm"
              }, _cache[4] || (_cache[4] = [
                _createStaticVNode("<option value=\"5\">5</option><option value=\"10\">10</option><option value=\"25\">25</option><option value=\"50\">50</option><option value=\"100\">100</option>", 5)
              ]), 512), [
                [_vModelSelect, show.value]
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "ml-1" }, "entries", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, [
              _cache[7] || (_cache[7] = _createElementVNode("span", { class: "mr-1" }, "Search:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "search",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search).value = $event)),
                class: "form-control form-control-sm",
                placeholder: "",
                "aria-controls": "basic-datatables"
              }, null, 512), [
                [_vModelText, search.value]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("table", _hoisted_13, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _renderSlot(_ctx.$slots, "thead")
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searched.value, (v, k) => {
                  return (_openBlock(), _createElementBlock("tr", null, [
                    (k >= (page.value - 1) * +show.value && k < ((page.value - 1) * +show.value) + +show.value)
                      ? _renderSlot(_ctx.$slots, "tbody", {
                          key: 0,
                          row: r(v),
                          index: k
                        })
                      : _createCommentVNode("", true)
                  ]))
                }), 256))
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, " Showing " + _toDisplayString(!searched.value.length ? 0 : ((page.value - 1) * +show.value) + 1) + " to " + _toDisplayString(page.value * +show.value > searched.value.length ? searched.value.length : page.value * +show.value) + " of " + _toDisplayString(search.value && searched.value.length != props.data.length ? searched.value.length : props.data.length) + " entries " + _toDisplayString(search.value && searched.value.length != props.data.length ? `(filtered from ${props.data.length} total entries)` : ''), 1)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("ul", _hoisted_19, [
              _createElementVNode("li", {
                class: _normalizeClass(`paginate_button page-item previous ${page.value === 1 ? 'disabled' : ''}`),
                id: "basic-datatables_previous"
              }, [
                _createElementVNode("span", {
                  "data-dt-idx": "0",
                  tabindex: "0",
                  class: "page-link",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (page.value = page.value === 1 ? 1 : page.value - 1))
                }, "Previous")
              ], 2),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginations.value, (v) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(`paginate_button page-item ${page.value === v ? 'active' : ''} ${v === '...' ? 'disabled' : ''}`)
                }, [
                  _createElementVNode("span", {
                    "data-dt-idx": v,
                    tabindex: "0",
                    class: "page-link",
                    onClick: ($event: any) => (page.value = v === '...' ? page.value : v)
                  }, _toDisplayString(v), 9, _hoisted_20)
                ], 2))
              }), 256)),
              _createElementVNode("li", {
                class: _normalizeClass(`paginate_button page-item next ${page.value === totalPage.value ? 'disabled' : ''}`),
                id: "basic-datatables_next"
              }, [
                _createElementVNode("span", {
                  "data-dt-idx": "8",
                  tabindex: "0",
                  class: "page-link",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (page.value = page.value === totalPage.value ? page.value : page.value + 1))
                }, "Next")
              ], 2)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})