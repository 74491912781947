<template>
    <div class="row no-gutters" style="height: 100%">
        <div class="col-sm-3 col-md-4 left">
            <div v-if="chatGroups.length" v-for="v in chatGroups"
                 class="d-flex align-items-center py-2 px-3 cg-item"
                 :class="{active: v.active && !chosenPerson}"
                 @click="setActive(v)"
            >
                <img :src="getPhoto(getOpponent(v))" alt="" class="avatar-img rounded-circle mr-2" style="width: 50px; height: 50px">
                <div class="d-flex flex-column" style="flex: 1">
                    <b>{{ getOpponent(v).name }}</b>
                    <div class="d-flex align-items-center">
                        <i v-if="v.last_sender_id === user.id"
                           class="fa fa-check mr-1"
                           :style="{color: v.active ? 'white' : '#aaaaaa'}"></i>
                        <span v-if="!v.last_chat_seen && v.last_sender_id !== user.id"
                              :class="{'unread-bullet-white': v.active}"
                              class="unread-bullet">
                        </span>
                        <span
                            class="last-chat"
                            :class="{'unread': !v.last_chat_seen && v.last_sender_id !== user.id, 'unread-white': v.active}">
                            {{ ellipsize(v.last_chat) }}
                        </span>
                    </div>
                    <span style="font-size: 12px; margin-left: auto">{{ v.last_sent }}</span>
                </div>
            </div>
            <div v-else class="mx-auto my-auto px-5 text-center">
                <i class="mt-5 d-inline-block">Click `Start New Chat` to start chatting now...</i>
            </div>
        </div>
        <div class="col-sm-9 col-md-8 right" style="height: 100%">
            <div class="right-header" v-if="chosenPerson">
                <div class="d-flex align-items-center py-2 px-3">
                    <div class="avatar-sm mr-2">
                        <img :src="getPhoto(chosenPerson)" alt="" class="avatar-img rounded-circle">
                    </div>
                    <b>{{ chosenPerson.name }}</b>
                </div>
            </div>
            <div class="right-header" v-else-if="activeChatGroup">
                <div class="d-flex align-items-center py-2 px-3">
                    <div class="avatar-sm mr-2">
                        <img :src="getPhoto(getOpponent(activeChatGroup))" alt="" class="avatar-img rounded-circle">
                    </div>
                    <b>{{ getOpponent(activeChatGroup).name }}</b>
                </div>
            </div>
            <div class="chat-container" id="chat-container">
                <ChatItem v-for="(v, k) in chats" :chat="v" :class="{'mt-1': k === 0}"/>
            </div>
            <div v-if="activeChatGroup || chosenPerson" class="chat-maker p-3">
                <input type="text" class="form-control mr-3" v-model="chat" style="flex: 1" @keyup.enter="send" placeholder="Send message...">
                <i class="fa fa-paper-plane send mr-2" @click="send"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch} from "vue";
import {User} from "@/utils/Models";
import {ellipsize, getPhoto, toSqlDateTime} from "@/utils/Helpers";
import Api from "@/utils/Api";
import Session from "@/utils/Session";
import {getOpponent, IChat, IChatGroup} from "@/utils/interface/Chat";
import ChatItem from "@/components/inbox/ChatItem.vue";
import {useStore} from "vuex";

export default defineComponent({
    name: "InboxDesktop",
    components: {ChatItem},
    props: {
        chosenPerson: Object as PropType<User|null>,
        load: Number
    },
    emits: ['clearChosenPerson'],
    setup(props, {emit}) {
        const chat = ref('')
        const chats = ref<IChat[]>([])
        const user = Session.getCurrentUser()!
        const chatGroups = ref<IChatGroup[]>([])
        const activeChatGroup = ref<IChatGroup|null>(null)
        const store = useStore()

        const reload = async () => {
            const r = await Api.get(`chat/group/${user.id}`)
            if (r.success) {
                chatGroups.value = (r.data as IChatGroup[]).map(v => ({...v, active: v.id === activeChatGroup.value?.id}))
            }
        }

        watch(() => props.load, (a, b) => reload())
        watch(() => props.chosenPerson, async (v: any, w: any) => {
            chats.value = []
            if (v) {
                let user_id_1: number, user_id_2: number
                let u1 = user.id, u2 = v.id
                if (u1 < u2) {
                    user_id_1 = u1
                    user_id_2 = u2
                }
                else {
                    user_id_1 = u2
                    user_id_2 = u1
                }

                const r = await Api.get(`chat/chats/${user_id_1}/${user_id_2}`)
                if (r.success) {
                    chats.value = r.data as IChat[]
                    setTimeout(() => scroll(), 100)
                }
                else {

                }
            }
        })

        onMounted(() => reload())
        const timer = setInterval(() => reload(), 10000)
        onBeforeUnmount(() => clearInterval(timer))

        const getChat = async () => {
            chats.value = []
            const r = await Api.get(`chat/chats/${activeChatGroup.value!.id}`)
            if (r.success) {
                chats.value = r.data as IChat[]
                setTimeout(() => scroll(), 100)
            }
            else {

            }
        }

        const send = async () => {
            if (!chat.value) {
                return
            }

            let user_id_1: number, user_id_2: number, to_id: number
            if (props.chosenPerson) {
                if (props.chosenPerson!.id < user.id) {
                    user_id_1 = props.chosenPerson!.id
                    user_id_2 = user.id
                }
                else {
                    user_id_1 = user.id
                    user_id_2 = props.chosenPerson!.id
                }
                to_id = props.chosenPerson!.id
            }
            else {
                const u = getOpponent(activeChatGroup.value!)
                if (u.id < user.id) {
                    user_id_1 = u.id
                    user_id_2 = user.id
                }
                else {
                    user_id_1 = user.id
                    user_id_2 = u.id
                }
                to_id = u.id
            }
            const c = chat.value
            chat.value = ''

            const ts = new Date().getTime()
            chats.value.push({
                from_id: user.id,
                to_id: to_id,
                chat: c,
                sent: toSqlDateTime(new Date()),
                ts: ts,
                done: false
            })
            setTimeout(() => scroll(), 100)

            const r = await Api.post('chat/send', {
                user_id_1,
                user_id_2,
                chat: c,
                sent: toSqlDateTime(new Date()),
                from_id: user.id,
                to_id,
                ts,
            })

            if (r.success) {
                chatGroups.value = (r.data.chat_group as IChatGroup[]).map(v => ({...v, active: v.id === activeChatGroup.value?.id}))
                chats.value = chats.value.map(v => {
                    return ({...v, done: !v.done ? v.ts === ts : v.done })
                })
            }
            else {

            }
        }

        const scroll = () => {
            let container = document.querySelector("#chat-container");
            if (container) {
                container.scrollTop = container.scrollHeight
            }
        }

        const setActive = (cg: IChatGroup) => {
            if (!cg.last_chat_seen && cg.last_sender_id != user.id) {
                store.commit('adaChat', false)
            }

            emit('clearChosenPerson')
            chatGroups.value.forEach(v => v.active = false)
            cg.active = true
            cg.last_chat_seen = 1
            activeChatGroup.value = cg
            getChat()
        }

        return {
            getPhoto, chat, send, chatGroups, getOpponent, setActive, activeChatGroup, chats, user, ellipsize
        }
    }
})
</script>

<style scoped>
.left {
    height: 100%;
    border-right: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
}
.right {
    background: #F9FBFD;
    height: 100%;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
}
.right-header {
    background: white;
}
.chat-container {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}
.chat-maker {
    background: white;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    border-bottom-right-radius: 20px;
}
.send {
    cursor: pointer;
    font-size: 24px;
    color: #31CE36;
}
.cg-item {
    cursor: pointer;
}
.cg-item:hover {
    background: #eeeeee;
}
.cg-item.active {
    background: #31CE36;
    color: white;
}
.last-chat {
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow: ellipsis;
}
.unread {
    font-weight: bold;
    color: #31CE36;
}
.unread-bullet {
    background-color: #31CE36;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    margin-right: 4px;
}
.unread-white {
    color: white;
}
.unread-bullet-white {
    background: white;
}
</style>