<template>
    <div class="card timeline-item" @click="() => onClick()">
        <div class="card-body pb-0">
            <div class="d-flex">
                <b style="font-size: 16px; flex: 1">{{notification.title}}</b>
                <i>{{toChatTime(notification.created_at)}}</i>
            </div>
            <p>{{notification.message}}</p>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {INotification} from "@/utils/interface/Notification";
import {toChatTime} from "@/utils/Helpers";
import {useRouter} from "vue-router";

export default defineComponent({
    name: "NotificationItem",
    props: {
        notification: Object as PropType<INotification>
    },
    setup(_) {
        const router = useRouter()

        return {
            toChatTime,
            onClick: () => {
                if (_.notification?.action === 'health') {
                    router.push('/student-health')
                }
                else if (_.notification?.action === 'classroom-journal') {
                    router.push('/timeline')
                }
                else if (_.notification?.action === 'progress') {
                    router.push('/academic-development')
                }
            }
        }
    }
})
</script>

<style scoped>
.timeline-item {
    margin: 10px auto;
    width: 100%;
    max-width: 600px;
    cursor: pointer;
}
</style>