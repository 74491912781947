<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div id="print" class="card-body" v-if="classroomJournal">
                            <div class="d-flex">
                                <div style="flex: 1">
                                    <img src="../../assets/img/bc-horizontal-new.png" height="50" alt="navbar brand" class="navbar-brand">
                                </div>
                                <div class="d-flex flex-column text-center">
                                    <h3 class="fw-bold mb-0">CLASSROOM JOURNAL</h3>
                                    <h4 class="fw-bold mb-0">Academic Year {{ classroomJournal?.date.split('-')[0] ?? '' }}</h4>
                                </div>
                                <div style="flex: 1" class="text-right">
                                    <img src="../../assets/img/daily.png" height="50">
                                </div>
                            </div>
                            <table class="table-bordered table mt-2">
                                <tr>
                                    <td class="fw-bold">Date </td>
                                    <td>{{ toIndoDateName(classroomJournal.date) }}</td>
                                    <td class="fw-bold">Lesson Hours</td>
                                    <td>{{ classroomJournal.lesson_hours }}</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td class="fw-bold">Grade</td>
                                    <td>{{ classroomJournal.grade_name }}</td>
                                    <td class="fw-bold">Subject</td>
                                    <td>{{ classroomJournal.subject_name }}</td>
                                    <td class="fw-bold">Teacher</td>
                                    <td>{{ classroomJournal.teacher_name }}</td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="text-center fw-bold">Student Attendance</td>
                                </tr>
                                <tr>
                                    <td colspan="6" style="padding: 0 !important;">
                                        <table class="table table-bordered mb-0 border-none">
                                            <tr>
                                                <td class="fw-bold">Name</td>
                                                <td class="fw-bold">Status</td>
                                                <td class="fw-bold">Note</td>
                                            </tr>
                                            <tr v-for="v in classroomJournal.attendances">
                                                <td>{{ v.student_name }}</td>
                                                <td>{{ attendanceStatus(v.status) }}</td>
                                                <td>{{ v.note }}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <span class="fw-bold">Materi Pembelajaran</span><br>
                                        <span style="white-space: pre;">{{ classroomJournal.materi_pembelajaran }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <span class="fw-bold">Kegiatan Pembelajaran</span><br>
                                        <span style="white-space: pre;">{{ classroomJournal.kegiatan_pembelajaran }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <span class="fw-bold">Catatan</span><br>
                                        <span style="white-space: pre;">{{ classroomJournal.catatan }}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                            <button class="btn btn-success" @click="download">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {IClassroomJournal, StudentAttendance} from "@/utils/interface/ClassroomJournal";
import {useRoute, useRouter} from "vue-router";
import Api from "@/utils/Api";
import {attendanceStatus, fix_lesson_hour, toIndoDateName} from "@/utils/Helpers";
import {error, loading} from "@/utils/Toast";

export default defineComponent({
    name: "DetailClassroomJournal",
    setup() {
        const route = useRoute()
        const router = useRouter()
        const id = route.params.id
        const classroomJournal = ref<IClassroomJournal|null>(null)

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`classroom-journal/${id}`)
            t.remove()

            if (r.success) {
                const x = r.data as IClassroomJournal
                classroomJournal.value = {
					...x,
					lesson_hours: fix_lesson_hour(x.lesson_hours),
					attendances: x.attendances.sort((a: StudentAttendance, b: StudentAttendance) => a.student_name!.localeCompare(b.student_name!))
				}
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        return {
            classroomJournal, toIndoDateName,
            download,
            back: () => router.back(), attendanceStatus
        }
    }
})

const download = () => {
    const prtHtml = document.getElementById('print')!.innerHTML;
    let stylesHtml = '';
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
    }
    stylesHtml += '<style>.table .table{background-color: transparent !important;}*{font-family:"Times New Roman",serif !important;color:#000!important}.table td,.table th{height:auto!important;padding:6px!important}.border-none{border-collapse:collapse;border:none}.border-none td{border:1px solid #000}.border-none tr:first-child td{border-top:none}.border-none tr:last-child td{border-bottom:none}.border-none tr td:first-child{border-left:none}.border-none tr td:last-child{border-right:none}</style>'

    const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0') as WindowProxy;
    WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body style="padding: 10px; font-size: 150% !important;">
            ${prtHtml}
          </body>
        </html>`
    );

    WinPrint.document.close();
    WinPrint.focus();
    setTimeout(() => WinPrint.print(), 100)
}
</script>

<style scoped>
#print * {
    font-family: 'Times New Roman', serif !important;
    color: black;
}

.table td, .table th {
    height: auto !important;
    padding: 6px !important;
}

.border-none {
    border-collapse: collapse;
    border: none;
}

.border-none td {
    border: 1px solid black;
}

.border-none tr:first-child td {
    border-top: none;
}

.border-none tr:last-child td {
    border-bottom: none;
}

.border-none tr td:first-child {
    border-left: none;
}

.border-none tr td:last-child {
    border-right: none;
}

</style>
