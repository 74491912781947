<template>
    <ClassroomJournalList
        v-if="selectedGroup"
        :classroom-journal-group="selectedGroup"
		:level="level as string"
        @back="reload"/>
    <ClassroomJournalPreview
        v-else-if="previewedGroup"
        :classroom-journal-group="previewedGroup"
        @back="() => previewedGroup = null"/>
    <div v-else class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Classroom Journal {{level === 'smp' ? 'SMP' : level === 'sma' ? 'SMA' : 'Pesantren'}}</div>
                                <div class="card-tools">
                                    <button @click="add" class="b-time btn btn-success btn-round mr-2">
                                        Add Journal
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mb-4">
                                <div class="col-sm-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Date</label>
                                        <input type="date" v-model="date" class="form-control" @change="() => reloadData()">
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
<!--                                <table class="table table-bordered">-->
<!--                                    <thead>-->
<!--                                    <tr>-->
<!--                                        <th>No</th>-->
<!--                                        <th>Date</th>-->
<!--                                        <th>Grade</th>-->
<!--                                        <th>Journal Count</th>-->
<!--                                        <th>Aksi</th>-->
<!--                                    </tr>-->
<!--                                    </thead>-->
<!--                                    <tbody>-->
<!--                                    <tr v-for="(v, k) in classJournalGroup">-->
<!--                                        <td>{{ k + 1 }}</td>-->
<!--                                        <td>{{ toIndoDateName(v.date) }}</td>-->
<!--                                        <td>-->
<!--                                            {{v.data.map(x => x.grade_name).join(', ')}}-->
<!--                                        </td>-->
<!--                                        <td>{{ v.data.length }}</td>-->
<!--                                        <td>-->
<!--                                            <button class="btn btn-success btn-sm mt-2" @click="list(v)">-->
<!--                                                <i class="fa fa-eye"></i>-->
<!--                                            </button>-->
<!--                                            <button class="btn btn-warning btn-sm my-2" @click="print(v)">-->
<!--                                                <i class="fa fa-print"></i>-->
<!--                                            </button>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    </tbody>-->
<!--                                </table>-->
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Date</th>
                                        <th>Grade</th>
                                        <th v-for="v in lessonHours">{{v}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
									<tr v-for="(v, k) in groups">
										<td>{{k + 1}}</td>
										<td>{{v.date}}</td>
										<td>{{v.grade_name}}</td>
										<td v-for="lh in lessonHours">
											<b>{{v.data.find(d => d.lesson_hours === lh)?.subject_name ?? '-'}}</b>
											<br>
											<button
												v-if="v.data.find(d => d.lesson_hours === lh)"
												class="btn btn-sm btn-success btn-round"
												@click="detail(v.data.find(d => d.lesson_hours === lh)!.id)"
											>
												Lihat
											</button>
										</td>
									</tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, computed, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {IClassroomJournal, IClassroomJournalGroup} from "@/utils/interface/ClassroomJournal";
import Api from "@/utils/Api";
import {fix_lesson_hour, getClassroomDate, setClassroomDate, toIndoDateName} from "@/utils/Helpers";
import {error, loading} from "@/utils/Toast";
import ClassroomJournalList from "@/components/classroom_journal/ClassroomJournalList.vue";
import ClassroomJournalPreview from "@/components/classroom_journal/ClassroomJournalPreview.vue";

interface IGroup {
	grade_id: number
	grade_name: string
	date: string
	data: IClassroomJournal[]
}

export default defineComponent({
    name: "ClassroomJournal",
    components: {ClassroomJournalPreview, ClassroomJournalList},
    setup() {
        const router = useRouter()
		const route = useRoute()
		const level = computed(() => route.params.level)
        const classJournal = ref<IClassroomJournal[]>([])
        const classJournalGroup = ref<IClassroomJournalGroup[]>([])
        const selectedGroup = ref<IClassroomJournalGroup|null>(null)
        const previewedGroup = ref<IClassroomJournalGroup|null>(null)
        const date = ref(getClassroomDate())
		const lessonHours = ref<string[]>([])
		const groups = ref<IGroup[]>([])

        const setGroup = (r: IClassroomJournal[]) => {
            classJournal.value = r

            let n: IClassroomJournalGroup[] = []

            for (let i = 0; i < classJournal.value.length; i++) {
                const v = classJournal.value[i]!
                const x = n.find(q => q.date === v.date)
                if (x) {
                    x.data.push(v)
                }
                else {
                    n.push({
                        date: v.date,
                        grade_name: v.grade_name ?? '',
                        grade_id: v.grade_id,
                        data: [v]
                    })
                }
            }

            n.sort((a: IClassroomJournalGroup, b: IClassroomJournalGroup) => {
                let keyA = new Date(a.date),
                    keyB = new Date(b.date);
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            })
            classJournalGroup.value = n
        }

        const reloadData = async () => {
            setGroup([])
			setClassroomDate(date.value)
			lessonHours.value = []
			groups.value = []

            const t = loading('Mendapatkan data')
            const r = await Api.get(`classroom-journal/date/${date.value}/level/${level.value}`)
            t.remove()

            if (r.success) {
				classJournal.value = (r.data as IClassroomJournal[])
					.map(v => ({
						...v,
						lesson_hours: fix_lesson_hour(v.lesson_hours),
						lesson_hours_number: +fix_lesson_hour(v.lesson_hours).split(':')[0],
					}))
					.sort((a, b) => a.grade_id - b.grade_id)
					.sort((a, b) => a.lesson_hours_number - b.lesson_hours_number)
				
				classJournal.value.forEach(v => {
					if (v.lesson_hours && !lessonHours.value.includes(v.lesson_hours)) {
						lessonHours.value.push(v.lesson_hours)
					}
				})

				classJournal.value.forEach(v => {
					const index = groups.value.findIndex(g => g.grade_id === v.grade_id)
					if (index === -1) {
						groups.value.push({
							grade_id: v.grade_id,
							grade_name: v.grade_name ?? '',
							date: v.date,
							data: [v]
						})
					}
					else {
						groups.value[index].data.push(v)
					}
				})
                // setGroup(r.data as IClassroomJournal[])
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        onMounted(() => reloadData())
		watch(() => level.value, () => reloadData())

        const reload = (r?: IClassroomJournal[]) => {
            selectedGroup.value = null
            if (r) {
                setGroup(r)
            }
        }

        return {
            classJournal, toIndoDateName, classJournalGroup, selectedGroup, previewedGroup, date, reloadData, level, lessonHours, groups,
            add: () => router.push(`/classroom-journal/${level.value}/add`),
            list: (v: IClassroomJournalGroup) => selectedGroup.value = v,
            print: (v: IClassroomJournalGroup) => previewedGroup.value = v,
            detail: (id: number) => router.push(`/classroom-journal/${level.value}/${id}`),
            reload
        }
    }
})
</script>

<style scoped>
.btn-sm {
	padding: 3px 10px;
	font-weight: bold;
	margin-bottom: 4px;
}
</style>
