<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="fw-bold">Tambah Kelas</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Kelas <b class="text-danger">*</b></label>
                                        <select class="form-control" v-model="ref_class_id">
                                            <option v-for="v in ref_class" :value="v.id">{{ v.name }}</option>
                                        </select>
                                        <span v-if="ref_class_idErr" class="text-danger">{{ ref_class_idErr }}</span>
                                    </div>
                                    <div class="form-group" v-if="ref_class_id">
                                        <label>Nama Kelas <b class="text-secondary">(Opsional)</b></label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">{{ ref_class.find(v => v.id === ref_class_id).name }} -</div>
                                            </div>
                                            <input type="text" class="form-control" v-model="name" placeholder="Masukkan Nama Kelas" required>
                                        </div>
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Nama Kelas <b class="text-secondary">(Opsional)</b></label>
                                        <input type="text" class="form-control" v-model="name" placeholder="Masukkan Nama Kelas" required>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Wali Kelas <b class="text-danger">*</b></label>
                                        <select class="form-control" v-model="user_id">
                                            <option v-for="v in ref_user" :value="v.id">{{ v.name }}</option>
                                        </select>
                                        <span v-if="user_idErr" class="text-danger">{{ user_idErr }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Siswa <b class="text-danger">*</b></label>
                                        <Multiselect
                                            v-model="selectedAddStudent"
                                            mode="tags"
                                            :options="refStudent"
                                            :searchable="true"
                                            :can-clear="false"
                                            :can-deselect="false"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row justify-content-end">
                            <button class="btn btn-danger mr-2" @click="cancel">Batal</button>
                            <button class="btn btn-success" @click="save">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import Api from "@/utils/Api";
import {Siswa} from "@/utils/Models";
import {loading, success, error} from "@/utils/Toast";
import Multiselect from "@vueform/multiselect";
import {IOption} from "@/utils/interface/Assignment";

export default defineComponent({
    name: "CreateKelas",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const ref_class = ref([])
        const ref_user = ref([])
        const ref_class_id = ref('')
        const name = ref('')
        const user_id = ref('')
        const refStudent = ref<IOption[]>([])
        const selectedAddStudent = ref<IOption[]>([])

        const ref_class_idErr = ref('')
        const user_idErr = ref('')

        onMounted(() => {
            const loadings = [0,0,0]
            const t = loading('Mendapatkan data');
            const hideLoading = () => {
                if (loadings.every(v => v)) {
                    t.remove()
                }
            }

            (async () => {
                const r = await Api.get(`master/kelas/ref`)
                if (r.success) {
                    ref_class.value = r.data
                }
                else {
                    error(r.message ?? 'gagal')
                }
                loadings[0] = 1
                hideLoading()
            })();

            (async () => {
                const r2 = await Api.get(`master/guru`)
                if (r2.success) {
                    ref_user.value = r2.data
                }
                else {
                    error(r2.message ?? 'gagal')
                }
                loadings[1] = 1
                hideLoading()
            })();

            (async () => {
                const r2 = await Api.get('master/journal')
                if (r2.success) {
                    refStudent.value = (r2.data.siswa as Siswa[]).map(v => ({value: v.id, label: `${v.nama}`}))
                }
                else {
                    error(r2.message ?? 'gagal')
                }
                loadings[2] = 1
                hideLoading()
            })();
        })

        const cancel = () => router.back()
        const save = async () => {
            ref_class_idErr.value = ''
            user_idErr.value = ''

            if (!ref_class_id.value) {
                ref_class_idErr.value = '* Pilih Kelas'
                return
            }
            if (!user_id.value) {
                user_idErr.value = '* Pilih Wali Kelas'
                return
            }

            const t = loading('Menyimpan data')
            const r = await Api.post('master/kelas/create', {
                ref_class_id: ref_class_id.value,
                name: name.value,
                user_id: user_id.value
            })
            t.remove()

            if (r.success) {
                const r2 = await Api.get(`master/kelas/siswa/${r.id}/add/${selectedAddStudent.value.join(',')}`)
                router.back()
                success('Berhasil menyimpan data')
            }
            else {
                error(r.message ?? 'Gagal')
            }
        }

        return {
            cancel, save, ref_class, ref_user, ref_class_id, ref_class_idErr, name, user_id, user_idErr,
            refStudent, selectedAddStudent
        }
    }
})
</script>

<style scoped>

</style>
