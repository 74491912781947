import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/right.png'
import _imports_1 from '@/assets/img/export.png'


const _hoisted_1 = { class: "card timeline-item" }
const _hoisted_2 = { class: "card-header d-flex" }
const _hoisted_3 = { style: {"flex":"1","color":"#7997A1"} }
const _hoisted_4 = {
  key: 0,
  class: "badge-mobile text-white"
}
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  class: "fw-bold mb-0",
  style: {"color":"#135F6A"}
}
const _hoisted_7 = { style: {"color":"#7997A1"} }
const _hoisted_8 = {
  key: 0,
  class: "d-flex align-items-center mt-4"
}
const _hoisted_9 = ["href"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.announcement.created_at)), 1),
        (_ctx.dashboard)
          ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString('Announcement')))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.announcement.title), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.announcement.message), 1),
        (_ctx.announcement.action_detail)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", {
                class: "btn-bc-round",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.detail(_ctx.announcement.action_detail)))
              }, _cache[2] || (_cache[2] = [
                _createTextVNode(" DETAIL "),
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)
              ])),
              _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
              (_ctx.announcement.pdf)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.announcement.pdf,
                    target: "_blank",
                    class: "action-ctr"
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: ""
                    }, null, -1)
                  ]), 8, _hoisted_9))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_Modal, {
      show: _ctx.showModal,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
      title: "Message Detail"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", { innerHTML: _ctx.modalData }, null, 8, _hoisted_10)
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}