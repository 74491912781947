import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (v, k) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["d-flex flex-column py-2 px-4 tab", {active: k === _ctx.index}]),
        onClick: ($event: any) => (_ctx.changeIndex(k))
      }, [
        _createElementVNode("h5", null, [
          _createElementVNode("b", null, _toDisplayString(v), 1)
        ])
      ], 10, _hoisted_2))
    }), 256))
  ]))
}