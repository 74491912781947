<template>
    <div class="card">
        <div class="card-header">
            <h4 class="fw-bold mb-0">I. Teachers Attendance</h4>
        </div>
        <div class="card-body">
            <div class="d-flex align-items-center">
                <span style="flex: 1"></span>
                <div class="b-hadir"></div> <span>Hadir</span>
                <div class="b-izin"></div> <span>Izin</span>
                <div class="b-sakit"></div> <span>Sakit</span>
                <div class="b-alpha"></div> <span>Alpha</span>
                <div class="b-tugas"></div> <span>Tugas</span>
            </div>
            <div class="table-responsive" style="overflow-y: visible">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Note</th>
                        <th>Substitute</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v, k) in request!.teacher">
                        <td>{{ k + 1 }}</td>
                        <td>{{ v.name }}</td>
                        <td style="width: 80px"><Attendance :key="`att${v.person_id}`" :status="v.status" @statusChange="(s) => statusChange(s, +k)"/></td>
                        <td style="width: 300px">
                            <div class="form-group">
                                <textarea v-model="v.note" class="form-control"></textarea>
                            </div>
                        </td>
                        <td style="width: 250px">
                            <div class="form-group" v-if="v.status !== 'h'">
                                <Multiselect
									v-model="v.substitutes"
									:options="refPic"
									:searchable="true"
									:can-clear="true"
									:can-deselect="true"
									mode="tags"
								/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-6 col-sm-6 col-md-3">
                    <div class="form-group">
                        <label>a. Present</label>
                        <input type="text" class="form-control" v-model="request!.teacher_present">
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-md-3">
                    <div class="form-group">
                        <label>b. Absent</label>
                        <input type="text" class="form-control" v-model="request!.teacher_absent">
                    </div>
                </div>
            </div>
            <h5 class="fw-bold mt-3 text-center">Substitute Teacher</h5>
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Subject</th>
                    <th>Grade</th>
                    <th>Time</th>
                    <th>Replaced By</th>
                </tr>
                </thead>
                <tbody>
				<tr v-for="(v, k) in request!.absent_teachers">
					<td>{{ k + 1 }}</td>
					<td>{{ v.name }}</td>
					<td style="width: 200px">
						<div class="form-group">
							<Multiselect
								v-model="v.subject_id"
								:options="refSubject"
								:searchable="true"
								:can-clear="false"
							/>
						</div>
					</td>
					<td style="width: 200px">
						<div class="form-group">
							<Multiselect
								v-model="v.grade_id"
								:options="refGrade"
								:searchable="true"
								:can-clear="false"
							/>
						</div>
					</td>
					<td style="width: 200px">
						<div class="form-group">
							<input type="text" v-model="v.time" class="form-control">
						</div>
					</td>
					<td>{{ refPic.find(x => +x.value === +v.substitute_id)?.label ?? '' }}</td>
				</tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, watch} from "vue";
import DailyJournalRequest, { Teacher } from "@/utils/interface/DailyJournal"
import Attendance from "@/components/partials/Attendance.vue"
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "TeacherAttendance",
    components: {
        Attendance,
        Multiselect
    },
    props: {
        request: Object as () => DailyJournalRequest,
        refPic: {type: Array, required: true},
        refGrade: {type: Array, required: true},
        refSubject: {type: Array, required: true},
    },
    emits: ['statusChange', 'absenceTeachersChange'],
    setup(_, { emit }) {
        const statusChange = (s: string, i: number) => {
            _.request!.teacher[i].status = s;
            emit('statusChange')
        }
		watch(() => _.request!.teacher.map(v => ({status: v.status, substitutes: v.substitutes, note: v.note})), () => {
			let res = [] as Teacher[]
			_.request!.teacher.filter(v => v.status !== 'h').forEach(v => {
				if (v.substitutes?.length) {
					v.substitutes.forEach(id => {
						const existingAbsentTeacher = _.request!.absent_teachers.find(x => +v.person_id! === +x.person_id! && +id === +x.substitute_id!)
						const newT = {
							id: Date.now(),
							name: v.name,
							status: v.status,
							substitute_id: id,
							grade_id: 0,
							subject_id: 0,
							person_id: v.person_id,
							note: v.note,
						} as Teacher
						res.push(existingAbsentTeacher ? {...existingAbsentTeacher, note: v.note} : newT)
					})
				}
				else {
					res.push({
						id: Date.now(),
						person_id: v.person_id,
						name: v.name,
						status: v.status,
						grade_id: v.grade_id,
						subject_id: v.subject_id,
						note: v.note,
					} as Teacher)
				}
			})
			emit('absenceTeachersChange', res)
		}, { deep: true })

        return { statusChange }
    }
})
</script>

<style>
.multiselect-dropdown {
    z-index: 100000000;
}
.multiselect--active {
    z-index: 10000000;
}
.multiselect-tag {
	background: #2BB930;
}
</style>
