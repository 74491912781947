<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Add Announcement</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Title <b class="text-danger">*</b></label>
                                        <input type="text" v-model="title" class="form-control">
                                        <span class="text-danger" v-if="validation.title">{{ validation.title }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Message <b class="text-danger">*</b></label>
                                        <input type="text" v-model="message" class="form-control">
                                        <span class="text-danger" v-if="validation.message">{{ validation.message }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Kelas </label>
                                        <Multiselect
                                            mode="tags"
                                            v-model="grades"
                                            :options="refGrade"
                                            :searchable="true"
                                            :multiple="true"
                                        />
                                        <i class="mt-2 d-block">* Pilih kelas hanya jika ingin menambahkan pengumuman pada kelas yang dipilih. Jika ingin menambahkan pengumuman untuk semua kelas, biarkan kosong saja :)</i>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                                    <div class="form-group">
                                        <label>Lampiran</label>
                                        <div class="form-control">
                                            <div v-if="attachment_file" class="form-control mb-2 d-flex align-items-center" style="padding: 0.6rem">
                                                <span style="flex: 1">{{ attachment_file.name }}</span>
                                                <button class="btn btn-sm btn-danger" @click="remove_attachment()">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </div>
                                            <input type="file"
                                                   ref="question_file"
                                                   id="question_file"
                                                   accept="application/pdf"
                                                   @change="attachment_change"
                                                   style="display: none">
                                            <div class="d-flex flex-row-reverse" v-if="!attachment_file">
                                                <button type="button" @click="add_attachment" class="btn btn-sm btn-success">
                                                    <i class="fa fa-plus"></i> Pilih Lampiran
                                                </button>
                                            </div>
                                        </div>
                                        <p class="mt-1 mb-0">Tipe file yang diizinkan adalah .pdf dengan maksimal 5 MB.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-success" @click="save">Simpan</button>
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// @ts-ignore
import UploadAdapter from "@/utils/UploadAdapter";
import Api from "@/utils/Api";
import {useRouter} from "vue-router";
import {error, loading, success} from "@/utils/Toast";
import {IOption} from "@/utils/interface/Assignment";
import {Kelas} from "@/utils/Models";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
    name: "AddAnnouncement",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const title = ref('')
        const message = ref('')
        const grades = ref<number[]>([])
        const refGrade = ref<IOption[]>([])
        const editorData = ref('')
        const attachment_file = ref<File|null>(null)
        const validation = reactive({
            title: '',
            message: ''
        })

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get('master/journal')
            t.remove()

            if (r.success) {
                refGrade.value = (r.data.grade as Kelas[]).map(v => ({value: v.id, label: v.nama_kelas}))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        function uploader(editor: any) {
            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
                return new UploadAdapter( loader );
            }
        }

        const validate = () => {
            validation.title = ''
            validation.message = ''

            if (!title.value) {
                validation.title = 'Masukkan title'
                return false
            }
            if (!message.value) {
                validation.message = 'Masukkan message'
                return false
            }
            return true
        }
        const save = async () => {
            if (!validate()) {
                return
            }

            let data = new FormData()
            data.append('title', title.value)
            data.append('message', message.value)
            data.append('action_detail', editorData.value)
            if (attachment_file.value) {
                data.append('pdf', attachment_file.value)
            }
            grades.value.forEach(v => data.append('grades[]', `${v}`))

            const t = loading('Menyimpan data')
            const r = await Api.post('announcement', data, { 'Content-Type': 'multipart/form-data' })
            t.remove()

            if (r.success) {
                success('Berhasil menyimpan data')
                router.back()
            }
            else {
                error(r.message ?? 'gagal')
            }
        }

        return {
            title, message, attachment_file, validation, grades, refGrade,
            remove_attachment: () => attachment_file.value = null,
            attachment_change: (event: any) => attachment_file.value = event.target.files[0],
            add_attachment: () => document.getElementById('question_file')?.click(),
            editor: ClassicEditor,
            ckeditor: CKEditor.component,
            editorData,
            editorConfig: {
                extraPlugins: [uploader],
            },
            save,
            back: () => router.back(),
        };
    }
})
</script>

<style scoped>

</style>