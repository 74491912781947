import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card timeline-item" }
const _hoisted_6 = {
  class: "d-flex align-items-center",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("div", { class: "card-head-row" }, [
                  _createElementVNode("div", { class: "card-title" }, "Academic Calendar")
                ])
              ], -1)),
              _createElementVNode("div", {
                class: _normalizeClass(["card-body", {'px-0': _ctx.isMobile()}])
              }, [
                _createVNode(_component_Calendar, {
                  nearest: _ctx.nearest,
                  agendas: _ctx.agendas,
                  "on-agenda-click": _ctx.onAgendaClick
                }, null, 8, ["nearest", "agendas", "on-agenda-click"])
              ], 2)
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      show: _ctx.showModal,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false))
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("h4", {
            class: "mb-0",
            style: {"flex":"1"}
          }, "Detail Agenda", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(`my-cv-item-${_ctx.dataModal?.customData?.color}`),
            style: {"width":"15px","height":"15px","border-radius":"20px"}
          }, null, 2)
        ])
      ]),
      body: _withCtx(() => [
        _cache[3] || (_cache[3] = _createElementVNode("b", null, "Agenda", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.dataModal?.title), 1),
        _cache[4] || (_cache[4] = _createElementVNode("b", null, "Date", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.toIndoDateName(_ctx.dataModal?.start_date)) + " - " + _toDisplayString(_ctx.toIndoDateName(_ctx.dataModal?.end_date)), 1)
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}