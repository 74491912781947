<template>
    <div class="card timeline-item">
        <div class="card-body pb-0">
            <div class="d-flex align-items-center">
                <div class="avatar-sm">
                    <img class="avatar-img rounded-circle" :src="getPhoto(progress.teacher)">
                </div>
                <div class="d-flex flex-column ml-2">
                    <span class="fw-bold">{{ progress.teacher?.name ?? '-' }}</span>
                    <span class="text-muted" style="font-size: 12px">{{ toIndoDateNameTime(progress.created_at) }}</span>
                </div>
            </div>
        </div>
        <div class="card-header text-success d-flex flex-column">
            <div class="d-flex align-items-center" style="flex: 1">
                <i class="fa fa-book"></i><span class="ml-2">{{ progress.subject_name }} - Semester {{ progress.semester_name }}</span>
            </div>
            <div class="d-flex align-items-center" style="flex: 1; margin-top: 4px">
                <i class="fas fa-user-graduate"></i><span class="ml-2"></span> {{ progress.student_name }}
            </div>
        </div>
        <div class="card-header">
            <span style="white-space: pre-wrap;">{{ progress.note }}</span>
        </div>
        <div class="card-header" v-if="progress.attachments.length">
            <b>Attachments:</b>
            <a v-for="a in progress.attachments" :href="a.url" target="_blank" class="d-block">{{ a.file_name }}</a>
        </div>
        <div class="card-header d-none">

            <div v-for="c in progress.comments" v-if="expanded" class="mb-3">
                <div class="d-flex align-items-center">
                    <div class="avatar-sm">
                        <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(c.creator_photo)">
                    </div>
                    <div class="d-flex flex-column ml-2">
                        <span class="fw-bold" style="font-size: 12px">{{ c.creator_name ?? '-' }}</span>
                        <span style="font-size: 11px" class="text-muted">{{ toIndoDateNameTime(c.created_at) }}</span>
                    </div>
                </div>
                <div style="margin-left: 48px">{{ c.comment }}</div>
            </div>

            <span v-if="!expanded" class="text-success pointer underline" @click="toggleExpanded">
                {{ progress.comments?.length ?? 0 }} Komentar
            </span>
            <span v-else class="text-success pointer underline" @click="toggleExpanded">
                Sembunyikan
            </span>
            <div class="d-flex align-items-center">
                <div class="form-group px-0 mr-3" style="flex: 1">
                    <input type="text" v-model="newComment.comment" placeholder="Tambahkan komentar..." class="form-control" :readonly="loading">
                </div>
                <button class="btn btn-success btn-sm btn-rounded" @click="send" :disabled="loading">
                    <i class="fas fa-paper-plane"></i>
                    {{ loading ? 'Sending...' : 'Send' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {IProgress, IProgressComment} from "@/utils/interface/Progress";
import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";
import Session from "@/utils/Session";
import Api from "@/utils/Api";
import {error} from "@/utils/Toast";

export default defineComponent({
    name: "ProgressItem",
    props: {
        progress: Object as PropType<IProgress>,
        timeline: {
            type: Boolean,
            default: false
        }
    },
    emits: ['commentChange'],
    setup(_, {emit}) {
        const user = Session.getCurrentUser()!
        const newComment = ref<IProgressComment>({
            progress_id: _.progress?.id ?? 0,
            creator_type: 'ortu',
            creator_id: user.id,
            comment: '',
        })
        const loading = ref(false)
        const expanded = ref(false)

        const send = async () => {
            if (newComment.value.comment.length === 0 || loading.value) {
                return
            }

            loading.value = true
            const r = await Api.post('progress/add_comment', {...newComment.value})
            if (r.success) {
                emit('commentChange', _.progress, r.data as IProgressComment[])
                newComment.value.comment = ''
            }
            else {
                error('Gagal menambahkan komentar')
            }
            loading.value = false
        }

        return {
            newComment, toIndoDateNameTime, getPhoto, send, loading, expanded,
            toggleExpanded: () => expanded.value = !expanded.value
        }
    }
})
</script>

<style scoped>

</style>