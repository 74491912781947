import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "copyright ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("nav", { class: "pull-left" }, [
        _createElementVNode("ul", { class: "nav" }, [
          _createElementVNode("li", { class: "nav-item" }, [
            _createElementVNode("a", {
              class: "nav-link",
              href: "#"
            }, " Bumi Cendekia ")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(" 2021 - " + _toDisplayString((new Date()).getFullYear()) + ", made with ", 1),
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa fa-heart heart text-danger" }, null, -1)),
        _cache[1] || (_cache[1] = _createTextVNode(" by Bumi Cendekia "))
      ])
    ])
  ]))
}