<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="!assessment.length" class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - 80px)">
                        <img src="../../assets/img/e_s.png" width="200">
                        <span style="font-style: italic" v-if="done">Tidak ada tugas :)</span>
                        <span style="font-style: italic" v-else>Mendapatkan data tugas</span>
                    </div>
                    <div class="card assessment-item" v-else v-for="v in assessment">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div class="avatar-sm">
                                    <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(v.assignment.teacher_photo)">
                                </div>
                                <div class="d-flex flex-column ml-2">
                                    <span class="fw-bold">{{ v.assignment.teacher_name ?? '-' }}</span>
                                    <span style="font-size: 11px" class="text-muted">{{ toIndoDateNameTime(v.assignment.created_at) }}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center text-success mt-2" style="flex: 1">
                                <i class="fa fa-book"></i><span class="ml-2">{{ v.assignment.subject_name }} - {{ v.assignment.type }}</span>
                            </div>
                            <div class="d-flex align-items-center text-success mt-2"
                                 style="flex: 1; cursor: pointer"
                                 @click="show_student(v)">
                                <i class="fas fa-user-graduate"></i>
                                <span class="ml-2">
                                    {{ v.assignment.assignment_students.length }} Siswa
                                    <span v-if="v.assignment.assignment_students.filter(x => x.status !== 'new').length">
                                        ({{ v.assignment.assignment_students.filter(x => x.status !== 'new').length }} siswa telah mengerjakan)
                                    </span>
                                </span>
                            </div>
                            <div style="height: 1px; background: #eee" class="my-2"></div>
                            <div class="fw-bold mt-2" style="font-size: 16px">{{ v.assignment.title }}</div>
                            <div class="text-muted mt-2" style="font-size: 12px">{{ v.assignment.description }}</div>
                            <div v-if="v.assignment.link" class="mt-2">
                                <span style="font-size: 12px; font-weight: bold">Link:</span>
                                <a :href="v.assignment.link" target="_blank" class="d-block">{{ v.assignment.link }}</a>
                            </div>
                            <div v-if="v.assignment.questions.length" class="mt-2">
                                <span style="font-size: 12px; font-weight: bold">Unduh Soal ({{ v.assignment.questions.length }} file):</span>
                                <a v-for="q in v.assignment.questions" :href="q.url" target="_blank" class="d-block">{{ q.file_name }}</a>
                            </div>
                            <div style="height: 1px; background: #eee" class="my-2"></div>
                            <div style="font-size: 12px" class="fw-bold">Waktu Pengerjaan:</div>
                            <div>{{ toIndoDateNameTime(v.assignment.completion_date) }} - {{ toIndoDateNameTime(v.assignment.collection_date) }}</div>
                            <div style="height: 1px; background: #eee" class="my-2"></div>
                            <div v-if="v.status === 'new'">
                                <div class="text-danger" style="font-size: 12px">Kamu belum menyelesaikan tugas ini. Yuk kerjakan sekarang!</div>
                                <button class="btn btn-danger btn-sm mt-2" @click="detail(v.id)">
                                    Kerjakan Tugas
                                </button>
                            </div>
                            <div v-else>
                                <div class="text-success" style="font-size: 12px">Kamu sudah menyelesaikan tugas ini pada {{ toIndoDateNameTime(v.submitted_at) }}. Kalau kamu masih ragu dengan jawabanmu, kamu masih bisa mengeditnya.</div>
                                <button class="btn btn-success btn-sm mt-2" @click="detail(v.id)">
                                    Edit Jawaban
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal :show="showModal" title="Daftar Siswa" @close="showModal = false">
        <template #body>
            <div v-for="v in dataModal.assignment.assignment_students" class="card mb-2">
                <div class="card-body d-flex align-items-center">
                    <div class="avatar-sm">
                        <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(v.student_photo)">
                    </div>
                    <span style="flex: 1" class="ml-2">{{ v.student_name }}</span>
                    <span class="badge" :class="{'badge-success': v.status !== 'new', 'badge-danger': v.status === 'new'}">
                        {{ v.status !== 'new' ? 'SUDAH' : 'BELUM' }}
                    </span>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {IAssignment, IAssignmentStudent} from "@/utils/interface/Assignment";
import Api from "@/utils/Api";
import {error, loading, success} from "@/utils/Toast";
import Session from "@/utils/Session";
import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";
import {useRouter} from "vue-router";
import Modal from "@/components/partials/Modal.vue";

export default defineComponent({
    name: "MyAssessment",
    components: {Modal},
    setup() {
        const user = Session.getCurrentUser()!
        const assessment = ref<IAssignmentStudent[]>([])
        const router = useRouter()
        const done = ref(false)
        const showModal = ref(false)
        const dataModal = ref<IAssignmentStudent|null>(null)

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`assignment/student/assignment/${user.connection_id}`)
            t.remove()

            if (r.success) {
                assessment.value = r.data as IAssignmentStudent[]
            }
            else {
                error(r.message ?? 'gagal')
            }

            done.value = true
        })

        const show_student = (v: IAssignmentStudent) => {
            dataModal.value = v
            showModal.value = true
        }

        return {
            assessment, getPhoto, toIndoDateNameTime, done, show_student, showModal, dataModal,
            detail: (id: number) => router.push(`/my-assessment/${id}`)
        }
    }
})
</script>

<style scoped>
.assessment-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>