<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="fw-bold">Detail Kelas</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Kelas</label>
                                        <div>{{ ref_class.find(v => +v.id === +ref_class_id)?.name ?? '' }}</div>
                                    </div>
                                    <div class="form-group">
                                        <label>Nama Kelas</label>
                                        <div>{{ ref_class.find(v => +v.id === +ref_class_id)?.name ?? '' }} - {{ name ?? '' }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label>Wali Kelas</label>
                                        <div>{{ ref_user.find(v => +v.id === +user_id)?.name ?? '' }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="d-flex align-items-end mb-3">
                                            <label class="mb-0">Siswa</label>
                                            <div style="flex: 1"></div>
                                            <div class="d-flex flex-column align-items-end">
                                                <button class="btn btn-sm btn-success ml-2" @click="addStudent = true" v-if="!addStudent">
                                                    Tambah Siswa
                                                </button>
                                                <div class="d-flex">
                                                    <button class="btn btn-sm btn-success ml-2" @click="doAddStudent" v-if="addStudent">
                                                        Tambahkan
                                                    </button>
                                                    <button class="btn btn-sm btn-danger ml-2" @click="addStudent = false" v-if="addStudent">
                                                        Batal
                                                    </button>
                                                </div>
                                                <div class="my-2" v-if="addStudent" style="width: 250px">
                                                    <Multiselect
                                                        v-model="selectedAddStudent"
                                                        :options="refStudent"
                                                        :searchable="true"
                                                        :can-clear="false"
                                                        :can-deselect="false"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <table class="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Nama</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v, k) in students">
                                                <td>{{k + 1}}</td>
                                                <td>{{v.student.nama}}</td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm" @click="removeSiswa(v.student.id)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row justify-content-end">
                            <button class="btn btn-danger mr-2" @click="cancel">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Api from "@/utils/Api";
import {Kelas, Siswa} from "@/utils/Models";
import {error, loading, success} from "@/utils/Toast";
import Multiselect from "@vueform/multiselect";
import {IOption} from "@/utils/interface/Assignment";

export default defineComponent({
    name: "UpdateKelas",
    components: {
        Multiselect
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id

        const ref_class = ref([])
        const ref_user = ref([])
        const ref_class_id = ref('')
        const name = ref('')
        const user_id = ref('')
        const students = ref<any[]>([])
        const addStudent = ref(false)
        const refStudent = ref<IOption[]>([])
        const selectedAddStudent = ref<IOption|null>(null)

        const ref_class_idErr = ref('')
        const user_idErr = ref('')

        onMounted( () => {
            let loadings = [0, 0, 0, 0, 0]
            const t = loading('Mendapatkan data');
            const hideLoading = () => {
                if (loadings.every(v => v)) {
                    t.remove()
                }
            }

            (async () => {
                const rx = await Api.get(`master/kelas/${id}`)
                if (rx.success) {
                    const kelas = rx.data as Kelas
                    ref_class_id.value = kelas.ref_class_id
                    name.value = kelas.name
                    user_id.value = kelas.user_id
                }
                else {
                    error(rx.message ?? 'gagal')
                }
                loadings[0] = 1
                hideLoading()
            })();

            (async () => {
                const r = await Api.get(`master/kelas/ref`)
                if (r.success) {
                    ref_class.value = r.data
                }
                else {
                    error(r.message ?? 'gagal')
                }
                loadings[1] = 1
                hideLoading()
            })();

            (async () => {
                const r2 = await Api.get(`master/guru`)
                if (r2.success) {
                    ref_user.value = r2.data
                }
                else {
                    error(r2.message ?? 'gagal')
                }
                loadings[2] = 1
                hideLoading()
            })();

            (async () => {
                const r2 = await Api.get(`master/kelas/siswa/${id}`)
                if (r2.success) {
                    students.value = r2.data
                }
                else {
                    error(r2.message ?? 'gagal')
                }
                loadings[3] = 1
                hideLoading()
            })();

            (async () => {
                const r2 = await Api.get('master/journal')
                if (r2.success) {
                    refStudent.value = (r2.data.siswa as Siswa[]).map(v => ({value: v.id, label: `${v.nama}`}))
                }
                else {
                    error(r2.message ?? 'gagal')
                }
                loadings[4] = 1
                hideLoading()
            })();
        })

        const cancel = () => router.back()
        const save = async () => {
            ref_class_idErr.value = ''
            user_idErr.value = ''

            if (!ref_class_id.value) {
                ref_class_idErr.value = '* Pilih Kelas'
                return
            }
            if (!user_id.value) {
                user_idErr.value = '* Pilih Wali Kelas'
                return
            }

            const t = loading('Menyimpan data')
            const r = await Api.put(`master/kelas/update/${id}`, {
                ref_class_id: ref_class_id.value,
                name: name.value,
                user_id: user_id.value
            })
            t.remove()

            if (r.success) {
                router.back()
                success('Berhasil menyimpan data')
            }
            else {
                error(r.message ?? 'Gagal')
            }
        }

        const removeSiswa = async (student_id: number) => {
            if (!confirm('Yakin hapus siswa dari kelas ini?')) {
                return
            }

            const t = loading('Menghapus siswa');
            const r2 = await Api.get(`master/kelas/siswa/${id}/delete/${student_id}`)
            if (r2.success) {
                students.value = r2.data
            }
            else {
                error(r2.message ?? 'gagal')
            }
            t.remove()
            success('Berhasil menghapus siswa')
        }

        const doAddStudent = async () => {
            if (!selectedAddStudent.value) {
                return
            }
            if (students.value.find(v => +v.student_id === +selectedAddStudent.value!)) {
                error('Siswa sudah ada di kelas ini')
                return
            }
            const t = loading('Menambah siswa');
            const r2 = await Api.get(`master/kelas/siswa/${id}/add/${selectedAddStudent.value}`)
            if (r2.success) {
                students.value = r2.data
                success('Berhasil menambah siswa')
                addStudent.value = false
            }
            else {
                error(r2.message ?? 'gagal')
            }
            t.remove()
        }

        return {
            cancel, save, ref_class, ref_user, ref_class_id, ref_class_idErr, name, user_id, user_idErr, students,
            removeSiswa, addStudent, refStudent, selectedAddStudent, doAddStudent
        }
    }
})
</script>

<style scoped>

</style>
