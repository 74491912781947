import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-head-row" }
const _hoisted_8 = { class: "card-tools" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "table table-bordered" }
const _hoisted_11 = { class: "d-block" }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, "Syllabus / Lesson Plan", -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
                    class: "b-time btn btn-success btn-round mr-2"
                  }, " Add ")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("table", _hoisted_10, [
                _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "No"),
                    _createElementVNode("th", null, "Guru Pembuat"),
                    _createElementVNode("th", null, "Subject"),
                    _createElementVNode("th", null, "Kelas"),
                    _createElementVNode("th", null, "Semester"),
                    _createElementVNode("th", null, "Lampiran"),
                    _createElementVNode("th", null, "Aksi")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.syllabus, (v, k) => {
                    return (_openBlock(), _createElementBlock("tr", null, [
                      _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                      _createElementVNode("td", null, _toDisplayString(v.teacher_name), 1),
                      _createElementVNode("td", null, _toDisplayString(v.subject_name), 1),
                      _createElementVNode("td", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.grades, (g) => {
                          return (_openBlock(), _createElementBlock("label", _hoisted_11, _toDisplayString(g.nama_kelas), 1))
                        }), 256))
                      ]),
                      _createElementVNode("td", null, _toDisplayString(v.semester_name), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("a", {
                          href: v.attachment?.url,
                          target: "_blank"
                        }, _toDisplayString(v.attachment?.name), 9, _hoisted_12)
                      ]),
                      _createElementVNode("td", null, [
                        _createElementVNode("button", {
                          class: "btn btn-warning btn-sm mr-1",
                          onClick: ($event: any) => (_ctx.edit(v.id))
                        }, _cache[2] || (_cache[2] = [
                          _createElementVNode("i", { class: "fa fa-pencil" }, null, -1)
                        ]), 8, _hoisted_13),
                        _createElementVNode("button", {
                          class: "btn btn-success btn-sm mr-1",
                          onClick: ($event: any) => (_ctx.detail(v.id))
                        }, _cache[3] || (_cache[3] = [
                          _createElementVNode("i", { class: "fa fa-eye" }, null, -1)
                        ]), 8, _hoisted_14),
                        _createElementVNode("button", {
                          class: "btn btn-danger btn-sm",
                          onClick: ($event: any) => (_ctx.destroy(v.id))
                        }, _cache[4] || (_cache[4] = [
                          _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                        ]), 8, _hoisted_15)
                      ])
                    ]))
                  }), 256))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}