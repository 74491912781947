<template>
    <div class="row no-gutters" style="height: 100%">
        <div class="col-sm-3 col-md-4 left">
            <div v-if="chatGroups.length" v-for="v in chatGroups"
                 class="d-flex align-items-center py-2 px-3 cg-item"
                 :class="{active: v.active && !chosenPerson}"
                 @click="setActive(v)"
            >
                <img :src="getPhoto(getOpponent(v))" alt="" class="avatar-img rounded-circle mr-2" style="width: 50px; height: 50px">
                <div class="d-flex flex-column" style="flex: 1">
                    <b>{{ getOpponent(v).name }}</b>
                    <div class="d-flex align-items-center">
                        <i v-if="v.last_sender_id === user.id"
                           class="fa fa-check mr-1"
                           :style="{color: v.active ? 'white' : '#aaaaaa'}"></i>
                        <span v-if="!v.last_chat_seen && v.last_sender_id !== user.id"
                              :class="{'unread-bullet-white': v.active}"
                              class="unread-bullet">
                        </span>
                        <span
                            class="last-chat"
                            :class="{'unread': !v.last_chat_seen && v.last_sender_id !== user.id, 'unread-white': v.active}">
                            {{ ellipsize(v.last_chat) }}
                        </span>
                    </div>
                    <span style="font-size: 12px; margin-left: auto">{{ v.last_sent }}</span>
                </div>
            </div>
            <div v-else class="mx-auto my-auto px-5 text-center">
                <i class="mt-5 d-inline-block">Click `Start New Chat` to start chatting now...</i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch} from "vue";
import {getOpponent, IChat, IChatGroup} from "@/utils/interface/Chat";
import Api from "../../utils/Api";
import {ellipsize, getPhoto} from "@/utils/Helpers";
import Session from "../../utils/Session";
import {User} from "@/utils/Models";
import {useStore} from "vuex";

export default defineComponent({
    name: "InboxMobileChatGroup",
    props: {
        chosenPerson: Object as PropType<User|null>,
        load: Number,
    },
    emits: ['setActiveGroup', 'clearChosenPerson'],
    setup(props, {emit}) {
        const chats = ref<IChat[]>([])
        const activeChatGroup = ref<IChatGroup|null>(null)
        const chatGroups = ref<IChatGroup[]>([])
        const user = Session.getCurrentUser()!
        const store = useStore()

        const reload = async () => {
            const r = await Api.get(`chat/group/${user.id}`)
            if (r.success) {
                chatGroups.value = (r.data as IChatGroup[]).map(v => ({...v, active: v.id === activeChatGroup.value?.id}))
            }
        }

        watch(() => props.load, (a, b) => reload())

        onMounted(() => reload())
        const timer = setInterval(() => reload(), 10000)
        onBeforeUnmount(() => clearInterval(timer))

        const setActive = (cg: IChatGroup) => {
            if (!cg.last_chat_seen && cg.last_sender_id != user.id) {
                store.commit('adaChat', false)
            }

            emit('clearChosenPerson')
            chatGroups.value.forEach(v => v.active = false)
            cg.active = true
            cg.last_chat_seen = 1
            activeChatGroup.value = cg
            emit('setActiveGroup', cg)
        }

        return {
            getPhoto, chatGroups, getOpponent, setActive, activeChatGroup, user, ellipsize
        }
    }
})
</script>

<style scoped>
.cg-item {
    cursor: pointer;
}
.cg-item:hover {
    background: #eeeeee;
}
.cg-item.active {
    background: #31CE36;
    color: white;
}
.last-chat {
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow: ellipsis;
}
.unread {
    font-weight: bold;
    color: #31CE36;
}
.unread-bullet {
    background-color: #31CE36;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    margin-right: 4px;
}
.unread-white {
    color: white;
}
.unread-bullet-white {
    background: white;
}
</style>