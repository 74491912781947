<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
<!--                                <div class="card-title">Classroom Journal {{classroomJournalGroup?.grade_name}} On {{toIndoDateName(classroomJournalGroup?.date)}}</div>-->
                                <div class="card-title">Classroom Journal On {{toIndoDateName(classroomJournalGroup?.date)}}</div>
                                <div class="card-tools">
                                    <button @click="back" class="b-time btn btn-success btn-round mr-2">
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Date</th>
                                        <th>Lesson Hours</th>
                                        <th>Grade</th>
                                        <th>Subject</th>
                                        <th>Teacher</th>
                                        <th>Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v, k) in classroomJournalGroup.data">
                                        <td>{{ k + 1 }}</td>
                                        <td>{{ toIndoDateName(v.date) }}</td>
                                        <td>{{ v.lesson_hours }}</td>
                                        <td>{{ v.grade_name ?? '' }}</td>
                                        <td>{{ v.subject_name }}</td>
                                        <td>{{ v.teacher_name }}</td>
                                        <td>
                                            <button class="btn btn-warning btn-sm" @click="edit(v.id)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                            <button class="btn btn-danger btn-sm ml-2" @click="destroy(v.id)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                            <button class="btn btn-success btn-sm ml-2" @click="detail(v.id)">
                                                <i class="fa fa-eye"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {IClassroomJournalGroup} from "@/utils/interface/ClassroomJournal";
import {toIndoDateName} from "@/utils/Helpers";
import {useRouter} from "vue-router";
import {error, loading, success} from "@/utils/Toast";
import Api from "@/utils/Api";

export default defineComponent({
    name: "ClassroomJournalList",
    props: {
        classroomJournalGroup: {type: Object as PropType<IClassroomJournalGroup>, required: true},
		level: {type: String, required: true}
    },
    emits: ['back'],
    setup(_, {emit}) {
        const router = useRouter()

        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.delete(`classroom-journal/delete/${id}`)
                t.remove()

                if (r.success) {
                    success('Berhasil menghapus data')
                    emit('back', r.data)
                }
                else {
                    error(r.message ?? 'Gagal')
                }
            }
        }

        return {
            toIndoDateName,
            detail: (id: number) => router.push(`/classroom-journal/${_.level}/${id}`),
            edit: (id: number) => router.push(`/classroom-journal/${_.level}/edit/${id}`),
            destroy,
            back: () => emit('back')
        }
    }
})
</script>

<style scoped>

</style>