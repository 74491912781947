import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = { class: "col-sm-12 col-md-6" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "profile-picture mb-1" }
const _hoisted_11 = { class: "avatar avatar-xl" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "d-flex flex-column ml-2" }
const _hoisted_14 = { class: "fw-bold text-primary mb-0" }
const _hoisted_15 = { class: "table table-bordered mt-2" }
const _hoisted_16 = { class: "card-footer d-flex flex-row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "fw-bold" }, "Detail Siswa")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.siswa)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("img", {
                              src: _ctx.photo_url ?? _ctx.getPhoto(_ctx.siswa),
                              alt: "...",
                              class: "avatar-img rounded-circle",
                              id: "photo-display"
                            }, null, 8, _hoisted_12)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.siswa.nama), 1),
                          _createElementVNode("label", null, "NISN: " + _toDisplayString(_ctx.siswa.nisn), 1),
                          _createElementVNode("label", null, "NIS: " + _toDisplayString(_ctx.siswa.nis), 1)
                        ])
                      ]),
                      _createElementVNode("table", _hoisted_15, [
                        _createElementVNode("tbody", null, [
                          _createElementVNode("tr", null, [
                            _cache[1] || (_cache[1] = _createElementVNode("th", null, "Tempat Lahir", -1)),
                            _createElementVNode("td", null, _toDisplayString(_ctx.siswa.tempat_lahir), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[2] || (_cache[2] = _createElementVNode("th", null, "Tanggal Lahir", -1)),
                            _createElementVNode("td", null, _toDisplayString(_ctx.toIndoDateName(_ctx.siswa.tgl_lahir)), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[3] || (_cache[3] = _createElementVNode("th", null, "Agama", -1)),
                            _createElementVNode("td", null, _toDisplayString(_ctx.siswa.agama), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[4] || (_cache[4] = _createElementVNode("th", null, "Jenis Kelamin", -1)),
                            _createElementVNode("td", null, _toDisplayString(_ctx.siswa.jenis_kelamin === 'L' ? 'Laki-Laki' : 'Perempuan'), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[5] || (_cache[5] = _createElementVNode("th", null, "Alamat", -1)),
                            _createElementVNode("td", null, _toDisplayString(_ctx.siswa.alamat), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[6] || (_cache[6] = _createElementVNode("th", null, "Kelas", -1)),
                            _createElementVNode("td", null, _toDisplayString(_ctx.siswa.nama_kelas || _ctx.siswa.kelas), 1)
                          ])
                        ])
                      ])
                    ]),
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-sm-12 col-md-6" }, null, -1))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}