import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12 col-md-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = {
  class: "d-flex",
  style: {"width":"100%","padding-top":"10px","padding-bottom":"10px"}
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["checked"]
const _hoisted_23 = { class: "ml-1" }
const _hoisted_24 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_25 = { class: "form-group" }
const _hoisted_26 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_27 = { class: "form-group" }
const _hoisted_28 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_29 = { class: "col-12 col-md-6" }
const _hoisted_30 = { class: "form-group" }
const _hoisted_31 = { class: "form-control" }
const _hoisted_32 = {
  class: "form-control mb-2 d-flex align-items-center",
  style: {"padding":"0.6rem"}
}
const _hoisted_33 = { style: {"flex":"1","min-width":"0"} }
const _hoisted_34 = ["href"]
const _hoisted_35 = { key: 1 }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "d-flex flex-row-reverse" }
const _hoisted_38 = { class: "form-group" }
const _hoisted_39 = { class: "form-group" }
const _hoisted_40 = { class: "card-footer d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[30] || (_cache[30] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title" }, "Edit Assesment / Assignment")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", null, [
                      _createTextVNode("Guru Pembuat "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.teacher_id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.request.teacher_id) = $event)),
                      options: _ctx.refTeacher,
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.validation.teacher)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.validation.teacher), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", null, [
                      _createTextVNode("Subject "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.subject_id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.request.subject_id) = $event)),
                      options: _ctx.refSubject,
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.validation.subject)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.validation.subject), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[16] || (_cache[16] = _createElementVNode("label", null, [
                      _createTextVNode("Judul Tugas "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.request.title) = $event)),
                      class: "form-control",
                      id: "judul-tugas"
                    }, null, 512), [
                      [_vModelText, _ctx.request.title]
                    ]),
                    (_ctx.validation.title)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.validation.title), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[17] || (_cache[17] = _createElementVNode("label", null, [
                      _createTextVNode("Tipe Tugas "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.type,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.request.type) = $event)),
                      options: _ctx.refType,
                      searchable: true,
                      "can-clear": false,
                      "can-deselect": false
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.validation.type)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.validation.type), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[18] || (_cache[18] = _createElementVNode("label", null, [
                      _createTextVNode("Kelas "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      mode: "tags",
                      modelValue: _ctx.request.grade_options,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.request.grade_options) = $event)),
                      options: _ctx.refGrade,
                      searchable: true,
                      multiple: true,
                      onChange: _ctx.on_grade_change
                    }, null, 8, ["modelValue", "options", "onChange"]),
                    (_ctx.validation.grade)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.validation.grade), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _cache[21] || (_cache[21] = _createElementVNode("label", null, [
                      _createTextVNode("Siswa "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.request.student_options,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.request.student_options) = $event)),
                      mode: "multiple",
                      groups: true,
                      searchable: true,
                      "hide-selected": false,
                      "create-option": false,
                      "close-on-select": false,
                      "group-select": false,
                      options: _ctx.refStudent,
                      "no-options-text": _ctx.request.grade_options?.length ? 'Tidak ada siswa di kelas terpilih' : 'Pilih kelas dulu',
                      onClear: _ctx.on_student_clear
                    }, {
                      grouplabel: _withCtx(({ group, isPointed, isSelected }) => [
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("span", null, _toDisplayString(group.label) + " " + _toDisplayString(group.value), 1),
                          _cache[19] || (_cache[19] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
                          _createElementVNode("span", {
                            style: {"font-weight":"400","cursor":"pointer"},
                            onClick: ($event: any) => (_ctx.toggle_select_all(group.value, group.student_complete)),
                            class: "text-success"
                          }, _toDisplayString(group.student_complete ? 'Hapus semua' : 'Pilih semua'), 9, _hoisted_21)
                        ])
                      ]),
                      option: _withCtx(({ option, isSelected }) => [
                        _createElementVNode("input", {
                          type: "checkbox",
                          checked: isSelected(option)
                        }, null, 8, _hoisted_22),
                        _cache[20] || (_cache[20] = _createTextVNode()),
                        _createElementVNode("span", _hoisted_23, _toDisplayString(option.label), 1)
                      ]),
                      multiplelabel: _withCtx(({ values }) => [
                        _createElementVNode("div", {
                          class: "multiselect-multiple-label",
                          style: _normalizeStyle(`white-space: nowrap; overflow: hidden; width: ${_ctx.width - 60}px; text-overflow: ellipsis;`)
                        }, _toDisplayString(values.length) + " student" + _toDisplayString(values.length > 1 ? 's' : ''), 5)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "options", "no-options-text", "onClear"]),
                    (_ctx.validation.student)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.validation.student), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", null, [
                      _createTextVNode("Tanggal & Waktu Pengerjaan "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "datetime-local",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.request.completion_date) = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, _ctx.request.completion_date]
                    ]),
                    (_ctx.validation.completion_date)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.validation.completion_date), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _cache[23] || (_cache[23] = _createElementVNode("label", null, [
                      _createTextVNode("Tanggal & Waktu Pengumpulan "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "datetime-local",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.request.collection_date) = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, _ctx.request.collection_date]
                    ]),
                    (_ctx.validation.collection_date)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.validation.collection_date), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, [
                    _cache[26] || (_cache[26] = _createElementVNode("label", null, [
                      _createTextVNode("Unggah Soal "),
                      _createElementVNode("b", { class: "text-danger" }, "*")
                    ], -1)),
                    _createElementVNode("div", _hoisted_31, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request.questions.filter(v => v.deleted !== true), (v) => {
                        return (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createElementVNode("span", _hoisted_33, [
                            (v.url)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  href: v.url ?? '',
                                  target: "_blank"
                                }, _toDisplayString(v.file_name), 9, _hoisted_34))
                              : (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(v.file?.name), 1))
                          ]),
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-danger ml-1",
                            onClick: () => v.deleted = true
                          }, _cache[24] || (_cache[24] = [
                            _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                          ]), 8, _hoisted_36)
                        ]))
                      }), 256)),
                      _createElementVNode("input", {
                        type: "file",
                        ref: "question_file",
                        id: "question_file",
                        accept: "image/*, .mp3, video/mp4, video/x-m4v, video/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .zip, .rar, .7zip",
                        onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.question_change && _ctx.question_change(...args))),
                        style: {"display":"none"}
                      }, null, 544),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("button", {
                          type: "button",
                          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.add_question && _ctx.add_question(...args))),
                          class: "btn btn-sm btn-success"
                        }, _cache[25] || (_cache[25] = [
                          _createElementVNode("i", { class: "fa fa-plus" }, null, -1),
                          _createTextVNode(" Tambah File ")
                        ]))
                      ])
                    ]),
                    _cache[27] || (_cache[27] = _createElementVNode("p", { class: "mt-1 mb-0" }, "Tipe file yang diizinkan adalah .mp3, .mp4, .pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .xlsx, .xls, .rar, .zip, .ppt dengan maksimal 5 MB.", -1))
                  ]),
                  _createElementVNode("div", _hoisted_38, [
                    _cache[28] || (_cache[28] = _createElementVNode("label", null, "Link File", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.request.link) = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, _ctx.request.link]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _cache[29] || (_cache[29] = _createElementVNode("label", null, "Deskripsi", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      rows: "6",
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.request.description) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.request.description]
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("button", {
                class: "btn btn-success",
                onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
              }, "Simpan"),
              _createElementVNode("button", {
                class: "btn btn-danger mr-2",
                onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, "Kembali")
            ])
          ])
        ])
      ])
    ])
  ]))
}