<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="d-flex align-items-end">
                <button class="btn btn-success mb-2" @click="add"><i class="fa fa-plus"></i> Tambah</button>
                <div class="form-group ml-4" style="width: 200px">
                    <label>Tipe Subject</label>
                    <select class="form-control">
                        <option value="">--Semua--</option>
                        <option value="Karakter">Karakter</option>
                        <option value="Literasi">Literasi</option>
                        <option value="Numerasi">Numerasi</option>
                    </select>
                </div>
            </div>
			<PaginatedTable :data="pelajaran" class="mt-3">
				<template #thead>
					<th>No</th>
					<th>Kode Subject</th>
					<th>Nama Subject</th>
					<th>Tipe Subject</th>
					<th>Aksi</th>
				</template>
				<template #tbody="{row, index}">
					<td>{{ index + 1 }}</td>
					<td>{{ row.data.kode }}</td>
					<td>{{ row.data.nama }}</td>
					<td>{{ row.data.tipe }}</td>
					<td>
						<button class="btn btn-sm btn-success mr-1" @click="detail(row.data.id)"><i class="fa fa-eye"></i> </button>
						<button class="btn btn-sm btn-warning mr-1" @click="edit(row.data.id)"><i class="fa fa-pencil"></i> </button>
						<button class="btn btn-sm btn-danger" @click="destroy(row.data.id)"><i class="fa fa-trash"></i> </button>
					</td>
				</template>
			</PaginatedTable>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Api from "@/utils/Api";
import {Pelajaran} from "@/utils/Models";
import {useRouter} from "vue-router";
import {error, loading, success} from "@/utils/Toast";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "TabMataPelajaran",
	components: {PaginatedTable},
    setup() {
        const router = useRouter()
        const pelajaran = ref<Pelajaran[]>([])

        const reload = async () => {
            const res = await Api.get('master/pelajaran')
            if (res.success) {
                pelajaran.value = res.data as Pelajaran[]
            }
            else {
                error(res.message ?? 'Gagal medapatkan data')
            }
        }

        onMounted(async () => {
            await reload()
        })

        const add = () => router.push('/master-data/add-mapel')
        const destroy = async (id: number) => {
            if (confirm('Yakin hapus data ini?')) {
                const t = loading('Menghapus data')
                const r = await Api.delete(`master/pelajaran/destroy/${id}`)
                t.remove()

                if (r.success) {
                    success('Berhasil menghapus data')
                    await reload()
                }
                else {
                    error(r.message ?? 'Gagal')
                }
            }
        }
        const edit = (id: number) => router.push(`/master-data/edit-mapel/${id}`)
        const detail = (id: number) => router.push(`/master-data/detail-mapel/${id}`)

        return { pelajaran, add, destroy, edit, detail }
    }
})
</script>

<style scoped>

</style>
