import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "fas fa-home"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "fas fa-graduation-cap"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "fas fa-bookmark"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "fas fa-user-graduate"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  class: "fas fa-book"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "fas fa-calendar"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 1,
  class: "fas fa-suitcase-medical"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "fas fa-bullhorn"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  key: 1,
  class: "fas fa-bell"
}
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  key: 1,
  class: "fa fa-inbox"
}
const _hoisted_21 = { class: "sub-item" }
const _hoisted_22 = {
  key: 0,
  class: "notif-badge d-inline-block ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(`nav ${_ctx.isAndroid ? 'nav-primary-app' : 'nav-primary'}`)
  }, [
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/timeline')}])
    }, [
      _createVNode(_component_router_link, { to: "/timeline" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/timeline') ? '-active' : ''}/dashboard.png`
              }, null, 8, _hoisted_1))
            : (_openBlock(), _createElementBlock("i", _hoisted_2)),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "sub-item" }, "Dashboard ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/academic-development')}])
    }, [
      _createVNode(_component_router_link, { to: "/academic-development" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/academic-development') ? '-active' : ''}/progress-achievement.png`
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("i", _hoisted_4)),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sub-item" }, "Progress / Achievements ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/student-classroom')}])
    }, [
      _createVNode(_component_router_link, { to: "/student-classroom" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/student-classroom') ? '-active' : ''}/classroom-journal.png`
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("i", _hoisted_6)),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sub-item" }, "Classroom Journal ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/score-list')}])
    }, [
      _createVNode(_component_router_link, { to: "/score-list" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/score-list') ? '-active' : ''}/score-list.png`
              }, null, 8, _hoisted_7))
            : (_openBlock(), _createElementBlock("i", _hoisted_8)),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sub-item" }, "Score List ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/parent-report')}])
    }, [
      _createVNode(_component_router_link, { to: "/parent-report" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/parent-report') ? '-active' : ''}/report.png`
              }, null, 8, _hoisted_9))
            : (_openBlock(), _createElementBlock("i", _hoisted_10)),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "sub-item" }, "Report ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/academic-calendar')}])
    }, [
      _createVNode(_component_router_link, { to: "/academic-calendar" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/academic-calendar') ? '-active' : ''}/calendar.png`
              }, null, 8, _hoisted_11))
            : (_openBlock(), _createElementBlock("i", _hoisted_12)),
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "sub-item" }, "Academic Calendar ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/student-health')}])
    }, [
      _createVNode(_component_router_link, { to: "/student-health" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/student-health') ? '-active' : ''}/health.png`
              }, null, 8, _hoisted_13))
            : (_openBlock(), _createElementBlock("i", _hoisted_14)),
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "sub-item" }, "Health ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/announcements')}])
    }, [
      _createVNode(_component_router_link, { to: "/announcements" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/announcements') ? '-active' : ''}/announcement.png`
              }, null, 8, _hoisted_15))
            : (_openBlock(), _createElementBlock("i", _hoisted_16)),
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sub-item" }, "Announcement ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/notification')}])
    }, [
      _createVNode(_component_router_link, { to: "/notification" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/notification') ? '-active' : ''}/notification.png`
              }, null, 8, _hoisted_17))
            : (_openBlock(), _createElementBlock("i", _hoisted_18)),
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "sub-item" }, "Notification ", -1))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["nav-item", {active: _ctx.route.fullPath.startsWith('/inbox')}])
    }, [
      _createVNode(_component_router_link, { to: "/inbox" }, {
        default: _withCtx(() => [
          (_ctx.isAndroid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                src: `/images/ortu${_ctx.route.fullPath.startsWith('/inbox') ? '-active' : ''}/inbox.png`
              }, null, 8, _hoisted_19))
            : (_openBlock(), _createElementBlock("i", _hoisted_20)),
          _createElementVNode("span", _hoisted_21, [
            _cache[9] || (_cache[9] = _createTextVNode("Inbox ")),
            (_ctx.adaChat)
              ? (_openBlock(), _createElementBlock("span", _hoisted_22))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ], 2)
  ], 2))
}