<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Detail Daily Schedule</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="done">
                                <div class="col-sm-12 col-md-6">
                                    <b>Tahun Ajaran</b>
                                    <p>{{ schedule.tahun_ajaran }}</p>
                                </div>
                            </div>
                            <div class="row" v-if="done">
                                <div class="col-sm-12">
                                    <table class="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td rowspan="2" class="fw-bold">Day</td>
                                            <td rowspan="2" class="fw-bold">Time</td>
                                            <td v-for="g in Object.keys(compRefGrade).length" :colspan="compRefGrade[g].length" class="fw-bold">
                                                {{ compRefGrade[g][0].ref_class.name }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-for="g in refGrade" class="fw-bold">
                                                {{ g.nama_kelas }}
                                            </td>
                                        </tr>
                                        <DailyScheduleTime
                                            :times="mondayTimes"
                                            :day="`Monday`"
                                            :ref-grade="refGrade"
                                            :add-time="addTime"
                                            :edit-time="editTime"
                                            :delete-time="deleteTime"
                                            :add-subject="addSubject"
                                            :edit-subject="editSubject"
                                            :delete-subject="deleteSubject"
                                            :edit-event="editEvent"
                                        />
                                        <DailyScheduleTime
                                            :times="tuesdayTimes"
                                            :day="`Tuesday`"
                                            :ref-grade="refGrade"
                                            :add-time="addTime"
                                            :edit-time="editTime"
                                            :delete-time="deleteTime"
                                            :add-subject="addSubject"
                                            :edit-subject="editSubject"
                                            :delete-subject="deleteSubject"
                                            :edit-event="editEvent"
                                        />
                                        <DailyScheduleTime
                                            :times="wednesdayTimes"
                                            :day="`Wednesday`"
                                            :ref-grade="refGrade"
                                            :add-time="addTime"
                                            :edit-time="editTime"
                                            :delete-time="deleteTime"
                                            :add-subject="addSubject"
                                            :edit-subject="editSubject"
                                            :delete-subject="deleteSubject"
                                            :edit-event="editEvent"
                                        />
                                        <DailyScheduleTime
                                            :times="thursdayTimes"
                                            :day="`Thursday`"
                                            :ref-grade="refGrade"
                                            :add-time="addTime"
                                            :edit-time="editTime"
                                            :delete-time="deleteTime"
                                            :add-subject="addSubject"
                                            :edit-subject="editSubject"
                                            :delete-subject="deleteSubject"
                                            :edit-event="editEvent"
                                        />
                                        <DailyScheduleTime
                                            :times="fridayTimes"
                                            :day="`Friday`"
                                            :ref-grade="refGrade"
                                            :add-time="addTime"
                                            :edit-time="editTime"
                                            :delete-time="deleteTime"
                                            :add-subject="addSubject"
                                            :edit-subject="editSubject"
                                            :delete-subject="deleteSubject"
                                            :edit-event="editEvent"
                                        />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex flex-row-reverse">
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal :show="showModalAddHours" @close="closeModalTime" :title="modalTimeTitle">
        <template #body>
            <div class="form-group">
                <label>Hours <b class="text-danger">*</b></label>
                <input type="text" class="form-control" v-model="modalAddHours">
                <span class="text-danger" v-if="validation.time">{{ validation.time }}</span>
            </div>
        </template>
        <template #footer>
            <button class="btn btn-danger btn-sm" @click="closeModalTime">
                Cancel
            </button>
            <button class="btn btn-success btn-sm" @click="saveTime">
                Save
            </button>
        </template>
    </Modal>
    <Modal :show="showModalSubject" @close="closeModalSubject" :title="modalSubjectTitle">
        <template #body>
            <div class="form-group">
                <label>Subject <b class="text-danger">*</b></label>
                <Multiselect v-model="modalSubjectId" :options="refSubject" :searchable="true" :can-clear="false" :can-deselect="false"/>
                <span class="text-danger" v-if="validation.subject">{{ validation.subject }}</span>
            </div>
        </template>
        <template #footer>
            <button class="btn btn-danger btn-sm" @click="closeModalSubject">
                Cancel
            </button>
            <button class="btn btn-success btn-sm" @click="saveSubject">
                Save
            </button>
        </template>
    </Modal>
    <Modal :show="showModalEvent" @close="closeModalEvent" :title="modalEventTitle">
        <template #body>
            <div class="form-group">
                <label>Event <b class="text-danger">*</b></label>
                <input type="text" class="form-control" v-model="modalEventEvent">
                <span class="text-danger" v-if="validation.event">{{ validation.event }}</span>
            </div>
        </template>
        <template #footer>
            <button class="btn btn-danger btn-sm" @click="closeModalEvent">
                Cancel
            </button>
            <button v-if="modalEventTime && modalEventTime.event" class="btn btn-warning btn-sm" @click="deleteEvent">
                Delete
            </button>
            <button class="btn btn-success btn-sm" @click="saveEvent">
                Save
            </button>
        </template>
    </Modal>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Api from "@/utils/Api";
import {IDailySchedule, IDailyScheduleTime} from "@/utils/interface/DailySchedule";
import {error, loading, success} from "@/utils/Toast";
import Modal from "@/components/partials/Modal.vue";
import {Kelas, Pelajaran} from "@/utils/Models";
import {groupBy} from "@/utils/Helpers";
import DailyScheduleTime from "@/components/academic/daily_schedule/partials/DailyScheduleTime.vue";
import {IOption} from "@/utils/interface/Assignment";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
    name: "DetailDailySchedule",
    components: {DailyScheduleTime, Modal, Multiselect},
    setup() {
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id
        const done = ref(false)
        const schedule = ref<IDailySchedule>({tahun_ajaran: ''})
        const refSubject = ref<IOption[]>([])
        const showModalAddHours = ref(false)
        const modalAddHours = ref('')
        const refGrade = ref<Kelas[]>([])
        const compRefGrade = computed(() => groupBy(refGrade.value, 'ref_class_id'))
        const modalTimeTitle = ref('')
        const modalDay = ref('')
        const modalEditedId = ref<number|null>(null)
        const validation = reactive({time: '', subject: '', event: ''})
        const showModalSubject = ref(false)
        const modalSubjectTitle = ref('')
        const modalSubjectDailyScheduleSubjectId = ref(0)
        const modalSubjectId = ref(0)
        const showModalEvent = ref(false)
        const modalEventTitle = ref('')
        const modalEventEvent = ref('')
        const modalEventTime = ref<IDailyScheduleTime|null>(null)

        const mondayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Monday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })
        const tuesdayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Tuesday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })
        const wednesdayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Wednesday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })
        const thursdayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Thursday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })
        const fridayTimes = computed(() => {
            const res = schedule.value.daily_schedule_times?.filter(v => v.day === 'Friday')
            return !res || res.length === 0 ? [{first: true}] : res as IDailyScheduleTime[]
        })

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r2 = await Api.get(`daily-schedule/${id}`)
            const r = await Api.get('master/journal')
            t.remove()

            if (r2.success) {
                const a = r2.data as IDailySchedule

                schedule.value.id = a.id
                schedule.value.tahun_ajaran = a.tahun_ajaran
                schedule.value.daily_schedule_times = a.daily_schedule_times
                done.value = true
            }
            else {
                error(r2.message ?? 'gagal')
            }
            if (r.success) {
                refGrade.value = r.data.grade as Kelas[]
                refSubject.value = (r.data.mapel as Pelajaran[]).map(v => ({value: v.id, label: v.nama}))
            }
        })
        
        const timeEditing = {
            addTime: (day: string) => {
                modalDay.value = day
                modalTimeTitle.value = `Add Time On ${day}`
                showModalAddHours.value = true
            },
            editTime: (id: number, time: string) => {
                modalDay.value = ''
                modalTimeTitle.value = `Edit Time`
                modalAddHours.value = time
                modalEditedId.value = id
                showModalAddHours.value = true
            }, 
            saveTime: async () => {
                validation.time = ''
                if (!modalAddHours.value) {
                    validation.time = 'Masukkan jam'
                    return
                }

                if (modalEditedId.value) {
                    const t = loading('Menyimpan data')
                    const r = await Api.post(`daily-schedule/time/update/${modalEditedId.value}`, {
                        time: modalAddHours.value
                    })
                    t.remove()

                    if (r.success) {
                        success('Berhasil menyimpan')
                        schedule.value.daily_schedule_times = (r.data as IDailySchedule).daily_schedule_times
                        closeModalTime()
                    }
                    else {
                        error(r.message ?? 'gagal')
                    }
                }
                else {
                    const t = loading('Menyimpan data')
                    const r = await Api.post('daily-schedule/time/create', {
                        daily_schedule_id: schedule.value.id,
                        day: modalDay.value,
                        time: modalAddHours.value
                    })
                    t.remove()

                    if (r.success) {
                        success('Berhasil menambah jam')
                        schedule.value.daily_schedule_times = (r.data as IDailySchedule).daily_schedule_times
                        closeModalTime()
                    }
                    else {
                        error(r.message ?? 'gagal')
                    }
                }
            },
            deleteTime: async (id: number) => {
                if (confirm('Hapus data ini?')) {
                    const t = loading('Menghapus')
                    const r = await Api.delete(`daily-schedule/time/delete/${id}`)
                    t.remove()

                    if (r.success) {
                        success('Berhasil menghapus')
                        schedule.value.daily_schedule_times = (r.data as IDailySchedule).daily_schedule_times
                    }
                    else {
                        error(r.message ?? 'gagal')
                    }
                }
            } 
        }

        const subjectEditing = {
            addSubject: (id: number) => {
                modalSubjectTitle.value = 'Add Subject'
                modalSubjectDailyScheduleSubjectId.value = id
                showModalSubject.value = true
            },
            editSubject: (id: number, subject_id: number) => {
                modalSubjectTitle.value = 'Edit Subject'
                modalSubjectDailyScheduleSubjectId.value = id
                modalSubjectId.value = subject_id
                showModalSubject.value = true
            },
            saveSubject: async () => {
                validation.subject = ''
                if (!modalSubjectId.value) {
                    validation.subject = 'Pilih subject dulu'
                    return
                }

                const t = loading('Menyimpan')
                const r = await Api.post(`daily-schedule/subject/update/${modalSubjectDailyScheduleSubjectId.value}`, {
                    subject_id: modalSubjectId.value,
                    daily_schedule_id: id
                })
                t.remove()

                if (r.success) {
                    schedule.value.daily_schedule_times = (r.data as IDailySchedule).daily_schedule_times
                    closeModalSubject()
                }
                else {
                    error(r.message ?? 'gagal')
                }
            },
            deleteSubject: () => {

            }
        }

        const eventEditing = {
            editEvent: (t : IDailyScheduleTime) => {
                modalEventTime.value = t
                modalEventEvent.value = t.event ?? ''
                modalEventTitle.value = `${t.event ? 'Edit' : 'Add'} Event On ${t.day} ${t.time}`
                showModalEvent.value = true
            },
            saveEvent: async () => {
                validation.event = ''
                if (!modalEventEvent.value) {
                    validation.event = 'Masukkan nama event'
                    return
                }

                const t = loading('Menyimpan')
                const r = await Api.post(`daily-schedule/time/event/update/${modalEventTime.value!.id}`, {
                    event: modalEventEvent.value
                })
                t.remove()
                if (r.success) {
                    schedule.value.daily_schedule_times = (r.data as IDailySchedule).daily_schedule_times
                    closeModalEvent()
                }
                else {
                    error(r.message ?? 'gagal')
                }
            },
            deleteEvent: async () => {
                if (confirm('Delete event?')) {
                    const t = loading('Menghapus')
                    const r = await Api.delete(`daily-schedule/time/event/delete/${modalEventTime.value!.id}`)
                    t.remove()
                    if (r.success) {
                        schedule.value.daily_schedule_times = (r.data as IDailySchedule).daily_schedule_times
                        closeModalEvent()
                    }
                    else {
                        error(r.message ?? 'gagal')
                    }
                }
            }
        }

        const closeModalTime = () => {
            showModalAddHours.value = false
            modalDay.value = ''
            validation.time = ''
            modalAddHours.value = ''
            modalEditedId.value = null
        }
        
        const closeModalSubject = () => {
            modalSubjectId.value = 0
            showModalSubject.value = false
        }

        const closeModalEvent = () => {
            modalEventEvent.value = ''
            modalEventTime.value = null
            showModalEvent.value = false
        }

        return {
            done, schedule, showModalAddHours, modalAddHours,
            back: () => router.back(), validation, refSubject,
            refGrade, groupBy, compRefGrade, modalTimeTitle,
            ...timeEditing,
            closeModalTime, modalEditedId, showModalSubject, closeModalSubject, modalSubjectTitle, modalSubjectId,
            mondayTimes, tuesdayTimes, wednesdayTimes, thursdayTimes, fridayTimes,
            ...subjectEditing, ...eventEditing, showModalEvent, closeModalEvent, modalEventTitle, modalEventEvent, modalEventTime
        }
    }
})
</script>

<style scoped>
.a {
    text-decoration: underline;
    cursor: pointer;
    color: #1572e8;
}
td {
    text-align: center;
}
.table td, .table th {
    height: auto !important;
    padding-top: 8px !important;
    padding-bottom: 6px !important;
}
.modal-body {
    overflow: visible !important;
}
</style>

<style>
.multiselect-dropdown {
    z-index: 100000000;
}
.multiselect--active {
    z-index: 10000000;
}
</style>