<template>
    <tr v-for="(t, k) in times">
        <td v-if="k === 0 || t.first" :rowspan="times.length ? times.length : 1">
            <b>{{ day }}</b>
            <span class="btn btn-success btn-sm ml-2" @click="addTime(day)"><i class="fa fa-clock"></i> </span>
        </td>
        <td>
            <div v-if="!t.first">
                <span>{{ t.time ?? '-' }}</span>
                <span class="btn btn-success btn-sm ml-2" @click="editEvent(t)"><i class="fa fa-flag"></i> </span>
                <span class="btn btn-warning btn-sm ml-2" @click="editTime(t.id, t.time ?? '')"><i class="fa fa-pencil"></i> </span>
                <span class="btn btn-danger btn-sm ml-2" @click="deleteTime(t.id)"><i class="fa fa-trash"></i> </span>
            </div>
        </td>
        <td v-if="!t.event" v-for="g in refGrade">
            {{ t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_name ?? '' }}
            <span v-if="!t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_name && !t.first"
                  @click="addSubject(t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.id ?? 0)"
                  class="btn btn-sm btn-success">
                <i class="fa fa-plus"></i>
            </span>
            <span v-else-if="!t.first" class="btn btn-sm btn-warning ml-2"
                  @click="editSubject(t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.id ?? 0, t.daily_schedule_subjects?.find(x => x.grade_id === g.id)?.subject_id ?? 0)">
                <i class="fa fa-pencil"></i>
            </span>
        </td>
        <td v-else :colspan="refGrade.length" class="bg-eaea">
            {{ t.event ?? '' }}
        </td>
    </tr>
</template>

<script lang="ts">
import {defineComponent, PropType, watch} from "vue";
import {IDailyScheduleTime} from "@/utils/interface/DailySchedule";
import {Kelas} from "@/utils/Models";

export default defineComponent({
    name: "DailyScheduleTime",
    props: {
        times: Object as PropType<IDailyScheduleTime[]>,
        day: String,
        refGrade: Object as PropType<Kelas[]>,

        editEvent: Function as PropType<(t: IDailyScheduleTime) => void>,

        addTime: Function as PropType<(s: string) => void>,
        editTime: Function as PropType<(id: number, time: string) => void>,
        deleteTime: Function as PropType<(id: number) => void>,

        addSubject: Function as PropType<() => void>,
        editSubject: Function as PropType<(id: number, subject_id: number) => void>,
        deleteSubject: Function as PropType<(id: number) => void>,
    },
})
</script>

<style scoped>
td {
    text-align: center;
}
.table td, .table th {
    height: auto !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.btn-sm {
    font-size: 10px;
    color: white !important;
    padding: 4px 8px;
    border: 0 solid !important;
}
.btn-success {
    background: linear-gradient(to right, #01bc28, #3ad75b) !important;
}
.btn-warning {
    background: linear-gradient(to right, #FFA325, #fabd69) !important;
}
.btn-danger {
    background: linear-gradient(to right, #ff3ca7, #fa7dbe) !important;
}
.bg-eaea {
    background: linear-gradient(to right, #eff3ef, #deeee5) !important;
    font-weight: bold;
}
</style>