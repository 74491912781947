import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttendanceOptions = _resolveComponent("AttendanceOptions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["circle", `circle-${_ctx.status}`]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
    }, [
      _createElementVNode("b", null, _toDisplayString(_ctx.status.toUpperCase()), 1)
    ], 2),
    (_openBlock(), _createBlock(_component_AttendanceOptions, {
      show: _ctx.show,
      top: _ctx.top,
      left: _ctx.left,
      key: _ctx.key,
      statuses: _ctx.statuses,
      onChoose: _ctx.onChoose
    }, null, 8, ["show", "top", "left", "statuses", "onChoose"]))
  ], 64))
}