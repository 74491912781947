<template>
    <div class="card mb-2 assessment-item">
        <div class="card-body">
            <div class="d-flex align-items-center mb-2">
                <img class="avatar-img rounded-circle" style="width: 35px; height: 35px" :src="getPhoto(assignment.student_photo)">
                <span class="fw-bold ml-2" style="flex: 1">{{ assignment.student_name }}</span>
                <span v-if="assignment.status === 'done'" class="badge badge-success">Scored</span>
            </div>
            <div v-if="assignment.submitted_at === null">
                <span class="text-danger">
                    The student has not been submitted an answer yet.
                </span>
            </div>
            <div v-else>
                <div v-if="showAnswer" class="mb-2">
                    <div v-if="assignment.answer">
                        <b>Answer</b>
                        <div v-html="assignment.answer"></div>
                    </div>
                    <div v-if="assignment.link">
                        <b>Link</b>
                        <a class="d-block" :href="assignment.link" target="_blank">{{ assignment.link }}</a>
                    </div>
                    <div v-if="assignment.attachments?.length">
                        <b>Attachments ({{ assignment.attachments.length }} file)</b>
                        <a v-for="a in assignment.attachments" :href="a.url" class="d-block">{{ a.file_name }}</a>
                    </div>
                </div>
                <span class="pointer underline text-success" @click="showAnswer = !showAnswer">
                    {{ showAnswer ? 'Hide Answer' : 'Show Answer' }}
                </span>
            </div>
            <div class="my-2" style="background: #eee"></div>
            <div class="d-flex align-items-center">
                <div class="form-group pl-0 py-0" style="flex: 1">
                    <input type="number" v-model="assignment.score" class="form-control form-control-sm" :readonly="assignment.saving" placeholder="Score">
                </div>
                <button v-if="!done" class="btn btn-sm btn-success" :disabled="assignment.saving" @click="save">
                    <i class="fa fa-save"></i>
                </button>
            </div>
            <div class="d-flex align-items-center mt-2">
                <div class="form-group pl-0 py-0" style="flex: 1">
                    <input type="text" v-model="assignment.note" class="form-control form-control-sm" :readonly="assignment.saving_note" placeholder="Note">
                </div>
                <button v-if="!done" class="btn btn-sm btn-success" :disabled="assignment.saving_note" @click="saveNote">
                    <i class="fa fa-save"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {IAssignmentStudent} from "@/utils/interface/Assignment";
import {getPhoto, toIndoDateNameTime} from "@/utils/Helpers";
import {error, success} from "@/utils/Toast";
import Api from "@/utils/Api";

export default defineComponent({
    name: "EvaluationMobile",
    props: {
        assignment: Object as PropType<IAssignmentStudent>,
        done: {
            type: Boolean,
            default: false,
        }
    },
    emit: ['onChange'],
    setup(props, {emit}) {
        const showAnswer = ref(false)
        const v = props.assignment!

        const save = async () => {
            if (v.score === undefined) {
                error('Nilai belum diinput')
                return
            }

            v.saving = true
            const r = await Api.post(`assignment/score/${v.id}/${v.score}`, {})
            if (r.success) {
                success('Berhasil menyimpan nilai')
                emit('onChange', r.data as IAssignmentStudent)
            }
            else {
                error(r.message ?? 'Gagal menyimpan nilai')
            }
            v.saving = false
        }

        const saveNote = async () => {
            if (!v.note) {
                error('Note belum diinput')
                return
            }

            v.saving_note = true
            const r = await Api.post(`assignment/note/${v.id}`, {note: v.note})
            if (r.success) {
                success('Berhasil menyimpan note')
                emit('onChange', r.data as IAssignmentStudent)
            }
            else {
                error(r.message ?? 'Gagal menyimpan note')
            }
            v.saving_note = false
        }

        return {
            toIndoDateNameTime, getPhoto, showAnswer, save, saveNote
        }
    }
})
</script>

<style scoped>
.assessment-item {
    margin: 10px auto;
    width: 100%;
    max-width: 750px;
}
</style>